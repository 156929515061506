export const YELLOW = 'Yellow'
export const GREEN = 'Green'
export const BLUE = 'Blue'
export const TEAL = 'Teal'
export const PINK = 'Pink'
export const RED = 'Red'
export const PURPLE = 'Purple'
export const BLACK = 'Black'
export const WHITE = 'White'

export const YELLOW_BRISBANE = 'Yellow'
export const TEAL_BRISBANE = 'Teal'
export const BLUE_BRISBANE = 'Blue'
export const PURPLE_BRISBANE = 'Purple'
export const GREEN_BRISBANE = 'Green'
export const PINK_BRISBANE = 'Pink'
export const RED_BRISBANE = 'Red'
export const BLACK_BRISBANE = 'Black'
export const WHITE_BRISBANE = 'White'

export const sydneyPlans = [
  {
    plan: YELLOW,
    description:
      'This plan has been designed to help new climbers get the most out of the 9 Degrees gyms. The focus is on consolidating various drills and introducing some variety into boulder sessions. Great for someone that is getting into bouldering and looking to improve quickly.',
  },
  {
    plan: GREEN,
    description:
      'A plan that focuses on basic drills and 3 different styles of boulder sessions. This plan will suit an entry level climber.',
  },
  {
    plan: BLUE,
    description:
      'This plan is vary similar to the Yellow and Green. The focus is on drills and various boulder sessions. Great for someone that is finding it hard to break into the next level.',
  },
  {
    plan: TEAL,
    description:
      "It offers a variety of workouts with the main focus being drills and increased volume. It also introduces some optional strength and conditioning workouts. Each week you'll be building on the previous.",
  },
  {
    plan: PINK,
    description:
      'A plan that focuses on consolidating 4 types of drills and explores 3 different boulder workouts. This plan also has some some optional strength and conditioning workouts.',
  },
  {
    plan: RED,
    description:
      'This level can be hard to break through but this plan has been designed to push you as a climber both in skill and in volume. It has a selection of drills, boulder workouts and strength and conditioning workouts.',
  },
  {
    plan: PURPLE,
    description:
      "With this plan you'll work through a selection of drills, strength and conditioning workouts, plus specific boulder workouts. A plan that aims to push skill, volume and intensity.",
  },
  {
    plan: WHITE,
    description:
      'The hardest of the 9 Degree plans. This plan is aimed to push even the strongest climbers. Lots of volume and intensity balanced with the opportunity to practice the craft of bouldering hard.',
  },
]

export const brisbanePlans = [
  {
    plan: YELLOW_BRISBANE,
    description:
      'This plan has been designed to help new climbers get the most out of the 9 Degrees gyms. The focus is on consolidating various drills and introducing some variety into boulder sessions. Great for someone that is getting into bouldering and looking to improve quickly.',
  },
  {
    plan: TEAL_BRISBANE,
    description:
      'A plan that focuses on basic drills and 3 different styles of boulder sessions. This plan will suit an entry level climber.',
  },
  {
    plan: BLUE_BRISBANE,
    description:
      'This plan is vary similar to the Yellow and Green. The focus is on drills and various boulder sessions. Great for someone that is finding it hard to break into the next level.',
  },
  {
    plan: PURPLE_BRISBANE,
    description:
      "It offers a variety of workouts with the main focus being drills and increased volume. It also introduces some optional strength and conditioning workouts. Each week you'll be building on the previous.",
  },
  {
    plan: GREEN_BRISBANE,
    description:
      'A plan that focuses on consolidating 4 types of drills and explores 3 different boulder workouts. This plan also has some some optional strength and conditioning workouts.',
  },
  {
    plan: PINK_BRISBANE,
    description:
      'This level can be hard to break through but this plan has been designed to push you as a climber both in skill and in volume. It has a selection of drills, boulder workouts and strength and conditioning workouts.',
  },
  {
    plan: RED_BRISBANE,
    description:
      "With this plan you'll work through a selection of drills, strength and conditioning workouts, plus specific boulder workouts. A plan that aims to push skill, volume and intensity.",
  },
  {
    plan: BLACK_BRISBANE,
    description:
      'The hardest of the 9 Degree plans. This plan is aimed to push even the strongest climbers. Lots of volume and intensity balanced with the opportunity to practice the craft of bouldering hard.',
  },
  {
    plan: WHITE_BRISBANE,
    description:
      'The hardest of the 9 Degree plans. This plan is aimed to push even the strongest climbers. Lots of volume and intensity balanced with the opportunity to practice the craft of bouldering hard.',
  },
]
