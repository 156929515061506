import { useNavigate } from 'react-router-dom'
import { Button, Box, Heading, HStack, Icon, Image, Stack, Text } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { useQuery } from 'hooks'

import { useTemplatePlan } from 'domain/Plans/hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import Notify from 'components/Notification'

import logo from './assets/sequence-logo-white.png'

const WelcomePlanApply = () => {
  const navigate = useNavigate()
  const { refetch } = useTemplatePlan()
  const { data, isLoading, hasError } = useQuery('api/v1/purchases')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { code } = data[0]

  const handleApplyPlan = async () => {
    await refetch({ add: 'next_week', behaviour: 'overwrite', lookup_code: code })

    Notify({
      content: 'New training plan has been applied',
      type: 'success',
    })

    navigate('/planner')
  }

  return (
    <Box height="100vh" bg="brand.500" py="12" overflow="auto">
      <Box maxWidth="840px" width="100%" px="4" margin="auto">
        <Stack alignItems="center" spacing="8">
          <Image src={logo} alt="Logo" width="60px" height="auto" />
          <Heading
            textAlign="center"
            size="xl"
            color="white"
            fontWeight="thin"
            textTransform="uppercase"
            letterSpacing="widest"
          >
            Set Up Your Sequence Account
          </Heading>

          <Box textAlign="center" color="white" maxWidth="500px">
            <Text fontSize="lg" fontWeight="bold">
              Do you want to start your plan now?
            </Text>

            <Text mt="2">
              You can either load your plan into Sequence now or via the dedicated plan section
              within Sequence at any time.
            </Text>
            <Text color="white" mt="2">
              If you are unsure we would recommending applying it now.
            </Text>
          </Box>
        </Stack>

        <Box mt="8" mb="20" align="center">
          <Button
            background="white"
            letterSpacing="wide"
            color="brand.500"
            _hover={{ background: 'brand.600', color: 'white' }}
            onClick={handleApplyPlan}
          >
            Apply plan
          </Button>
        </Box>

        <Box></Box>

        <HStack spacing="3" justifyContent="flex-end">
          <Text color="white" fontSize="small">
            (2 of 2)
          </Text>

          <Button
            background="none"
            borderWidth="1px"
            borderColor="white"
            letterSpacing="wide"
            color="white"
            _hover={{ background: 'brand.600', color: 'white' }}
            onClick={() => navigate('/dashboard')}
          >
            Continue and apply plan later
            <Icon fontWeight="semibold" boxSize={4} ml="2" as={FaArrowRight} />
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}
export default WelcomePlanApply
