import React, { useState } from 'react'
import { Flex, Box, Text, Table, Thead, Tbody, Tr, Th, Td, useDisclosure } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import ActivityModal from 'domain/Planner/components/ActivityModal'
import { tzSafeDateFormat } from 'helpers/utils'

const WorkoutMetricsList = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [scheduledActivityId, setScheduledActivityId] = useState(null)

  const { data, isLoading, hasError } = useQuery(`api/v1/activities/${id}/metrics/list`)

  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(data).length) {
    return null
  }

  const handleDateClick = ({ scheduledActivityId }) => {
    setScheduledActivityId(scheduledActivityId)
    onOpen()
  }

  const hoverProps = { cursor: 'pointer', color: 'brand.500' }

  return (
    <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
      <Box maxWidth="670px" margin="0 auto" px={{ base: '10', md: '0' }}>
        <Flex direction="column">
          <Box>
            <Text fontSize="large">Completed Workouts</Text>
            <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
              All Time
            </Text>
          </Box>
        </Flex>

        <Table size="sm" variant="striped" mt="6">
          <Thead>
            <Tr>
              <Th width="120px">Date</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => {
              const formattedDate = tzSafeDateFormat(item.scheduled_at, 'd MMM yyyy')

              return (
                <Tr key={item.id}>
                  <Td
                    onClick={() => {
                      handleDateClick({
                        scheduledActivityId: item.id,
                      })
                    }}
                    style={{ textAlign: 'right' }}
                    _hover={hoverProps}
                  >
                    {formattedDate}
                  </Td>
                  <Td>{item.notes}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
      {isOpen && <ActivityModal id={scheduledActivityId} isOpen={isOpen} onClose={onClose} />}
    </Box>
  )
}

export default WorkoutMetricsList
