import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Badge,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Input,
  InputGroup,
  InputRightAddon,
  Textarea,
  Button,
  ButtonGroup,
  Tooltip,
  HStack,
  Text,
  Divider,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import Modal from 'components/Modal'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import { processGetError, processApiError } from 'helpers/utils'

const MENSTRUAL_STYLE = 'menstrual_start'

const DailyMetricsSettings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [biometricsData, setBiometricsData] = useState([])
  const [menstrualOptions, setMenstrualOptions] = useState([])
  const [editingId, setEditingId] = useState()
  const [editing, setEditing] = useState(false)
  const [createBiometric, setCreateBiometric] = useState(false)
  const [removeId, setRemoveId] = useState()
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [menstrualCycleTracking, setMenstrualCycleTracking] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    securedApi
      .get(`api/v1/biometric_attributes`)
      .then((response) => {
        // because of nested object need to pasrse the response manually...
        setBiometricsData(JSON.parse(response.request.response))

        const checkDataForMenstrualCycle = response.data.find(
          (metric) => metric.style === MENSTRUAL_STYLE
        )

        setMenstrualCycleTracking(!!checkDataForMenstrualCycle)

        if (!!checkDataForMenstrualCycle) {
          setMenstrualOptions(checkDataForMenstrualCycle.options)
        }
      })
      .catch((error) => processGetError(error))
  }

  const onSubmit = (data) => {
    securedApi
      .post(`api/v1/biometric_attributes`, {
        name: data.createName,
        units: data.createUnits,
        description: data.createDescription,
      })
      .then(() => {
        getData()
        setCreateBiometric(false)
        Notify({ content: 'Daily metric created', type: 'success' })
      })
      .catch((error) => {
        if (error.response.data.name) {
          const errorMessage = error.response.data.name[0]
          Notify({
            content: `Name ${errorMessage}, please try a different name.`,
            type: 'error',
          })
        } else {
          processApiError(error)
        }
      })
  }

  const onUpdate = (data) => {
    securedApi
      .patch(`/api/v1/biometric_attributes/${editingId}`, {
        ...data,
        options: {
          menstrualDuration: data.menstrualDuration,
          menstrualPeriodLength: data.menstrualPeriodLength,
          menstrualOvulationDay: data.menstrualOvulationDay,
        },
      })
      .then(() => {
        endEditing()
        getData()
        Notify({ content: 'Biometric updated', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  const onRemove = () => {
    securedApi
      .delete(`/api/v1/biometric_attributes/${removeId}`)
      .then(() => {
        getData()
        Notify({ content: 'Daily metric removed', type: 'success' })
      })
      .catch((error) => processApiError(error))

    setRemoveId(null)
    setRemoveModalOpen(false)
  }

  const toggleTrackingMenstrualCycles = () => {
    securedApi
      .post(`api/v1/biometric_attributes`, {
        name: 'Menstrual Cycle',
        style: MENSTRUAL_STYLE,
        options: { menstrualDuration: 28, menstrualPeriodLength: 5 },
      })
      .then(() => {
        getData()
        setMenstrualCycleTracking(!menstrualCycleTracking)
        Notify({
          content: 'Menstrual cycle tracking enabled',
          type: 'success',
        })
      })
      .catch((error) => processApiError(error))
  }

  const handleRemoveBiometric = (id) => {
    setRemoveId(id)
    setRemoveModalOpen(true)
  }

  const handleEdit = (id) => {
    setCreateBiometric(false)
    setEditing(true)
    setEditingId(id)
  }

  const handleCancelEdit = () => {
    endEditing()
    reset()
  }

  const handleCreateBiometric = () => {
    endEditing()
    setCreateBiometric(true)
  }

  const endEditing = () => {
    setEditing(false)
    setEditingId(null)
  }

  const renderRemoveModal = () => (
    <Modal
      isOpen={removeModalOpen}
      closeModal={() => setRemoveModalOpen(false)}
      onAccept={() => onRemove()}
      title="Are you sure?"
      subTitle={
        <Text>
          This will also <b>remove all associated records</b> and can't be undone
        </Text>
      }
    />
  )

  const renderBiometricsData = () => {
    if (!biometricsData.length) {
      return <Box marginBottom="4">You currently don't have any biometrics.</Box>
    }

    const MenstrualOptionField = ({ label, field, tooltip, isRequired, units }) => {
      return (
        <Box width="150px">
          <Tooltip label={tooltip}>
            <FormControl id={field} marginBottom="4" isRequired={isRequired}>
              <FormLabel fontSize="sm">{label}</FormLabel>
              <InputGroup>
                <Input
                  width="55px"
                  defaultValue={menstrualOptions[field]}
                  type="number"
                  {...register(field)}
                />
                {units && <InputRightAddon children={units} />}
              </InputGroup>
            </FormControl>
          </Tooltip>
        </Box>
      )
    }

    return biometricsData.map((data) => {
      data.options = Object.values(data.options)

      const isMenstrualCycle = data.style === MENSTRUAL_STYLE

      if (editingId === data.id) {
        return (
          <Box key={data.name} marginBottom="10">
            <form onSubmit={handleSubmit(onUpdate)}>
              {isMenstrualCycle ? (
                <>
                  <Flex flexDirection="column" gap={2}>
                    <Box>
                      <FormLabel fontWeight="semibold">Menstrual Cycle</FormLabel>
                    </Box>
                    <Box width="50%">
                      <Text fontSize="sm">
                        Enter the most common values that apply to you. If you encounter variations
                        and different experiences you can mark them on your planner as they occur,
                        e.g. if you start ovulating a day early.
                      </Text>
                    </Box>
                    <Flex gap={4}>
                      <MenstrualOptionField
                        label="Duration of Cycle"
                        field="menstrualDuration"
                        tooltip="This is used to provide a prediction of your
                          future cycles beyond any that you record, so that you
                          can plan your upcoming schedule accordingly 🗓️"
                        units="days"
                        isRequired
                      />
                      <MenstrualOptionField
                        label="Period Length"
                        field="menstrualPeriodLength"
                        tooltip="This is used to categorise the different stages
                          of your cycle, so that you can optimise your planning
                          and performance 💪"
                        units="days"
                        isRequired
                      />
                      <MenstrualOptionField
                        label="Ovulation Day"
                        field="menstrualOvulationDay"
                        tooltip="Defaults to the middle of your cycle, but you can specify a day if you want"
                        units="#"
                      />
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  <HStack spacing="24px">
                    <Box>
                      <FormControl id="name" isInvalid={errors.name} marginBottom="4" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input defaultValue={data.name} {...register('name', { required: true })} />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl id="units" marginBottom="4">
                        <FormLabel>Units</FormLabel>
                        <Input defaultValue={data.units} {...register('units')} />
                      </FormControl>
                    </Box>
                  </HStack>

                  <FormControl id="description" marginBottom="4">
                    <FormLabel>Description</FormLabel>
                    <Textarea defaultValue={data.description} {...register('description')} />
                  </FormControl>
                </>
              )}

              <ButtonGroup spacing="4" marginTop="4" display="flex" justifyContent="flex-end">
                <Button size="xs" variant="ghost" onClick={() => handleCancelEdit()}>
                  Cancel
                </Button>
                <Button size="xs" type="submit" colorScheme="yellow" color="white">
                  Update
                </Button>
              </ButtonGroup>
            </form>
          </Box>
        )
      }

      return (
        <Box key={data.name} marginBottom="10">
          <HStack spacing="24px">
            <Box width="400px">
              {isMenstrualCycle ? (
                <>
                  <Text display="inline" fontWeight="semibold">
                    {data.name}
                  </Text>
                  <Badge ml="2">{menstrualOptions.menstrualDuration} days</Badge>
                </>
              ) : (
                <>
                  <Text display="inline" fontWeight="semibold">
                    {data.name}
                  </Text>
                  {data.units && <Badge ml="2">{data.units}</Badge>}
                  <Text fontSize="sm">{data.description}</Text>
                </>
              )}
            </Box>

            {!editing && (
              <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
                <Button size="xs" onClick={() => handleEdit(data.id)}>
                  Edit
                </Button>
                <Button size="xs" variant="ghost" onClick={() => handleRemoveBiometric(data.id)}>
                  Remove
                </Button>
              </ButtonGroup>
            )}
          </HStack>
        </Box>
      )
    })
  }

  const renderCreateForm = () => {
    return (
      <Box marginBottom="10" paddingBottom="4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <HStack spacing="24px">
            <Box>
              <FormControl id="name" isInvalid={errors.createName} marginBottom="4" isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Weight"
                  defaultValue=""
                  {...register('createName', { required: true })}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl id="units" marginBottom="4">
                <FormLabel>Units</FormLabel>
                <Input placeholder="kg" {...register('createUnits')} />
              </FormControl>
            </Box>
          </HStack>

          <FormControl id="description" marginBottom="4">
            <FormLabel>Description</FormLabel>
            <Textarea {...register('createDescription')} />
          </FormControl>

          <ButtonGroup spacing="4" marginTop="4" display="flex" justifyContent="flex-end">
            <Button size="xs" variant="ghost" onClick={() => setCreateBiometric(false)}>
              Cancel
            </Button>
            <Button size="xs" type="submit" colorScheme="yellow" color="white">
              Create
            </Button>
          </ButtonGroup>
        </form>
      </Box>
    )
  }

  const TrackMenstrualCycleButton = () => {
    if (menstrualCycleTracking) {
      return null
    }

    return (
      <Box marginTop="6">
        <Button
          display="flex"
          marginTop="6"
          variant="primary"
          size="xs"
          onClick={toggleTrackingMenstrualCycles}
        >
          Track Menstrual Cycle
        </Button>
      </Box>
    )
  }

  const InstructionsHeader = () => (
    <Box fontSize="small" mb="6">
      <Text>
        Here you can setup daily metric attributes that you might want to record on any day in your
        calendar.
      </Text>
    </Box>
  )

  const InstructionsFooter = () => {
    if (biometricsData.length) {
      return (
        <>
          <Divider my="6" />
          <Box>
            <Text fontSize="small">
              Enter values for these metrics using the quick add menu on the relevant day in your
              calendar.{' '}
              {menstrualCycleTracking && (
                <>
                  This is also where you record a day as the start of your cycle, then your current
                  and predicted cycle days will be displayed.
                </>
              )}
            </Text>
          </Box>
        </>
      )
    }
  }

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions>
        <Link
          href="https://docs.sequence-app.com/features/daily-metrics"
          isExternal
          variant="brand"
          fontWeight="bold"
        >
          Learn more about daily metrics <ExternalLinkIcon />
        </Link>
      </InnerPageWrapperActions>

      <Box marginBottom="20">
        <Box background="white">
          <Box padding="4">
            <Text fontWeight="medium" fontSize="large" color="black">
              Configure daily metrics
            </Text>

            <InstructionsHeader />

            {renderBiometricsData()}

            {createBiometric && renderCreateForm()}

            {!createBiometric && (
              <Button size="xs" variant="primary" onClick={() => handleCreateBiometric()}>
                Create Metric
              </Button>
            )}

            <TrackMenstrualCycleButton />

            <InstructionsFooter />

            {removeModalOpen && renderRemoveModal()}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default DailyMetricsSettings
