import { Badge, Tooltip } from '@chakra-ui/react'

const AdminBanner = ({ impersonateData }) => {
  return (
    <Tooltip label={impersonateData.impersonatingUserEmail}>
      <Badge colorScheme="red" width="fit-content">
        {impersonateData.impersonatingUserName}
      </Badge>
    </Tooltip>
  )
}

export default AdminBanner
