import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Container, Flex, Stack, Spacer, Text, Link } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import ClientSection from './Components/ClientSection'
import { sortClientsByType } from './helpers'
import AllClientComments from './Components/AllClientComments'

const CoachingDashboard = () => {
  const { data, isLoading, hasError } = useQuery(
    `/api/v1/coaching/connections?list_clients=true&metrics=true`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { activeClients } = sortClientsByType(data)

  const hasClients = data.length > 0
  const hasActiveClients = activeClients.length > 0

  const renderZeroState = () => {
    if (hasClients) {
      return null
    }
    return (
      <>
        <Text color="gray.500" textAlign="center">
          You currently haven't added any clients.
          <br />
          Add your first{' '}
          <Link as={NavLink} to={`/coaching/clients`}>
            client
          </Link>{' '}
          to get started.
        </Text>
      </>
    )
  }

  const renderZeroActiveClients = () => {
    if (hasActiveClients || !hasClients) {
      return null
    }
    return (
      <>
        <Text color="gray.500" textAlign="center">
          You current don't have any active clients. <br />
          Once you do, your dashboard will be filled with a range of great info.
        </Text>
        <Text color="gray.500" textAlign="center">
          Manage your{' '}
          <Link as={NavLink} to={`/coaching/clients`}>
            clients
          </Link>
          .
        </Text>
      </>
    )
  }

  return (
    <Container maxW="container.xl" py="6" mb="28">
      <Stack spacing="4">
        {renderZeroState()}
        {renderZeroActiveClients()}

        <Flex>
          <Box display="block" margin="0 auto" width="100%" maxW="600px">
            <ClientSection data={activeClients} showStats />
          </Box>
          <Box display="block" maxW="sm" mx="auto" width="100%">
            {hasActiveClients && <AllClientComments />}
          </Box>
        </Flex>
      </Stack>
    </Container>
  )
}

export default CoachingDashboard
