import React from 'react'

import MetricsContainer from './MetricsContainer'
import DailyMetricsContent from './DailyMetricsContent'

const DailyMetrics = () => {
  return (
    <MetricsContainer>
      <DailyMetricsContent />
    </MetricsContainer>
  )
}

export default DailyMetrics
