import React from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

import { useQuery } from 'hooks'

import Select from 'components/Select'
import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'

const NoteGoalLinker = ({ control, defaultValues }) => {
  const { data, isLoading, hasError } = useQuery('/api/v1/goals')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  //convert data to format for react-select
  const options = data.map((goal) => ({
    value: goal.id,
    label: goal.title,
  }))

  return (
    <FormControl>
      <FormLabel fontSize="sm" mb="0" mr="0">
        Link To Goal(s)
        <Controller
          name="goals"
          {...{ control, defaultValues }}
          render={({ field }) => (
            <Select defaultValue={defaultValues} isMulti {...field} {...{ options }} />
          )}
        />
      </FormLabel>
    </FormControl>
  )
}
export default NoteGoalLinker
