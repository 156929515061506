import React from 'react'
import { Select } from '@chakra-ui/react'
import { Globe } from 'react-feather'

const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone
const currentCountry = currentZone && currentZone.split('/')[0]

const timezones = [
  { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { label: '(GMT-11:00) American Samoa', value: 'Pacific/Pago_Pago' },
  { label: '(GMT-11:00) Midway Island', value: 'Pacific/Midway' },
  { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { label: '(GMT-09:00) Alaska', value: 'America/Juneau' },
  {
    label: '(GMT-08:00) Pacific Time (US & Canada)',
    value: 'America/Los_Angeles',
  },
  { label: '(GMT-08:00) Tijuana', value: 'America/Tijuana' },
  { label: '(GMT-07:00) Arizona', value: 'America/Phoenix' },
  { label: '(GMT-07:00) Chihuahua', value: 'America/Chihuahua' },
  { label: '(GMT-07:00) Mazatlan', value: 'America/Mazatlan' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'America/Denver' },
  { label: '(GMT-06:00) Central America', value: 'America/Guatemala' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'America/Chicago' },
  { label: '(GMT-06:00) Guadalajara', value: 'America/Mexico_City' },
  { label: '(GMT-06:00) Mexico City', value: 'America/Mexico_City' },
  { label: '(GMT-06:00) Monterrey', value: 'America/Monterrey' },
  { label: '(GMT-06:00) Saskatchewan', value: 'America/Regina' },
  { label: '(GMT-05:00) Bogota', value: 'America/Bogota' },
  {
    label: '(GMT-05:00) Eastern Time (US & Canada)',
    value: 'America/New_York',
  },
  {
    label: '(GMT-05:00) Indiana (East)',
    value: 'America/Indiana/Indianapolis',
  },
  { label: '(GMT-05:00) Lima', value: 'America/Lima' },
  { label: '(GMT-05:00) Quito', value: 'America/Lima' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'America/Halifax' },
  { label: '(GMT-04:00) Caracas', value: 'America/Caracas' },
  { label: '(GMT-04:00) Georgetown', value: 'America/Guyana' },
  { label: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
  { label: '(GMT-04:00) Puerto Rico', value: 'America/Puerto_Rico' },
  { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
  { label: '(GMT-03:30) Newfoundland', value: 'America/St_Johns' },
  { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  {
    label: '(GMT-03:00) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
  { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'Atlantic/South_Georgia' },
  { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { label: '(GMT+00:00) Edinburgh', value: 'Europe/London' },
  { label: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon' },
  { label: '(GMT+00:00) London', value: 'Europe/London' },
  { label: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia' },
  { label: '(GMT+00:00) UTC', value: 'Etc/UTC' },
  { label: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam' },
  { label: '(GMT+01:00) Belgrade', value: 'Europe/Belgrade' },
  { label: '(GMT+01:00) Berlin', value: 'Europe/Berlin' },
  { label: '(GMT+01:00) Bern', value: 'Europe/Zurich' },
  { label: '(GMT+01:00) Bratislava', value: 'Europe/Bratislava' },
  { label: '(GMT+01:00) Brussels', value: 'Europe/Brussels' },
  { label: '(GMT+01:00) Budapest', value: 'Europe/Budapest' },
  { label: '(GMT+01:00) Casablanca', value: 'Africa/Casablanca' },
  { label: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen' },
  { label: '(GMT+01:00) Dublin', value: 'Europe/Dublin' },
  { label: '(GMT+01:00) Ljubljana', value: 'Europe/Ljubljana' },
  { label: '(GMT+01:00) Madrid', value: 'Europe/Madrid' },
  { label: '(GMT+01:00) Paris', value: 'Europe/Paris' },
  { label: '(GMT+01:00) Prague', value: 'Europe/Prague' },
  { label: '(GMT+01:00) Rome', value: 'Europe/Rome' },
  { label: '(GMT+01:00) Sarajevo', value: 'Europe/Sarajevo' },
  { label: '(GMT+01:00) Skopje', value: 'Europe/Skopje' },
  { label: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm' },
  { label: '(GMT+01:00) Vienna', value: 'Europe/Vienna' },
  { label: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw' },
  { label: '(GMT+01:00) West Central Africa', value: 'Africa/Algiers' },
  { label: '(GMT+01:00) Zagreb', value: 'Europe/Zagreb' },
  { label: '(GMT+01:00) Zurich', value: 'Europe/Zurich' },
  { label: '(GMT+02:00) Athens', value: 'Europe/Athens' },
  { label: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest' },
  { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { label: '(GMT+02:00) Harare', value: 'Africa/Harare' },
  { label: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki' },
  { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { label: '(GMT+02:00) Kaliningrad', value: 'Europe/Kaliningrad' },
  { label: '(GMT+02:00) Kyiv', value: 'Europe/Kiev' },
  { label: '(GMT+02:00) Pretoria', value: 'Africa/Johannesburg' },
  { label: '(GMT+02:00) Riga', value: 'Europe/Riga' },
  { label: '(GMT+02:00) Sofia', value: 'Europe/Sofia' },
  { label: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn' },
  { label: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius' },
  { label: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad' },
  { label: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul' },
  { label: '(GMT+03:00) Kuwait', value: 'Asia/Kuwait' },
  { label: '(GMT+03:00) Minsk', value: 'Europe/Minsk' },
  { label: '(GMT+03:00) Moscow', value: 'Europe/Moscow' },
  { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { label: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh' },
  { label: '(GMT+03:00) St. Petersburg', value: 'Europe/Moscow' },
  { label: '(GMT+03:00) Volgograd', value: 'Europe/Volgograd' },
  { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { label: '(GMT+04:00) Abu Dhabi', value: 'Asia/Muscat' },
  { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { label: '(GMT+04:00) Muscat', value: 'Asia/Muscat' },
  { label: '(GMT+04:00) Samara', value: 'Europe/Samara' },
  { label: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi' },
  { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { label: '(GMT+05:00) Ekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: '(GMT+05:00) Islamabad', value: 'Asia/Karachi' },
  { label: '(GMT+05:00) Karachi', value: 'Asia/Karachi' },
  { label: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent' },
  { label: '(GMT+05:30) Chennai', value: 'Asia/Kolkata' },
  { label: '(GMT+05:30) Kolkata', value: 'Asia/Kolkata' },
  { label: '(GMT+05:30) Mumbai', value: 'Asia/Kolkata' },
  { label: '(GMT+05:30) New Delhi', value: 'Asia/Kolkata' },
  { label: '(GMT+05:30) Sri Jayawardenepura', value: 'Asia/Colombo' },
  { label: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
  { label: '(GMT+06:00) Almaty', value: 'Asia/Almaty' },
  { label: '(GMT+06:00) Astana', value: 'Asia/Dhaka' },
  { label: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka' },
  { label: '(GMT+06:00) Urumqi', value: 'Asia/Urumqi' },
  { label: '(GMT+06:30) Rangoon', value: 'Asia/Rangoon' },
  { label: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Hanoi', value: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta' },
  { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: '(GMT+07:00) Novosibirsk', value: 'Asia/Novosibirsk' },
  { label: '(GMT+08:00) Beijing', value: 'Asia/Shanghai' },
  { label: '(GMT+08:00) Chongqing', value: 'Asia/Chongqing' },
  { label: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong' },
  { label: '(GMT+08:00) Irkutsk', value: 'Asia/Irkutsk' },
  { label: '(GMT+08:00) Kuala Lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { label: '(GMT+08:00) Singapore', value: 'Asia/Singapore' },
  { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { label: '(GMT+08:00) Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
  { label: '(GMT+09:00) Osaka', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Sapporo', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { label: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  { label: '(GMT+10:00) Canberra', value: 'Australia/Melbourne' },
  { label: '(GMT+10:00) Guam', value: 'Pacific/Guam' },
  { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { label: '(GMT+10:00) Melbourne', value: 'Australia/Melbourne' },
  { label: '(GMT+10:00) Port Moresby', value: 'Pacific/Port_Moresby' },
  { label: '(GMT+10:00) Sydney', value: 'Australia/Sydney' },
  { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  { label: '(GMT+11:00) Magadan', value: 'Asia/Magadan' },
  { label: '(GMT+11:00) New Caledonia', value: 'Pacific/Noumea' },
  { label: '(GMT+11:00) Solomon Is.', value: 'Pacific/Guadalcanal' },
  { label: '(GMT+11:00) Srednekolymsk', value: 'Asia/Srednekolymsk' },
  { label: '(GMT+12:00) Auckland', value: 'Pacific/Auckland' },
  { label: '(GMT+12:00) Fiji', value: 'Pacific/Fiji' },
  { label: '(GMT+12:00) Kamchatka', value: 'Asia/Kamchatka' },
  { label: '(GMT+12:00) Marshall Is.', value: 'Pacific/Majuro' },
  { label: '(GMT+12:00) Wellington', value: 'Pacific/Auckland' },
  { label: '(GMT+12:45) Chatham Is.', value: 'Pacific/Chatham' },
  { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  { label: '(GMT+13:00) Samoa', value: 'Pacific/Apia' },
  { label: '(GMT+13:00) Tokelau Is.', value: 'Pacific/Fakaofo' },
].sort((a, b) => {
  var x = a.value
  var y = b.value

  if (currentZone && currentZone === x) {
    return -1
  }

  if (currentCountry && x.includes(currentCountry) && !y.includes(currentCountry)) {
    return -1
  }
  return 0
})

const TimezonePicker = ({ unselectLabel, ...props }) => {
  return (
    <Select {...props} icon={<Globe />} variant="outline">
      {unselectLabel && <option key="unset">{unselectLabel}</option>}
      {timezones.map((timezone) => (
        <option key={timezone.label} value={timezone.value}>
          {timezone.label}
        </option>
      ))}
    </Select>
  )
}

export default TimezonePicker
