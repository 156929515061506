import React from 'react'
import { Box, Icon, Td } from '@chakra-ui/react'
import { MoreVertical as DragHandleIcon } from 'react-feather'

import TextOverflow from 'components/TextOverflow'

import { AddAttemptButton, EditButton, LogbookButtons } from './'
import { tableWidths, pickStyleMapping } from 'domain/Widgets/Logbook/constants'

const ContentRow = ({
  provided,
  snapshot,
  entry,
  handleAddAttempt,
  handleEditEntry,
  logbook,
  showEditButton,
}) => {
  const showAttemptStyles = ['attempt', 'work']
  const showAttemptButton = showEditButton && showAttemptStyles.includes(entry.style)
  const styleMapping = pickStyleMapping(logbook)

  const showDragHandle = showEditButton
  const enableDragHandle = logbook.entries.length > 1

  const { isDragging } = snapshot
  const border = isDragging && 0

  // Specifying both maxWidth and width when isDragging
  // ensures the row holds its shape at all times
  // If these fields (name / notes) are empty
  // then when dragging their width will collapse

  return (
    <>
      <Td border={border} width={tableWidths.name} maxWidth={tableWidths.name}>
        <TextOverflow>{entry.name}</TextOverflow>
      </Td>
      <Td border={border} width={tableWidths.grade_id}>
        {entry.grade}
      </Td>
      <Td border={border} width={tableWidths.style}>
        {styleMapping[entry.style]}
      </Td>
      <Td border={border} width={tableWidths.attempts}>
        {entry.attempts}
      </Td>
      <Td border={border} width={tableWidths.notes} maxWidth={tableWidths.notes}>
        <TextOverflow>{entry.notes}</TextOverflow>
      </Td>
      <Td border={border} width={tableWidths.buttons} maxWidth={tableWidths.buttons} padding={0}>
        <LogbookButtons flexDirection="row-reverse">
          <DragHandle {...{ showDragHandle, enableDragHandle, provided, border }} />
          {showEditButton && <EditButton onClick={() => handleEditEntry(entry)} />}
          {showAttemptButton && <AddAttemptButton onClick={() => handleAddAttempt(entry)} />}
        </LogbookButtons>
      </Td>
    </>
  )
}

// When editing a row, the dragHandleProps still need to be present otherwise an error like this happens:
//
// A setup problem was encountered.
// > Invariant failed: Draggable[id: 00c0a1e4-3d2c-410d-82cd-545bbb215b51]: Unable to find drag handle

// But we don't want the user to be able to actually drag something when editing, so it needs to be hidden from view
// We just have a Box there with the dragHandleProps at all times with display: none

const DragHandle = ({ showDragHandle, enableDragHandle, provided, border }) => {
  return (
    <Box
      {...(showDragHandle && enableDragHandle && provided.dragHandleProps)}
      cursor={!enableDragHandle && 'not-allowed'}
      marginRight="4px"
      marginBottom="-6px" // fix alignment issue due to this Icon needing to be in a Box for dnd props
    >
      <Icon
        as={DragHandleIcon}
        _style={{ display: 'hidden' }}
        w="4"
        h="4"
        color="gray.500"
        opacity={!showDragHandle && 0}
        _hover={{ color: 'brand.500' }}
      />
      <Box style={{ display: 'none' }} {...provided.dragHandleProps}></Box>
    </Box>
  )
}

export default ContentRow
