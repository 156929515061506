import React, { useState } from 'react'
import { Select } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { InputRequired } from './'

const renderGradeOptions = (entry, logbook) => {
  const { grade_options } = logbook

  // show all logbook grade_options if new entry
  // or if this entry has same grade scale as the logbook
  const entryIsNew = entry.id === undefined
  const entryInGradeOptions = grade_options.some((option) => option.id === entry.grade_id)

  if (entryIsNew || entryInGradeOptions) {
    return grade_options.map(({ id, display }) => (
      <option key={id} value={id}>
        {display}
      </option>
    ))
  } else {
    // otherwise only show the selected grade
    // (which will be from another grade scale - handled by BE)
    const id = entry.grade_id
    const display = entry.grade

    return (
      <option key={id} value={id}>
        {display}
      </option>
    )
  }
}

const InputGrade = ({ entry, logbook }) => {
  const { register } = useFormContext()

  const { grade_options } = logbook

  // only show the placeholder if select has options for an entire list of grades
  // (otherwise the entry has a grade from a different grade scale to the current grade scale for this logbook)
  const entryIsNew = entry.id === undefined
  const entryInGradeOptions = grade_options.some((option) => option.id === entry.grade_id)
  const showGradePlaceholder = entryIsNew || entryInGradeOptions

  return (
    <>
      <Select
        size="sm"
        marginTop="2px"
        marginLeft="1px"
        borderColor="gray.400"
        variant="outline"
        placeholder={showGradePlaceholder && ' '}
        {...register('grade_id', {
          required: 'Required',
          value: entry.grade_id,
        })}
      >
        {renderGradeOptions(entry, logbook)}
      </Select>
      <InputRequired field="grade_id" />
    </>
  )
}

export default InputGrade
