import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { groupBy } from 'lodash'

const VolumeTooltip = (props) => {
  const { showPastMissing } = props

  if (!props.active) {
    return null
  }

  // Seems to sometimes return null which causes things to bomb.
  if (!props.payload?.[0]) {
    return null
  }

  let label = 'n/a'
  if (props.payload?.[0]) {
    label = props.payload[0].payload.date_text
    const start_date = props.payload[0].payload.week_start_date
    const end_date = props.payload[0].payload.week_end_date
    label = `${start_date} to ${end_date}`
  }

  // for now, don't show first week of "future" data set since it is presented
  // slightly differently to past data
  // but do show it if future on and missing off
  const isFirstFuture = props.payload?.[0].payload.future_first === true
  const hideTooltip = isFirstFuture && showPastMissing

  if (hideTooltip) {
    return null
  }

  const isFuture = props.payload?.[0].payload.future === true
  const isPast = !isFuture
  let newPayload

  if (isPast && showPastMissing) {
    const x = props.payload.map((item) => {
      item.dataKeyCommon = item.dataKey.replace('_miss', '')
      return item
    })

    const y = groupBy(x, 'dataKeyCommon')

    newPayload = Object.keys(y).map((key) => {
      const item = y[key]
      let modified = {}

      Object.assign(modified, ...item)

      const base = item[0]
      const miss = item[1]

      modified.name = base.name
      modified.value = base.value
      if (miss.value.toString() !== '0') {
        modified.value = `${base.value} (${miss.value})`
      }

      return modified
    })
  } else {
    newPayload = props.payload
  }

  const finalStyle = {
    margin: 0,
    padding: 10,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    whiteSpace: 'nowrap',
    ...props.contentStyle,
  }

  const runningTotal = newPayload.reduce((total, num) => {
    return total + num.value
  }, 0)

  const noDataForDate = runningTotal === 0
  if (noDataForDate) {
    return null
  }

  return (
    <Box style={finalStyle}>
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 'smaller',
          marginBottom: '8px',
        }}
      >
        {label}
      </Text>
      {
        // reverse so tooltip order reflects chart order
        newPayload.reverse().map((p, index) => {
          if (p.value === '0' || p.value === 0) {
            return null
          }
          return (
            <Flex gap={2} mb={1} key={index}>
              <Box p={3} bg={p.fill}></Box>
              <Text>{p.name}:</Text>
              <Text>{p.value}</Text>
            </Flex>
          )
        })
      }
      {isPast && showPastMissing && (
        <Text fontSize="xs" mt={3} textAlign="center">
          Figures in brackets are for
          <br />
          incomplete / missed workouts
        </Text>
      )}
    </Box>
  )
}

export default VolumeTooltip
