import {
  HStack,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Text,
  Tooltip,
} from '@chakra-ui/react'

const ClientStats = ({ data }) => {
  const { workouts, sliders } = data

  const StatBlock = ({ item }) => {
    const TooltipText = ({ item }) => {
      return (
        <Box>
          <Text>Current period: {item.value}</Text>
          <Text>Previous period: {item.value_old}</Text>
        </Box>
      )
    }

    return (
      <Tooltip label={<TooltipText item={item} />}>
        <Stat>
          <StatLabel fontSize="12px" fontWeight="normal">
            {item.type}
          </StatLabel>
          <StatNumber color="gray.600" fontSize="16px" fontWeight="semibold">
            {item.value}
          </StatNumber>
          <StatHelpText fontSize="12px">
            {item.movement === 'same' ? <>— </> : <StatArrow type={item.movement} />}
            {item.movement_value}
          </StatHelpText>
        </Stat>
      </Tooltip>
    )
  }

  return (
    <HStack>
      <StatBlock item={workouts} />
      {sliders.map((item, index) => (
        <StatBlock key={index} item={item} />
      ))}
    </HStack>
  )
}

export default ClientStats
