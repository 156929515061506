import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  AspectRatio,
  SimpleGrid,
  Box,
  Container,
  HStack,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react'

import LeeCujesBio from './assets/lee-avatar-3.png'
import LeeCujesHero2 from './assets/lee-cujes-hero-2.jpeg'
import LeeCujesHero3 from './assets/lee-cujes-hero-3.jpeg'

import { LC_MOONBOARD_BENCHMARK_BUSTER_URL } from 'domain/Plans/constants'

const LeeCujesCollection = () => {
  return (
    <>
      <Image
        alt="Training Psych"
        src={LeeCujesHero3}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'lg' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Heading size={{ base: '2xl', md: 'xl' }}>Lee Cujes</Heading>

              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                To the Moonboard and beyond!
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                A lover of all things training and with a climbing career stretching back to 1994,
                Lee started coaching climbers in 2007.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                More recently, Lee has become well known as an avid Moonboard frother, and is part
                of the secret society of benchmark moderators.
              </Text>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={LeeCujesBio} alt="Lee Cujes" />
          </AspectRatio>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }}>
          <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Plans
            </Text>
            <Divider />
          </HStack>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="3" maxW="lg">
                  <Text fontSize="xl" fontWeight="bold">
                    Moonboard Benchmark Buster Plan
                  </Text>
                  <Text>
                    Adaptable to almost any climbing level,{' '}
                    <Text as="span" fontWeight="bold">
                      Benchmark Buster
                    </Text>{' '}
                    has been designed to help you level up your Moonboard game, and become a
                    stronger climber in the process.
                  </Text>
                  <Text>
                    Choose to complete a 5-week or 10-week block of this training — it’s up to you.
                    Plus, you retain access to this plan for as long as you have an active Sequence
                    membership. You might choose to complete it several times each year.
                  </Text>
                  <Text>
                    For those who are a bit unsure about the Moonboard, or training boards in
                    general, this plan will help show you exactly what to do with your sessions, as
                    well as providing supplementary (non-Moonboard) training that will support what
                    you are doing on the board. Get stronger, stay injury free.
                  </Text>
                </Stack>
                <HStack>
                  <Button
                    as={NavLink}
                    to={`/plans/plan/${LC_MOONBOARD_BENCHMARK_BUSTER_URL}`}
                    variant="primary"
                    _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                  >
                    View Plan
                  </Button>
                </HStack>
              </Stack>
            </Box>
          </Container>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                    More coming soon...
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={LeeCujesHero2}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: '2xl' }}
        width="full"
      />
    </>
  )
}

export default LeeCujesCollection
