import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import LastXDays from './CommonMetrics'
import Performance from './Performance'
import Volume from './Volume'
import Category from './Category'

const ChartHeader = ({ title }) => {
  return (
    <Text fontSize="lg" marginBottom="2">
      {title}
    </Text>
  )
}

const MetricsCollection = () => {
  return (
    <Box>
      <Box marginTop="10">
        <ChartHeader title="Volume" />
        <LastXDays />
        <Volume />
      </Box>

      <Box marginTop="10">
        <ChartHeader title="Feeling / Performance / Motivation / RPE" />
        <LastXDays />
        <Performance />
      </Box>

      <Box marginTop="10">
        <ChartHeader title="Workouts by Category" />
        <LastXDays />
        <Category />
      </Box>
    </Box>
  )
}

export default MetricsCollection
