import React from 'react'
import { Text, Box, useDisclosure } from '@chakra-ui/react'
import { format } from 'date-fns'

import AddWeekLabel from './AddWeekLabel'

const WeekLabel = ({ refresh, day, dayData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const weekLabel = dayData[0]?.label?.content
  const defaultWeekLabelContent = dayData[0]?.label?.content
  const defaultWeekLabelId = dayData[0]?.label?.id

  const formattedDate = format(day, 'yyyy-MM-dd')

  const showLabel = weekLabel
  return (
    <>
      {showLabel ? (
        <Box maxWidth="400px" min="20px">
          <Text
            onClick={onOpen}
            noOfLines={1}
            fontWeight="bold"
            fontSize="small"
            _hover={{ cursor: 'pointer' }}
          >
            {weekLabel}
          </Text>
        </Box>
      ) : (
        <Box h="20px" />
      )}
      <AddWeekLabel
        date={formattedDate}
        {...{
          defaultWeekLabelContent,
          defaultWeekLabelId,
          isOpen,
          onClose,
          refresh,
        }}
      />
    </>
  )
}

export default WeekLabel
