import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'

import { useQuery } from '../../../hooks'

import LoadingSpinner from '../../../components/LoadingSpinner'
import Error from '../../../components/General/Error'

import ColorOverview from '../../Metrics/components/ColorOverview'
import WorkoutOverview from './WorkoutOverview'
import WorkoutHours from './WorkoutHours'

const NA = 'n/a'

const WorkoutMetricsAllTime = ({ id }) => {
  const { data, isLoading, hasError } = useQuery(`api/v1/activities/${id}/metrics/all_time`)

  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(data).length) {
    return null
  }

  const { completed, hours, performed, felt, motivated, rpe } = data

  return (
    <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
      <Box maxWidth="670px" margin="0 auto" px={{ base: '10', md: '0' }}>
        <Flex direction="column">
          <Box>
            <Text fontSize="large">Metrics</Text>
            <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
              All Time
            </Text>
          </Box>
        </Flex>

        <Box>
          <WorkoutOverview value={completed} />
        </Box>

        <Box>
          <WorkoutHours value={hours} />
        </Box>

        <Flex direction={{ base: 'column', md: 'row' }}>
          {felt !== NA && (
            <Box w={{ base: '100%', md: '50%' }}>
              <ColorOverview value={felt} field="felt" label="Average Feeling Score" />
            </Box>
          )}

          {motivated !== NA && (
            <Box w={{ base: '100%', md: '50%' }}>
              <ColorOverview value={motivated} field="motivated" label="Average Motivation Score" />
            </Box>
          )}
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }}>
          {performed !== NA && (
            <Box w={{ base: '100%', md: '50%' }}>
              <ColorOverview
                value={performed}
                field="performed"
                label="Average Performance Score"
              />
            </Box>
          )}
          {rpe !== NA && (
            <Box w={{ base: '100%', md: '50%' }}>
              <ColorOverview value={rpe} field="performed" label="Average RPE Score" />
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default WorkoutMetricsAllTime
