import React, { PureComponent, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  Center,
  Checkbox,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  HStack,
  Tooltip as ChakraTooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { Move } from 'react-feather'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
// ** options for graph settings icon **
// Aperture
// BarChart
// BarChart2
// Edit
// Edit2
// Layers
// MoreHorizontal
// PlusSquare
// Settings
// Sliders
// ** options for select graphs icon **
// Columns
// Grid <--
// Layout
// Table
// Sliders
// Trello <--

import {
  Info,
  MoreHorizontal as GraphSettingsIcon,
  Trello as SelectGraphsIcon,
} from 'react-feather'

import { mean, groupBy } from 'lodash'

import { useDemoData } from 'hooks'

import securedApi from 'backend/axios'
import { processAppError, reorder, tzSafeDateFormatISODate } from 'helpers/utils'

import DailyMetricsModal from 'domain/DailyMetrics/DailyMetricsModal'
import Milestone from 'domain/Milestones/Milestone'
import {
  calcXYDomains,
  calcXTicks,
  calcYTicks,
  calcYDomain,
  calcYMaxSum,
  getDataValues,
  getLabelValues,
  tickFormatterDate,
} from 'helpers/charts'

export const NoData = () => {
  return (
    <Box textAlign="center">
      <Text>No data recorded for this time period</Text>
    </Box>
  )
}

export const ChartContainer = ({ props, children }) => {
  return (
    <Box mt={4} mb={10} {...props}>
      {children}
    </Box>
  )
}

export const ChartHeader = ({ title, info }) => {
  return (
    <Box flex="1" textAlign="center" fontWeight="semibold">
      {title}
    </Box>
  )
}

export const CustomTooltip = (props) => {
  if (!props.active) {
    return null
  }

  let label = 'n/a'

  if (props.payload?.[0]) {
    label = props.payload[0].payload.date_text
  }

  return (
    <DefaultTooltipContent
      payload={props.payload}
      contentStyle={props.contentStyle}
      label={label}
      labelStyle={{ fontWeight: 'bold', fontSize: 'smaller' }}
    />
  )
}

export const CustomTooltipMeasure = (props) => {
  if (!props.active) {
    return null
  }

  if (!props.payload[0]) {
    return null
  }

  let payloadIndex = 0
  if (props.payload.length > 1) {
    // i.e. calculate the following value instead of always being zero
    payloadIndex = 0
  }

  // data is repeated for every dimension (each payload has all value/unit data)
  // but the line data could have value_X changed to the mean
  // so take the first 'points' data instead
  const firstPointsPayload = props.payload.find((x) => x.unit === 'points')?.payload
  const date_value = props.payload[0].payload.date_value
  const date_text = props.payload[0].payload.date_text

  const PayloadContent = ({ payload }) => {
    // only 1 dimension (if a dataKey is passed)

    // needed as this is used within the scheduled activity modal and bombs
    if (!payload || props.dataKey === undefined) {
      return null
    }

    const unitsKey = props.dataKey.replace('value', 'units')
    const meanKey = props.dataKey.replace('value', 'mean')
    var value = payload[props.dataKey]
    var units = payload[unitsKey]
    var mean = payload[meanKey]

    // multiple dimensions
    var value_1 = payload.value
    var value_2 = payload.value_2
    var value_3 = payload.value_3
    var units_1 = payload.units
    var units_2 = payload.units_2
    var units_3 = payload.units_3

    // strip trailing .0 if present
    value = value?.toString().replace(/\.0$/, '')
    value_1 = value_1?.toString().replace(/\.0$/, '')
    value_2 = value_2?.toString().replace(/\.0$/, '')
    value_3 = value_3?.toString().replace(/\.0$/, '')

    // treat some common units
    if (['1-10', '10 scale', '/10', 'out of 10'].includes(units)) {
      units = '/ 10'
    }
    if (['1-3', '/3', '/ 3', 'out of 3'].includes(units)) {
      units = '/ 3'
    }

    const noValue = Number.isNaN(value) || value === 'NaN'

    return (
      <Box key={payload.date_value}>
        {props.showActivity && <Text>{payload.activity_name}</Text>}
        {noValue ? (
          <Text fontStyle="italic">No value was entered</Text>
        ) : (
          <>
            {props.showSingleDimension ? (
              <>
                <Text>
                  {value} {units}
                </Text>
                {payload.show_mean && <Text fontSize="sm">Day average: {mean}</Text>}
              </>
            ) : (
              <>
                {payload.dimensions >= 1 && (
                  <>
                    <Text>
                      {value_1} {units_1}
                    </Text>
                  </>
                )}

                {payload.dimensions >= 2 && (
                  <>
                    <Text>
                      {value_2 || 0} {units_2}
                    </Text>
                  </>
                )}
                {payload.dimensions >= 3 && (
                  <>
                    <Text>
                      {value_3 || 0} {units_3}
                    </Text>
                  </>
                )}
                {(payload.mean || payload.mean_2 || payload.mean_3) && (
                  <>
                    <Text fontSize="sm">Day averages:</Text>
                    {payload.mean && (
                      <Text fontSize="sm">
                        {payload.mean} {units}
                      </Text>
                    )}
                    {payload.mean_2 && (
                      <Text fontSize="sm">
                        {payload.mean_2} {units_2}
                      </Text>
                    )}
                    {payload.mean_3 && (
                      <Text fontSize="sm">
                        {payload.mean_3} {units_3}
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    )
  }

  return (
    <Box opacity={0.75} p={2} borderRadius="md" bg="#F0F0F0" textAlign="center">
      <Text fontWeight="bold" fontSize="sm">{`${date_text}`}</Text>
      {props.showSingleDimension ? (
        <>
          {props.payload
            .sort((a, b) => {
              return parseFloat(b.value) - parseFloat(a.value)
            })
            .map((x) => {
              // don't show tooltip data for non 'points' payloads
              // i.e. this will exclude the 'mean line' <Line /> element
              if (x?.unit !== 'points') {
                return null
              }

              return <PayloadContent payload={x.payload} />
            })}
        </>
      ) : (
        <PayloadContent payload={firstPointsPayload} />
      )}
    </Box>
  )
}

export const CustomTooltipMeasureWeight = (props) => {
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null
  }

  if (!props.payload) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null
  }

  let value_1 = 'n/a'
  let value_2 = 'n/a'
  let value_3 = null

  if (props.payload[0]) {
    if (props.payload[0].payload.label) {
      value_1 = '' //props.payload[0].payload.label_date_value;
      value_2 = props.payload[0].payload.label

      return (
        <Box opacity={0.75} p={2} borderRadius="md" bg="#F0F0F0" textAlign="center">
          <Text fontStyle="italic">{`${value_2}`}</Text>
        </Box>
      )
    } else {
      value_1 = props.payload[0].payload.date_text
      value_2 = props.payload[0].payload.value
      value_3 = props.payload[0].payload.rmean

      return (
        <Box opacity={0.75} p={2} borderRadius="md" bg="#F0F0F0" textAlign="center">
          <Text fontWeight="bold" fontSize="sm">{`${value_1}`}</Text>
          {value_3 ? <Text>{`${value_3}`}</Text> : <></>}
          {value_2 ? <Text fontSize="sm">Point: {`${value_2}`}</Text> : <></>}
        </Box>
      )
    }
  }

  return null
}

export class TickWeekLabel extends PureComponent {
  render() {
    const { x, y, payload, weekLabels } = this.props

    var weekLabelObj = weekLabels.filter(function (val) {
      return val.label_date_value === payload.value
    })
    var weekHasLabel = weekLabelObj.length
    var weekLabel = weekHasLabel ? weekLabelObj[0].label : ''

    return (
      <ChakraTooltip label={weekLabel}>
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fontSize="smaller"
            fill="#666"
            textDecoration={weekHasLabel ? 'underline' : ''}
            cursor={weekHasLabel ? 'pointer' : ''}
          >
            {tickFormatterDate(payload.value)}
          </text>
        </g>
      </ChakraTooltip>
    )
  }
}

// peformance
export const getDataValuesPerf = (data) => {
  return {
    felt: data
      .map((e) => {
        return e.felt
      })
      .filter(function (val) {
        return val !== null
      }),
    performed: data
      .map((e) => {
        return e.performed
      })
      .filter(function (val) {
        return val !== null
      }),
  }
}

export const MetricsChart = ({
  item,
  annotations,
  dataKey,
  yMin,
  yMax = 'pick',
  height = 400,
  margin = {
    top: 15,
    left: 20, // so axis label is not covered
    bottom: 5,
    right: 20, // so last tick can be centered (so second last tick will not be hidden)
  },
  externalChartOptions,
  tooltip,
  children,
}) => {
  const [showMenstrualCycles, setShowMenstrualCycles] = useState(true)
  const [showMilestones, setShowMilestones] = useState(true)
  const [showMilestoneModal, setShowMilestoneModal] = useState(false)
  const [milestoneId, setMilestoneId] = useState(null)
  const [date, setDate] = useState(null)
  const [menstrualCycleId, setMenstrualCycleId] = useState(null)
  const [biometricsOpen, setBiometricsOpen] = useState(false)
  const [showStatistics, setShowStatistics] = useState(false)

  const { data, statistics } = item
  const { week_labels } = annotations

  const { y_values } = getDataValues(data, dataKey)
  const { x_ticks } = calcXTicks(annotations.range)
  const { xy_domains } = calcXYDomains(annotations, y_values)

  // need to extract this setting to determine how to calculate the yMax
  const showPastMissing = externalChartOptions?.missing?.state

  // reassign yMin zero was passed
  let yMinValue
  if (yMin === 'zero') {
    yMinValue = 0
  } else {
    yMinValue = xy_domains.y_domain_min
  }
  yMin = yMinValue

  // reassign yMax if a method was passed and pass value if value passed
  let yMaxValue
  if (yMax === 'pick') {
    // yMaxValue = xy_domains.y_domain_max - 0.05 * (xy_domains.y_domain_max - yMin)
    yMaxValue = xy_domains.y_domain_max
  } else if (yMax === 'sum') {
    yMaxValue = calcYMaxSum(data, showPastMissing)
  } else {
    yMaxValue = yMax
  }
  yMax = yMaxValue

  const includeStatistics = statistics !== undefined
  const useDefaultTooltip = tooltip === undefined

  return (
    <Box>
      <ChartOptions
        {...{
          annotations,
          includeStatistics,
          showMenstrualCycles,
          showMilestones,
          showStatistics,
          setShowMenstrualCycles,
          setShowMilestones,
          setShowStatistics,
          externalChartOptions,
        }}
      />
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" />
          {MetricsXAxis({ xy_domains, x_ticks, week_labels })}
          {children}
          {ChartLineSegments({
            annotations,
            showMenstrualCycles,
            showMilestones,
            setMilestoneId,
            setShowMilestoneModal,
            setBiometricsOpen,
            setDate,
            setMenstrualCycleId,
            yMin,
            yMax,
          })}
          {useDefaultTooltip ? (
            <Tooltip
              allowEscapeViewBox={{ x: true }}
              position={{ y: 30 }}
              contentStyle={{ opacity: 0.8, position: 'relative', left: 20 }}
              content={<CustomTooltip />}
            />
          ) : (
            tooltip
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {showStatistics && <MeasureSummaryStats statistics={statistics} item={item} />}

      <MilestoneModal {...{ showMilestoneModal, setShowMilestoneModal, milestoneId }} />
      {biometricsOpen && (
        <DailyMetricsModal
          date={date}
          open={biometricsOpen}
          onClose={() => setBiometricsOpen(false)}
          highlightMenstrualCycle
        />
      )}
    </Box>
  )
}

export const MeasureLineChart = ({
  item,
  annotations,
  activities,
  handlePointClick = () => {},
}) => {
  const { dimensions, units, units_2, units_3 } = item

  const [dataKey, setDataKey] = useState('value')
  const [showSingleDimension, setShowSingleDimension] = useState(false)
  const [showSecondaryYAxis, setShowSecondaryYAxis] = useState(false)
  const [showDimensionBars, setShowDimensionBars] = useState(false)
  const [showWorkoutBreakdown, setShowWorkoutBreakdown] = useState(true)

  useEffect(() => {
    if (showWorkoutBreakdown) {
      setShowSingleDimension(true)
      setShowSecondaryYAxis(false)
    }
  }, [showWorkoutBreakdown])

  const comboMode = showSecondaryYAxis || showDimensionBars
  const activitiesPresent = activities !== undefined

  const { y_values } = getDataValues(item.data, dataKey)
  const { xy_domains } = calcXYDomains(annotations, y_values)
  const { y_ticks } = calcYTicks(xy_domains)

  const y_label_max_digits = Math.max(
    ...[xy_domains.y_domain_min, xy_domains.y_domain_max].map((e) => {
      return e.toString().length
    })
  )
  const y_label_dx = -3 - 7 * y_label_max_digits

  let displayUnits
  if (dataKey === 'value') {
    displayUnits = units
  }
  if (dataKey === 'value_2') {
    displayUnits = units_2
  }
  if (dataKey === 'value_3') {
    displayUnits = units_3
  }

  const SelectUnitButton = ({ dim }) => {
    let newDataKey
    let text
    if (dim === 1) {
      newDataKey = 'value'
      text = units
    }
    if (dim === 2) {
      newDataKey = 'value_2'
      text = units_2
    }
    if (dim === 3) {
      newDataKey = 'value_3'
      text = units_3
    }

    const variant = !comboMode && newDataKey === dataKey ? 'solid' : 'outline'

    return (
      <Button
        colorScheme="brand"
        variant={variant}
        onClick={() => {
          setDataKey(newDataKey)
          setShowDimensionBars(false)
          setShowSecondaryYAxis(false)
          // default to workoutBreakdown when user switches back from All
          if (!showSingleDimension) {
            setShowWorkoutBreakdown(true)
          }
          setShowSingleDimension(true)
        }}
      >
        {text}
      </Button>
    )
  }

  const SelectComboButton = ({ type }) => {
    let text
    let onClick
    let variant

    if (type === 'secondary') {
      text = 'All'
      variant = showSecondaryYAxis ? 'solid' : 'outline'
      onClick = () => {
        setDataKey('value')
        setShowSecondaryYAxis(true)
        setShowDimensionBars(false)
        setShowSingleDimension(false)
        setShowWorkoutBreakdown(false)
      }
    }
    if (type === 'bars') {
      text = 'Bars'
      variant = showDimensionBars ? 'solid' : 'outline'
      onClick = () => {
        setDataKey('value')
        setShowSecondaryYAxis(false)
        setShowDimensionBars(true)
        setShowSingleDimension(false)
        setShowWorkoutBreakdown(false)
      }
    }
    // if (type === 'workout') {
    //   text = 'Workouts'
    //   variant = showWorkoutBreakdown ? 'solid' : 'outline'
    //   onClick = () => {
    //     setDataKey('value')
    //     setShowSecondaryYAxis(false)
    //     setShowDimensionBars(false)
    //     setShowSingleDimension(false)
    //     setShowWorkoutBreakdown(!showWorkoutBreakdown)
    //   }
    // }

    return (
      <Button variant={variant} colorScheme="brand" onClick={onClick}>
        {text}
      </Button>
    )
  }

  const unitsColourMap = {
    value: '#82ca9d',
    value_2: '#8884d8',
    value_3: '#fdb619',
  }

  const objectMap = (obj, fn) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

  const splitDataToLinesPoints = (data) => {
    const activityDataPoints = structuredClone(data)
    const activityDataDateGrouped = structuredClone(groupBy(data, 'date_value'))
    const activityDataDateGroupedFunction = objectMap(activityDataDateGrouped, (v, k, i) => {
      const first = v[0]

      // values on the same day will be shifted up by 1 so that clicking works
      // but these incremented values won't have a corresponding line value
      // so all lines will be hidden, and the line data added to each point
      if (v.length > 1) {
        const arr_value = v.map((e) => parseFloat(e.value))
        const arr_value_2 = v.map((e) => parseFloat(e.value_2))
        const arr_value_3 = v.map((e) => parseFloat(e.value_3))

        const fn = mean
        const mean_1 = fn(arr_value)
        const mean_2 = fn(arr_value_2)
        const mean_3 = fn(arr_value_3)

        // save the mean values to the first payload
        first['value'] = mean_1
        first['value_2'] = mean_2
        first['value_3'] = mean_3
        first['mean'] = mean_1
        first['mean_2'] = mean_2
        first['mean_3'] = mean_3

        // change the corresponding values in the points to shift the x value up by 1 so clicking works
        var points = activityDataPoints.filter((x) => parseFloat(x.date_value) === parseFloat(k))
        points.forEach((x, i) => {
          x.date_value = x.date_value + i
          x.show_mean = true
          x.mean = mean_1
          x.mean_2 = mean_2
          x.mean_3 = mean_3
        })

        return first
      } else {
        return first
      }
    })

    const activityDataLine = Object.values(activityDataDateGroupedFunction)

    return { dataLines: activityDataLine, dataPoints: activityDataPoints }
  }

  const LinePair = ({
    idLine,
    idPoints,
    dataLines,
    dataPoints,
    dataKeyResolved,
    stroke,
    strokeWidth = 2,
    handlePointClick,
    name,
    yAxisId = 'left',
    hide,
  }) => {
    return (
      <>
        <Line
          key={idLine}
          name={name}
          lineType="line"
          data={dataLines}
          dataKey={dataKeyResolved}
          yAxisId={yAxisId}
          type="monotone"
          dot={false}
          activeDot={false}
          strokeWidth={strokeWidth}
          stroke={stroke}
          animationDuration={0}
          hide={hide}
          connectNulls
        />
        <Line
          key={idPoints}
          name={name}
          unit="points"
          lineType="points"
          data={dataPoints}
          dataKey={dataKeyResolved}
          yAxisId={yAxisId}
          type="monotone"
          strokeWidth={0}
          dot={{ stroke: stroke, strokeWidth: 2 }}
          activeDot={{ onClick: handlePointClick, cursor: 'pointer' }}
          animationDuration={0}
          hide={hide}
          legendType="none"
        />
      </>
    )
  }

  const VariantByActivity = () => {
    return (
      <MetricsChart
        item={item}
        annotations={annotations}
        dataKey={dataKey}
        yMax={'pick'}
        externalChartOptions={{
          workoutBreakdown: { state: showWorkoutBreakdown, setState: setShowWorkoutBreakdown },
        }}
      >
        <YAxis
          name={'name'}
          yAxisId="left"
          domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
          dataKey={dataKey}
          ticks={y_ticks}
          label={{
            value: displayUnits,
            angle: -90,
            dx: y_label_dx,
          }}
        />
        {activities.map((activity) => {
          const { data } = item

          const idPoints = `activity_points_${activity.id}`
          const idLine = `activity_line_${activity.id}`
          const stroke = activity.category_hex_colour

          const activityDataRaw = data.filter((x) => x.activity_id === activity.id)
          const { dataLines, dataPoints } = splitDataToLinesPoints(activityDataRaw)
          const dataKeyResolved = showSecondaryYAxis ? 'value' : dataKey

          // don't show anything if there is not data for the given period (otherwise it looks weird)
          if (dataPoints.length) {
            return LinePair({
              idLine,
              idPoints,
              dataLines,
              dataPoints,
              stroke,
              dataKeyResolved,
              handlePointClick,
            })
          } else {
            return null
          }
        })}
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={
            <CustomTooltipMeasure
              dataKey={dataKey}
              dimensions={dimensions}
              showSingleDimension={showSingleDimension}
              showActivity={true}
            />
          }
        />
      </MetricsChart>
    )
  }

  const VariantLines = () => {
    const labels = [units, units_2, units_3].filter((e) => e !== null && e !== undefined)
    const [lineProps, setLineProps] = useState(
      labels.reduce(
        (a, { key }) => {
          a[key] = false
          return a
        },
        { hover: null }
      )
    )

    const handleLegendMouseEnter = (e) => {
      if (!lineProps[e.dataKey]) {
        setLineProps({ ...lineProps, hover: e.dataKey })
      }
    }

    const handleLegendMouseLeave = (e) => {
      setLineProps({ ...lineProps, hover: null })
    }

    const selectLine = (e) => {
      setLineProps({
        ...lineProps,
        [e.dataKey]: !lineProps[e.dataKey],
        hover: null,
      })
    }

    const formatLegendText = (value) => {
      return <span style={{ cursor: 'pointer' }}>{value}</span>
    }

    const setStrokeWidthHover = (value_field) => {
      const defaultValue = 2
      const hoverValue = 3
      return Number(lineProps.hover === value_field ? hoverValue : defaultValue)
    }

    const externalChartOptions = activitiesPresent
      ? {
          workoutBreakdown: { state: showWorkoutBreakdown, setState: setShowWorkoutBreakdown },
        }
      : {}

    const { dataLines, dataPoints } = splitDataToLinesPoints(item.data)

    const idPoints = `activity_points`
    const idLine = `activity_line`
    const dataKeyResolved = showSecondaryYAxis ? 'value' : dataKey

    return (
      <MetricsChart
        item={item}
        annotations={annotations}
        dataKey={dataKey}
        yMax={'pick'}
        externalChartOptions={externalChartOptions}
      >
        <YAxis
          name={'name'}
          yAxisId="left"
          domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
          dataKey={dataKey}
          ticks={y_ticks}
          label={{
            value: displayUnits,
            angle: -90,
            dx: y_label_dx,
          }}
        />
        {LinePair({
          idLine,
          idPoints,
          dataLines,
          dataPoints,
          stroke: unitsColourMap[dataKey],
          strokeWidth: setStrokeWidthHover('value'),
          dataKeyResolved,
          handlePointClick,
          name: units,
          hide: lineProps['value'] === true,
        })}
        {showSecondaryYAxis &&
          (() => {
            const secondaryYAxisLabel = units_3 ? units_2 + ' & ' + units_3 : units_2

            const { y_values: y_values_2 } = getDataValues(item.data, 'value_2')
            const { y_values: y_values_3 } = getDataValues(item.data, 'value_3')
            const y_values_2_3 = [...y_values_2, ...y_values_3]
            const secondary_y_domain = calcYDomain(y_values_2_3)

            return (
              <>
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  domain={[secondary_y_domain.y_domain_min, secondary_y_domain.y_domain_max]}
                  label={{
                    value: secondaryYAxisLabel,
                    angle: -90,
                  }}
                />
                {LinePair({
                  idLine: idLine + '_2',
                  idPoints: idPoints + '_2',
                  dataLines,
                  dataPoints,
                  stroke: unitsColourMap['value_2'],
                  strokeWidth: setStrokeWidthHover('value_2'),
                  dataKeyResolved: 'value_2',
                  handlePointClick,
                  yAxisId: 'right',
                  name: units_2,
                  hide: lineProps['value_2'] === true,
                })}
                {LinePair({
                  idLine: idLine + '_3',
                  idPoints: idPoints + '_3',
                  dataLines,
                  dataPoints,
                  stroke: unitsColourMap['value_3'],
                  strokeWidth: setStrokeWidthHover('value_3'),
                  dataKeyResolved: 'value_3',
                  handlePointClick,
                  yAxisId: 'right',
                  name: units_3,
                  hide: lineProps['value_3'] === true,
                })}
                <Legend
                  formatter={formatLegendText}
                  onClick={selectLine}
                  onMouseOver={handleLegendMouseEnter}
                  onMouseOut={handleLegendMouseLeave}
                />
              </>
            )
          })()}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={
            <CustomTooltipMeasure
              dataKey={dataKey}
              dimensions={dimensions}
              showSingleDimension={showSingleDimension}
            />
          }
        />
      </MetricsChart>
    )
  }

  const VariantBars = () => {
    return <Text>Bars</Text>
  }

  return (
    <>
      {dimensions > 1 && (
        <Flex gap={4}>
          <Box>
            <SelectUnitButton dim={1} />
            {dimensions >= 2 && <SelectUnitButton dim={2} />}
            {dimensions >= 3 && <SelectUnitButton dim={3} />}
          </Box>
          <Box>
            <SelectComboButton type="secondary" />
            {/*<SelectComboButton type="bars" />*/}
          </Box>
        </Flex>
      )}
      {showDimensionBars ? (
        <VariantBars />
      ) : showWorkoutBreakdown && activitiesPresent ? (
        <VariantByActivity />
      ) : (
        <VariantLines />
      )}
    </>
  )
}

// from options
//
//       <YAxis
//         name={"name"}
//         dataKey={y_options ? Number("value") : "value"}
//         ticks={y_options ? y_ticks_values : null}
//         tickFormatter={y_options ? formatYAxis : null}
//         domain={[y_domain_min, y_domain_max]}
//         label={{
//           value: item.units,
//           angle: -90,
//           dx: -30,
//         }}
//       />

export const MeasureBarChart = ({ item, annotations, x_ticks, xy_domains }) => {
  const { week_labels } = annotations

  return (
    <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
      <BarChart
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        data={item.data}
        barCategoryGap="20"
        barSize="2"
        stackOffset="expand"
      >
        <XAxis
          type="number"
          dataKey={'x'}
          scale="time"
          // name="date"
          allowDuplicatedCategory
          domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
          tick={<TickWeekLabel weekLabels={week_labels} />}
          ticks={x_ticks}
          tickFormatter={tickFormatterDate}
        />
        <YAxis domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]} yAxisId="left" />
        <Bar dataKey="y" fill="#82ca9d" />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip cursor={{ fill: '#aabbcc33' }} content={<CustomTooltipMeasure />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export const MeasureScatterChart = ({ item, annotations, x_ticks, xy_domains }) => {
  const { week_labels } = annotations
  return (
    <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
      <ScatterChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }} data={item.data}>
        <XAxis
          type="number"
          dataKey={'x'}
          name="date"
          domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
          tick={<TickWeekLabel weekLabels={week_labels} />}
          ticks={x_ticks}
          tickFormatter={tickFormatterDate}
        />
        <YAxis
          yAxisId="left"
          name={'name'}
          domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
          label={{
            value: item.units,
            angle: -90,
            dx: -30,
          }}
        />
        <Scatter dataKey="y" fill="#82ca9d" />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltipMeasure />} />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export const WeightChart = ({ item, annotations, handlePointClick = () => {} }) => {
  const { data, statistics } = item

  const [showWeightLine, setShowWeightLine] = useState(true)
  const [showWeightPoints, setShowWeightPoints] = useState(true)

  const { y_values } = getDataValues(data)
  const { x_ticks } = calcXTicks(annotations.range)
  const { xy_domains } = calcXYDomains(annotations, y_values)
  var { y_ticks } = calcYTicks(xy_domains)

  var y_label_max_digits = Math.max(
    ...[xy_domains.y_domain_min, xy_domains.y_domain_max].map((e) => {
      return e.toString().length
    })
  )
  var y_label_dx = -3 - 7 * y_label_max_digits

  return (
    <>
      <MetricsChart item={item} annotations={annotations} yMax={'pick'}>
        <YAxis
          yAxisId="left"
          name={'name'}
          domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
          ticks={y_ticks}
          label={{
            value: item.units,
            angle: -90,
            dx: y_label_dx,
          }}
        />
        {showWeightLine && (
          <Line
            yAxisId="left"
            connectNulls
            name="rmean-line"
            type="monotone"
            dataKey="rmean"
            dot={false}
            strokeWidth={2}
            stroke="#82ca9d"
          />
        )}
        {showWeightPoints && (
          <Line
            yAxisId="left"
            dataKey="value"
            name="value"
            strokeWidth={0}
            dot={{ stroke: '#82ca9d', strokeWidth: 2 }}
            activeDot={{ onClick: handlePointClick, cursor: 'pointer' }}
          />
        )}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltipMeasureWeight />} />
      </MetricsChart>
      <Flex justifyContent="right" marginTop={1} marginRight={4}>
        <Checkbox
          isChecked={!showWeightPoints}
          onChange={() => setShowWeightPoints(!showWeightPoints)}
        >
          <Text marginTop={1} fontSize="sm">
            Hide Points
          </Text>
        </Checkbox>
      </Flex>
    </>
  )
}

export const MeasureSummaryStats = ({ statistics, item }) => {
  const { best, average, worst } = statistics
  const { biometric_name } = item
  const isWeight = biometric_name === 'Weight'

  const labelWorst = isWeight ? 'Lowest' : 'Worst'
  const labelAverage = isWeight ? 'Average' : 'Average'
  const labelBest = isWeight ? 'Highest' : 'Best'

  const StatisticsBubble = ({ text, value, bg }) => {
    return (
      <Box>
        <Box
          h="20"
          w="20"
          rounded="full"
          bg={bg}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontWeight="bold">{value}</Text>
        </Box>
        <Box my="3" textAlign="center">
          <Text fontSize="x-small" textTransform="uppercase">
            {text}
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Center>
      <HStack mt="8" spacing="24">
        <StatisticsBubble text={labelWorst} value={worst} bg="brand.200" />
        <StatisticsBubble text={labelAverage} value={average} bg="brand.400" />
        <StatisticsBubble text={labelBest} value={best} bg="brand.500" />
      </HStack>
    </Center>
  )
}

export const MenstrualCycleLabel = (props) => {
  const demoData = useDemoData((state) => state.demoData)
  return (
    <g
      cursor="pointer"
      onClick={() => {
        if (demoData) {
          return null
        }
        return handleMenstrualCycleLink(
          props.cycle_record,
          props.setBiometricsOpen,
          props.setDate,
          props.setMenstrualCycleId
        )
      }}
    >
      <rect x={props.viewBox.x} y={props.viewBox.y} fill="none" width={24} height={24} />

      <ChakraTooltip label={props.cycle_record.date_text}>
        <svg
          width="24"
          height="24"
          x={props.viewBox.x - 12}
          y={props.viewBox.y - 12}
          fill="#ffffff"
          stroke="#bb4878"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <circle cx="12" cy="12" r="6"></circle>
          <circle cx="12" cy="12" r="2"></circle>
        </svg>
      </ChakraTooltip>
    </g>
  )
}

export const MilestoneLabel = (props) => {
  const demoData = useDemoData((state) => state.demoData)

  return (
    <g
      cursor="pointer"
      onClick={() => {
        if (demoData) {
          return null
        }
        return handleMilestoneLink(
          props.milestone.id,
          props.setMilestoneId,
          props.setShowMilestoneModal
        )
      }}
    >
      <rect x={props.viewBox.x} y={props.viewBox.y} fill="none" width={24} height={24} />

      <ChakraTooltip label={props.milestone.title}>
        <svg
          width="24"
          height="24"
          x={props.viewBox.x - 12}
          y={props.viewBox.y - 12}
          fill="#ffffff"
          stroke="#48bb78"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <circle cx="12" cy="12" r="6"></circle>
          <circle cx="12" cy="12" r="2"></circle>
        </svg>
      </ChakraTooltip>
    </g>
  )
}

const handleMenstrualCycleLink = (
  cycle_record,
  setBiometricsOpen,
  setDate,
  setMenstrualCycleId
) => {
  const { id, date_text, date_value } = cycle_record
  if (id === 'demo') {
    return null
  }

  // get date as yyyy-mm-dd without timezone interference
  const newDate = tzSafeDateFormatISODate(date_value)

  setDate(newDate)
  setMenstrualCycleId(id)
  setBiometricsOpen(true)
}

const handleMilestoneLink = (id, setMilestoneId, setShowMilestoneModal) => {
  if (id === 'demo') {
    return null
  }

  setMilestoneId(id)
  setShowMilestoneModal(true)
}

export const ChartOptions = ({
  annotations,
  includeStatistics,
  showMenstrualCycles,
  showMilestones,
  showStatistics,
  setShowMenstrualCycles,
  setShowMilestones,
  setShowStatistics,
  externalChartOptions = {},
}) => {
  const menstrualCycleDataPresent = isMenstrualCycleDataPresent(annotations)

  const { future, missing, workoutBreakdown } = externalChartOptions

  const includeFuture = future !== undefined
  const includeMissing = missing !== undefined
  const includeWorkoutBreakdown = workoutBreakdown !== undefined

  const CheckboxLabel = ({ text }) => {
    return (
      <Text mt={1} fontSize="sm">
        {text}
      </Text>
    )
  }

  const MenuItemCheckBox = ({ text, state, setState }) => {
    return (
      <MenuItem fontSize="small" p="2">
        <Checkbox
          isChecked={state}
          onChange={(e) => setState(e.target.checked)}
          colorScheme="brand"
          width="100%"
        >
          <CheckboxLabel text={text} />
        </Checkbox>
      </MenuItem>
    )
  }

  return (
    <Flex justifyContent="flex-end" mr="4">
      <Flex flexDirection="column">
        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={
              <Icon
                as={GraphSettingsIcon}
                w="4"
                h="4"
                color="gray.500"
                // strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            }
            bg="transparent"
            _hover={{
              cursor: 'pointer',
              color: 'brand.500',
              bg: 'transparent',
            }}
            size="x-small"
            width="18px"
            _expanded={{ color: 'brand.500', bg: 'transparent' }}
          />

          <MenuList>
            <MenuOptionGroup fontSize="small" title="Graph Options">
              {includeWorkoutBreakdown && (
                <MenuItemCheckBox
                  text="Workout Breakdown"
                  state={workoutBreakdown.state}
                  setState={workoutBreakdown.setState}
                />
              )}
              <MenuItemCheckBox
                text="Milestones"
                state={showMilestones}
                setState={setShowMilestones}
              />
              {menstrualCycleDataPresent && (
                <MenuItemCheckBox
                  text="Menstrual Cycle"
                  state={showMenstrualCycles}
                  setState={setShowMenstrualCycles}
                />
              )}
              {includeStatistics && (
                <MenuItemCheckBox
                  text="Statistics"
                  state={showStatistics}
                  setState={setShowStatistics}
                />
              )}
              {includeMissing && (
                <MenuItemCheckBox
                  text="Incomplete / Missed"
                  state={missing.state}
                  setState={missing.setState}
                />
              )}
              {includeFuture && (
                <MenuItemCheckBox text="Future" state={future.state} setState={future.setState} />
              )}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
}

export const MilestoneModal = (props) => {
  return (
    <Modal
      isOpen={props.showMilestoneModal}
      onClose={() => props.setShowMilestoneModal(false)}
      scrollBehavior="inside"
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Milestone milestoneId={props.milestoneId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

// end will override the y value, otherwise will try be a bit smart
const calcLineSegment = (records, record, idx, y_min, y_max, end = null) => {
  // dodge code considerations:
  // - if there are other milestones closeby put at angle (otherwise they overlap)
  // - if y pos of label is too close to the top don't have angle (otherwise it goes off canvas)
  // - might need to adjust y pos for really close milestones
  //   (maybe alternate between 2 y_end values)
  // - or just hide some or make font size smaller

  let y_end
  if (end === null) {
    const isFirst = idx === 0
    const isSecond = idx === 1

    const oneDay = 86400000
    const lastX = isFirst ? 0 : records[idx - 1].date_value
    const lastX2 = isFirst || isSecond ? 0 : records[idx - 2].date_value
    const diff = record.date_value - lastX
    const diff2 = lastX - lastX2
    const isClose = diff <= oneDay
    const isClose2 = diff2 <= oneDay

    // simple version:
    // put on second layer if close, unless day before was also close
    // doesn't work for 3 days in a row but that would look really cluttered anyway

    const layer1 = y_max
    const layer2 = y_max - 1
    y_end = isClose ? (isClose2 ? layer1 : layer2) : layer1
  } else {
    y_end = end
  }

  return [
    {
      x: record.date_value,
      y: y_min,
    },
    {
      x: record.date_value,
      y: y_end,
    },
  ]
}

export const ChartLineSegments = ({
  annotations,
  showMenstrualCycles,
  showMilestones,
  setMilestoneId,
  setShowMilestoneModal,
  setBiometricsOpen,
  setDate,
  setMenstrualCycleId,
  yMin,
  yMax,
}) => {
  const { menstrual_cycles, milestones } = annotations
  const menstrualCycleDataPresent = isMenstrualCycleDataPresent(annotations)

  return (
    <>
      {milestones && // data is passed from api
        showMilestones && // chart setting turned on
        MilestoneSegments({
          milestones,
          setMilestoneId,
          setShowMilestoneModal,
          yMin,
          yMax,
        })}
      {menstrual_cycles && // data is passed from api
        showMenstrualCycles && // chart setting turned on
        menstrualCycleDataPresent && // user has attribute
        MenstrualCycleSegments({
          menstrual_cycles,
          setBiometricsOpen,
          setDate,
          setMenstrualCycleId,
          yMin,
          yMax,
        })}
    </>
  )
}

export const MilestoneSegments = ({
  milestones,
  setMilestoneId,
  setShowMilestoneModal,
  yMin,
  yMax,
}) => {
  return milestones.map((milestone, idx) => {
    const segment = calcLineSegment(milestones, milestone, idx, yMin, yMax)

    return (
      <ReferenceLine
        yAxisId="left"
        key={`milestone-${idx}`}
        stroke={'#3377553c'}
        segment={segment}
        label={
          <MilestoneLabel
            {...{
              milestone,
              setMilestoneId,
              setShowMilestoneModal,
            }}
          />
        }
      />
    )
  })
}

export const MenstrualCycleSegments = ({
  menstrual_cycles,
  setBiometricsOpen,
  setDate,
  setMenstrualCycleId,
  yMin,
  yMax,
}) => {
  return menstrual_cycles.map((cycle_record, idx) => {
    const segment = calcLineSegment(menstrual_cycles, cycle_record, idx, yMin, yMax - 0.5)

    return (
      <ReferenceLine
        yAxisId="left"
        key={`cycle-${idx}`}
        stroke={'#bb48783c'}
        segment={segment}
        label={
          <MenstrualCycleLabel
            {...{
              cycle_record,
              setBiometricsOpen,
              setDate,
              setMenstrualCycleId,
            }}
          />
        }
      />
    )
  })
}

export const MetricsXAxis = ({ xy_domains, x_ticks, week_labels }) => {
  return (
    <XAxis
      name="date"
      type="number"
      dataKey="date_value"
      domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
      tick={<TickWeekLabel weekLabels={week_labels} />}
      ticks={x_ticks}
      tickFormatter={tickFormatterDate}
      // fixes bug with activeDot not working for multiple lines
      // even if the XAxis type is number and not category
      // https://github.com/recharts/recharts/issues/3044
      allowDuplicatedCategory={false}
    />
  )
}

const isMenstrualCycleDataPresent = (annotations) => {
  return annotations.menstrual_cycles !== null
}

export const SelectableMetricsContainer = ({
  type,
  hasDataMeasures,
  showMeasures,
  setShowMeasures,
  hideMeasures,
  setHideMeasures,
  cardComponent,
  demo = false,
  extraButtons,
  children,
}) => {
  const SelectItemCard = cardComponent

  const [showPickerModal, setShowPickerModal] = useState(false)

  // move an item from one list to another list
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  const getList = (id) => {
    switch (id) {
      case 'droppableShow':
        return showMeasures
      case 'droppableHide':
        return hideMeasures
    }
  }

  const getSetList = (id) => {
    switch (id) {
      case 'droppableShow':
        return setShowMeasures
      case 'droppableHide':
        return setHideMeasures
    }
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index)

      getSetList(source.droppableId)(items)
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )

      setShowMeasures(result.droppableShow)
      setHideMeasures(result.droppableHide)
    }
  }

  const getItemStyle = (isDragging, isDropAnimating, draggableStyle, hasData) => {
    if (!isDropAnimating) {
      return {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 0 10px 0`,
        border: '1px solid #EDF2F7',

        // change background colour if dragging
        background: isDragging ? '#fff' : '#fff',

        // styles we need to apply on draggables
        ...draggableStyle,
      }
    }
    return {
      ...draggableStyle,
      background: '#ddd',
      transitionDuration: `0.001s`,
    }
  }

  const getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? '#eee' : '#fff',
      width: 250,
      minHeight: '85%',
      padding: '5px',
      // 80 = based on size of 1 card, might be hard to detect
      // 85% = rough estimation; TODO: useRef to get height of modal at the time (was glitchy)
    }
  }

  const NoMeasuresSelected = () => {
    return <Box m={8}>No metrics selected</Box>
  }

  const onClose = () => {
    if (!demo) {
      const prefs = [{ name: 'default', order: 1, show: showMeasures, hide: hideMeasures }]
      const chartPrefsType = `chart_prefs_${type}`
      const params = { [chartPrefsType]: prefs }

      securedApi
        .post(`/accounts/set_preference`, {
          account: params,
        })
        .then(() => {
          // succeed silently
        })
        .catch((error) => {
          processAppError(error, 'Error saving chart preferences')
        })
    }

    setShowPickerModal(false)
  }

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <Box>
          {extraButtons}
          <ChakraTooltip label="Select Metrics">
            <IconButton
              onClick={() => setShowPickerModal(true)}
              variant="ghost"
              _hover={{ cursor: 'pointer', color: 'brand.500' }}
              icon={
                <Icon
                  as={SelectGraphsIcon}
                  w="6"
                  h="6"
                  strokeWidth="1px"
                  backgroundColor="transparent"
                />
              }
            />
          </ChakraTooltip>
        </Box>
      </Flex>
      <Box>{showMeasures.length ? children : <NoMeasuresSelected />}</Box>
      <Modal isOpen={showPickerModal} onClose={onClose} scrollBehavior="inside" size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ModalHeader textAlign="center" pb="0">
              Visible Measures
            </ModalHeader>
            <Flex justifyContent="center">
              <DragDropContext onDragEnd={onDragEnd}>
                <Box m={4}>
                  <Text
                    textAlign="center"
                    fontSize="x-small"
                    textTransform="uppercase"
                    color="gray.500"
                  >
                    Show
                  </Text>
                  <Droppable droppableId="droppableShow">
                    {(provided, snapshot) => (
                      <Box ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {showMeasures.map((id, index) => {
                          const hasData = hasDataMeasures.indexOf(id) >= 0

                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(provided, snapshot) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    snapshot.isDropAnimating,
                                    provided.draggableProps.style,
                                    hasData
                                  )}
                                >
                                  <SelectItemCard id={id} />
                                </Box>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
                <Box m={4}>
                  <Text
                    textAlign="center"
                    fontSize="x-small"
                    textTransform="uppercase"
                    color="gray.500"
                  >
                    Hide
                  </Text>
                  <Droppable droppableId="droppableHide">
                    {(provided, snapshot) => (
                      <Box ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {hideMeasures.map((id, index) => {
                          const hasData = hasDataMeasures.indexOf(id) >= 0

                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(provided, snapshot) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    snapshot.isDropAnimating,
                                    provided.draggableProps.style,
                                    hasData
                                  )}
                                >
                                  <SelectItemCard id={id} />
                                </Box>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              </DragDropContext>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export const ItemCard = ({ key, title, subtitle, hasData }) => {
  const textColour = hasData ? '' : '#fa4a4a55'
  const iconColour = hasData ? '' : '#fa4a4aaa'

  return (
    <Flex justifyContent="space-between">
      <Flex
        key={key}
        pl={4}
        pt={4}
        pb={4}
        style={{ color: textColour }}
        flexDirection="column"
        width="100%"
        p="2"
      >
        <Flex alignItems="center" width="100%" justifyContent="space-between">
          <Text fontSize="small" wordBreak="break-word">
            {title}
          </Text>
          <Flex>
            {!hasData && (
              <Box mr="1" style={{ color: iconColour }}>
                <ChakraTooltip label="This measure has no data for the selected period">
                  <Info size="20" />
                </ChakraTooltip>
              </Box>
            )}
            <Icon as={Move} color="gray.400" strokeWidth="1px" w="2" h="2" ml="1" />
          </Flex>
        </Flex>
        <Text fontSize="small" color="gray.500" mb="1">
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  )
}
