import React from 'react'
import { Flex } from '@chakra-ui/react'

const LogbookButtons = ({ border, children, ...props }) => {
  return (
    <Flex gap="1" {...props}>
      {children}
    </Flex>
  )
}

export default LogbookButtons
