import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Box,
  Image,
  Stack,
  Text,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
} from '@chakra-ui/react'

import { useTemplatePlan } from '../../hooks'

import FullPageLoader from 'components/FullPageLoader'
import Notify from 'components/Notification'

import BoulderPlanImage from './assets/boulderPlan.png'
import RoutesPlanImage from './assets/routesPlan.png'
import CombinedPlanImage from './assets/combinedPlan.png'

import {
  SEQ_BOULDER_PLAN_FREE_CODE,
  SEQ_ROUTES_PLAN_FREE_CODE,
  SEQ_COMBINED_PLAN_FREE_CODE,
} from 'domain/Plans/constants'

export const BOULDER = 'Boulder'
export const ROUTES = 'Routes'
export const COMBINED = 'Combined'

export const PlanPreviewModal = ({ planName, planCode, isOpen, onClose }) => {
  const renderPlanImage = () => {
    if (planCode === SEQ_BOULDER_PLAN_FREE_CODE) {
      return BoulderPlanImage
    }

    if (planCode === SEQ_ROUTES_PLAN_FREE_CODE) {
      return RoutesPlanImage
    }

    if (planCode === SEQ_COMBINED_PLAN_FREE_CODE) {
      return CombinedPlanImage
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{planName} Plan Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody background="gray.100">
            <Image marginBottom="10" objectFit="cover" src={renderPlanImage()} alt="Plan Preview" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export const PlanApplyModal = ({ planName, planCode, isOpen, onClose }) => {
  const navigate = useNavigate()
  const { refetch } = useTemplatePlan()
  const [loading, setLoading] = useState(false)
  const [startValue, setStartValue] = useState('next_week')
  const [overwrite, setOverwrite] = useState(true)

  const handleApplyPlan = async () => {
    setLoading(true)
    const behaviour = overwrite ? 'overwrite' : 'merge'

    await refetch({ add: startValue, behaviour, lookup_code: planCode })

    Notify({
      content: 'New training plan has been applied',
      type: 'success',
    })

    navigate('/planner')
  }

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          The{' '}
          <Text fontWeight="bold" display="inline">
            {planName}
          </Text>{' '}
          plan will be added to your schedule.
          <Text fontWeight="bold" marginTop="4" marginBottom="2">
            Start your plan
          </Text>
          <RadioGroup onChange={setStartValue} value={startValue}>
            <Stack direction="row">
              <Radio value="this_week" colorScheme="yellow">
                This week
              </Radio>
              <Radio value="next_week" colorScheme="yellow">
                Next week
              </Radio>
            </Stack>
          </RadioGroup>
          <Checkbox
            colorScheme="yellow"
            marginTop="4"
            isChecked={overwrite}
            onChange={() => {
              setOverwrite(!overwrite)
            }}
          >
            Overwrite any planned workouts
          </Checkbox>
          <Box marginY="4">
            <Button colorScheme="brand" width="100%" onClick={() => handleApplyPlan()}>
              Confirm
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
