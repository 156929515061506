import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const Description = ({ item }) => {
  return (
    <Box marginTop="6">
      <Text color="black.500" fontWeight="semibold">
        Description
      </Text>
      <Text marginTop="1">{item?.activity_description}</Text>
    </Box>
  )
}

export default Description
