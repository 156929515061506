import React, { useState } from 'react'
import { Button, ButtonGroup, Icon, Spacer, Tooltip } from '@chakra-ui/react'
import { Trash2 as TrashIcon } from 'react-feather'

import Modal from 'components/Modal'

import { LOGBOOK_TYPE, SLIDER_TYPE } from 'domain/Widgets/constants'
import { widgetTextMap } from 'domain/Widgets/mappers'

const EditWidgetModalActions = ({ widget, handleChange, onDeleteWidget, onEditWidgetClose }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const { type } = widget

  const hideDeleteButton = widget.new || type === LOGBOOK_TYPE || type === SLIDER_TYPE

  const deleteWidget = () => {
    onDeleteWidget()
    setDeleteModalOpen(false)
    onEditWidgetClose()
  }

  const DeleteWidgetIconButton = ({ type }) => {
    const label = `Delete ${widgetTextMap[type]}`

    return (
      <Tooltip label={label}>
        <Icon
          as={TrashIcon}
          w="5"
          h="5"
          color="gray.500"
          _hover={{ cursor: 'pointer', color: 'red.500' }}
          onClick={() => setDeleteModalOpen(true)}
          data-cy="widget-delete"
        />
      </Tooltip>
    )
  }

  return (
    <>
      <ButtonGroup spacing="4" display="flex" alignItems="center" mb="2" mt="6">
        {!hideDeleteButton && <DeleteWidgetIconButton type={type} />}
        <Spacer />
        <Button variant="outline" onClick={onEditWidgetClose} data-cy="widget-cancel">
          Cancel
        </Button>
        <Button colorScheme="brand" onClick={handleChange} data-cy="widget-save">
          Save
        </Button>
      </ButtonGroup>

      <Modal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        title="Are you sure?"
        subTitle="All of the data recorded on your workouts for this widget will also be deleted."
        onAccept={deleteWidget}
      />
    </>
  )
}

export default EditWidgetModalActions
