import {
  RotateCw as AddAttemptIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Trash as DeleteIcon,
  CornerUpLeft as CancelEditIcon,
} from 'react-feather'
import { IconButton, Tooltip } from '@chakra-ui/react'

import Icon from 'components/Icons/Icon'

const LogbookIconButton = ({ onClick, as, tooltipContent }) => {
  return <Icon w="4" h="4" color="gray.500" {...{ as, onClick, tooltipContent }} />
}

export const AddAttemptButton = ({ onClick }) => {
  const tooltipContent = 'Log another attempt'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={AddAttemptIcon} />
}

export const CancelEditButton = ({ onClick }) => {
  const tooltipContent = 'Discard edits'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={CancelEditIcon} />
}

export const DeleteButton = ({ onClick }) => {
  const tooltipContent = 'Delete entry'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={DeleteIcon} />
}

export const DiscardButton = ({ onClick }) => {
  const tooltipContent = 'Discard entry'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={DeleteIcon} />
}

export const EditButton = ({ onClick }) => {
  const tooltipContent = 'Edit entry'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={EditIcon} />
}

export const SaveButton = ({ onClick }) => {
  const tooltipContent = 'Save changes'

  return <LogbookIconButton {...{ onClick, tooltipContent }} as={SaveIcon} />
}
