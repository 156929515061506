import React from 'react'

import { Box, HStack, Stat, StatLabel, StatNumber } from '@chakra-ui/react'

const IncompleteReasonsSummary = ({ data }) => {
  return (
    <Box mb="4">
      <HStack mt="2" spacing="20">
        <Box>
          <Stat>
            <StatLabel>Completed</StatLabel>
            <StatNumber>{data.completed ? data.completed : '0'}</StatNumber>
          </Stat>
        </Box>
        <Box>
          <Stat>
            <StatLabel>Incomplete</StatLabel>
            <StatNumber>{data.incomplete ? data.incomplete : '0'}</StatNumber>
          </Stat>
        </Box>
      </HStack>
    </Box>
  )
}

export default IncompleteReasonsSummary
