import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
} from '@chakra-ui/react'
import { add } from 'date-fns'

import securedApi from 'backend/axios'
import RichTextArea from 'components/RichTextArea'
import Notify from 'components/Notification'
import DatePicker from 'components/DatePicker/DatePicker'
import { processApiError } from 'helpers/utils'

const GoalsCreate = () => {
  const navigate = useNavigate()
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const status = watch('status')
  const showStartEndDateFields =
    status === 'in_progress' || status === 'complete' || status === 'cancelled'

  useEffect(() => {
    if (showStartEndDateFields) {
      setValue('start_date', new Date())
      setValue('end_date', add(new Date(), { months: 1 }))
    }
  }, [showStartEndDateFields, setValue])

  useEffect(() => {
    register('details', { required: true })
  }, [register])

  const onSubmit = (data) => {
    securedApi
      .post(`/api/v1/goals`, {
        ...data,
      })
      .then(() => {
        Notify({ content: 'Goal created', type: 'success' })
        navigate('/goals')
      })
      .catch((error) => {
        processApiError(error, 'Error creating goal. Please try again.')
      })
  }

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl marginBottom="4" isRequired>
          <FormLabel>What is your goal?</FormLabel>

          <Input bg="white" placeholder="The Groove Train" {...register('title')} />
          <FormHelperText>
            e.g. send a particular climb, "Climb 5 25's before Easter" or "Boulder V8" or "Onsight a
            25"
          </FormHelperText>
        </FormControl>

        <FormControl marginBottom="4" isRequired isInvalid={errors['details']}>
          <FormLabel>
            Details about your goal{' '}
            <FormErrorMessage display="inline" fontWeight="bold">
              Required
            </FormErrorMessage>
          </FormLabel>

          <Controller
            name="details"
            control={control}
            required
            render={({ field }) => <RichTextArea {...field} />}
          />
        </FormControl>

        <FormControl maxW="180px" marginBottom="4" isRequired>
          <FormLabel>Duration</FormLabel>
          <Select placeholder="Select option" bg="white" {...register('style')} variant="outline">
            <option value="undefined">N/A</option>
            <option value="short_term">Short term</option>
            <option value="medium_term">Medium term</option>
            <option value="long_term">Long term</option>
          </Select>
        </FormControl>

        <FormControl maxW="180px" marginBottom="4" isRequired>
          <FormLabel>Status</FormLabel>
          <Select placeholder="Select option" bg="white" {...register('status')} variant="outline">
            <option value="idea">Idea</option>
            <option value="in_progress">In progress</option>
            <option value="complete">Complete</option>
            <option value="cancelled">Cancelled</option>
          </Select>
        </FormControl>

        {showStartEndDateFields && (
          <>
            <FormControl marginBottom="4">
              <FormLabel>Start date</FormLabel>
              <Controller
                control={control}
                name="start_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    dateFormat="d/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selectedDate={field.value}
                  />
                )}
              />
            </FormControl>

            <FormControl marginBottom="4">
              <FormLabel>End date</FormLabel>
              <Controller
                control={control}
                name="end_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    dateFormat="d/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selectedDate={field.value}
                  />
                )}
              />
            </FormControl>
          </>
        )}

        {/* <FormControl marginBottom="4">
          <FormLabel>Notes</FormLabel>
          <Textarea minH="150px" bg="white" {...register("notes")} />
        </FormControl> */}

        {/* <FormControl marginBottom="4">
          <FormLabel>Final date - todo</FormLabel>
        </FormControl> */}

        <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={() => navigate(`/goals`)}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="brand">
            Create
          </Button>
        </ButtonGroup>
      </form>
    </Container>
  )
}

export default GoalsCreate
