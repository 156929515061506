import { Flex, Spinner } from '@chakra-ui/react'

const LoadingSpinner = ({ margin = '4', size = 'md' }) => {
  return (
    <Flex justifyContent="center" m={margin}>
      <Spinner color="gray.500" size={size} />
    </Flex>
  )
}

export default LoadingSpinner
