import humanizeDuration from 'humanize-duration'

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'YEAR',
      mo: () => 'MONTH',
      w: () => 'WEEK',
      d: () => 'DAY',
      h: () => 'HR',
      m: () => 'MIN',
      s: () => 'SEC',
      ms: () => 'MIL SEC',
    },
  },
})
