import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import { widgetEditFormComponentMap } from 'domain/Widgets/mappers'

const EditWidgetModal = ({
  type,
  widget,
  onCreateWidget,
  onUpdateWidget,
  onDeleteWidget,
  isEditWidgetOpen,
  onEditWidgetClose,
  activityId,
}) => {
  // render relevant FormFields component based on the widget type
  const renderEditForm = () => {
    const EditFormComponent = widgetEditFormComponentMap[type]

    return (
      <EditFormComponent
        {...{
          widget,
          activityId,
          onCreateWidget,
          onUpdateWidget,
          onDeleteWidget,
          onEditWidgetClose,
        }}
      />
    )
  }

  return (
    <Modal
      isOpen={isEditWidgetOpen}
      onClose={onEditWidgetClose}
      blockScrollOnMount={false}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform="capitalize">Edit {type} Widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="2">{renderEditForm()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EditWidgetModal
