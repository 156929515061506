import React, { useState } from 'react'
import { format } from 'date-fns'
import { groupBy } from 'lodash'
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Badge,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import { processGetError, processApiError, tzSafeDateFormat } from 'helpers/utils'

const TrainingNotes = ({ id, activityId, notes, onSetNotes, readOnly }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [previousNotesLoading, setPreviousNotesLoading] = useState(false)
  const [listData, setListData] = useState([])

  const handleSaveNotes = (event) => {
    const { value } = event.currentTarget

    if (value === notes) {
      return
    }

    securedApi
      .patch(`api/v1/scheduled_activities/${id}`, {
        scheduled_activity: {
          notes: value,
        },
      })
      .catch((error) => processApiError(error))

    onSetNotes(value)
  }

  const handleLoadPreviousNotesModal = () => {
    setPreviousNotesLoading(true)
    onOpen()

    securedApi
      .get(`api/v1/activities/${activityId}/metrics/list`)
      .then(({ data }) => {
        setListData(data)
      })
      .catch((error) => {
        processGetError(error)
        Notify({
          content: 'Error loading previous notes. Please try again.',
          type: 'error',
        })
      })
      .finally(() => {
        setPreviousNotesLoading(false)
      })
  }

  const renderModalContent = () => {
    const TrYear = ({ item }) => {
      return (
        <Tr>
          <Td>
            <Badge colorScheme="brand">{item.year}</Badge>
          </Td>
          <Td></Td>
        </Tr>
      )
    }

    const TrNotes = ({ item }) => {
      const formattedDate = tzSafeDateFormat(item.scheduled_at, 'MMM d')

      return (
        <Tr key={item.id}>
          <Td title={item.scheduled_at}>{formattedDate}</Td>
          <Td style={{ whiteSpace: 'pre-wrap' }}>{item.notes ? item.notes : '-'}</Td>
        </Tr>
      )
    }

    if (previousNotesLoading) {
      return <LoadingSpinner />
    }

    if (listData.length === 0) {
      return (
        <Box>
          <Text>Workout has not been completed yet</Text>
        </Box>
      )
    }

    const grp = groupBy(listData, 'year')
    const years = Object.keys(grp).sort().reverse()
    const thisYear = format(new Date(), 'yyyy') // this is ok to get current year

    const multipleYears = years.length > 1
    const onlyYearIsNotThisYear = !multipleYears && years[0] !== thisYear
    const showYearRows = multipleYears || onlyYearIsNotThisYear

    return (
      <Box>
        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              <Th width="80px">Date</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {years.map((year) => {
              const items = grp[year]

              return items.map((item, idx) => {
                const firstRowOfYear = idx === 0

                if (showYearRows) {
                  if (firstRowOfYear) {
                    return (
                      <>
                        <TrYear item={item} />
                        <TrNotes item={item} />
                      </>
                    )
                  } else {
                    return <TrNotes item={item} />
                  }
                } else {
                  return <TrNotes item={item} />
                }
              })
            })}
          </Tbody>
        </Table>
      </Box>
    )
  }

  return (
    <>
      <FormControl>
        <FormLabel fontSize="14" marginTop="4">
          Training Notes
          <Link
            as="span"
            color="yellow.500"
            fontWeight="bold"
            ml="2"
            fontSize="x-small"
            onClick={handleLoadPreviousNotesModal}
          >
            View previous training notes
          </Link>
        </FormLabel>
        {readOnly ? (
          <Textarea
            placeholder="No notes"
            defaultValue={notes}
            size="sm"
            color="gray.600"
            isReadOnly
            resize={readOnly && 'none'}
          />
        ) : (
          <Textarea
            placeholder="Add your notes here..."
            defaultValue={notes}
            size="sm"
            color="gray.600"
            onBlur={(event) => handleSaveNotes(event)}
          />
        )}
      </FormControl>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent top="3.75rem" maxHeight="500px">
          <ModalHeader>Previous Training Notes</ModalHeader>
          <ModalBody paddingBottom="6">{renderModalContent()}</ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  )
}

export default TrainingNotes
