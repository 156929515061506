import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { processGetError } from 'helpers/utils'
import { useImpersonate } from 'hooks'
import LoadingSpinner from 'components/LoadingSpinner'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import WorkoutContainer from 'domain/Workouts/WorkoutComponents/WorkoutContainer'
import TitleHeader from 'domain/Workouts/WorkoutComponents/TitleHeader'
import Duration from './WorkoutComponents/Duration'

import Description from 'domain/Workouts/WorkoutComponents/Description'
import Details from 'domain/Workouts/WorkoutComponents/Details'
import WidgetContainer from 'domain/Workouts/WidgetContainer'
import ActionsMenu from 'domain/Workouts/WorkoutComponents/ActionsMenu'
import { WorkoutMetrics } from './WorkoutMetrics'

const Workout = () => {
  const { id } = useParams()

  const impersonateData = useImpersonate((state) => state.impersonateData)
  const isCoaching = impersonateData.coachingConnectionId !== null

  const [workout, setWorkout] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/activities/${id}`)
      .then((response) => setWorkout(response.data))
      .catch((error) => processGetError(error))
      .finally(() => setLoading(false))
  }, [id])

  const renderWorkout = (workout) => {
    if (loading || workout === null) {
      return <LoadingSpinner />
    }

    const hasWidgets = Object.keys(workout.widgets).length > 0
    const isSharable = workout.shared && !isCoaching
    const isArchived = workout.archived

    return (
      <Box>
        <Box key={workout.id} pb="20">
          <InnerPageWrapperActions backAction="/workouts">
            <ActionsMenu {...{ id, isSharable, isArchived }} />
          </InnerPageWrapperActions>
          <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
            <TitleHeader item={workout} />
            <Box marginLeft="8%" maxWidth="670px">
              <Duration item={workout} />
              <Description item={workout} />
              {hasWidgets && <WidgetContainer widgets={workout.widgets} />}
              <Details item={workout} />
            </Box>
          </Box>
          <WorkoutMetrics {...{ id, workout }} />
        </Box>
      </Box>
    )
  }

  return <WorkoutContainer>{renderWorkout(workout)}</WorkoutContainer>
}

export default Workout
