import { create } from 'zustand'

import { add, format, startOfToday, startOfWeek, parse } from 'date-fns'

const today = startOfToday()

const usePlannerDate = create((set, get) => ({
  currentDay: today,
  // setCurrentDay: (value) => {
  //   set({ currentDay: value });
  // },
  currentWeek: startOfWeek(today, { weekStartsOn: 1 }),
  // setCurrentWeek: (value) => {
  //   set({ currentWeek: value });
  // },
  currentMonth: format(today, 'MMM-yyyy'),
  // setCurrentMonth: (value) => {
  //   set({ currentMonth: value });
  // },
  // daysOfWeek: () => {
  //   const firstDayCurrentWeek = get().currentWeek;
  //   return eachDayOfInterval({
  //     start: firstDayCurrentWeek,
  //     end: endOfWeek(firstDayCurrentWeek, { weekStartsOn: 1 }),
  //   });
  // },
  // daysOfMonth: () => {
  //   const firstDayCurrentMonth = parse(
  //     get().currentWeek,
  //     "MMM-yyyy",
  //     new Date()
  //   );
  //   return eachDayOfInterval({
  //     start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
  //     end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
  //   });
  // },
  // firstDayCurrentMonth: () => {
  //   const currentWeek = get().currentWeek;
  //   return parse(currentWeek, "MMM-yyyy", new Date());
  // },
  setToday: () => {
    set({
      currentDay: today,
      currentWeek: startOfWeek(today, { weekStartsOn: 1 }),
      currentMonth: format(today, 'MMM-yyyy'),
    })
  },
  setPrevDay: () => {
    const yesterday = add(get().currentDay, { days: -1 })
    set({
      currentDay: yesterday,
      currentWeek: startOfWeek(yesterday, { weekStartsOn: 1 }),
      currentMonth: format(yesterday, 'MMM-yyyy'),
    })
  },
  setNextDay: () => {
    const tomorrow = add(get().currentDay, { days: 1 })
    set({
      currentDay: tomorrow,
      currentWeek: startOfWeek(tomorrow, { weekStartsOn: 1 }),
      currentMonth: format(tomorrow, 'MMM-yyyy'),
    })
  },
  setPrevWeek: () => {
    const firstDayNextWeek = add(get().currentWeek, { weeks: -1 })
    set({
      currentDay: firstDayNextWeek,
      currentWeek: firstDayNextWeek,
      currentMonth: format(firstDayNextWeek, 'MMM-yyyy'),
    })
  },
  setNextWeek: () => {
    const firstDayNextWeek = add(get().currentWeek, { weeks: 1 })
    set({
      currentDay: firstDayNextWeek,
      currentWeek: firstDayNextWeek,
      currentMonth: format(firstDayNextWeek, 'MMM-yyyy'),
    })
  },
  setPrevMonth: () => {
    const firstDayCurrentMonth = parse(get().currentMonth, 'MMM-yyyy', new Date())
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })

    set({
      currentDay: firstDayNextMonth,
      currentWeek: startOfWeek(firstDayNextMonth, { weekStartsOn: 1 }),
      currentMonth: format(firstDayNextMonth, 'MMM-yyyy'),
    })
  },
  setNextMonth: () => {
    const firstDayCurrentMonth = parse(get().currentMonth, 'MMM-yyyy', new Date())
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })

    set({
      currentDay: firstDayNextMonth,
      currentWeek: startOfWeek(firstDayNextMonth, { weekStartsOn: 1 }),
      currentMonth: format(firstDayNextMonth, 'MMM-yyyy'),
    })
  },
}))

export default usePlannerDate
