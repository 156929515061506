import React from 'react'
import { isSameDay, parseISO, format } from 'date-fns'
import { Button, Box, Flex, Text, VStack, Spacer, Icon, HStack } from '@chakra-ui/react'
import { PlusCircle, ChevronRight, ChevronLeft, ZoomIn, ZoomOut } from 'react-feather'

import { dateToString } from 'helpers/utils'
import { useQuery, useUserPreferences } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import DailyMetricsPlannerModal from 'domain/DailyMetrics/DailyMetricsPlannerModal'

import ActivityDetails from './components/ActivityDetails'
import CalendarDay from './components/CalendarDay'
import ExtraDayDetails from './components/ExtraDayDetails'
import WeekLabel from './components/WeekLabel'
import DayNotes from './components/DayNotes'

import DayActions from './DayActions'
import { usePlannerDate, usePlannerMethods } from './hooks'

const MobileView = ({ daysOfWeek }) => {
  const compactView = useUserPreferences((state) => state.compactView)
  const toggleCompactView = useUserPreferences((state) => state.toggleCompactView)

  const date = dateToString(daysOfWeek[0])
  const { data, isLoading, hasError } = useQuery(
    `api/v1/schedules/user_week?start_date_of_period=${date}`
  )

  const setToday = usePlannerDate((state) => state.setToday)
  const setPrevWeek = usePlannerDate((state) => state.setPrevWeek)
  const setNextWeek = usePlannerDate((state) => state.setNextWeek)

  const {
    refreshData,
    showDayActions,
    handleAddWorkoutToDay,
    selectedDay,
    isDayActionsModalOpen,
    setIsDayActionsModalOpen,
  } = usePlannerMethods(data)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const renderNavigation = () => {
    return (
      <Flex m="4" alignItems="center">
        <Text color="gray.500" fontWeight="bold" fontSize="xs">
          {format(daysOfWeek[0], 'MMMM yyyy')}
        </Text>
        <Spacer />
        <HStack spacing="4">
          <Box cursor="pointer" height="20px" onClick={() => toggleCompactView()}>
            {compactView ? (
              <Icon
                as={ZoomIn}
                w="5"
                h="5"
                color="gray.500"
                strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            ) : (
              <Icon
                as={ZoomOut}
                w="5"
                h="5"
                color="gray.500"
                strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            )}
          </Box>
          <Button size="sm" colorScheme="brand" variant="outline" onClick={setToday}>
            Today
          </Button>
          <Button size="sm" colorScheme="brand" variant="outline" onClick={setPrevWeek}>
            <ChevronLeft size="14px" />
          </Button>
          <Button size="sm" colorScheme="brand" variant="outline" onClick={setNextWeek}>
            <ChevronRight size="14px" />
          </Button>
        </HStack>
      </Flex>
    )
  }

  const renderWorkout = (day) => {
    const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

    if (!dayData.length) {
      // `day` could not be found in `data`
      return
    }

    if (!dayData[0].content.length) {
      return (
        <Text fontSize="small" fontStyle="italic" color="gray.500">
          Nothing planned for this day
        </Text>
      )
    } else {
      return dayData[0].content.map((item, idx) => (
        <Box key={item.id} mb="1.5" _first={{ marginTop: 1.5 }} _last={{ marginBottom: 0 }}>
          <Box bg="white" border="1px" borderColor="gray.100" _hover={{ cursor: 'grab' }}>
            <ActivityDetails
              id={item.id}
              name={item.activity_name}
              category={item.category_name}
              categoryHexColour={item.category_hex_colour}
              description={item.activity_description}
              duration={item.actual_duration || item.duration}
              completed={item.completed}
              notes={item.notes}
              incomplete={item.incomplete}
              refresh={refreshData}
              linkedGoals={item.linked_goals}
              isMobile
            />
          </Box>
        </Box>
      ))
    }
  }

  const Day = ({ day }) => {
    return (
      <Box key={day.toString()} w="100%">
        <DayNotes refresh={refreshData} {...{ data, day }} />
        {renderWorkout(day)}
      </Box>
    )
  }

  const renderWeekDays = () => {
    return daysOfWeek.map((day, index) => {
      const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

      return (
        <Box key={day.toString()} width="100%" px="4">
          <WeekLabel refresh={refreshData} {...{ day, dayData }} />
          <Box display="flex" alignItems="center" height="6">
            <CalendarDay refresh={refreshData} {...{ day, dayData }} />
            <Spacer />
            <ExtraDayDetails {...{ data, day }} />
            <Icon
              as={PlusCircle}
              w="4"
              h="4"
              mt="1"
              ml="2"
              onClick={() => showDayActions(day)}
              color="gray.400"
              _hover={{ color: 'brand.500', cursor: 'pointer' }}
            />
          </Box>
          <Day {...{ day }} />
        </Box>
      )
    })
  }

  return (
    <>
      {renderNavigation()}
      <VStack spacing="2" mb="32">
        {renderWeekDays()}
      </VStack>
      {isDayActionsModalOpen && (
        <DayActions
          refresh={refreshData}
          day={selectedDay}
          isOpen={isDayActionsModalOpen}
          onClose={() => setIsDayActionsModalOpen(false)}
          onAddWorkout={handleAddWorkoutToDay}
        />
      )}

      <DailyMetricsPlannerModal />
    </>
  )
}

export default MobileView
