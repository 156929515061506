import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import LeeCujesBenchmarkBuster from './LeeCujes/LeeCujesBenchmarkBuster'

import EightWeekBoulderingPerformance from './ClimbStrong/EightWeekBoulderingPerformance'
import FullTilt from './ClimbStrong/FullTilt'

import SequenceBoulderPlanFree from './Sequence/SequenceBoulderPlanFree'
import SequenceRoutesPlanFree from './Sequence/SequenceRoutesPlanFree'
import SequenceCombinedPlanFree from './Sequence/SequenceCombinedPlanFree'

import {
  LC_MOONBOARD_BENCHMARK_BUSTER_URL,
  CS_BOULDERING_PERFORMANCE_URL,
  CS_FULL_TILT_URL,
  SEQ_BOULDER_PLAN_FREE_URL,
  SEQ_ROUTES_PLAN_FREE_URL,
  SEQ_COMBINED_PLAN_FREE_URL,
} from 'domain/Plans/constants'

const renderPlan = (id) => {
  switch (id) {
    case LC_MOONBOARD_BENCHMARK_BUSTER_URL:
      return <LeeCujesBenchmarkBuster />
    case SEQ_BOULDER_PLAN_FREE_URL:
      return <SequenceBoulderPlanFree />
    case SEQ_ROUTES_PLAN_FREE_URL:
      return <SequenceRoutesPlanFree />
    case SEQ_COMBINED_PLAN_FREE_URL:
      return <SequenceCombinedPlanFree />
    case CS_BOULDERING_PERFORMANCE_URL:
      return <EightWeekBoulderingPerformance />
    case CS_FULL_TILT_URL:
      return <FullTilt />
    default:
      return null //todo: 404
  }
}

const Plan = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{renderPlan(id)}</>
}

export default Plan
