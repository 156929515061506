import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'
import { FormHeading } from 'components/Forms'
import { AuthWrapper, EmailField } from 'domain/Auth/Elements'

import { processAppStatus, processAppError } from 'helpers/utils'

const schema = z.object({
  email: z.string().email({ message: 'Email required' }),
})

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ email }) => {
    securedApi
      .post('/password/forgot', { email })
      .then((response) => {
        processAppStatus(response)
        resetField('email')
      })
      .catch((error) => processAppError(error))
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading heading="Forgot your password?" />
        <EmailField {...{ register, errors }} marginTop="8" />
        <Button type="submit" colorScheme="brand" width="100%">
          Email password reset link
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default ForgotPassword
