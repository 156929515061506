import React from 'react'
import { Box, Button, Container, Stack, useDisclosure } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import HeaderDivider from 'components/General/HeaderDivider'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import CategoryItem from './CategoryItem'

import CategoryCreate from './CategoryCreate'

const Categories = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, isLoading, hasError, mutate } = useQuery('api/v1/categories')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const handleUpdate = () => {
    mutate()
  }

  const renderCategories = () => {
    return data.map((item) => <CategoryItem key={item.id} onUpdate={handleUpdate} {...{ item }} />)
  }

  return (
    <>
      <Container maxW="container.lg" mb="28">
        <InnerPageWrapperActions>
          <Button colorScheme="brand" onClick={onOpen}>
            Add New Category
          </Button>
        </InnerPageWrapperActions>
        <Box display="block" margin="0 auto" width="100%" maxW="600px">
          <HeaderDivider heading="Categories" />

          <Box margin="auto">
            <Stack spacing="4">{renderCategories()}</Stack>
          </Box>
        </Box>
      </Container>
      <CategoryCreate onAdd={handleUpdate} {...{ isOpen, onClose }} />
    </>
  )
}

export default Categories
