import React from 'react'
import { createRoot } from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { SWRConfig } from 'swr'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'

import { trackLiveQueries } from './helpers/swrConfig'

import UserProvider from './providers/UserProvider'

import App from './domain/App'

import theme from './theme'
import './theme/styles.css'

import securedApi from './backend/axios'
const fetcher = (url) => securedApi.get(url).then((res) => res.data)

const root = createRoot(document.getElementById('root'))

root.render(
  <SWRConfig
    value={{
      fetcher,
      use: [trackLiveQueries],
    }}
  >
    <ChakraProvider {...{ theme }}>
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </ChakraProvider>
  </SWRConfig>
)
