import React from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Box, Heading, Stack, Link, Card, CardBody } from '@chakra-ui/react'

import { pluralize } from 'helpers/utils'

import HeaderDivider from 'components/General/HeaderDivider'
import LastXDaysStatic from 'components/General/LastXDaysStatic'

import ColorSchemePicker from './ColorSchemePicker'
import ClientStats from './ClientStats'

const ClientSection = ({ heading, data, showStats }) => {
  if (!data.length) {
    return null
  }

  return (
    <>
      {heading && <HeaderDivider {...{ heading }} />}
      <Stack spacing="4">
        {data.map(
          ({
            client_id,
            name,
            coaching_connection_id,
            status,
            num_scheduled_activities,
            metrics,
          }) => {
            return (
              <Link
                key={client_id}
                as={NavLink}
                to={`/coaching/client/${coaching_connection_id}`}
                border="1px"
                borderColor="transparent"
                _hover={{ borderColor: 'brand.500', cursor: 'pointer' }}
              >
                <Card rounded="0">
                  <CardBody>
                    <Heading size="sm">{name}</Heading>
                    {status === 'active' && (
                      <>
                        <Stack mt="1">
                          <Box>
                            <Badge colorScheme={ColorSchemePicker(num_scheduled_activities)}>
                              {num_scheduled_activities}{' '}
                              {pluralize(num_scheduled_activities, 'workout')} scheduled
                            </Badge>
                          </Box>
                        </Stack>
                        {showStats && (
                          <Stack>
                            <Box mt="2">
                              <LastXDaysStatic days="7" />
                            </Box>
                            <ClientStats data={metrics} />
                          </Stack>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Link>
            )
          }
        )}
      </Stack>
    </>
  )
}

export default ClientSection
