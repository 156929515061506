import { numberInputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  numberInputAnatomy.keys
)

const filled = definePartsStyle({
  field: {
    borderWidth: '1px',
    borderRadius: 'none',
    borderColor: 'gray.400',
    background: 'white',
    _active: {
      background: 'white',
    },
    _hover: {
      background: 'white',
      borderColor: 'brand.500',
    },
    _focus: {
      background: 'white',
      borderColor: 'brand.500',
    },
  },
})

export const numberInputTheme = defineMultiStyleConfig({ variants: { filled } })
