import React from 'react'
import { Routes, Route } from 'react-router-dom'

import GoalsHome from './GoalsHome'
import GoalsCreate from './GoalsCreate'
import GoalsEdit from './GoalsEdit'
import GoalsDetails from './GoalsDetails'

const Goals = () => {
  return (
    <Routes>
      <Route path="/" element={<GoalsHome />} />
      <Route path="create" element={<GoalsCreate />} />
      <Route path=":id" element={<GoalsDetails />} />
      <Route path="edit/:id" element={<GoalsEdit />} />
    </Routes>
  )
}

export default Goals
