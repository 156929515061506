import { useContext } from 'react'

import { UserContext } from 'providers/UserProvider'

const useDisplayUpgrade = () => {
  const { planContext } = useContext(UserContext)
  const [userPlan] = planContext

  //TODO check if this is still the case
  // component initial render is happening before context values are loaded
  // (then after they are loaded component is probably rerendering with available values)
  // (this is confirmed because isTrial = userPlan?.slice etc. is undefined initially)
  // => probably fine but could be tighter

  // only show upgrade button for trial accounts
  const isTrial = userPlan?.slice(0, 5) === 'Trial'
  const isPlanPurchase = userPlan?.slice(10, -1) === 'plan-purchase'
  const isCeasedCoachingAmnesty = userPlan?.includes('(ceased coaching client)')

  const displayUpgrade = isTrial || isPlanPurchase || isCeasedCoachingAmnesty

  return { displayUpgrade }
}

export default useDisplayUpgrade
