import { useNavigate } from 'react-router-dom'
import {
  Spacer,
  Box,
  Text,
  ModalHeader,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { tzSafeDateFormat } from 'helpers/utils'
import { MoreHorizontal } from 'react-feather'

const DailyMetricsModalHeader = ({ date, onNavigate }) => {
  let navigate = useNavigate()

  const displayDate = tzSafeDateFormat(date, 'DDDD')

  const handleSettingsNavigation = () => {
    onNavigate?.()
    navigate('/metrics/daily-metrics-settings')
  }

  return (
    <ModalHeader display="flex">
      <Box>
        <Text mb="2" fontSize="sm">
          {displayDate}
        </Text>
        <Text fontWeight="bold" fontSize="md">
          Daily Metrics
        </Text>
      </Box>
      <Spacer />

      <Menu gutter="0">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={
            <Icon
              as={MoreHorizontal}
              w="4"
              h="4"
              color="gray.500"
              _hover={{ cursor: 'pointer', color: 'brand.500' }}
            />
          }
          variant="none"
        />
        <MenuList fontSize="small">
          <MenuItem onClick={handleSettingsNavigation} p="2">
            Edit
          </MenuItem>
        </MenuList>
      </Menu>
    </ModalHeader>
  )
}

export default DailyMetricsModalHeader
