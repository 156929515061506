import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import {
  Badge,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Box,
  Container,
  Stack,
  Text,
  Spacer,
  Flex,
  Link,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { v4 as uuidv4 } from 'uuid'

import { useQuery } from 'hooks'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import Notify from 'components/Notification'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import { CategoryBlock } from 'domain/Workouts/CategoryBlock'

import { AddWidgetModal, EditWidgetModal, WidgetIcon } from 'domain/Widgets/components'
import { useWidgetMethods } from 'domain/Widgets/hooks'

const WidgetHeading = ({ type, title }) => (
  <Badge pt={1.5} pl={1.5} pr={2} colorScheme="brand" mb="1">
    <HStack alignContent="center" mb="1">
      <WidgetIcon {...{ type }} boxSize={3} />
      <Text>{title}</Text>
    </HStack>
  </Badge>
)

const Widgets = ({ templatedActivity }) => {
  const methods = useForm()
  const [editWidgetData, setEditWidgetData] = useState({})
  const {
    isOpen: isEditWidgetOpen,
    onOpen: onEditWidgetOpen,
    onClose: onEditWidgetClose,
  } = useDisclosure()
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()

  const { data, isLoading, hasError } = useQuery(
    `api/v1/${templatedActivity ? 'templated_widgets' : 'widgets'}?include_details=1`
  )

  const { handleAddNewWidget, handleCreateWidget, handleUpdateWidget, handleDeleteWidget } =
    useWidgetMethods({
      isWidgetsPage: true,
      setEditWidgetData: setEditWidgetData,
      onEditWidgetOpen: onEditWidgetOpen,
      onEditWidgetClose: onEditWidgetClose,
      templatedActivity: templatedActivity,
    })

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  // specific to Widgets.js
  const handleOpenEditWidget = (widget) => {
    setEditWidgetData(widget)
    onEditWidgetOpen()
  }

  const renderActivityInfo = (activities) => {
    if (!activities) {
      return
    }

    if (!activities.length) {
      return
    }

    const activityContent = activities.map((activity) => {
      const activityName = templatedActivity ? activity.activity_name : activity.name
      const url = templatedActivity ? `/coaching-workout/${activity.id}` : `/workout/${activity.id}`

      return (
        <Flex key={activity.id} fontSize="small" color="gray.500" my="2">
          <CategoryBlock colour={activity.category_hex_colour} />
          <Link as={NavLink} to={url} variant="brand" fontWeight="bold" ml="1">
            {activityName}
          </Link>
        </Flex>
      )
    })

    if (activities.length > 10) {
      return (
        <Box mt="2">
          <Text fontSize="small">This widget is linked to more than 10 workouts.</Text>
          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton paddingX="0" _hover={{ background: 'none' }}>
                <Box
                  as="span"
                  fontSize="small"
                  flex="1"
                  textAlign="left"
                  color="brand.500"
                  fontWeight="bold"
                >
                  Show workouts
                </Box>
                <AccordionIcon color="brand.500" />
              </AccordionButton>

              <AccordionPanel pb={4}>{activityContent}</AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      )
    } else {
      return (
        <Box>
          <Box fontSize="small" mt="2">
            <Text>Workouts using this widget:</Text>
          </Box>

          {activityContent}
        </Box>
      )
    }
  }

  const EditButton = ({ widget }) => {
    return (
      <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
        <Button size="xs" onClick={() => handleOpenEditWidget(widget)}>
          Edit
        </Button>
      </ButtonGroup>
    )
  }

  const renderLogbooks = () => {
    if (!data.logbook.length) {
      return
    }

    return (
      <Box>
        <WidgetHeading type="logbook" title="Logbooks" />
        <Box fontSize="small" mb="2">
          <Text>
            Your account has {data.logbook.length} logbooks. These can't be edited or deleted.
          </Text>
        </Box>
        <Stack spacing="14px">
          {data.logbook.map((logbook) => {
            const activities = templatedActivity
              ? logbook.content.templated_activities
              : logbook.content.activities

            return (
              <Box key={logbook.id} background="#fff" p="4">
                <HStack>
                  <Text fontWeight="semibold" textTransform="capitalize">
                    {logbook.content.name}
                  </Text>
                  <Spacer />
                  <EditButton widget={logbook} />
                </HStack>
                {renderActivityInfo(activities)}
              </Box>
            )
          })}
        </Stack>
      </Box>
    )
  }

  const renderMeasures = () => {
    if (!data.measure.length) {
      return
    }

    return (
      <Box>
        <WidgetHeading type="measure" title="Measures" />
        <Stack spacing="14px">
          {data.measure.map((measure) => {
            const filteredActivities = templatedActivity
              ? measure.content.templated_activities
              : measure.content.activities

            return (
              <Box key={measure.id} background="#fff" p="4">
                <HStack>
                  <Box>
                    <Flex alignItems="center">
                      <Text fontWeight="semibold" textTransform="capitalize">
                        {measure.content.name}
                      </Text>
                      <Badge ml="2">{measure.content.units}</Badge>
                      {!!measure.content.smart_default && (
                        <Badge ml="2" variant="outline">
                          Smart Default
                        </Badge>
                      )}
                    </Flex>
                  </Box>
                  <Spacer />
                  <EditButton widget={measure} />
                </HStack>
                {renderActivityInfo(filteredActivities)}
              </Box>
            )
          })}
        </Stack>
      </Box>
    )
  }

  const renderSelects = () => {
    if (!data.select.length) {
      return
    }

    return (
      <Box>
        <WidgetHeading type="select" title="Selects" />
        <Stack spacing="14px">
          {data.select.map((select) => {
            const filteredActivities = templatedActivity
              ? select.content.templated_activities
              : select.content.activities

            return (
              <Box key={select.id} background="#fff" p="4">
                <HStack>
                  <Box>
                    <Flex alignItems="center">
                      <Text fontWeight="semibold" textTransform="capitalize">
                        {select.content.name}
                      </Text>
                      <Badge ml="2">{select.content.options?.length} options</Badge>
                    </Flex>
                  </Box>
                  <Spacer />
                  <EditButton widget={select} />
                </HStack>
                {renderActivityInfo(filteredActivities)}
              </Box>
            )
          })}
        </Stack>
      </Box>
    )
  }

  const renderSliders = () => {
    if (!data.slider.length) {
      return
    }

    return (
      <Box>
        <WidgetHeading type="slider" title="Sliders" />
        <Box fontSize="small" mb="2">
          <Text>
            Your account has {data.slider.length} sliders. These can't be edited or deleted.
          </Text>
        </Box>
        <Stack spacing="14px">
          {data.slider.map((slider) => {
            const filteredActivities = templatedActivity
              ? slider.content.templated_activities
              : slider.content.activities

            return (
              <Box key={slider.id} background="#fff" p="4">
                <HStack>
                  <Text fontWeight="semibold" textTransform="capitalize">
                    {slider.content.name}
                  </Text>
                </HStack>
                {renderActivityInfo(filteredActivities)}
              </Box>
            )
          })}
        </Stack>
      </Box>
    )
  }

  return (
    <Box pb="20">
      <Container maxWidth="container.lg">
        <InnerPageWrapperActions>
          <Link
            href="https://docs.sequence-app.com/features/Widgets/widgets"
            isExternal
            variant="brand"
            fontWeight="bold"
          >
            Learn more about widgets <ExternalLinkIcon />
          </Link>
        </InnerPageWrapperActions>

        <Flex direction="column" alignItems="center">
          <FormProvider {...methods}>
            <Stack spacing="14px" maxWidth="500px" width="100%">
              <HStack mb="4">
                <Heading size="lg">Widgets</Heading>
                <Spacer />
                <Button onClick={onAddOpen} variant="primary" size={['sm', 'md']}>
                  New Widget
                </Button>
              </HStack>

              {renderSliders()}
              {renderSelects()}
              {renderLogbooks()}
              {renderMeasures()}
            </Stack>
            {isEditWidgetOpen && (
              <EditWidgetModal
                type={editWidgetData.type}
                widget={editWidgetData}
                onUpdateWidget={(updatedWidgetData) => handleUpdateWidget(updatedWidgetData)}
                onDeleteWidget={() => handleDeleteWidget(editWidgetData)}
                onCreateWidget={handleCreateWidget}
                isEditWidgetOpen={isEditWidgetOpen}
                onEditWidgetClose={onEditWidgetClose}
              />
            )}
          </FormProvider>
        </Flex>
        <AddWidgetModal onAddNew={handleAddNewWidget} isOpen={isAddOpen} onClose={onAddClose} />
      </Container>
    </Box>
  )
}

export default Widgets
