import MetricsContainer from 'domain/Metrics/MetricsContainer'
import SelectMetrics from './SelectMetrics'

const SelectMetricsWrapper = () => {
  return (
    <MetricsContainer>
      <SelectMetrics />
    </MetricsContainer>
  )
}

export default SelectMetricsWrapper
