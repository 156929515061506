import { useState } from 'react'
import {
  Text,
  Link,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { NavLink, useNavigate } from 'react-router-dom'
import { MoreHorizontal } from 'react-feather'

import securedApi from 'backend/axios'

import { processApiError } from 'helpers/utils'

import ImportButton from 'domain/Workouts/WorkoutComponents/ImportButton'

import Notify from 'components/Notification'
import Modal from 'components/Modal'

const ActionsMenu = ({ id, isEditable = true, isSharable }) => {
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)

  const confirmDeleteWorkout = () => {
    setModalOpen(true)
  }

  const handleDuplicateWorkout = () => {
    securedApi
      .post(`api/v1/templated_activities/${id}/duplicate`)
      .then(({ data }) => {
        navigate(`/coaching-workout/${data.id}/edit`)

        Notify({ content: 'Workout duplicated', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  const deleteWorkout = () => {
    securedApi
      .delete(`api/v1/templated_activities/${id}`)
      .then(() => {
        navigate('/coaching/workouts')
        Notify({ content: 'Workout deleted', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  return (
    <>
      <Menu gutter="0">
        <MenuButton
          as={IconButton}
          icon={
            <Icon
              as={MoreHorizontal}
              w="4"
              h="4"
              color="gray.500"
              _hover={{ cursor: 'pointer', color: 'brand.500' }}
            />
          }
          variant="none"
        />
        <MenuList>
          {isEditable && (
            <>
              <MenuItem>
                <Link
                  as={NavLink}
                  to={`/coaching-workout/${id}/edit`}
                  width="100%"
                  p="2"
                  _hover={{ textDecoration: 'none' }}
                >
                  <Text fontSize="sm" fontWeight="normal">
                    Edit
                  </Text>
                </Link>
              </MenuItem>
              <MenuItem>
                <Text fontSize="sm" width="100%" p="2" onClick={handleDuplicateWorkout}>
                  Duplicate
                </Text>
              </MenuItem>
              <MenuDivider />
            </>
          )}

          {isSharable != null && (
            <>
              <MenuItem isDisabled={!isSharable} display="block">
                <ImportButton {...{ id }} />
                {!isSharable && (
                  <Text fontSize="xs" fontStyle="italic" p="2">
                    Select a client to enable add
                  </Text>
                )}
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuItem>
            <Text
              fontSize="sm"
              fontWeight="normal"
              p="2"
              width="100%"
              onClick={confirmDeleteWorkout}
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        title="Are you sure?"
        subTitle="This workout will be deleted. It will not delete any copies previously added to clients."
        onAccept={deleteWorkout}
      ></Modal>
    </>
  )
}

export default ActionsMenu
