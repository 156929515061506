import React from 'react'
import { Box, Tr, Th, Tooltip, useDisclosure } from '@chakra-ui/react'

import { EditWidgetModal } from 'domain/Widgets/components'
import { LOGBOOK_TYPE } from 'domain/Widgets/constants'
import { useWidgetMethods } from 'domain/Widgets/hooks'

import { tableWidths } from 'domain/Widgets/Logbook/constants'

const HeaderRow = ({ logbook, isAdding, isEditing, triggerRerender }) => {
  // need to convert the logbook object to a widget 'content' type object for the edit modal
  const widget = {
    id: logbook.id,
    type: 'logbook',
    content: logbook,
  }

  const {
    isOpen: isEditWidgetOpen,
    onOpen: onEditWidgetOpen,
    onClose: onEditWidgetClose,
  } = useDisclosure()

  const { handleUpdateWidget } = useWidgetMethods({
    onEditWidgetOpen: onEditWidgetOpen,
    onEditWidgetClose: onEditWidgetClose,
  })

  const handleHeaderClickGrade = () => {
    if (isAdding || isEditing) {
      // can't change grade scale while creating/editing an entry
    } else {
      onEditWidgetOpen()
    }
  }

  const labelGradeId = (
    <Box align="center">
      Grade
      <br />
      The values here are for the chosen grade scale
      <br />
      You can change this here if you aren't making an entry or on the Widgets page at any time
      <br />
      <br />
      If an entry is for a different grade scale than currently selected then you can't change it
    </Box>
  )

  const labelAttempts = (
    <Box align="center">
      Attempts
      <br />
      Either put in the number of attempts taken
      <br />
      or leave empty and that will just count as 1
    </Box>
  )

  const HeaderTooltip = ({ label, children }) => {
    return (
      <Tooltip label={label}>
        <Box cursor="pointer">{children}</Box>
      </Tooltip>
    )
  }

  const EditLogbookWidgetModal = () => {
    return (
      <EditWidgetModal
        type={LOGBOOK_TYPE}
        widget={widget}
        onUpdateWidget={(updatedWidgetData) => {
          handleUpdateWidget(updatedWidgetData)
          triggerRerender()
        }}
        isEditWidgetOpen={isEditWidgetOpen}
        onEditWidgetClose={onEditWidgetClose}
      />
    )
  }

  return (
    // widths are needed here in case there are no content rows
    // (and they need to be the same as those for a content row of course)
    <Tr>
      <Th maxWidth={tableWidths.name}>Name</Th>
      <Th width={tableWidths.grade_id} onClick={handleHeaderClickGrade}>
        <HeaderTooltip label={labelGradeId}>Grade</HeaderTooltip>
        {isEditWidgetOpen && <EditLogbookWidgetModal />}
      </Th>
      <Th width={tableWidths.style}>Style</Th>
      <Th width={tableWidths.attempts}>
        <HeaderTooltip label={labelAttempts}>Att.</HeaderTooltip>
      </Th>
      <Th maxWidth={tableWidths.notes}>Notes</Th>
      <Th width={tableWidths.buttons}></Th>
    </Tr>
  )
}

export default HeaderRow
