import { isSameDay, parseISO } from 'date-fns'

import securedApi from 'backend/axios'
import { processApiError, reorder } from 'helpers/utils'

/**
 * Moves a workout item from one list to another list.
 */
export const moveWorkout = (source, destination, currentSourceDay, currentDestinationDay) => {
  const sourceClone = Array.from(source[0].content)
  const destClone = Array.from(destination[0].content)

  const [removed] = sourceClone.splice(currentSourceDay.index, 1)

  destClone.splice(currentDestinationDay.index, 0, removed)

  source[0].content = sourceClone
  destination[0].content = destClone

  const result = [source, destination]

  return result
}

export const moveNote = (source, destination, currentSourceDay, currentDestinationDay) => {
  const sourceClone = Array.from(source[0].notes)
  const destClone = Array.from(destination[0].notes)

  const [removed] = sourceClone.splice(currentSourceDay.index, 1)

  destClone.splice(currentDestinationDay.index, 0, removed)

  source[0].notes = sourceClone
  destination[0].notes = destClone

  const result = [source, destination]

  return result
}

export const workoutManipulation = ({ result, data }) => {
  const { source, destination, draggableId } = result

  // dropped outside the list
  if (!destination) {
    return
  }

  if (destination.droppableId === source.droppableId) {
    const items = reorder(data[destination.droppableId].content, source.index, destination.index)

    data[destination.droppableId].content = items

    const reorderedPosition = items.findIndex((item) => item.id === `${draggableId}`)

    securedApi
      .put(`api/v1/scheduled_activities/${draggableId}/reorder`, {
        pos: reorderedPosition,
      })
      .catch((error) => {
        processApiError(error, 'Error moving workout', function () {
          // TODO undo the reorder in the UI
        })
      })
  }

  if (destination.droppableId !== source.droppableId) {
    const currentSourceDay = data[source.droppableId]

    const currentDestinationDay = data[destination.droppableId]

    const result = moveWorkout([currentSourceDay], [currentDestinationDay], source, destination)

    const positionOfMovedItem = result[1]
      .map(({ content }) => {
        return content
      })[0]
      .findIndex((item) => item.id === draggableId)

    securedApi
      .put(`api/v1/scheduled_activities/${draggableId}/move`, {
        date: currentDestinationDay.date,
        pos: positionOfMovedItem,
      })
      .catch((error) => {
        processApiError(error, 'Error moving workout', function () {
          // TODO undo the move in the UI
        })
      })
  }
}

export const noteManipulation = ({ result, data }) => {
  const { source, destination, draggableId } = result

  // dropped outside the list
  if (!destination) {
    return
  }

  if (destination.droppableId === source.droppableId) {
    //droppableId contains "note-"
    const realDestinationDroppableIdId = destination.droppableId.split('-').pop()

    const items = reorder(data[realDestinationDroppableIdId].notes, source.index, destination.index)

    data[realDestinationDroppableIdId].notes = items

    const reorderedPosition = items.findIndex((item) => item.id === `${draggableId}`)

    securedApi
      .put(`api/v1/scheduled_notes/${draggableId}/reorder`, {
        pos: reorderedPosition,
      })
      .catch((error) => {
        processApiError(error, 'Error moving workout', function () {
          // TODO undo the reorder in the UI
        })
      })
  }

  if (destination.droppableId !== source.droppableId) {
    //droppableId contains "note-"
    const realDestinationDroppableIdId = destination.droppableId.split('-').pop()
    const realSourceDroppableIdId = source.droppableId.split('-').pop()

    const currentSourceDay = data[realSourceDroppableIdId]

    const currentDestinationDay = data[realDestinationDroppableIdId]

    const result = moveNote([currentSourceDay], [currentDestinationDay], source, destination)

    const positionOfMovedItem = result[1]
      .map(({ notes }) => {
        return notes
      })[0]
      .findIndex((item) => item.id === draggableId)

    securedApi
      .put(`api/v1/scheduled_notes/${draggableId}/move`, {
        date: currentDestinationDay.date,
        pos: positionOfMovedItem,
      })
      .catch((error) => {
        processApiError(error, 'Error moving note', function () {
          // TODO undo the move in the UI
        })
      })
  }
}

export const isRestDay = (day, data) =>
  data.some((dayData) =>
    dayData.notes.some((note) => note.style === 'rest' && isSameDay(parseISO(dayData.date), day))
  )
