import React, { useState } from 'react'
import { Flex } from '@chakra-ui/react'

import { useDemoData, useMetricsDays, useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'

import CategoryPieChart from './components/CategoryPieChart'
import { BreakdownLink, CategoryBarChart } from './components/CategoryBarChart'

const Category = () => {
  const [sessionBreakdownType, setSessionBreakdownType] = useState('time')

  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const { data, isLoading, hasError } = useQuery(
    `api/v1/metrics/breakdown_by_category?demo=${demoData}&days=${days}`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { categories } = data.breakdown_by_category

  return (
    <>
      <Flex marginLeft={3} marginTop={2} marginBottom={1}>
        <BreakdownLink
          type="time"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
        <BreakdownLink
          type="total"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
      </Flex>
      <CategoryBarChart data={categories} breakdown={sessionBreakdownType} />
      <CategoryPieChart data={categories} breakdown={sessionBreakdownType} />
    </>
  )
}

export default Category
