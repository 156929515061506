import { NavLink } from 'react-router-dom'
import { Link, MenuItem } from '@chakra-ui/react'

export const NavItem = ({ to, title, fontSize = 'lg' }) => (
  <Link
    as={NavLink}
    color="gray.500"
    _activeLink={{ color: 'black' }}
    _hover={{ textDecoration: 'none', color: 'brand.500' }}
    end
    {...{ fontSize, to }}
  >
    {title}
  </Link>
)

export const MenuLink = ({ to, title }) => {
  return (
    <MenuItem as={NavLink} to={to} _activeLink={{ color: 'black' }} p="2">
      {title}
    </MenuItem>
  )
}
