import { useRadio, useRadioGroup, Box, HStack } from '@chakra-ui/react'

export const EmojiDisplay = ({ lookup }) => {
  const emojiKeyDisplay = [
    { key: 'none', display: '💪' },
    { key: 'light', display: '💪🏻' },
    { key: 'medium-light', display: '💪🏼' },
    { key: 'medium', display: '💪🏽' },
    { key: 'medium-dark', display: '💪🏾' },
    { key: 'dark', display: '💪🏿' },
    { key: 'lgbtqiap', display: '🏳️‍🌈' },
  ]

  var keys = emojiKeyDisplay.map((x) => x.key)
  if (!keys.includes(lookup)) {
    lookup = 'none'
  }

  return emojiKeyDisplay.filter(function (val) {
    return val.key === lookup
  })[0].display
}

export const EmojiPicker = ({ name, defaultValue, onChange }) => {
  const options = ['none', 'light', 'medium-light', 'medium', 'medium-dark', 'dark', 'lgbtqiap']

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: defaultValue,
    onChange: onChange,
  })

  const group = getRootProps()

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={value} {...radio}>
            <EmojiDisplay lookup={value} />
          </RadioCard>
        )
      })}
    </HStack>
  )
}

export const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="14px"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          color: 'white',
          borderColor: 'orange.300',
          borderWidth: '2px',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={2}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  )
}
