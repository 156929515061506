import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Button, Stack, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

import { AuthWrapper } from './Elements'

const UnsubscribeDaily = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const handleUnsubscribeDaily = () => {
    securedApi
      .post('accounts/unsubscribe_daily', {
        token,
      })
      .then((response) => {
        if (response.data.error) {
          Notify({ content: response.data.error, type: 'error' })
          return
        }
        Notify({ content: response.data.status, type: 'success' })
        navigate('/')
      })
      .catch((error) => {
        Notify({ content: error.response.data.error, type: 'error' })
      })
  }

  return (
    <AuthWrapper>
      <Stack>
        <Box>
          <Text align="center">
            Click to unsubscribe from{' '}
            <Text as="span" fontWeight="bold">
              daily updates
            </Text>
            .
          </Text>
          <Text align="center">You can turn them back on in Settings at any time.</Text>
        </Box>
        <Button colorScheme="brand" onClick={handleUnsubscribeDaily}>
          Unsubscribe
        </Button>
      </Stack>
    </AuthWrapper>
  )
}

export default UnsubscribeDaily
