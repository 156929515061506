import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Flex, Link, Text, Center } from '@chakra-ui/react'

import CheckoutFormWrapper from 'components/Payment/CheckoutFormWrapper'
import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { tzSafeDateFormat } from 'helpers/utils'
import { useQuery } from 'hooks'

import SubscriptionLimit from './SubscriptionLimit'
import SubscriptionMessage from './SubscriptionMessage'

const SubscriptionCreate = () => {
  const revalidateOnFocus = false
  const { data, isLoading, hasError } = useQuery('/accounts/status', revalidateOnFocus)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { next_payment_date } = data

  const formattedPaymentDate = next_payment_date
    ? tzSafeDateFormat(next_payment_date, 'd MMM yyyy')
    : null

  const messages = {
    trialInProgress: {
      title: 'Subscribe to Sequence',
      subtitle: 'Take your climbing to the next level',
    },
    trialEnded: {
      title: 'Your account has reached the trial limit',
      subtitle: 'Subscribe today to continue to use Sequence',
    },
    subscriptionCancelled: {
      title: 'Reactivate your Sequence subscription',
      subtitle: 'Take your climbing to the next level',
    },
    bonusSubscriptionWithPlanPurchase: {
      title: 'Continue your Sequence subscription',
      subtitle: (
        <>
          <Text>Take your climbing to the next level</Text>
          <Text mt={2}>
            You received a bonus 3 month subscription to Sequence by purchasing a training plan
          </Text>

          <Text>This will end on {formattedPaymentDate}</Text>
          <Text mt={2}>Subscribe today to ensure no interruptions to your account</Text>
          <Text>You won't be charged until your bonus subscription ends</Text>
        </>
      ),
    },
    // subscriptionExpired: {
    //   title: "Your subscription has expired",
    //   subtitle: "Please ensure your payment is up to date",
    // },
  }

  const messagesOption = data.trial
    ? data.valid
      ? 'trialInProgress'
      : 'trialEnded'
    : data.bonus_subscription_with_plan_purchase
    ? 'bonusSubscriptionWithPlanPurchase'
    : data.subscribed_while_on_plan_purchase
    ? 'subscribedWhileOnPlanPurchase'
    : data.valid_subscription
    ? 'alreadySubscribed'
    : 'subscriptionCancelled'

  const alreadySubscribed = ['alreadySubscribed', 'subscribedWhileOnPlanPurchase'].includes(
    messagesOption
  )
  const subscriptionExpired = messagesOption === 'subscriptionExpired' // not implemented yet
  const subscriptionCancelled = messagesOption === 'subscriptionCancelled'

  const Header = ({ messagesOption }) => {
    const { title, subtitle } = messages[messagesOption]

    return (
      <Box>
        <Text fontSize="large" fontWeight="bold" align="center">
          {title}
        </Text>
        <Text align="center">{subtitle}</Text>
      </Box>
    )
  }

  const Payment = () => {
    return (
      <Box mt={{ base: 5, lg: 10 }} mb={{ base: 2, lg: 10 }}>
        {subscriptionExpired ? (
          <Text>
            Go to your{' '}
            <Link as={NavLink} to="/settings/account">
              account page
            </Link>{' '}
            to manage your account.
          </Text>
        ) : (
          <CheckoutFormWrapper />
        )}
      </Box>
    )
  }

  const PleaseContactUs = () => {
    return (
      <Box>
        <Text fontSize="small">
          Any questions, please <Link href="mailto:hello@sequence-app.com">contact us</Link>.
        </Text>
        {data.valid ? null : (
          <Text fontSize="small">
            Don't want to subscribe? You can{' '}
            <Link as={NavLink} to="/settings/account">
              export your data
            </Link>
            .
          </Text>
        )}
      </Box>
    )
  }

  return (
    <Box>
      <Center mt="10">
        <Box>{subscriptionCancelled ? null : <SubscriptionLimit data={data} />}</Box>
      </Center>
      {alreadySubscribed && (
        <Center mt="10">
          <Text>You are already subscribed!</Text>
        </Center>
      )}
      <Center mt={{ base: 2, lg: 10 }} p="4">
        <Box
          display="flex"
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          gap={{ base: 6, lg: 12 }}
        >
          {!alreadySubscribed && (
            <Box>
              <Flex flexDirection="column" alignItems="center">
                <>
                  <Header messagesOption={messagesOption} />
                  <Payment />
                  <PleaseContactUs />
                </>
              </Flex>
            </Box>
          )}

          <Box
            background="#fff"
            padding="4"
            boxShadow="md"
            maxW="400px"
            display={{ base: 'none', md: 'block' }}
          >
            <SubscriptionMessage />
          </Box>
        </Box>
      </Center>
    </Box>
  )
}

export default SubscriptionCreate
