import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Container, Button, ButtonGroup, Spacer } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

import LoadingSpinner from 'components/LoadingSpinner'
import { FormHeading } from 'components/Forms'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import ActionsMenu from 'domain/Workouts/WorkoutComponents/ActionsMenu'

import {
  NameSection,
  CategorySection,
  DurationSection,
  DescriptionSection,
  WidgetSection,
  OnRockSection,
  DetailsSection,
} from './FormComponents'

import { processGetError, processApiError, categoryOptionFormatter } from 'helpers/utils'

const WorkoutEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()

  const methods = useForm()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [category, setCategory] = useState()
  const [options, setOptions] = useState([])
  const [description, setDescription] = useState('')
  const [duration, setDuration] = useState('')
  const [details, setDetails] = useState('')
  const [outdoor, setOutdoor] = useState(false)
  const [archived, setArchived] = useState(false)
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    setLoading(true)

    securedApi
      .get('api/v1/categories')
      .then((response) => {
        setOptions(categoryOptionFormatter(response.data))
      })
      .catch((error) => processGetError(error))

    securedApi
      .get(`api/v1/activities/${id}`)
      .then(({ data }) => {
        setName(data.activity_name)
        setCategory({
          label: data.category_name,
          value: data.category_id,
        })

        setDescription(data.activity_description)
        setDetails(data.activity_details)
        setOutdoor(data.outdoor || false)
        setArchived(data.archived)

        setWidgets(data.widgets)

        const adjustedDuration = Math.floor(Number(data.duration) / 60 / 1000)
        setDuration(adjustedDuration ? adjustedDuration : '')
      })
      .catch((error) => processGetError(error))
      .finally(() => setLoading(false))
  }, [id])

  const onSubmit = (data) => {
    const { name, description, category, details, duration, outdoor } = data

    const adjustedDuration = Math.floor(Number(duration) * 60 * 1000)

    setLoading(true)

    securedApi
      .patch(`api/v1/activities/${id}`, {
        activity: {
          name,
          description,
          category_id: category.value,
          details,
          duration: adjustedDuration,
          outdoor,
        },
      })
      .then(() => {
        navigate('/workouts')
        Notify({ content: 'Workout updated', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => setLoading(false))
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const isSharable = false
  const isArchived = archived

  return (
    <Container maxW="container.lg" mb="28" data-cy="workout-edit">
      <InnerPageWrapperActions>
        <ActionsMenu isEditable={false} {...{ id, isSharable, isArchived }} />
      </InnerPageWrapperActions>
      <Container maxW="container.sm">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHeading heading="Edit Workout" />

            <NameSection defaultValue={name} {...{ register, errors }} />
            <DescriptionSection defaultValue={description} {...{ register, errors }} />
            <CategorySection defaultValue={category} {...{ control, options, errors }} />
            <DurationSection defaultValue={duration} {...{ register }} />
            <OnRockSection defaultValue={outdoor} {...{ register }} />
            <WidgetSection activityId={id} createWidgets={widgets} setCreateWidgets={setWidgets} />

            <DetailsSection defaultValue={details} {...{ control }} />

            <ButtonGroup mt="10" spacing="4" display="flex" alignItems="center">
              <Spacer />
              <Button variant="outline" onClick={() => navigate(`/workout/${id}`)} data-cy="cancel">
                Cancel
              </Button>

              <Button
                type="submit"
                isLoading={loading}
                loadingText="Updating"
                colorScheme="brand"
                data-cy="submit"
              >
                Update
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Container>
    </Container>
  )
}

export default WorkoutEdit
