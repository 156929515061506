import React from 'react'
import { Container } from '@chakra-ui/react'

import CreateNoteForm from 'domain/Notes/components/CreateNoteForm'

const MilestoneCreate = () => {
  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <CreateNoteForm isMilestone={true} />
    </Container>
  )
}

export default MilestoneCreate
