import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processApiError } from 'helpers/utils'

import CategoryColorPicker from './CategoryColorPicker'

const schema = z.object({
  name: z.string().min(1, { message: 'Name required' }),
})

const CategoryCreate = ({ onAdd, isOpen, onClose }) => {
  const [currentColor, setCurrentColor] = useState('#03a9f4')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const handleSetColor = (value) => {
    setCurrentColor(value)
  }

  const onSubmit = ({ name }) => {
    securedApi
      .post(`/api/v1/categories`, {
        name,
        hex_colour: currentColor,
      })
      .then(() => {
        Notify({ content: 'Category has been created', type: 'success' })
        onAdd()
        onClose()
      })
      .catch((error) => {
        processApiError(error)
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="2" pb="6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Flex alignItems="flex-start">
                <CategoryColorPicker onSetColor={handleSetColor} {...{ currentColor }} />
                <FormControl isInvalid={errors.name}>
                  <Input
                    {...register('name', {
                      required: true,
                    })}
                    autoFocus={true}
                  />
                  <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <ButtonGroup spacing="4" marginTop="4" display="flex">
                <Spacer />
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>

                <Button type="submit" colorScheme="brand">
                  Save
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CategoryCreate
