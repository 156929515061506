import { useEffect } from 'react'
import { mutate } from 'swr'

export let liveQueries = new Set()

export function trackLiveQueries(useSWRNext) {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      liveQueries.add(key)

      return () => {
        liveQueries.delete(key)
      }
    }, [key])

    return swr
  }
}

export async function revalidateLiveQueries() {
  let promises = [...liveQueries.values()].map((key) => mutate(key))

  return Promise.all(promises)
}
