import { Menu as MenuIcon, User as UserIcon } from 'react-feather'
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'

import { useCoaching } from 'hooks'
import { useSignOut, useDisplayUpgrade } from 'domain/Navigation/hooks'

import UpgradeButton from 'components/General/UpgradeButton'

import { MenuLink } from 'domain/Navigation/Components/Links'
import CoachingModeToggle from 'domain/Navigation/CoachingModeToggle'

export const MainMobileNavigation = () => {
  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)
  const { displayUpgrade } = useDisplayUpgrade()

  const generalLinks = [
    {
      to: '/dashboard',
      title: 'Dashboard',
    },
    {
      to: '/planner',
      title: 'Planner',
    },
    {
      to: '/workouts',
      title: 'Workouts',
    },
    {
      to: '/plans',
      title: 'Plans',
    },
    {
      divider: true,
    },
    {
      to: '/metrics',
      title: 'Metrics',
    },
    {
      to: '/goals',
      title: 'Goals',
    },
    {
      to: '/tests',
      title: 'Tests',
    },
  ]

  const coachingLinks = [
    {
      to: '/coaching',
      title: 'Dashboard',
    },
    {
      to: '/coaching/clients',
      title: 'Clients',
    },
    {
      to: '/coaching/workouts',
      title: 'Coaching Workouts',
    },
    {
      to: '/coaching/billing',
      title: 'Billing',
    },
  ]

  const links = isCoachingInterfaceEnabled ? coachingLinks : generalLinks

  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Navigation" icon={<MenuIcon />} variant="ghost" />
      <MenuList p="1">
        {links.map(({ to, title, divider }, idx) => {
          if (divider) {
            return <MenuDivider key={idx} />
          } else {
            return <MenuLink key={idx} to={to} title={title} p="2" />
          }
        })}
        {displayUpgrade && (
          <Flex p="2">
            <UpgradeButton />
          </Flex>
        )}
      </MenuList>
    </Menu>
  )
}

export const SecondaryMobileNavigation = () => {
  const { signOut } = useSignOut()

  const links = [
    {
      to: '/settings',
      title: 'Settings',
    },
    {
      divider: true,
    },
  ]

  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="User settings" icon={<UserIcon />} variant="ghost" />
      <MenuList p="1">
        <CoachingModeToggle isMobile />
        {links.map(({ to, title, divider }, idx) => {
          if (divider) {
            return <MenuDivider key={idx} />
          } else {
            return <MenuLink key={idx} to={to} title={title} p="2" />
          }
        })}
        <MenuItem onClick={signOut} p="2">
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
