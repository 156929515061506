import React, { useEffect, useState, useRef } from 'react'
import { ReferenceLine, Area, YAxis, Tooltip, Text } from 'recharts'
import { Box, Flex, Text as CText } from '@chakra-ui/react'
import { groupBy } from 'lodash'

import { useDemoData, useMetricsDays } from 'hooks'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { calcYDomain, calcYTicks } from 'helpers/charts'
import { processGetError } from 'helpers/utils'
import VolumeTooltip from 'domain/Metrics/components/VolumeTooltip'

import { MetricsChart } from './CommonMeasure'

const Volume = () => {
  const isFirstRender = useRef(true)

  const [metricsData, setMetricsData] = useState({})
  const [metricsBaseData, setMetricsBaseData] = useState({})
  const [metricsWithFutureData, setMetricsWithFutureData] = useState({})

  const [max, setMax] = useState(0)
  const [annotations, setAnnotations] = useState({})
  const [annotationsBase, setAnnotationsBase] = useState({})
  const [annotationsFuture, setAnnotationsFuture] = useState({})

  const [categories, setCategories] = useState({})

  const [showFuture, setShowFuture] = useState(false)
  const [showPastMissing, setShowPastMissing] = useState(true)
  const [showTodayLine, setShowTodayLine] = useState(false)

  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  useEffect(() => {
    securedApi
      .get(`api/v1/metrics/hours_by_category?demo=${demoData}&days=${days}`)
      .then((response) => {
        const base = response.data.hours_by_category
        setMetricsBaseData(base.data)
        setMetricsWithFutureData(base.future_data)
        setAnnotationsBase(base.annotations)
        setAnnotationsFuture(base.future_annotations)
        setCategories(base.categories)
        setMax(base.max)

        if (showFuture) {
          setMetricsData(base.future_data)
          setAnnotations(base.future_annotations)
        } else {
          setMetricsData(base.data)
          setAnnotations(base.annotations)
        }
      })
      .catch((error) => {
        processGetError(error)
      })
  }, [demoData, days])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      if (showFuture) {
        setMetricsData(metricsWithFutureData)
        setAnnotations(annotationsFuture)
        setShowTodayLine(true)
      } else {
        setMetricsData(metricsBaseData)
        setAnnotations(annotationsBase)
        setShowTodayLine(false)
      }
    }
  }, [showFuture])

  if (!Object.keys(metricsData).length) {
    return <LoadingSpinner />
  }

  const y_domain = calcYDomain([0, max])
  const { y_ticks } = calcYTicks(y_domain)

  return (
    <MetricsChart
      item={{ data: metricsData }}
      annotations={annotations}
      yMin={'zero'}
      yMax={'sum'}
      externalChartOptions={{
        future: { state: showFuture, setState: setShowFuture },
        missing: { state: showPastMissing, setState: setShowPastMissing },
      }}
      tooltip={
        <Tooltip
          allowEscapeViewBox={{ x: true }}
          position={{ y: 30 }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{
            opacity: 0.8,
            position: 'relative',
            left: 20,
          }}
          content={<VolumeTooltip {...{ showPastMissing }} />}
        />
      }
    >
      <YAxis
        yAxisId="left"
        orientation="left"
        domain={[y_domain.y_domain_min, y_domain.y_domain_max]}
        ticks={y_ticks}
        label={
          <Text x={0} y={0} dx={50} dy={200} offset={0} angle={-90}>
            Hours
          </Text>
        }
      />
      {categories.map((category) => {
        const { id, name, hex_colour } = category

        const keyPast = id
        const keyMissing = `${id}_miss`
        const keyFuture = `${id}_future`

        return (
          <React.Fragment key={id}>
            <Area
              yAxisId="left"
              key={keyPast}
              type="monotone"
              dataKey={keyPast}
              stackId="stackPast"
              strokeWidth={0}
              activeDot={false}
              fill={hex_colour}
              fillOpacity={1}
              name={name}
              animationDuration={0}
            />
            {showPastMissing && (
              <Area
                yAxisId="left"
                key={keyMissing}
                type="monotone"
                dataKey={keyMissing}
                stackId="stackPast"
                strokeWidth={0}
                activeDot={false}
                fill={hex_colour}
                fillOpacity={0.7}
                name={`${name} (missing)`}
                animationDuration={0}
              />
            )}
            {showFuture && (
              <Area
                yAxisId="left"
                key={keyFuture}
                type="monotone"
                dataKey={keyFuture}
                stackId="stackFuture"
                strokeWidth={0}
                fill={hex_colour}
                fillOpacity={0.3}
                activeDot={false}
                name={`${name} (future)`}
                animationDuration={0}
              />
            )}
          </React.Fragment>
        )
      })}
      {showTodayLine && (
        <ReferenceLine
          yAxisId="left"
          x={annotationsFuture.today_line}
          stroke={'#223344'}
          strokeWidth={1}
        />
      )}
    </MetricsChart>
  )
}

export default Volume
