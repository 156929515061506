import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Box,
  UnorderedList,
  ListItem,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'

import { useStateManager } from 'hooks'

import { FormHeading } from 'components/Forms'
import ActionLink from 'components/General/ActionLink'
import Notify from 'components/Notification'
import { EmailField, PasswordFields, SignUpButton } from 'domain/Auth/Elements'

const schema = z
  .object({
    name: z.string().min(1, { message: 'Required' }),
    email: z.string().email().min(2),
    password: z.string().min(6, { message: 'Required - must have at least 6 characters' }),
    password_confirmation: z.string().min(6, { message: 'Required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const SignUp = () => {
  let navigate = useNavigate()

  const { clearAll, setSignin, setAccountStatus } = useStateManager()

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ name, email, password, password_confirmation }) => {
    setLoading(true)
    securedApi
      .post('/signup', {
        signup: {
          name,
          email,
          password,
          password_confirmation,
        },
      })
      .then((response) => {
        signUpSuccessful(response)
      })
      .catch((error) => {
        signUpFailed(error)
      })
  }

  const signUpSuccessful = (response) => {
    if (!response.data.csrf) {
      signUpFailed(response)
      return
    }

    setSignin(response.data)
    setAccountStatus(response.data.user)

    const { id, name, email, plan } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    navigate('/welcome')
  }

  const signUpFailed = (error) => {
    const errorContent = error.response?.data?.error || 'Error signing up. Please try again.'
    Notify({
      content: errorContent,
      type: 'error',
    })

    setLoading(false)

    clearAll()
  }

  return (
    <Flex width="100%" py="10" px="4" justifyContent="center">
      <Box display={['none', 'none', 'block']} marginRight="20" marginTop="150px">
        <Text fontSize="lg" fontWeight="semibold">
          A platform that empowers you to
          <br /> be a better climber.
        </Text>
        <UnorderedList ml="0" pl="4">
          <ListItem>Build and plan your training in seconds</ListItem>
          <ListItem>Full control over the way you train</ListItem>
          <ListItem>Completely customisable workouts</ListItem>
          <ListItem>Analyze your training (and your climbing!)</ListItem>
          <ListItem>And so much more...</ListItem>
        </UnorderedList>
        <Text fontSize="lg" fontWeight="semibold">
          Built by climbers, for climbers.
        </Text>
      </Box>
      <Box maxWidth="400px" width="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormHeading heading="Sign up to Sequence">
            Already have an account? <ActionLink to="/sign-in">Sign in</ActionLink>
          </FormHeading>

          <FormControl marginTop="8" marginBottom="4" isInvalid={errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              {...register('name', {
                required: true,
              })}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>

          <EmailField {...{ register, errors }} />
          <PasswordFields {...{ register, errors }} />
          <SignUpButton {...{ loading }} />
        </form>
      </Box>
    </Flex>
  )
}

export default SignUp
