import React from 'react'
import { Check } from 'react-feather'
import { Flex, Box, Icon } from '@chakra-ui/react'

import { pluralize } from '../../../helpers/utils'

const SessionOverview = ({ value }) => {
  return (
    <Flex justifyContent={{ base: 'none', md: 'center' }} my={{ base: '8', md: '14' }}>
      <Box fontSize={{ base: '8xl', md: '9xl' }} color="gray.600">
        {value}
      </Box>
      <Box mt={{ base: '9', md: '12' }} ml="4" fontSize="lg" color="gray.600">
        <Icon as={Check} color="green.400" w="6" h="6" display="block" />
        {`You have completed`} <br /> {`${pluralize(value, 'session')} this past week`}
      </Box>
    </Flex>
  )
}

export default SessionOverview
