import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Box, Flex, Grid, Spacer } from '@chakra-ui/react'
import { isSameMonth, isSameDay, parseISO } from 'date-fns'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { dateToString } from 'helpers/utils'
import { useQuery, useSidebarToggle } from 'hooks'

import DailyMetricsPlannerModal from 'domain/DailyMetrics/DailyMetricsPlannerModal'

import DayActions from './DayActions'
import DeleteWorkout from './DeleteWorkout'
import MenstrualCycleModal from './MenstrualCycleModal'
import DroppableDay from './DroppableDay'
import DroppableNote from './DroppableNote'
import GridDay from './GridDay'
import { isRestDay } from './helpers'

import CalendarDay from './components/CalendarDay'
import ExtraDayDetails from './components/ExtraDayDetails'
import WeekLabel from './components/WeekLabel'

import { usePlannerMethods } from './hooks'

const MonthView = ({ daysOfMonth, firstDayCurrentMonth }) => {
  //firstDayCurrentMonth maybe this doesn't need to be passed in
  // const [data, setData] = useState([]);

  const sidebarOpen = useSidebarToggle((state) => state.sidebarOpen)

  const date = dateToString(daysOfMonth[0])

  const { data, isLoading, hasError, mutate } = useQuery(
    `api/v1/schedules/user_month?start_date_of_period=${date}`
  )

  // TODO: is this bad while waiting for query to process?
  // maybe the useQuery hook should be in usePlannerMethods
  // and we just pass the base url in
  const {
    refreshData,
    renderDraggableWorkout,
    showDayActions,
    handleAddWorkoutToDay,
    selectedDay,
    isDayActionsModalOpen,
    setIsDayActionsModalOpen,
    handleOnDragEnd,
    handleDeleteScheduledActivity,
    isConfirmDeleteModalOpen,
    setIsConfirmDeleteModalOpen,
    openMensutralCycleForDay,
    isMenstrualCycleModalOpen,
    setIsMenstrualCycleModalOpen,
  } = usePlannerMethods(data)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const MonthDay = ({ provided, day }) => {
    return (
      <GridDay
        isRestDay={isRestDay(day, data)}
        day={day}
        provided={provided}
        data={data}
        refreshData={refreshData}
        showDayActions={showDayActions}
        renderDraggableWorkout={renderDraggableWorkout}
      />
    )
  }

  const renderMonthDays = () => {
    return daysOfMonth.map((day, index) => {
      const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

      return (
        <Box key={day.toString()} minW="130px">
          <WeekLabel refresh={refreshData} {...{ day, dayData }} />
          <Flex alignItems="center" height="6">
            <CalendarDay {...{ day, firstDayCurrentMonth }} />
            <Spacer />
            <ExtraDayDetails {...{ data, day, openMensutralCycleForDay }} />
          </Flex>

          <DroppableNote {...{ index, day, data }} />

          <DroppableDay index={index} day={day} Day={MonthDay} />
        </Box>
      )
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Flex mb="10">
          <Box width="100%">
            <Grid templateColumns="repeat(7, 1fr)" gap={4}>
              {renderMonthDays()}
            </Grid>
          </Box>
          {sidebarOpen && (
            <Box ml="4" background="gray.200" width="400px">
              <Box mb="4">Week data</Box>
              <Box>Show data for the current weeks display.</Box>
            </Box>
          )}
        </Flex>
      </DragDropContext>
      {isDayActionsModalOpen && (
        <DayActions
          refresh={refreshData}
          day={selectedDay}
          isOpen={isDayActionsModalOpen}
          onClose={() => setIsDayActionsModalOpen(false)}
          onAddWorkout={handleAddWorkoutToDay}
        />
      )}
      <DeleteWorkout
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onAccept={handleDeleteScheduledActivity}
      />
      <MenstrualCycleModal
        isOpen={isMenstrualCycleModalOpen}
        onClose={() => setIsMenstrualCycleModalOpen(false)}
        day={selectedDay}
        data={data}
        refreshPlannerData={() => mutate()}
      />
      <DailyMetricsPlannerModal />
    </>
  )
}

export default MonthView
