import React from 'react'
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Cell,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { pickStyleMapping, styleColourMapping } from 'domain/Widgets/Logbook/constants'

export const EntriesByStyle = ({ data, logbook, stylesToShow }) => {
  const styleMapping = pickStyleMapping(logbook)

  // generate a complete dataset with all stylesToShow (fill missing with 0)
  const completeData = stylesToShow.map((style_text) => ({
    style_text,
    total: data.find((item) => item.style_text === style_text)?.total || 0,
  }))

  return (
    <ResponsiveContainer height={450}>
      <BarChart data={completeData}>
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="category" dataKey={(x) => styleMapping[x.style_text]} />
        <YAxis type="number" allowDecimals={false} />
        <Bar dataKey="total">
          {completeData.map((entry, index) => {
            const style = entry.style_text

            return (
              <Cell
                key={`total-cell-${index}`}
                name={styleMapping['attempt'] + 'total'}
                fill={styleColourMapping[style]}
              />
            )
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
