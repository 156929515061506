import { Badge } from '@chakra-ui/react'

const CoachingBadge = ({ style }) => {
  if (style !== 'coaching') {
    return null
  }

  return (
    <Badge backgroundColor="brand.400" color="white">
      Coaching
    </Badge>
  )
}

export default CoachingBadge
