import React, { useState } from 'react'
import {
  Button,
  Container,
  useDisclosure,
  Input,
  Stack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

import { PlanApplyModal } from 'domain/Plans/components/PlanModals'

const PlansTest = () => {
  const [planCode, setPlanCode] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onConfirmOpen = () => onOpen()

  const handleChange = (event) => setPlanCode(event.target.value)

  return (
    <Container p={20}>
      <Stack>
        <FormControl>
          <FormLabel>Enter Plan Code</FormLabel>
          <Input value={planCode} onChange={handleChange} />
        </FormControl>
        <Button variant="primary" onClick={onConfirmOpen} isDisabled={!planCode}>
          Apply Plan
        </Button>
      </Stack>

      <PlanApplyModal planName="" {...{ planCode, isOpen, onClose }} />
    </Container>
  )
}

export default PlansTest
