import React from 'react'
import { Badge, Flex, Text } from '@chakra-ui/react'
import { startCase } from 'lodash'

// maintain:
//
// - include every type from constants
// - build and include corresponding ModalButtonContent{Widget} component

import { LOGBOOK_TYPE, MEASURE_TYPE, SELECT_TYPE, SLIDER_TYPE } from 'domain/Widgets/constants'

import { ModalButtonContentLogbook } from './ModalButtonContentLogbook'
import { ModalButtonContentMeasure } from './ModalButtonContentMeasure'
import { ModalButtonContentSelect } from './ModalButtonContentSelect'
import { ModalButtonContentSlider } from './ModalButtonContentSlider'

export const ModalButtonContent = ({ widget }) => {
  const { type, content } = widget

  switch (type) {
    case LOGBOOK_TYPE:
      return <ModalButtonContentLogbook content={content} />

    case MEASURE_TYPE:
      return <ModalButtonContentMeasure content={content} />

    case SELECT_TYPE:
      return <ModalButtonContentSelect content={content} />

    case SLIDER_TYPE:
      return <ModalButtonContentSlider content={content} />
  }
}
