import moment from 'moment'
import { min, max, omit, range, sum } from 'lodash'

import { tzSafeDateFormat } from 'helpers/utils'

export const tickFormatterDate = (date) => {
  return tzSafeDateFormat(date, 'd MMM')
}

export const calcXYDomains = (annotations, y_values) => {
  const x = calcXDomain(annotations)
  const y = calcYDomain(y_values)

  return {
    xy_domains: {
      x_domain_min: x.x_domain_min,
      x_domain_max: x.x_domain_max,
      y_domain_min: y.y_domain_min,
      y_domain_max: y.y_domain_max,
    },
  }
}

export const calcXTicks = (range) => {
  const x_min = range?.start.value
  const x_max = range?.end.value
  const x_range = x_max - x_min
  const perday = 86400000
  const whole_days = Math.trunc(x_range / perday)
  const days_per_tick = 7

  // tick on every monday
  // moment snaps a sunday to the next monday, so offset by 1 day
  const firstMonday = moment(x_min - perday).day('Monday')

  let num_weeks = Math.ceil(whole_days / 7) + 1
  if (!isFinite(num_weeks)) {
    num_weeks = 1
  }
  if (num_weeks === 1) {
    num_weeks += 1
  }

  const try_ticks = [...Array(num_weeks + 1).keys()].slice(1).map((i) => {
    return firstMonday + (i - 1) * perday * days_per_tick
  })

  return {
    x_ticks: try_ticks,
  }
}

export const calcXDomain = (annotations) => {
  const x_min = annotations.range?.start?.value
  const x_max = annotations.range?.end?.value
  const x_buffer = 0 // now looks better with no buffer

  return {
    x_domain_min: x_min - x_buffer,
    x_domain_max: x_max + x_buffer,
  }
}

export const calcYDomain = (y_values) => {
  const y_min = Math.min(...y_values)
  const y_max = Math.max(...y_values)
  var y_multiple = 10 ** Math.ceil(Math.log10(y_max) - 1)

  if (y_max < 500) {
    y_multiple = 50
  }
  if (y_max < 200) {
    y_multiple = 20
  }
  if (y_max < 100) {
    y_multiple = 10
  }
  if (y_max < 25) {
    y_multiple = 5
  }

  // initial guess
  var y_domain_min = Math.floor((y_min - 1) / y_multiple) * y_multiple
  var y_domain_max = Math.ceil(y_max / y_multiple) * y_multiple

  // if y_max is y_domain_max, then add another tick
  if (y_max === y_domain_max) {
    y_domain_max = y_domain_max + y_multiple
  }

  // hack some 'option' type scenarios
  if (y_min === 0 && y_max === 1) {
    y_domain_min = -0.5
    y_domain_max = 1.5
  }
  if (y_min === 0 && y_max === 5) {
    y_domain_min = -1
    y_domain_max = 6
  }
  if (y_min === 1 && y_max === 5) {
    y_domain_min = -1
    y_domain_max = 6
  }
  if (y_min === 1 && y_max === 5) {
    y_domain_min = -1
    y_domain_max = 6
  }

  // if the min value is positive and below a certain threshold, snap down to zero
  // (also, if y_min is negative, leave it as is)
  if (y_min < 10 && y_min >= 0) {
    y_domain_min = 0
  }

  return {
    y_domain_min: y_domain_min,
    y_domain_max: y_domain_max,
  }
}

export const calcYTicks = (xy_domains) => {
  // null ticks means recharts will guess
  let ticks = null

  const { y_domain_min: y_min, y_domain_max: y_max } = xy_domains
  const diff = y_max - y_min
  const start = Math.floor(y_min)
  const end = Math.ceil(y_max + 1)
  let increment

  if (diff <= 10000) {
    increment = 1000
  }
  if (diff <= 5000) {
    increment = 500
  }
  if (diff <= 2000) {
    increment = 200
  }
  if (diff <= 700) {
    increment = 100
  }
  if (diff <= 200) {
    increment = 20
  }
  if (diff === 150) {
    increment = 25
  }
  if (diff === 140) {
    increment = 25
  }
  if (diff <= 100) {
    increment = 20
  }
  if (diff <= 40) {
    increment = 10
  }
  if (diff <= 20) {
    increment = 5
  }
  if (diff <= 10) {
    increment = 2
  }
  if (diff <= 6) {
    increment = 1
  }

  // console.log('start', start, 'end', end, 'diff', diff, 'increment', increment)

  if (increment) {
    ticks = range(start, end, increment)
  }

  // console.log('ticks', ticks)

  // hack some 'option' type scenarios
  if (y_min === -1 && y_max === 6) {
    ticks = [0, 1, 2, 3, 4, 5]
  }
  if (y_min === -0.5 && y_max === 1.5) {
    ticks = [0, 1]
  }

  return {
    y_ticks: ticks,
  }
}

export const calcYMin = (data) => {
  const y_totals = data.map((e) => {
    const vals = pickVals(e)
    return min(Object.values(vals))
  })

  return min(y_totals)
}

// calculate the max y value where all data points for a given x point are aggregated
export const calcYMaxSum = (data, showPastMissing = false) => {
  const y_totals = data.map((e) => {
    const vals = pickVals(e, showPastMissing)
    return sum(Object.values(vals))
  })

  return max(y_totals)
}

// calculate the max y value where each data point for a given x point is considered independent
export const calcYMaxPick = (data, showPastMissing = false) => {
  const y_totals = data.map((e) => {
    const vals = pickVals(e, showPastMissing)
    return max(Object.values(vals))
  })

  return max(y_totals)
}

export const getDataValues = (data, dataKey = 'value') => {
  return {
    x_values: data.map((e) => {
      return e.date_value
    }),
    y_values: data
      .map((e) => {
        return parseFloat(e[dataKey])
      })
      .filter((val) => !Number.isNaN(val)),
  }
}

export const getLabelValues = (data) => {
  return {
    label_values: data.map((e) => {
      return e.label_date_value
    }),
  }
}

// if data has a column called y then it will just use that
//   otherwise it will try and remove non-data columns and guess
// if missing/incomplete are not shown, then dont include values
export const pickVals = (e, showPastMissing) => {
  if (Object.keys(e).indexOf('y') >= 0) {
    return { y: parseFloat(e['y']) }
  } else {
    const baseOmitKeys = [
      'date_text',
      'date_value',
      'week_start_date',
      'week_end_date',
      'notes',
      // these are boolean values that mess with sums:
      // (since true gets interpreted as a value of 1)
      'future',
      'future_first',
    ]

    let filterKeys
    if (showPastMissing) {
      filterKeys = [...baseOmitKeys]
    } else {
      const missKeys = Object.keys(e).filter((k) => k.match('_miss') !== null)
      filterKeys = [...baseOmitKeys, ...missKeys]
    }

    const filterVals = omit(e, filterKeys)

    return filterVals
  }
}
