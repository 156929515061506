import React, { useEffect, useState } from 'react'
import { Edit } from 'react-feather'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import {
  Badge,
  Box,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Icon,
  Text,
  Tooltip,
  Spacer,
  Stack,
  Divider,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import RichTextArea from 'components/RichTextArea'

import CoachingBadge from 'domain/Notes/components/CoachingBadge'
import MilestoneMetrics from 'domain/Metrics/MilestoneMetrics'
import NoteGoalLinker from 'domain/Notes/NoteGoalLinker'

import { processGetError, processApiError, tzSafeDateFormat } from 'helpers/utils'

import RescheduleNote from 'domain/Schedule/ActivityComponents/RescheduleNote'

const MILESTONE = 'milestone'

const PlannerNote = ({ open, onClose, noteId, getAndSetNewDayData }) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [noteData, setNoteData] = useState({})
  const [milestoneData, setMilestoneData] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const {
    isOpen: isRescheduleOpen,
    onOpen: onRescheduleOpen,
    onClose: onRescheduleClose,
  } = useDisclosure()

  const methods = useForm()
  const { control, register, handleSubmit } = methods

  useEffect(() => {
    if (open && noteId) {
      setLoading(true)
      securedApi
        .get(`api/v1/scheduled_notes/${noteId}`)
        .then(({ data }) => setNoteData(data))
        .catch((error) => processGetError(error))
        .finally(() => setLoading(false))
    }
  }, [open, noteId])

  useEffect(() => {
    if (noteData.scheduled_at && noteData.style === MILESTONE) {
      const date = noteData.scheduled_at
      securedApi
        .get(`api/v1/metrics/milestone?date=${date}&weeks=6`)
        .then(({ data }) => setMilestoneData(data))
        .catch((error) => processGetError(error))
    }
  }, [noteData])

  const defaultValues = noteData?.goals?.map((goal) => ({
    value: goal.id,
    label: goal.title,
  }))

  const onSubmit = ({ title, details, goals }) => {
    setProcessing(true)

    // create new array of goal ids
    const goalIds = goals?.map((goal) => goal.value)

    securedApi
      .patch(`api/v1/scheduled_notes/${noteData.id}`, {
        scheduled_note: {
          title,
          style: noteData.style,
          scheduled_at: noteData.scheduled_at,
          description: details,
          goal_ids: goalIds,
        },
      })
      .then(() => {
        getAndSetNewDayData()
        Notify({ content: 'Updated', type: 'success' })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProcessing(false)
        onClose()
      })
  }

  const setEditMode = () => {
    setIsEditing(!isEditing)
  }

  const handleClose = () => {
    setIsEditing(false)
    onClose()
  }

  const deleteNote = () => {
    const deleteMessage = `${noteData.style === MILESTONE ? 'Milestone' : 'Note'} deleted.`

    securedApi
      .delete(`api/v1/scheduled_notes/${noteId}`)
      .then(() => {
        Notify({
          content: deleteMessage,
          type: 'success',
        })
        getAndSetNewDayData()
        handleClose()
      })
      .catch((error) => processApiError(error))
  }

  const renderRescheduleModal = (
    <RescheduleNote
      isOpen={isRescheduleOpen}
      onClose={onRescheduleClose}
      currentNoteId={noteData.id}
    />
  )

  const renderNote = () => {
    const formattedDate = tzSafeDateFormat(noteData.scheduled_at, 'd/M/yy')

    if (isEditing) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex>
              <Spacer />
              <Box mr="14" mt="3">
                <Button size="xs" width="100%" onClick={onRescheduleOpen}>
                  Reschedule
                </Button>
              </Box>
            </Flex>

            <ModalHeader pt="0" pb="0" display="flex" alignItems="center">
              <FormControl isRequired>
                <FormLabel fontSize="sm" mb="0">
                  Title
                </FormLabel>
                <Input
                  defaultValue={noteData.title}
                  placeholder="Awesome training day"
                  {...register('title')}
                />
              </FormControl>
            </ModalHeader>

            <ModalBody>
              <Stack spacing="4">
                <FormControl>
                  <FormLabel fontSize="sm" mb="0">
                    Details
                  </FormLabel>
                  <Controller
                    name="details"
                    defaultValue={noteData.description}
                    control={control}
                    render={({ field }) => <RichTextArea {...field} />}
                  />
                </FormControl>

                <NoteGoalLinker {...{ control, defaultValues }} />
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup spacing="4" marginTop="2" display="flex" width="100%">
                <Button variant="outline" onClick={deleteNote} disabled={processing}>
                  Delete
                </Button>
                <Spacer />
                <Button variant="outline" onClick={setEditMode} disabled={processing}>
                  Cancel
                </Button>
                <Button type="submit" colorScheme="brand" isLoading={processing}>
                  Update
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      )
    }

    return (
      <>
        <ModalHeader pb="0" display="flex" alignItems="center">
          {noteData.title}
          <Box ml="2">
            <CoachingBadge style={noteData.style} />
          </Box>
          <Spacer />

          <Tooltip label="Edit">
            <Icon
              as={Edit}
              w="4"
              h="4"
              color="gray.500"
              mt="-5"
              mr="6"
              _hover={{ cursor: 'pointer', color: 'brand.500' }}
              onClick={setEditMode}
            />
          </Tooltip>
        </ModalHeader>

        <ModalBody pb="4">
          <Text fontSize="xs" pb="2">
            {formattedDate}
          </Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: noteData.description,
            }}
          />

          {noteData.goals && noteData.goals.length > 0 && (
            <Box mt="2">
              <Divider />
              <Text fontWeight="medium" fontSize="sm" mt="2">
                Linked Goals
              </Text>
              <Stack direction="row" mt="2">
                {noteData.goals.map((goal) => (
                  <Badge key={goal.id} variant="outline">
                    {goal.title}
                  </Badge>
                ))}
              </Stack>
            </Box>
          )}

          {noteData.style === MILESTONE && <MilestoneMetrics data={milestoneData} />}
        </ModalBody>
      </>
    )
  }

  if (!Object.keys(noteData).length) {
    return null
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        closeOnOverlayClick={isEditing ? false : true}
      >
        <ModalOverlay />
        <ModalContent pb="2">
          <ModalCloseButton />
          {renderNote()}
        </ModalContent>
      </Modal>
      {isRescheduleOpen && renderRescheduleModal}
    </>
  )
}

export default PlannerNote
