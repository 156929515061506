import React from 'react'
import { Stack } from '@chakra-ui/react'

import WorkoutMetricsAllTime from './WorkoutMetricsAllTime'
import WorkoutMetricsCustomMeasures from './WorkoutMetricsCustomMeasures'
import WorkoutMetricsMagicNumber from './WorkoutMetricsMagicNumber'
import WorkoutMetricsList from './WorkoutMetricsList'

import { MEASURE_TYPE } from 'domain/Widgets/constants'

const WorkoutMetrics = ({ id, workout }) => {
  const hasWidgetMeasures = workout.widgets.filter((x) => x.type === MEASURE_TYPE).length > 0

  return (
    <Stack spacing="4" marginTop="4">
      <WorkoutMetricsMagicNumber {...{ id, workout }} />
      <WorkoutMetricsAllTime {...{ id }} />
      {hasWidgetMeasures && <WorkoutMetricsCustomMeasures {...{ id }} />}
      <WorkoutMetricsList {...{ id }} />
    </Stack>
  )
}

export default WorkoutMetrics
