import React, { useState } from 'react'
import { Button, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { formatPrice, processApiError } from 'helpers/utils'

export const BuyPlanButton = ({ plan }) => {
  const [loading, setLoading] = useState(false)

  const { id, stripe_price_unit_amount, currency } = plan
  const cost_string = formatPrice(stripe_price_unit_amount, currency)

  const handlePurchaseButton = () => {
    setLoading(true)
    securedApi
      .get(`api/v1/products/purchase?id=${id}`)
      .then(({ data }) => {
        window.open(data.url, '_self', 'noreferrer')
      })
      .catch((error) => {
        processApiError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Button variant="primary" size="lg" isLoading={loading} onClick={handlePurchaseButton}>
      <Text>Buy {cost_string}</Text>
    </Button>
  )
}
