import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from '@chakra-ui/react'

const ActionLink = ({ children, to }) => {
  return (
    <Link
      as={NavLink}
      {...{ to }}
      color="gray.500"
      fontSize="sm"
      textDecoration="underline"
      _hover={{ cursor: 'pointer', color: 'brand.500' }}
    >
      {children}
    </Link>
  )
}

export default ActionLink
