import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box } from '@chakra-ui/react'

const DroppableDay = ({ index, day, Day }) => {
  return (
    <Droppable droppableId={index.toString()} key={day.toString()}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          // isDraggingOver={snapshot.isDraggingOver}
          style={{
            backgroundColor: snapshot.isDraggingOver ? '' : '',
          }}
          {...provided.droppableProps}
        >
          <Day {...{ provided, day }} />
          {/*{provided.placeholder} week had this but not month */}
        </Box>
      )}
    </Droppable>
  )
}

export default DroppableDay
