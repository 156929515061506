import { ACTIVE_TYPE, INACTIVE_TYPE, PENDING_TYPE, INVITED_TYPE } from './constants'

export const sortClientsByType = (data) => {
  const sortClients = data.reduce((result, client) => {
    const status = client.status

    if (!result[status]) {
      result[status] = []
    }

    result[status].push(client)

    return result
  }, {})

  const pendingClients = sortClients[PENDING_TYPE] || []
  const invitedClients = sortClients[INVITED_TYPE] || []
  const activeClients = sortClients[ACTIVE_TYPE] || []
  const inactiveClients = sortClients[INACTIVE_TYPE] || []

  return { pendingClients, invitedClients, activeClients, inactiveClients }
}
