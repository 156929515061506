import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Box,
  Stack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { processAppStatus, processAppError } from 'helpers/utils'

const ChangePassword = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
  })

  const handleChangePassword = ({ oldPassword, newPassword, passwordConfirmation }) => {
    securedApi
      .put('password/update', {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: passwordConfirmation,
      })
      .then((response) => {
        processAppStatus(response)
        reset()
      })
      .catch((error) => {
        processAppError(error)
      })
  }

  return (
    <Box display="block" maxWidth="400px">
      <form onSubmit={handleSubmit(handleChangePassword)}>
        <Stack spacing="6" width="100%">
          <FormControl>
            <FormLabel htmlFor="oldPassword">Current Password</FormLabel>
            <Input
              type="password"
              {...register('oldPassword', {
                required: 'Required - Must provide current password',
              })}
            />
            {errors.oldPassword && (
              <FormErrorMessage>{errors.oldPassword.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="newPassword">New Password</FormLabel>
            <Input
              type="password"
              {...register('newPassword', {
                required: 'Required - Must provide new password',
                minLength: {
                  value: 6,
                  message: 'Password must have at least 6 characters',
                },
              })}
            />

            {errors.newPassword && (
              <FormErrorMessage>{errors.newPassword.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="passwordConfirmation">Confirm New Password</FormLabel>

            <Input
              type="password"
              {...register('passwordConfirmation', {
                required: 'Required - Must provide new password confirmation',
                minLength: {
                  value: 6,
                  message: 'Password must have at least 6 characters',
                },
              })}
            />

            {errors.passwordConfirmation && (
              <FormErrorMessage>{errors.passwordConfirmation.message}</FormErrorMessage>
            )}
          </FormControl>

          <Button variant="primary" type="submit">
            Change password
          </Button>
        </Stack>
      </form>
    </Box>
  )
}

export default ChangePassword
