import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Link, Flex, Spacer, Text } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

const Goals = ({ data }) => {
  const renderGoal = ({ id, title }) => {
    return (
      <Box key={id}>
        <Link
          fontSize="18"
          mb="2"
          as={NavLink}
          to={`/goals/${id}`}
          fontWeight="bold"
          color="yellow.500"
        >
          {title}
        </Link>
      </Box>
    )
  }

  const renderGoals = () => {
    if (!data.length) {
      return (
        <Text>
          You currently don't have any goals in progress.{' '}
          <Link
            href="https://docs.sequence-app.com/features/goals"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            <br />
            Learn more about goals <ExternalLinkIcon />
          </Link>
        </Text>
      )
    }

    return data.map((goal) => renderGoal(goal))
  }

  return (
    <Flex flexDirection="column" height="100%">
      <Box mb="2">
        <Text fontSize="large">Goals</Text>

        <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
          In Progress
        </Text>
      </Box>
      {renderGoals()}
      <Spacer />
      <Box display="flex" flexDirection="row-reverse">
        <Link fontSize="small" color="yellow.500" as={NavLink} to={`/goals`}>
          View Goals
        </Link>
      </Box>
    </Flex>
  )
}

export default Goals
