import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processApiError } from 'helpers/utils'

import CategoryColorPicker from './CategoryColorPicker'

const schema = z.object({
  name: z.string().min(1, { message: 'Name required' }),
})

const EditCategoryItem = ({ item, onClose, onUpdate }) => {
  const [currentColor, setCurrentColor] = useState(item.hex_colour)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: item.name,
    },
    resolver: zodResolver(schema),
  })

  const handleSetColor = (value) => {
    setCurrentColor(value)
  }

  const onSubmit = ({ name }) => {
    securedApi
      .patch(`/api/v1/categories/${item.id}`, {
        name,
        hex_colour: currentColor,
      })
      .then(() => {
        Notify({ content: 'Category has been updated', type: 'success' })
        onUpdate()
        onClose()
      })
      .catch((error) => {
        processApiError(error)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Flex>
          <CategoryColorPicker onSetColor={handleSetColor} {...{ currentColor }} />
          <FormControl isInvalid={errors.name}>
            <Input
              {...register('name', {
                required: true,
              })}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>
          <Spacer />
          <HStack ml="2">
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button type="submit" colorScheme="brand">
              Save
            </Button>
          </HStack>
        </Flex>
      </Box>
    </form>
  )
}

export default EditCategoryItem
