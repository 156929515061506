import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Flex, Spacer, Link, Text } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import CoachingBadge from '../Notes/components/CoachingBadge.js'

const RecentNotes = ({ data }) => {
  const renderNote = ({ id, title, style }) => {
    return (
      <Box key={id}>
        <Link
          fontSize="18"
          mb="2"
          display="inline-block"
          as={NavLink}
          to={`/note/${id}`}
          fontWeight="bold"
          color="gray.500"
          background="gray.200"
          px="2"
          py="1"
          _hover={{
            textDecoration: 'none',
            background: 'gray.400',
            color: 'white',
          }}
        >
          {title}
          <Box display="inline" ml="2">
            <CoachingBadge {...{ style }} />
          </Box>
        </Link>
      </Box>
    )
  }

  const renderRecentNotes = () => {
    if (!data.length) {
      return (
        <Text>
          You currently haven't recorded any Notes.{' '}
          <Link
            href="https://docs.sequence-app.com/features/notes"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            <br />
            Learn more about Notes <ExternalLinkIcon />
          </Link>
        </Text>
      )
    }

    return data.map((note) => renderNote(note))
  }

  return (
    <Flex flexDirection="column" height="100%">
      <Box mb="2">
        <Text fontSize="large">Recent Notes</Text>
      </Box>
      {renderRecentNotes()}
      <Spacer />
      <Box display="flex" flexDirection="row-reverse">
        <Link fontSize="sm" color="yellow.500" as={NavLink} to={`/notes`}>
          View Notes
        </Link>
      </Box>
    </Flex>
  )
}

export default RecentNotes
