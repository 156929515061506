import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  Container,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import RichTextArea from 'components/RichTextArea'
import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import Modal from 'components/Modal'
import DatePicker from 'components/DatePicker/DatePicker'

import { processGetError, processApiError, tzSafeNewDate } from 'helpers/utils'

const GoalsEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })
  const [loading, setLoading] = useState(false)
  const [goal, setGoal] = useState({})
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/goals/${id}`)
      .then(({ data }) => {
        setGoal(data)
        setValue('status', data.status)

        if (data.start_date) {
          setValue('start_date', tzSafeNewDate(data?.start_date))
        }

        if (data.end_date) {
          setValue('end_date', tzSafeNewDate(data?.end_date))
        }

        setLoading(false)
      })
      .catch((error) => {
        processGetError(error)
        setLoading(false)
      })
  }, [id, setValue])

  const onSubmit = (data) => {
    securedApi
      .patch(`/api/v1/goals/${id}`, {
        ...data,
      })
      .then(() => {
        Notify({ content: 'Goal updated', type: 'success' })
        navigate(`/goals/${id}`)
      })
      .catch((error) => {
        processApiError(error, 'Error updating goal. Please try again.')
      })
  }

  const handleDelete = () => {
    securedApi
      .delete(`api/v1/goals/${id}`)
      .then(() => {
        Notify({ content: 'Goal has been deleted', type: 'success' })
        navigate('/goals')
      })
      .catch((error) => {
        processApiError(error, 'Error deleting goal. Please try again.')
      })
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(goal).length) {
    return <Text>No goal found</Text>
  }

  const status = watch('status')
  const showStartEndDateFields =
    status === 'in_progress' || status === 'complete' || status === 'cancelled'

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl marginBottom="4" isRequired>
          <FormLabel>What is your goal?</FormLabel>
          <Input defaultValue={goal.title} bg="white" {...register('title')} />
        </FormControl>

        <FormControl marginBottom="4" isInvalid={errors['details']} isRequired>
          <FormLabel>
            Details about your goal{' '}
            <FormErrorMessage display="inline" fontWeight="bold">
              Required
            </FormErrorMessage>
          </FormLabel>
          <Controller
            name="details"
            control={control}
            defaultValue={goal.details}
            render={({ field }) => <RichTextArea {...field} />}
          />
        </FormControl>

        <FormControl maxW="180px" marginBottom="4" isRequired>
          <FormLabel>Duration</FormLabel>
          <Select
            placeholder="Select option"
            defaultValue={goal.style}
            bg="white"
            variant="outline"
            {...register('style')}
          >
            <option value="undefined">N/A</option>
            <option value="short_term">Short term</option>
            <option value="medium_term">Medium term</option>
            <option value="long_term">Long term</option>
          </Select>
        </FormControl>

        <FormControl maxW="180px" marginBottom="4" isRequired>
          <FormLabel>Status</FormLabel>
          <Select
            placeholder="Select option"
            defaultValue={goal.status}
            bg="white"
            variant="outline"
            {...register('status')}
          >
            <option value="idea">Idea</option>
            <option value="in_progress">In progress</option>
            <option value="complete">Complete</option>
            <option value="cancelled">Cancelled</option>
          </Select>
        </FormControl>

        {showStartEndDateFields && (
          <>
            <FormControl marginBottom="4">
              <FormLabel>Start date</FormLabel>
              <Controller
                control={control}
                name="start_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    dateFormat="d/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selectedDate={field.value}
                  />
                )}
              />
            </FormControl>

            <FormControl marginBottom="4">
              <FormLabel>End date</FormLabel>
              <Controller
                control={control}
                name="end_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    dateFormat="d/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selectedDate={field.value}
                  />
                )}
              />
            </FormControl>
          </>
        )}

        {/* <FormControl marginBottom="4">
          <FormLabel>Notes</FormLabel>
          <Textarea
            defaultValue={goal.notes}
            minH="150px"
            bg="white"
            {...register("notes")}
          />
        </FormControl> */}

        {/*
        <FormControl marginBottom="4">
          <FormLabel>Final date - todo</FormLabel>
        </FormControl> */}

        <ButtonGroup spacing="4" marginTop="2" display="flex">
          <Button onClick={() => setDeleteModalOpen(true)} variant="outline">
            Delete
          </Button>
          <Spacer />
          <Button variant="outline" onClick={() => navigate(`/goals/${id}`)}>
            Cancel
          </Button>

          <Button type="submit" colorScheme="brand">
            Update
          </Button>
        </ButtonGroup>
      </form>
      <Modal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        title="Are you sure?"
        subTitle="This action can't be undone"
        onAccept={() => handleDelete()}
      />
    </Container>
  )
}

export default GoalsEdit
