import React, { useState } from 'react'
import { Box, Flex, FormControl, FormLabel, FormHelperText, Switch, Select } from '@chakra-ui/react'
import TimePicker from 'rc-time-picker-luxon'

import { strToLxTime, lxTimeToStr, disabledMinutes } from './helpers'

const WeeklyEmailSettings = ({
  send_weekly_emails,
  weekly_email_day,
  weekly_email_time,
  onToggleEmailSettingsWeekly,
  onHandleSetWeeklyEmailTime,
  onHandleSetWeeklyEmailDay,
}) => {
  const [sendWeeklyEmails, setSendWeeklyEmails] = useState(send_weekly_emails)
  const [weeklyEmailDay, setWeeklyEmailDay] = useState(weekly_email_day)
  const [weeklyEmailTime, setWeeklyEmailTime] = useState(weekly_email_time)

  const handleToggleEmailSettingsWeekly = () => {
    setSendWeeklyEmails(!sendWeeklyEmails)
    onToggleEmailSettingsWeekly(!sendWeeklyEmails)
  }

  const handleSetWeeklyEmailTime = (time) => {
    const timeStr = lxTimeToStr(time)
    setWeeklyEmailTime(timeStr)
    onHandleSetWeeklyEmailTime(timeStr)
  }

  const handleSetWeeklyEmailDay = (event) => {
    const day = event.target.value
    setWeeklyEmailDay(day)
    onHandleSetWeeklyEmailDay(day)
  }

  return (
    <>
      <FormControl>
        <FormLabel>
          <Box>Receive a weekly email report</Box>
          <FormHelperText>
            Contains a review of the previous week, with details about what you did and didn't do.
          </FormHelperText>
        </FormLabel>

        <Switch
          id="weekly-email"
          colorScheme="yellow"
          isChecked={sendWeeklyEmails}
          onChange={handleToggleEmailSettingsWeekly}
        />
      </FormControl>

      {sendWeeklyEmails && (
        <FormControl>
          <FormLabel>
            <FormHelperText>
              Set the time and day you would like to receive your weekly email:
            </FormHelperText>
          </FormLabel>
          <Flex>
            <Box maxW="60px">
              <TimePicker
                showSecond={false}
                onChange={handleSetWeeklyEmailTime}
                value={strToLxTime(weeklyEmailTime)}
                allowEmpty={false}
                disabledMinutes={disabledMinutes}
                hideDisabledOptions={true}
              />
            </Box>

            <Select
              placeholder={null}
              size="sm"
              width="150px"
              ml="2"
              onChange={handleSetWeeklyEmailDay}
              defaultValue={weeklyEmailDay}
              borderSize="1px"
              borderColor="#d9d9d9"
            >
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </Select>
          </Flex>
        </FormControl>
      )}
    </>
  )
}

export default WeeklyEmailSettings
