import { toast } from 'react-toastify'

// Types
// success
// info
// warn
// error

export const Notify = ({ autoClose = true, content, position = 'bottom-right', type, onClick }) => {
  toast(content, {
    autoClose,
    closeButton: false,
    pauseOnFocusLoss: false,
    position,
    type,
    onClick,
  })
}

export default Notify
