import { useState } from 'react'

import securedApi from 'backend/axios'

const useTemplatePlan = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const performRequest = async ({
    add = 'this_week',
    behaviour = 'overwrite',
    focus,
    signup = false,
    lookup_code,
  }) => {
    setLoading(true)
    setError('')

    try {
      await securedApi.post('api/v1/schedules/add_from_template', {
        level: 1,
        focus,
        duration: 4,
        on_signup: signup,
        behaviour,
        when_to_add: add,
        lookup_code,
      })
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, refetch: performRequest }
}

export default useTemplatePlan
