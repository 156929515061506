import { Icon, Text, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-feather'

const LinkedGoalsIcon = ({ linkedGoals, color = 'gray.500' }) => {
  if (linkedGoals.length) {
    const text = linkedGoals.map((x) => <Text>{x.title}</Text>)

    return (
      <Tooltip label={text}>
        <Icon mr="2" as={Link} color={color} w="4" h="4" strokeWidth="1" cursor="pointer" />
      </Tooltip>
    )
  } else {
    return null
  }
}

export default LinkedGoalsIcon
