import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processApiError } from 'helpers/utils'

import TestForm from './TestForm'

const TestsCreate = () => {
  const navigate = useNavigate()

  const onSubmit = (data) => {
    securedApi
      .post(`/api/v1/tests`, {
        ...data,
      })
      .then(() => {
        Notify({ content: 'Test has been created', type: 'success' })
        navigate('/tests')
      })
      .catch((error) => {
        processApiError(error)
      })
  }

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <Box background="white" padding="6">
        <TestForm create {...{ onSubmit }} />
      </Box>
    </Container>
  )
}

export default TestsCreate
