import React from 'react'

import MetricsContainer from './MetricsContainer'
import Measures from './Measures'

const MetricsCustomMeasures = () => {
  return (
    <MetricsContainer>
      <Measures />
    </MetricsContainer>
  )
}

export default MetricsCustomMeasures
