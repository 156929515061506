import React from 'react'
import { Badge, FormLabel, Text } from '@chakra-ui/react'

const ActivityModalHeader = ({ label }) => {
  return (
    <FormLabel>
      <Badge
        // badge auto does these:
        // fontWeight="bold"
        // textTransform="uppercase"
        bgColor="gray.100"
      >
        {label}
      </Badge>
    </FormLabel>
  )
}

export default ActivityModalHeader
