import { Badge, Box } from '@chakra-ui/react'

const CoachingBadgeIcon = ({ style }) => {
  if (style !== 'coaching') {
    return null
  }

  return (
    <Box display="inline" mx="2">
      <Badge backgroundColor="brand.400" color="white">
        C
      </Badge>
    </Box>
  )
}

export default CoachingBadgeIcon
