import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const outline = defineStyle({
  borderRadius: 'none',
  borderWidth: '1px',
  borderColor: 'gray.400',
  background: 'white',
  boxShadow: 'none',
  _active: {
    background: 'white',
    boxShadow: 'none',
  },
  _hover: {
    background: 'white',
    borderColor: 'brand.500',
    boxShadow: 'none',
  },
  _focus: {
    background: 'white',
    borderColor: 'brand.500',
    boxShadow: 'none',
  },
})

export const textareaTheme = defineStyleConfig({
  variants: { outline },
})
