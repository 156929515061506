import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spacer,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processApiError } from 'helpers/utils'

const AddWeekLabel = ({
  date,
  defaultWeekLabelContent,
  defaultWeekLabelId,
  isOpen,
  onClose,
  refresh,
}) => {
  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    if (defaultWeekLabelId) {
      securedApi
        .put(`api/v1/schedule_labels/${defaultWeekLabelId}`, {
          label_date: date,
          duration: 'week',
          style: 'normal',
          content: data.title,
        })
        .then(() => {
          Notify({ content: 'Week label updated', type: 'success' })
        })
        .catch((error) => {
          processApiError(error, 'Error updating week label. Please try again.')
        })
        .finally(() => {
          refresh()
          onClose()
        })
    } else {
      securedApi
        .post(`api/v1/schedule_labels`, {
          label_date: date,
          duration: 'week',
          style: 'normal',
          content: data.title,
        })
        .then(() => {
          Notify({ content: 'Week label added', type: 'success' })
        })
        .catch((error) => {
          processApiError(error, 'Error adding week label. Please try again.')
        })
        .finally(() => {
          refresh()
          onClose()
        })
    }
  }

  const handleDelete = () => {
    securedApi
      .delete(`/api/v1/schedule_labels/${defaultWeekLabelId}`)

      .then(() => {
        Notify({ content: 'Week label deleted', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error deleting week label. Please try again.')
      })
      .finally(() => {
        refresh()
        onClose()
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Week label</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Input
                autoFocus
                placeholder="Rest week"
                defaultValue={defaultWeekLabelContent}
                {...register('title')}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            {defaultWeekLabelId && (
              <>
                <Button onClick={() => handleDelete()} variant="outline">
                  Delete
                </Button>
                <Spacer />
              </>
            )}

            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="brand" type="submit">
              {defaultWeekLabelId ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default AddWeekLabel
