import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Box,
  Container,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { tzSafeDateFormat } from 'helpers/utils'
import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

const TestsHome = () => {
  const { data, isLoading, hasError } = useQuery('/api/v1/tests')

  const renderCreateButton = () => {
    return (
      <Box display="flex" my="8">
        <Button as={NavLink} to="/tests/create" colorScheme="brand" color="white" marginLeft="auto">
          New Test
        </Button>
      </Box>
    )
  }

  const renderTest = (test) => {
    const formattedDate = test.test_date ? tzSafeDateFormat(test.test_date, 'd/M/yy') : '-'

    return (
      <Tr key={test.id}>
        <Td>{test.number}</Td>
        <Td>{formattedDate}</Td>
        <Td>
          <Link
            key={test.id}
            as={NavLink}
            to={`/tests/${test.id}`}
            color="brand.500"
            fontWeight="bold"
            _hover={{ textDecoration: 'none' }}
            _focus={{ outline: 'none' }}
          >
            Details
          </Link>
        </Td>
      </Tr>
    )
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const renderTests = () => {
    if (!data.length) {
      return (
        <Text>
          You currently haven't logged any tests.{' '}
          <Link
            href="https://docs.sequence-app.com/features/tests"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            <br />
            Learn more about Tests <ExternalLinkIcon />
          </Link>
        </Text>
      )
    }

    return (
      <Box background="white" padding="6">
        <Heading as="h2" size="xl" marginBottom={4} paddingLeft={6}>
          Tests
        </Heading>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Test Number</Th>
              <Th>Date</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>{data.map((test) => renderTest(test))}</Tbody>
        </Table>
      </Box>
    )
  }

  return (
    <Container maxW="container.sm">
      {renderCreateButton()}
      {renderTests()}
    </Container>
  )
}

export default TestsHome
