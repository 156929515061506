import React from 'react'
import { Box, Container, Flex, Stack, Spacer, Text, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import ClientSection from './Components/ClientSection'
import AddClient from './Components/AddClient'

import { ACTIVE_TYPE, INACTIVE_TYPE, PENDING_TYPE, INVITED_TYPE } from './constants'

const CoachingClients = () => {
  const { data, isLoading, hasError } = useQuery(
    `/api/v1/coaching/connections?list_clients=truemetrics=true`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const pendingClients = data.filter((client) => client.status === PENDING_TYPE)
  const invitedClients = data.filter((client) => client.status === INVITED_TYPE)
  const activeClients = data.filter((client) => client.status === ACTIVE_TYPE)
  const inactiveClients = data.filter((client) => client.status === INACTIVE_TYPE)

  return (
    <Container maxW="container.xl" py="6">
      <Stack spacing="4">
        <Flex>
          <Spacer />
          <AddClient />
        </Flex>
        {!data.length && (
          <Text color="gray.500" textAlign="center">
            You current haven't added any clients.
            <Link
              href="https://docs.sequence-app.com/coaching/adding-clients"
              isExternal
              color="yellow.500"
            >
              <br />
              Learn more about adding clients <ExternalLinkIcon />
            </Link>
          </Text>
        )}
        <Box display="block" margin="0 auto" width="100%" maxW="600px">
          <ClientSection heading={`Pending invites`} data={pendingClients} />
          <ClientSection heading={`Invited clients`} data={invitedClients} />
          <ClientSection heading={`Active clients`} data={activeClients} />
          <ClientSection heading={`Inactive clients`} data={inactiveClients} />
        </Box>
      </Stack>
    </Container>
  )
}

export default CoachingClients
