import React from 'react'
import { Controller } from 'react-hook-form'

import {
  Box,
  Tooltip,
  Icon,
  FormErrorMessage,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Textarea,
  Input,
  Checkbox,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import { Info, ChevronDown, ChevronUp } from 'react-feather'

import Select from 'components/Select'
import RichTextArea from 'components/RichTextArea'

import WorkoutWidgets from './WorkoutWidgets/WorkoutWidgets'

export const InfoIcon = React.forwardRef(({ ...rest }, ref) => (
  <Icon
    ref={ref}
    as={Info}
    w="4"
    h="4"
    color="gray.500"
    ml="1"
    strokeWidth="1.5px"
    _hover={{ cursor: 'pointer', color: 'brand.500' }}
    {...rest}
  />
))

export const NameSection = ({ defaultValue, register, errors }) => (
  <FormControl marginBottom="6" isRequired={true} isInvalid={errors.name}>
    <FormLabel marginBottom="0">Name</FormLabel>

    <Input
      data-cy="name-input"
      placeholder="Fingerboard - Max Hangs"
      type="text"
      {...{ defaultValue }}
      {...register('name', {
        required: 'Required',
        maxLength: { value: 30, message: '30 characters max' },
      })}
    />
    {errors.name && (
      <FormErrorMessage
        display="inline"
        fontWeight="bold"
      >{`${errors.name.message}`}</FormErrorMessage>
    )}
  </FormControl>
)

export const CategorySection = ({ defaultValue, control, options, errors }) => (
  <FormControl marginBottom="6" isRequired={true} isInvalid={errors.category}>
    <FormLabel marginBottom="0">Category</FormLabel>

    <Box width="200px" data-cy="category-select">
      <Controller
        name="category"
        rules={{ required: true }}
        {...{ control, defaultValue }}
        render={({ field }) => <Select {...field} {...{ options }} />}
      />
    </Box>

    {errors.category && (
      <FormErrorMessage display="inline" fontWeight="bold">
        Category is required
      </FormErrorMessage>
    )}
  </FormControl>
)

export const DurationSection = ({ defaultValue, register }) => (
  <FormControl marginBottom="6">
    <FormLabel display="flex" alignItems="center" marginBottom="0">
      Duration{' '}
      <Text as="span" fontSize="sm" color="gray.500" ml="1">
        (Minutes)
      </Text>
      <Tooltip
        label="Total time of workout, including rest time during the workout (i.e. how much time out of
      your day does this workout take?). You can adjust this every time you do this workout if
      it takes less or more time than usual."
      >
        <InfoIcon />
      </Tooltip>
    </FormLabel>

    <NumberInput min={0} {...{ defaultValue }} width="90px">
      <NumberInputField
        data-cy="duration-input"
        bg="white"
        placeholder="120"
        {...register('duration', {
          valueAsNumber: true,
        })}
      />
      <NumberInputStepper>
        <NumberIncrementStepper color="gray.500" />
        <NumberDecrementStepper color="gray.500" />
      </NumberInputStepper>
    </NumberInput>
  </FormControl>
)

export const DescriptionSection = ({ defaultValue, register, errors }) => (
  <FormControl marginBottom="6" isRequired={true} isInvalid={errors.description}>
    <FormLabel display="flex" alignItems="center" marginBottom="0">
      Description{' '}
      <Text as="span" fontSize="sm" color="gray.500" ml="1">
        (200 characters max)
      </Text>
      <Tooltip label="Short description about your workout. A sentence or two so you can easily understand what it is about.">
        <InfoIcon />
      </Tooltip>
    </FormLabel>

    <Textarea
      data-cy="description-textarea"
      placeholder="A brief description about your workout (1 or 2 sentences)"
      type="textarea"
      {...register('description', {
        required: 'Required',
        maxLength: { value: 200, message: '200 characters max' },
      })}
      background="white"
      {...{ defaultValue }}
    />

    {errors.description && (
      <FormErrorMessage
        display="inline"
        fontWeight="bold"
      >{`${errors.description.message}`}</FormErrorMessage>
    )}
  </FormControl>
)

export const WidgetSection = ({
  activityId,
  createWidgets,
  setCreateWidgets,
  templatedActivity,
}) => (
  <>
    <FormControl>
      <FormLabel display="flex" alignItems="center" marginBottom="0">
        Widgets
        <Tooltip label="Add extra customized functionality to this workout">
          <InfoIcon />
        </Tooltip>
      </FormLabel>
    </FormControl>

    <Box marginTop="1" marginBottom="6">
      <WorkoutWidgets
        widgets={createWidgets}
        {...{ activityId, setCreateWidgets, templatedActivity }}
      />
    </Box>
  </>
)

export const OnRockSection = ({ defaultValue, register }) => (
  <FormControl marginBottom="6">
    <FormLabel as="legend" display="flex" alignItems="center" marginBottom="0" htmlFor={null}>
      On Rock
      <Tooltip label="Use this to track how much time you spend outside on rock.">
        <InfoIcon />
      </Tooltip>
    </FormLabel>

    <Checkbox
      defaultChecked={defaultValue}
      colorScheme="brand"
      background="white"
      size="lg"
      width="auto"
      type="checkbox"
      {...register('outdoor')}
    />
  </FormControl>
)

export const DetailsSection = ({ defaultValue, control }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <FormControl marginBottom="6">
      <FormLabel
        display="flex"
        alignItems="center"
        onClick={!defaultValue ? onToggle : undefined}
        _hover={{ cursor: 'pointer' }}
        marginBottom="0"
      >
        Extra Details
        <Text as="span" fontSize="sm" color="gray.500" ml="1">
          (detailed instructions, add videos, images, etc)
        </Text>
        {!defaultValue && (
          <Icon
            as={isOpen ? ChevronUp : ChevronDown}
            w="5"
            h="5"
            color="gray.500"
            ml="1"
            strokeWidth="1px"
            _hover={{ cursor: 'pointer', color: 'brand.500' }}
          />
        )}
      </FormLabel>

      <Collapse in={isOpen || !!defaultValue} animateOpacity>
        <Controller
          name="details"
          {...{ control, defaultValue }}
          render={({ field }) => <RichTextArea {...field} />}
        />
      </Collapse>
    </FormControl>
  )
}

export const OwnerNotesSection = ({ defaultValue, register }) => (
  <FormControl marginBottom="6">
    <FormLabel display="flex" alignItems="center" marginBottom="0">
      Notes
      <Tooltip label="Your notes for this workout (this will not be visible when copying to a client)">
        <InfoIcon />
      </Tooltip>
    </FormLabel>

    <Textarea
      placeholder="Your notes for this workout (this will not be visible when copying to a client)"
      type="textarea"
      {...register('owner_notes')}
      background="white"
      {...{ defaultValue }}
    />
  </FormControl>
)
