import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Button,
  HStack,
  Box,
  Center,
  SimpleGrid,
  Container,
  Heading,
  VStack,
  Image,
  Stack,
  Text,
  ListItem,
  UnorderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Link as ChakraLink,
  useDisclosure,
} from '@chakra-ui/react'

import { Gallery } from '../components/Gallery'

import { PlanPreviewModal, PlanApplyModal } from '../components/PlanModals'

import { SEQ_BOULDER_PLAN_FREE, SEQ_BOULDER_PLAN_FREE_CODE } from 'domain/Plans/constants'

import Hero1 from './assets/boulder-hero-1.png'
import Hero2 from './assets/boulder-hero-2.jpeg'

import Slide1 from './assets/slide-1.png'
import Slide2 from './assets/slide-2.png'
import Slide3 from './assets/slide-3.png'
import Slide4 from './assets/slide-4.png'
import Slide5 from './assets/slide-5.png'
import Slide6 from './assets/slide-6.png'

export const images = [
  {
    id: '01',
    src: Slide1,
    alt: 'Slide 1',
  },
  {
    id: '02',
    src: Slide2,
    alt: 'Slide 2',
  },
  {
    id: '03',
    src: Slide3,
    alt: 'Slide 3',
  },
  {
    id: '04',
    src: Slide4,
    alt: 'Slide 4',
  },
  {
    id: '05',
    src: Slide5,
    alt: 'Slide 5',
  },
  {
    id: '05',
    src: Slide6,
    alt: 'Slide 6',
  },
]

const SequenceBoulderPlanFree = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Box
        as="section"
        bg="gray.800"
        py="12"
        position="relative"
        h={{ base: '560px', md: '640px' }}
        bgImage={Hero1}
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
          h: 'full',
          bg: 'blackAlpha.700',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center flexDirection="column" textAlign="center" color="white" h="full">
            <Heading size="2xl" fontWeight="extrabold" color="#fff">
              Boulder Plan
            </Heading>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              A variety of workouts with the main focus being strength and power
            </Text>
          </Center>
        </Box>
      </Box>

      <Container
        pt={{ base: '16', md: '24' }}
        pb={{ base: '0', md: '0', sm: '16' }}
        maxWidth="container.lg"
      >
        <Stack align="end">
          <VStack justifyContent="center" spacing="2">
            <Button variant="primary" size="lg" onClick={onConfirmOpen}>
              Free
            </Button>
            <Button variant="link" color="yellow.500" size="sm" onClick={onOpen}>
              Preview Plan
            </Button>
          </VStack>
        </Stack>
      </Container>
      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                Designed to help give direction, accountability and progression.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                This plan offers a variety of workouts with the main focus being{' '}
                <Text as="span" fontWeight="bold">
                  strength
                </Text>{' '}
                and{' '}
                <Text as="span" fontWeight="bold">
                  power
                </Text>
                . Each week you'll be building on the previous with load and intensity.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                This plan has been designed by Sequence's co-founder, Jake Bresnehan so takes full
                advantage of the app and it's features.
              </Text>
            </Stack>
          </Stack>
          <Stack spacing="3" justifyContent="center" align="center">
            <UnorderedList fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
              <ListItem>4 weeks</ListItem>
              <ListItem>Repeatable</ListItem>
              <ListItem>A great structure to build on</ListItem>
              <ListItem>Variety of workouts</ListItem>
              <ListItem>Suits a large range of climbing abilities</ListItem>
            </UnorderedList>
          </Stack>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Gallery images={images} />
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                This plan builds in training load and intensity for 3 weeks, followed by a
                de-load/sharpen week where all the hard work starts to pay off.
              </Text>
              <Text fontWeight="bold">Weekly time commitment:</Text>
              <UnorderedList fontSize={{ base: 'lg', md: 'xl' }} mt="0">
                <ListItem>
                  2 - 3 sessions on the boulder wall per week. Session length varies between 1 to
                  1.5 hours.
                </ListItem>
                <ListItem>1 - 2 outdoor boulder sessions.</ListItem>
                <ListItem>Specific fingerboard sessions.</ListItem>
                <ListItem>
                  Supplementary strength, cardio and flexibility sessions throughout the week, no
                  more than 1.5 hours a week.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box bg="white">
        <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing="3" align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Ready to level up your bouldering?</Heading>
              <Text color="on-accent-muteed" maxW="2xl" textAlign="center" fontSize="xl">
                This plan is designed to help you get stronger and more powerful.
              </Text>
            </Stack>

            <VStack justifyContent="center" spacing="2">
              <Button variant="primary" size="lg" onClick={onConfirmOpen}>
                Free
              </Button>
              <Button variant="link" color="yellow.500" size="sm" onClick={onOpen}>
                Preview Plan
              </Button>
            </VStack>
          </Stack>
        </Container>
      </Box>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Grid templateColumns="repeat(3, 1fr)" gap={16}>
          <GridItem colSpan={1}>
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>FAQs</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Everything you need to know about the plan and billing.
                </Text>
              </Stack>
            </Stack>
          </GridItem>
          <GridItem colSpan={2}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Accordion allowMultiple>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>How is the plan delivered</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    After you have successfully purchased the plan, you will be able to add the plan
                    to your Sequence planner.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Can I do the plan more than once?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    You pay for the plan once but keep access to it forever. You can choose to re-do
                    the plan for as long as you maintain your Sequence membership.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Is it available on mobile?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    100%. As long as you have the Sequence mobile app installed, you can access your
                    current schedule and log your training sessions.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </GridItem>
        </Grid>
      </Container>

      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }} align="center" justifyContent="center">
          <Stack spacing={{ base: '4', md: '5' }} maxW="xl">
            <Box align="center">
              <Stack spacing="3">
                <Heading size={{ base: 'sm', md: 'md' }}>Still have questions?</Heading>

                <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Can't find the answer you're looking for? Our team is happy to answer your
                  questions.
                </Text>
                <HStack justifyContent="center">
                  <ChakraLink
                    color="brand.500"
                    fontWeight="bold"
                    href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
                  >
                    Send us an email
                  </ChakraLink>
                </HStack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={Hero2}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />

      <PlanPreviewModal
        planName={SEQ_BOULDER_PLAN_FREE}
        planCode={SEQ_BOULDER_PLAN_FREE_CODE}
        {...{ isOpen, onClose }}
      />
      <PlanApplyModal
        planName={SEQ_BOULDER_PLAN_FREE}
        planCode={SEQ_BOULDER_PLAN_FREE_CODE}
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
      />
    </>
  )
}

export default SequenceBoulderPlanFree
