import React from 'react'
import { Text } from '@chakra-ui/react'

const LastXDaysStatic = ({ days }) => {
  return (
    <Text fontSize="x-small" textTransform="uppercase" color="gray.500" display="inline">
      Last <Text as="span">{days}</Text> Days
    </Text>
  )
}

export default LastXDaysStatic
