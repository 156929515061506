import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Checkbox,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Importer, ImporterField, enUS } from 'react-csv-importer'
import { Info } from 'react-feather'

import securedApi from 'backend/axios'
import Icon from 'components/Icons/Icon'
import LoadingSpinner from 'components/LoadingSpinner'

import { processApiError } from 'helpers/utils'

// include the widget CSS file (original is at 'react-csv-importer/dist/index.css')
import './react-csv-importer.css'

const enUS2 = {
  ...enUS,
  ...{
    fieldsStep: {
      ...enUS.fieldsStep,
      nextButton: 'Process',
    },
    progressStep: {
      ...enUS.progressStep,
      statusPending: 'Processing...',
      statusComplete: 'Ready to Submit',
      stepSubtitle: 'Processed',
    },
  },
}

const FileImporter = ({ setStatus, appendRows, onCloseImporter }) => {
  return (
    <Importer
      locale={enUS2}
      skipEmptyLines={true}
      restartable={false}
      defaultNoHeader={false}
      dataHandler={async (rows) => {
        // may be called several times
        // receives a list of parsed objects based on defined fields and user column mapping;
        // we add all the rows to one object and send this to the BE at once
        // so that the entire file/rows can be inspected on the BE before deciding a course of action
        await appendRows(rows)
      }}
      onStart={({ file, preview, fields, columnFields }) => {
        setStatus('processing')
      }}
      onComplete={({ file, preview, fields, columnFields }) => {
        setStatus('processed')
      }}
    >
      <ImporterField name="date" label="Date" />
      <ImporterField name="value" label="Value" />
      {/*<ImporterField name="notes" label="Notes" optional />*/}
    </Importer>
  )
}

const ImportDailyMetrics = () => {
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState(null)
  const [importRows, setImportRows] = useState([])

  // import status: 'processing', 'processed', 'submitting', 'submitted'
  const [status, setStatus] = useState(null)

  const [biometricAttributes, setBiometricAttributes] = useState()
  const [biometricAttribute, setBiometricAttribute] = useState()
  const [overwriteRecords, setOverwriteRecords] = useState(false)

  useEffect(() => {
    securedApi
      .get(`/api/v1/biometric_attributes`)
      .then(({ data }) => {
        setBiometricAttributes(data)
      })
      .catch((error) => processApiError(error))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  const appendRows = (rows) => {
    setImportRows((importRows) => [...importRows, ...rows])
  }

  const onCloseImporter = () => {
    setStatus(null)
    setMessage(null)
    setImportRows(null)
    setBiometricAttribute(null)
  }

  const sendImportRows = () => {
    setStatus('submitting')

    securedApi
      .post(`/api/v1/import/biometrics`, {
        biometric_attribute_id: biometricAttribute.id,
        rows: importRows,
        overwrite: overwriteRecords,
      })
      .then(({ data }) => {
        setStatus('submitted')
        setMessage(data.message)
      })
      .catch((error) => processApiError(error))
  }

  const OverwriteInfoIcon = () => (
    <Icon
      as={Info}
      color="gray.400"
      tooltipContent="Overwrite existing values for this metric with the same date"
    />
  )

  const SubmitButton = () => {
    const buttonContent = status === 'submitting' ? <LoadingSpinner /> : 'Submit'
    const buttonTooltip =
      !requirementsMet &&
      'In order to import, a CSV file needs to be processed and the destination metric must be selected'

    return (
      <Tooltip label={buttonTooltip}>
        <Button
          onClick={sendImportRows}
          isDisabled={buttonDisabled}
          colorScheme="brand"
          width="100px"
        >
          {buttonContent}
        </Button>
      </Tooltip>
    )
  }

  const FrameHeading = ({ text }) => (
    <Heading as="h3" size="md" marginBottom={5}>
      {text}
    </Heading>
  )

  const requirementsMet =
    ['processed', 'submitting', 'submitted'].includes(status) && biometricAttribute
  const buttonDisabled = !requirementsMet || status === 'submitting'

  return (
    <Container maxW="container.lg">
      <Box align="center" marginTop={10}>
        <Heading size="lg">Biometrics Import</Heading>
      </Box>
      <Flex gap={12} marginTop={20}>
        <Box width="550px">
          <FrameHeading text="Process CSV File" />
          <FileImporter
            {...{
              setStatus,
              appendRows,
              onCloseImporter,
            }}
          />
        </Box>
        <Box width="400px">
          <FrameHeading text="Submit" />
          <Flex flexDirection="column" gap={5}>
            <Box height="50px">
              <Select
                placeholder="Select metric"
                onChange={(e) => {
                  const biometricAttributeId = e.target.value
                  setBiometricAttribute(
                    biometricAttributes.find((x) => x.id === biometricAttributeId)
                  )
                }}
              >
                {biometricAttributes.map((biometricAttribute) => {
                  const { id, name, units } = biometricAttribute
                  const optionText = units ? `${name} (${units})` : name

                  return (
                    <option key={id} value={id}>
                      {optionText}
                    </option>
                  )
                })}
              </Select>
            </Box>
            <Flex flexDirection="column">
              <Checkbox
                isChecked={overwriteRecords}
                onChange={(e) => setOverwriteRecords(e.target.checked)}
                colorScheme="brand"
                width="100%"
              >
                Overwrite existing values <OverwriteInfoIcon />
              </Checkbox>
              <Box mt="1">
                <SubmitButton />
              </Box>
            </Flex>
            <Text>{message}</Text>
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

export default ImportDailyMetrics
