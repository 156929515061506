import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import {
  Button,
  Box,
  Container,
  Text,
  Spacer,
  SimpleGrid,
  Icon,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { groupBy } from 'lodash'
import { Server as WidgetIcon } from 'react-feather'

import { useQuery } from 'hooks'

import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'
import Modal from 'components/Modal'
import CategoryStrip from 'components/Workouts/CategoryStrip'
import WorkoutSearch from 'components/Workouts/WorkoutSearch'

import { shortEnglishHumanizer } from 'helpers/duration'

const CoachingWorkouts = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [coachingWorkoutId, setCoachingWorkoutId] = useState(null)
  const [searching, setSearching] = useState(false)
  const [searchedWorkouts, setSearchedWorkouts] = useState([])

  const { data, isLoading, hasError, mutate } = useQuery(`api/v1/templated_activities?list=true`)
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    hasError: hasCategoriesError,
  } = useQuery(`api/v1/templated_categories`)

  if (hasError || hasCategoriesError) return <Error />

  if (isLoading || isCategoriesLoading) {
    return <LoadingSpinner />
  }

  const deleteCoachingWorkout = () => {
    securedApi
      .delete(`api/v1/templated_activities/${coachingWorkoutId}`)
      .then(() => {
        mutate()
        setModalOpen(false)
        Notify({ content: 'Coaching Workout deleted.', type: 'success' })
      })
      .catch((error) => console.log(error))
  }

  const handleContextMenuClick = (event, data) => {
    event.stopPropagation()

    if (data.action === 'delete') {
      setCoachingWorkoutId(data.id)
      setModalOpen(true)
    }
  }

  const handleOnCategorySelection = ({ name }) => {
    setSearching(true)
    setSearchedWorkouts(data.filter((i) => i.category_name === name))
  }

  const handleSearchClose = () => {
    setSearchedWorkouts([])
    setSearching(false)
  }

  const handleOnChangeSearch = (event) => {
    setSearching(true)
    const regexp = new RegExp(event.target.value, 'i')
    setSearchedWorkouts(data.filter((i) => regexp.test(i.activity_name)))
  }

  const renderCoachingWorkouts = ({ workouts }) => {
    if (!data.length) {
      return (
        <Text color="gray.500" textAlign="center">
          You current haven't added any coaching workouts.
          <ChakraLink
            href="https://docs.sequence-app.com/coaching/coaching-workouts"
            isExternal
            color="yellow.500"
          >
            <br />
            Learn more about coaching workouts <ExternalLinkIcon />
          </ChakraLink>
        </Text>
      )
    }

    const categoryGroups = groupBy(workouts, 'category_name')

    const individualCoachingWorkout = () => {
      return Object.entries(categoryGroups).map(([key, value]) => {
        return value.map((coachingWorkout) => {
          const {
            id,
            activity_name,
            activity_description,
            category_name,
            category_hex_colour,
            duration,
          } = coachingWorkout

          return (
            <div key={id}>
              <ContextMenuTrigger id={id}>
                <Link to={`/coaching-workout/${id}`}>
                  <Box
                    background="#fff"
                    cursor="pointer"
                    borderWidth="1px"
                    borderColor="transparent"
                    _hover={{ borderColor: 'yellow.500' }}
                    paddingX="4"
                    paddingY="5"
                    transition="all 150ms ease-in-out"
                  >
                    <Text fontWeight="medium" fontSize="large" color="black">
                      {activity_name}
                    </Text>

                    <CategoryStrip name={category_name} colour={category_hex_colour} />

                    <Text mt="2" color="gray.500" fontSize="small">
                      {activity_description}
                    </Text>

                    {duration && duration > 0 && (
                      <Text color="black" mt="3" fontWeight="semibold" fontSize="small">
                        {shortEnglishHumanizer(duration)}
                      </Text>
                    )}
                  </Box>
                </Link>
              </ContextMenuTrigger>
              <ContextMenu id={id}>
                <Box background="gray.100" borderRadius="4">
                  <MenuItem data={{ action: 'delete', id: id }} onClick={handleContextMenuClick}>
                    <Box
                      py="2"
                      px="4"
                      cursor="pointer"
                      _hover={{ background: 'brand.500', color: 'white' }}
                    >
                      Delete Coaching Workout
                    </Box>
                  </MenuItem>
                </Box>
              </ContextMenu>
            </div>
          )
        })
      })
    }

    return (
      <SimpleGrid columns={[3, null, 4]} spacing="12px">
        {individualCoachingWorkout()}
      </SimpleGrid>
    )
  }

  return (
    <>
      <Box pb="20">
        <Container maxWidth="container.lg">
          <Box display="flex" mt="6" minHeight="100px" alignItems="center">
            <WorkoutSearch
              {...{ categories }}
              onCategorySelection={handleOnCategorySelection}
              onSearchClose={handleSearchClose}
              onChangeSearch={handleOnChangeSearch}
            />
            <Box
              as={Link}
              to="/coaching/widgets"
              display="flex"
              flexDirection="column"
              alignItems="center"
              mx="4"
              mt="1"
              _hover={{ cursor: 'pointer' }}
            >
              <Icon as={WidgetIcon} w="5" h="5" color="#999" />

              <Text mt="2" fontSize="x-small" textTransform="uppercase">
                Widgets
              </Text>
            </Box>
            <Spacer />

            {!!data.length && (
              <Button
                as={Link}
                to="/bulk-add-coaching-workouts"
                mr="2"
                colorScheme="brand"
                variant="outline"
              >
                Bulk Add Workouts
              </Button>
            )}

            <Button as={Link} variant="primary" to="/create-coaching-workout">
              New Coaching Workout
            </Button>
          </Box>

          {searching
            ? renderCoachingWorkouts({ workouts: searchedWorkouts })
            : renderCoachingWorkouts({ workouts: data })}
        </Container>
        <Modal
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          title="Are you sure?"
          subTitle="This workout will be deleted. It will not delete any copies previously added to clients."
          onAccept={deleteCoachingWorkout}
        ></Modal>
      </Box>
    </>
  )
}

export default CoachingWorkouts
