import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Spacer,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import MeasureChart from './MeasureChart'
import MeasureTable from './MeasureTable'

const WidgetMeasureModal = ({ isOpen, onOpen, onClose, measure }) => {
  const [previousLoading, setPreviousLoading] = useState(false)
  const [listData, setListData] = useState([])

  // measure plot/table state toggles
  const measurePlotStates = ['single', 'combined', 'table']
  const [measurePlotState, setMeasurePlotState] = useState(measurePlotStates[0])

  useEffect(() => {
    setPreviousLoading(true)

    if (isOpen) {
      securedApi
        .get(`api/v1/widget_measures/${measure.widget_measure_id}/metrics/list`)
        .then(({ data }) => {
          setListData(data)
        })
        .catch((error) => {
          processGetError(error)
        })
        .finally(() => {
          setPreviousLoading(false)
        })
    }
  }, [isOpen, measure])

  const renderModalContent = () => {
    if (previousLoading) {
      return <LoadingSpinner />
    }

    if (listData.length === 0) {
      return (
        <Box>
          <Text>Workout has not been completed yet</Text>
        </Box>
      )
    }

    const { info, entries } = listData

    if (entries.length === 0) {
      return (
        <Box>
          <Text>No measurements have been recorded yet</Text>
        </Box>
      )
    }

    const ChangePlotLink = ({ type }) => {
      const mapTypeText = {
        single: 'chart',
        combined: 'combined',
        table: 'table',
      }

      const handleLinkClick = (type) => {
        setMeasurePlotState(type)
      }

      return (
        <Link
          as="span"
          color="yellow.500"
          fontWeight={type === measurePlotState ? 'bold' : ''}
          mx="2"
          fontSize="sm"
          fontVariant="smallCaps"
          onClick={() => handleLinkClick(type)}
        >
          {mapTypeText[type]}
        </Link>
      )
    }

    return (
      <Box>
        <Flex mb={2}>
          <Box>{info.name}</Box>
          <Spacer />
          <Flex>
            <ChangePlotLink type="single" />
            {info.dimensions == 2 && <ChangePlotLink type="combined" />}
            {info.dimensions == 3 && <ChangePlotLink type="combined" />}
            <ChangePlotLink type="table" />
          </Flex>
        </Flex>

        {['single', 'combined'].includes(measurePlotState) && (
          <MeasureChart
            data={listData}
            measurePlotState={measurePlotState}
            setMeasurePlotState={setMeasurePlotState}
          />
        )}

        {measurePlotState === 'table' && <MeasureTable data={listData} />}
      </Box>
    )
  }

  return (
    <Modal scrollBehavior="inside" {...{ isOpen, onClose }}>
      <ModalOverlay />
      <ModalContent top="1rem">
        <ModalHeader>Previous Measurements</ModalHeader>
        <ModalBody paddingBottom="6">{renderModalContent(measure)}</ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}

export default WidgetMeasureModal
