import React from 'react'
import {
  Book as LogbookIcon,
  BarChart2 as MeasureIcon,
  File as SelectIcon,
  Sliders as SliderIcon,
} from 'react-feather'

import { LOGBOOK_TYPE, MEASURE_TYPE, SELECT_TYPE, SLIDER_TYPE } from 'domain/Widgets/constants'

import LogbookFormFields from 'domain/Widgets/Logbook/FormComponents/LogbookFormFields'
import MeasureFormFields from 'domain/Widgets/Measure/FormComponents/MeasureFormFields'
import SelectFormFields from 'domain/Widgets/Select/FormComponents/SelectFormFields'

export const widgetTextMap = {
  [LOGBOOK_TYPE]: 'Logbook',
  [MEASURE_TYPE]: 'Measure',
  [SELECT_TYPE]: 'Select',
  [SLIDER_TYPE]: 'Slider',
}

export const widgetIconMap = {
  [LOGBOOK_TYPE]: LogbookIcon,
  [MEASURE_TYPE]: MeasureIcon,
  [SELECT_TYPE]: SelectIcon,
  [SLIDER_TYPE]: SliderIcon,
}

export const widgetEditFormComponentMap = {
  [LOGBOOK_TYPE]: LogbookFormFields,
  [MEASURE_TYPE]: MeasureFormFields,
  [SELECT_TYPE]: SelectFormFields,
  [SLIDER_TYPE]: null,
}
