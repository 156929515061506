import React, { useState } from 'react'
import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

import { pluralize } from '../../helpers/utils'
import BigOverview from './components/BigOverview'
import { BreakdownLink, CategoryBarChart } from './components/CategoryBarChart'

const MilestoneMetrics = ({ data }) => {
  const [sessionBreakdownType, setSessionBreakdownType] = useState('time')

  if (!Object.keys(data).length) {
    return null
  }

  const { all_time, weeks } = data

  const { hours_trained, days_on_rock, by_category, rest_days } = all_time

  const renderWeeks = (weeks) => {
    const tableData = () => {
      return weeks.map((week, index) => {
        const {
          week_no,
          hours_trained,
          num_workouts,
          rest_days,
          days_on_rock,
          felt,
          motivated,
          performed,
        } = week

        return (
          <Tr key={index}>
            <Td>{week_no}</Td>
            <Td>{Number.parseFloat(hours_trained).toFixed(2)}</Td>
            <Td>{num_workouts}</Td>
            <Td>{rest_days}</Td>
            <Td>{days_on_rock}</Td>
            <Td>{felt}</Td>
            <Td>{motivated}</Td>
            <Td>{performed}</Td>
          </Tr>
        )
      })
    }

    return (
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Hours</Th>
            <Th>Workouts</Th>
            <Th>Rest Days</Th>
            <Th>Days on Rock</Th>
            <Th>Felt</Th>
            <Th>Motivated</Th>
            <Th>Performed</Th>
          </Tr>
        </Thead>
        <Tbody>{tableData()}</Tbody>
      </Table>
    )
  }

  return (
    <Box marginTop="4" borderTop="1px solid" borderTopColor="gray.300" paddingY="4">
      <Text textTransform="uppercase" fontSize="small" fontWeight="semibold" color="gray.500">
        Last 6 weeks
      </Text>

      <Box mt="2" textAlign="center">
        <Flex ml="6" justifyContent="space-between">
          <Box mr="6">
            <BigOverview value={Number.parseFloat(hours_trained).toFixed(2)} label="Hours" />
          </Box>

          <Flex mr="6">
            <Box mr="6">
              <BigOverview
                value={days_on_rock}
                label={`${pluralize(days_on_rock, 'Day')} on Rock`}
              />
            </Box>
            <Box ml="6">
              <BigOverview value={rest_days} label={`Rest ${pluralize(rest_days, 'day')}`} />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box marginTop="10">
        <Text textTransform="uppercase" fontSize="small" fontWeight="semibold" color="gray.500">
          Session breakdown
        </Text>
        <Flex marginLeft={3} marginTop={2} marginBottom={1}>
          <BreakdownLink
            type="time"
            sessionBreakdownType={sessionBreakdownType}
            setSessionBreakdownType={setSessionBreakdownType}
          />
          <BreakdownLink
            type="total"
            sessionBreakdownType={sessionBreakdownType}
            setSessionBreakdownType={setSessionBreakdownType}
          />
        </Flex>
        <CategoryBarChart data={by_category} breakdown={sessionBreakdownType} />
      </Box>
      <Box marginTop="10">
        <Text textTransform="uppercase" fontSize="small" fontWeight="semibold" color="gray.500">
          Weekly breakdown
        </Text>
      </Box>
      <Box marginTop="2">{renderWeeks(weeks)}</Box>
    </Box>
  )
}

export default MilestoneMetrics
