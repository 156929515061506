import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react'

import { useActivityModal } from 'hooks'

import { usePlannerMethods } from 'domain/Planner/hooks'

import { shortEnglishHumanizer } from 'helpers/duration'

import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import CategoryStrip from 'components//Workouts/CategoryStrip'

import Menu from 'domain/Schedule/ActivityComponents/Menu'
import Details from 'domain/Schedule/ActivityComponents/Details'
import ActivityDuration from 'domain/Schedule/ActivityComponents/ActivityDuration'
import TrainingNotes from 'domain/Schedule/ActivityComponents/TrainingNotes'
import OutdoorToggle from 'domain/Schedule/ActivityComponents/OutdoorToggle'
import MarkAsIncomplete from 'domain/Schedule/ActivityComponents/MarkAsIncomplete'
import CompleteActions from 'domain/Schedule/ActivityComponents/CompleteActions'

import WidgetLogbooks from 'domain/Widgets/Logbook/ActivityComponents/WidgetLogbooks'
import WidgetMeasures from 'domain/Widgets/Measure/ActivityComponents/WidgetMeasures'
import WidgetSelects from 'domain/Widgets/Select/ActivityComponents/WidgetSelects'
import WidgetSliders from 'domain/Widgets/Slider/ActivityComponents/WidgetSliders'

import { processApiError, tzSafeDateFormat } from 'helpers/utils'

import LinkedGoalsIcon from './LinkedGoalsIcon'

const ActivityModal = ({
  id,
  isOpen,
  onClose,
  highlightMeasureId,
  highlightLogbookEntryId,
  finalFocusRef,
  readOnly = false,
}) => {
  const { refreshData: refresh } = usePlannerMethods()
  const setActivityModalClose = useActivityModal((state) => state.setActivityModalClose)

  const [forceRerender, setForceRerender] = useState(false)

  const [loading, setLoading] = useState(true)
  const [currentActivity, setCurrentActivity] = useState()
  const [duration, setDuration] = useState()
  const [currentActivityId, setCurrentActivityId] = useState()
  const [completed, setCompleted] = useState(false)
  const [incomplete, setIncomplete] = useState(false)
  const [notes, setNotes] = useState()
  const [outdoor, setOutdoor] = useState(false)

  // Widgets
  const [logbooks, setLogbooks] = useState([])
  const [measures, setMeasures] = useState([])
  const [selects, setSelects] = useState([])
  const [sliders, setSliders] = useState([])

  useEffect(() => {
    setLoading(true)
    getData()
  }, [id])

  useEffect(() => {
    // by not called setLoading during a triggerRerender
    // the UI will not flicker due to the loading spinner
    getData()
  }, [forceRerender])

  const triggerRerender = () => {
    setForceRerender((prevForceRerender) => !prevForceRerender)
  }

  const getData = () => {
    securedApi
      .get(`/api/v1/scheduled_activities/${id}`)
      .then(({ data }) => {
        setCurrentActivity(data)
        setCurrentActivityId(data.id)
        setDuration(data.actual_duration || data.duration)
        setCompleted(data.completed)
        setNotes(data.notes)
        setOutdoor(data.actual_outdoor || data.outdoor || false)
        setIncomplete(data.incomplete)

        // Widgets
        setLogbooks(data.widgets?.logbooks)
        setMeasures(data.widgets?.measures)
        setSelects(data.widgets?.selects)
        setSliders(data.widgets?.sliders)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDeleteActivity = ({ id: activityToDeleteId }) => {
    securedApi
      .delete(`api/v1/scheduled_activities/${activityToDeleteId}`)
      .then(() => {
        refresh()
        Notify({ content: 'Workout removed', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  const handleSetDuration = (value) => {
    const adjustedDuration = Math.floor(Number(value) * 60 * 1000)
    setDuration(adjustedDuration)
  }

  const handleOnClose = () => {
    refresh()

    //modal is used in a few places, so we need to make sure we're closing correctly based on context
    onClose?.()
    setActivityModalClose?.()
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }

    const {
      activity_id,
      activity_name,
      category_name,
      category_hex_colour,
      activity_description,
      activity_details,
      scheduled_at,
      linked_goals,
    } = currentActivity

    const scheduled_at_format = tzSafeDateFormat(scheduled_at, 'd MMM yyyy')

    return (
      <>
        <ModalHeader pb="0">
          <Box display="flex" alignItems="center">
            <Box>{activity_name}</Box>
            <Spacer />
            <LinkedGoalsIcon linkedGoals={linked_goals} />
            <Box>
              <Text fontSize="sm">{scheduled_at_format}</Text>
            </Box>
            <Box mr="-4">
              <Menu
                originalActivityId={activity_id}
                onRemove={() => onClose()}
                currentActivityHasMeasures={measures?.length}
                hasNotes={!!notes}
                isCompleted={completed}
                currentActivityId={id}
                onDeleteActivity={handleDeleteActivity}
                {...{ readOnly }}
              />
            </Box>
          </Box>
        </ModalHeader>

        <ModalBody color="gray.600">
          <Box display="flex">
            <Box flexGrow="1">
              <CategoryStrip name={category_name} colour={category_hex_colour} inline={true} />

              <Box mt="2">
                <Text fontSize="xs" fontWeight="semibold" color="black">
                  Duration: {duration > 0 ? shortEnglishHumanizer(duration) : '-'}
                </Text>
              </Box>

              <Text fontSize="sm">{activity_description}</Text>
              <Details activityDetails={activity_details} />
              <ActivityDuration
                onSetDuration={handleSetDuration}
                {...{ duration }}
                {...{ readOnly }}
              />
              <Box>
                <WidgetSliders
                  scheduledActivityId={currentActivityId}
                  onSetSliders={setSliders}
                  {...{ sliders }}
                  {...{ readOnly }}
                />
                <WidgetSelects
                  scheduledActivityId={currentActivityId}
                  onSetSelects={setSelects}
                  {...{ selects }}
                  {...{ readOnly }}
                />
                <WidgetMeasures
                  scheduledActivityId={currentActivityId}
                  onSetMeasures={setMeasures}
                  {...{ measures, highlightMeasureId }}
                  {...{ readOnly }}
                />
                <WidgetLogbooks
                  scheduledActivityId={currentActivityId}
                  onSetLogbooks={setLogbooks}
                  {...{ logbooks, highlightLogbookEntryId }}
                  {...{ triggerRerender }} // so that editing grade scale can trigger rerender so grades are updated
                  {...{ readOnly }}
                />
              </Box>
              <TrainingNotes
                id={id}
                activityId={activity_id}
                onSetNotes={setNotes}
                {...{ notes }}
                {...{ readOnly }}
              />
              <Flex marginTop={4}>
                <OutdoorToggle onSetOutdoor={setOutdoor} {...{ outdoor }} {...{ readOnly }} />
                <Spacer />
                {!readOnly && (
                  <MarkAsIncomplete
                    onSetCompleted={setCompleted}
                    onSetIncomplete={setIncomplete}
                    onCloseModal={handleOnClose}
                    {...{
                      incomplete,
                      currentActivityId,
                      refresh,
                    }}
                  />
                )}
              </Flex>

              {!readOnly && (
                <CompleteActions
                  onSetCompleted={setCompleted}
                  onSetIncomplete={setIncomplete}
                  onCloseModal={handleOnClose}
                  {...{
                    completed,
                    incomplete,
                    currentActivityId,
                    outdoor,
                    measures,
                    sliders,
                    notes,
                    duration,
                    refresh,
                  }}
                />
              )}
            </Box>
          </Box>
        </ModalBody>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      finalFocusRef={finalFocusRef}
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  )
}

export default ActivityModal
