import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import Notify from 'components/Notification'
import { INVITED_TYPE } from '../constants'

const ClientInvite = ({ id, name, email, isOpen, onClose, status }) => {
  const { handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    const inviteEndpoint = status === INVITED_TYPE ? 'reinvite_client' : 'invite_client'

    setLoading(true)
    securedApi
      .post(`/api/v1/coaching/${inviteEndpoint}`, { email })
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: `${name} has been invited`, type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Invite {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Send an invite email to{' '}
              <Text as="span" fontWeight="bold">
                {email}
              </Text>
            </Text>
            <Text>Are you sure?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" type="submit" isLoading={loading}>
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default ClientInvite
