import React from 'react'
import { Box } from '@chakra-ui/react'

const BigOverview = ({ value, label }) => {
  return (
    <Box fontSize={{ base: '5xl', md: '7xl' }} color="gray.600">
      {value}
      <Box textTransform="uppercase" fontSize="xs" fontWeight="semibold" ml="2" color="gray.600">
        {label}
      </Box>
    </Box>
  )
}

export default BigOverview
