import { Box, Button, Flex, Text } from '@chakra-ui/react'

const Pagination = ({ table }) => {
  const PaginationButton = ({ onClick, isDisabled, label }) => {
    return (
      <Button
        color="gray.600"
        size="sm"
        p={1}
        borderWidth={1}
        borderRadius={3}
        {...{ onClick, isDisabled }}
      >
        {label}
      </Button>
    )
  }

  return (
    <Box py={2} bg="#fff">
      <Flex gap={2} alignItems="center" flexDirection="column">
        <Flex gap={2} alignItems="center">
          <PaginationButton
            onClick={() => table.firstPage()}
            isDisabled={!table.getCanPreviousPage()}
            label={'<<'}
          />
          <PaginationButton
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
            label={'<'}
          />
          <PaginationButton
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
            label={'>'}
          />
          <PaginationButton
            onClick={() => table.lastPage()}
            isDisabled={!table.getCanNextPage()}
            label={'>>'}
          />
        </Flex>
        <Flex gap={1} alignItems="center">
          <Text fontSize="sm" color="gray.500">
            Page
          </Text>
          <Text fontWeight="bold" fontSize="sm" color="gray.500">
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Pagination
