import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { groupBy, startCase } from 'lodash'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  Spacer,
  SimpleGrid,
  Icon,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { Server as WidgetIcon } from 'react-feather'

import { useQuery } from 'hooks'

import securedApi from 'backend/axios'

import { processApiError } from 'helpers/utils'
import { shortEnglishHumanizer } from 'helpers/duration'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import Notify from 'components/Notification'
import Modal from 'components/Modal'
import CategoryStrip from 'components/Workouts/CategoryStrip'
import WorkoutSearch from 'components/Workouts/WorkoutSearch'

import ImportWorkoutModal from './ImportWorkoutModal'

const Workouts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchParams] = useSearchParams()

  const {
    data: workouts,
    isLoading: isWorkoutsLoading,
    hasError: hasWorkoutsError,
    mutate: mutateWorkouts,
  } = useQuery(`api/v1/activities?skip_widgets=1`)
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    hasError: hasCategoriesError,
  } = useQuery(`api/v1/categories`)

  const [loadWithParam, setLoadWithParam] = useState(false)
  const [importWorkoutId, setImportWorkoutId] = useState(null)
  const [isLibrary, setIsLibrary] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)
  const [workoutId, setWorkoutId] = useState()

  const [searching, setSearching] = useState(false)
  const [searchedWorkouts, setSearchedWorkouts] = useState([])

  // set workoutId based on param
  useEffect(() => {
    const paramImport = searchParams.get('import')
    const paramLibrary = searchParams.get('library')

    if (paramImport) {
      setImportWorkoutId(paramImport)
      setLoadWithParam(true)
    }
    if (paramLibrary) {
      setIsLibrary(true)
    }
  }, [searchParams])

  const handleFindForImport = () => {
    const validIdFormat = true

    if (validIdFormat) {
      onOpen()
    } else {
      console.log('invalid id format')
    }
  }

  // open modal automatically if param was supplied
  useEffect(() => {
    if (loadWithParam) {
      handleFindForImport()
    }
  }, [loadWithParam])

  if (hasWorkoutsError || hasCategoriesError) return <Error />

  if (isWorkoutsLoading || isCategoriesLoading) {
    return <LoadingSpinner />
  }

  const nonArchivedWorkouts = workouts.filter((workout) => workout.archived === false)
  const archivedWorkouts = workouts.filter((workout) => workout.archived === true)

  const handleImportModalClose = (shouldRefresh) => {
    if (shouldRefresh) {
      mutateWorkouts()
      Notify({ content: 'Workout imported', type: 'success' })
    }
    onClose()
  }

  const handleContextMenuClick = (event, data) => {
    event.stopPropagation()

    if (data.action === 'delete') {
      setWorkoutId(data.id)
      setModalOpen(true)
    }

    if (data.action === 'archive') {
      archiveWorkout(data.id)
    }

    if (data.action === 'unarchive') {
      unarchiveWorkout(data.id)
    }
  }

  const unarchiveWorkout = (id) => {
    updateArchiveStatus(id, false)
  }

  const archiveWorkout = (id) => {
    updateArchiveStatus(id, true)
  }

  const updateArchiveStatus = (id, status) => {
    securedApi
      .patch(`api/v1/activities/${id}`, {
        activity: {
          archived: status,
        },
      })
      .then(() => {
        mutateWorkouts()

        const content = status ? 'Workout has been archived' : 'Workout has been unarchived'
        Notify({ content: content, type: 'success' })
      })
      .catch((error) => {
        processApiError(error, "Can't archive workout")
      })
  }

  const deleteWorkout = () => {
    securedApi
      .delete(`api/v1/activities/${workoutId}`)
      .then(() => {
        mutateWorkouts()

        setModalOpen(false)
        Notify({ content: 'Workout deleted', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, "Can't delete workout")
      })
  }

  const Workout = ({ workout, archived, children }) => {
    const WorkoutMenuItem = ({ action, text }) => {
      const _text = text || `${startCase(action)} Workout`

      return (
        <MenuItem data={{ action: action, id: workout.id }} onClick={handleContextMenuClick}>
          <Box
            py="2"
            px="4"
            data-cy={`${action}-workout`}
            cursor="pointer"
            _hover={{ background: 'brand.500', color: 'white' }}
          >
            {_text}
          </Box>
        </MenuItem>
      )
    }

    return (
      <Box key={workout.id}>
        <ContextMenuTrigger id={workout.id}>
          <Link to={`/workout/${workout.id}`}>
            <Box
              background="#fff"
              cursor="pointer"
              borderWidth="1px"
              borderColor="transparent"
              _hover={{ borderColor: 'yellow.500' }}
              paddingX="4"
              paddingY="5"
              transition="all 150ms ease-in-out"
              data-cy="workout"
            >
              {children}
            </Box>
          </Link>
        </ContextMenuTrigger>
        <ContextMenu id={workout.id}>
          <Box background="gray.100" borderRadius="4">
            <Box textAlign="center" background="gray.200" p="1">
              <Text fontWeight="bold">Workout options</Text>
            </Box>
            <WorkoutMenuItem action={archived ? 'unarchive' : 'archive'} />
            <WorkoutMenuItem action={'delete'} />
          </Box>
        </ContextMenu>
      </Box>
    )
  }

  const renderWorkouts = (workoutData) => {
    if (!workoutData.length) {
      return (
        <Text color="gray.500" fontWeight="bold" align="center">
          No Workouts
        </Text>
      )
    }

    const categoryGroups = groupBy(workoutData, 'category_name')

    const individualWorkouts = () => {
      return Object.entries(categoryGroups).map(([key, value]) => {
        return value.map((workout) => (
          <Workout key={workout.id} workout={workout}>
            <Text fontWeight="medium" fontSize="large" color="black">
              {workout.activity_name}
            </Text>

            <CategoryStrip name={workout.category_name} colour={workout.category_hex_colour} />

            <Text mt="2" color="gray.500" fontSize="small">
              {workout?.activity_description}
            </Text>

            {workout.duration && workout.duration > 0 && (
              <Text color="black" mt="3" fontWeight="semibold" fontSize="small">
                {shortEnglishHumanizer(workout.duration)}
              </Text>
            )}
          </Workout>
        ))
      })
    }

    return (
      <SimpleGrid columns={[1, 3, null, 4]} spacing="12px">
        {individualWorkouts()}
      </SimpleGrid>
    )
  }

  const renderArchivedWorkouts = () => {
    if (!archivedWorkouts.length) {
      return null
    }

    const categoryGroups = groupBy(archivedWorkouts, 'category_name')

    const individualArchivedWorkouts = () => {
      return Object.entries(categoryGroups).map(([_, value]) => {
        return value.map((workout) => (
          <Workout key={workout.id} workout={workout} archived>
            <Text fontWeight="medium" fontSize="large" color="black">
              {workout.activity_name}
            </Text>
            <CategoryStrip name={workout.category_name} colour={workout.category_hex_colour} />
          </Workout>
        ))
      })
    }

    return (
      <Box mt="10" pt="10" borderTop="1px solid #ccc">
        <Heading as="h4" size="md" textAlign="center" mb="4">
          Archived Workouts
        </Heading>
        <Center>
          <Text maxWidth="600px">
            Archiving workouts will retain all data and scheduled instances of the workout
            (including into the future), they just won't appear when adding a workout.
          </Text>
        </Center>

        <SimpleGrid mt="10" columns={[1, 3, null, 4]} spacing="12px">
          {individualArchivedWorkouts()}
        </SimpleGrid>
      </Box>
    )
  }

  const handleOnChangeSearch = (event) => {
    setSearching(true)
    const regexp = new RegExp(event.target.value, 'i')
    setSearchedWorkouts(workouts.filter((i) => regexp.test(i.activity_name)))
  }

  const handleOnCategorySelection = ({ name }) => {
    setSearching(true)
    setSearchedWorkouts(workouts.filter((i) => i.category_name === name))
  }

  const handleSearchClose = () => {
    setSearchedWorkouts([])
    setSearching(false)
  }

  return (
    <>
      <Box pb="20">
        <Container maxWidth="container.lg">
          <Box
            display={['block', 'flex']}
            my="4"
            flexDirection={[null, 'row-reverse']}
            alignItems="center"
            minH="80px"
          >
            <Button
              as={Link}
              variant="primary"
              to="/create-workout"
              size={['sm', 'md']}
              display="flex"
              mb={['4', '0']}
            >
              New Workout
            </Button>
            <Box m="4">
              <Button as={Link} to="/categories" variant="outline" color="gray.600">
                Edit Categories
              </Button>
            </Box>

            <Spacer />
            <HStack alignItems="center" spacing="4">
              <WorkoutSearch
                {...{ categories }}
                onCategorySelection={handleOnCategorySelection}
                onSearchClose={handleSearchClose}
                onChangeSearch={handleOnChangeSearch}
              />

              <Box
                as={Link}
                to="/widgets"
                display="flex"
                flexDirection="column"
                alignItems="center"
                _hover={{ cursor: 'pointer' }}
              >
                <Icon as={WidgetIcon} w="5" h="5" color="#999" mt="1" />

                <Text mt="2" fontSize="x-small" textTransform="uppercase">
                  Widgets
                </Text>
              </Box>
            </HStack>
          </Box>

          {searching ? (
            renderWorkouts(searchedWorkouts)
          ) : (
            <>
              {renderWorkouts(nonArchivedWorkouts)}
              {renderArchivedWorkouts()}
            </>
          )}
        </Container>
        <Modal
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          title="Are you sure?"
          subTitle="This workout and all completed data recorded for this workout will be deleted."
          onAccept={deleteWorkout}
        ></Modal>
      </Box>

      {isOpen && (
        <ImportWorkoutModal
          {...{
            isOpen,
            handleImportModalClose,
            importWorkoutId,
            isLibrary,
          }}
        />
      )}
    </>
  )
}

export default Workouts
