import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { tzSafeDateFormat } from 'helpers/utils'

const GoalDates = ({ startDate, endDate }) => {
  return (
    <Box>
      {startDate && (
        <Text fontSize="xs" color="gray.500" as="span">
          {tzSafeDateFormat(startDate, 'd/M/yy')}
        </Text>
      )}

      {endDate && (
        <Text fontSize="xs" color="gray.500" as="span">
          {'  - '}
          {tzSafeDateFormat(endDate, 'd/M/yy')}
        </Text>
      )}
    </Box>
  )
}

export default GoalDates
