import React from 'react'
import {
  HStack,
  Box,
  Center,
  SimpleGrid,
  Container,
  Heading,
  Image,
  Stack,
  VStack,
  Text,
  ListItem,
  UnorderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Link as ChakraLink,
} from '@chakra-ui/react'

import { Gallery } from '../components/Gallery'
import Navigation from '../components/Navigation'

import ClimbStrongHero2 from './assets/climb-strong-hero-2.jpeg'
import ClimbStrongHero4 from './assets/climb-strong-hero-4.jpeg'
import ClimbStrongHero6 from './assets/climb-strong-hero-6.jpeg'

import Slider1 from './assets/climb-strong-slider-1.png'
import Slider2 from './assets/climb-strong-slider-2.png'
import Slider3 from './assets/climb-strong-slider-3.png'
import Slider4 from './assets/climb-strong-slider-4.png'
import Slider5 from './assets/climb-strong-slider-5.png'
import Slider6 from './assets/climb-strong-slider-6.png'
import Slider7 from './assets/climb-strong-slider-7.png'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import { BuyPlanButton } from '../components/BuyPlanButton'
import { useQueryProviderPlans } from '../hooks'

import { CS_URL, CS_PROVIDER, CS_BOULDERING_PERFORMANCE_CODE } from 'domain/Plans/constants'

export const images = [
  {
    id: '01',
    src: Slider1,
    alt: 'Slide 1',
  },
  {
    id: '02',
    src: Slider2,
    alt: 'Slide 2',
  },
  {
    id: '03',
    src: Slider3,
    alt: 'Slide 3',
  },
  {
    id: '04',
    src: Slider4,
    alt: 'Slide 4',
  },
  {
    id: '05',
    src: Slider5,
    alt: 'Slide 5',
  },
  {
    id: '06',
    src: Slider6,
    alt: 'Slide 6',
  },
  {
    id: '07',
    src: Slider7,
    alt: 'Slide 7',
  },
]

const EightWeekBoulderingPerformance = () => {
  const links = [
    {
      name: 'Climb Strong Plans',
      url: `/plans/${CS_URL}`,
    },
  ]
  const { data, isLoading, hasError } = useQueryProviderPlans({
    provider: CS_PROVIDER,
    code: CS_BOULDERING_PERFORMANCE_CODE,
  })

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const renderPaymentButton = () => {
    const { purchased } = data[0]

    if (purchased) {
      return (
        <VStack>
          <Text color="brand.500" fontWeight="bold">
            Already purchased!
          </Text>
          <ChakraLink
            href="/plans/purchased-plans"
            color="gray.500"
            fontWeight="light"
            fontSize="small"
            _hover={{ textDecoration: 'none', color: 'brand.500' }}
            end
          >
            View purchased plan
          </ChakraLink>
        </VStack>
      )
    }

    return <BuyPlanButton plan={data[0]} />
  }

  return (
    <>
      <Navigation {...{ links }} />
      <Box
        as="section"
        bg="gray.800"
        py="12"
        position="relative"
        h={{ base: '560px', md: '640px' }}
        bgImage={ClimbStrongHero2}
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
          h: 'full',
          bg: 'blackAlpha.700',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center flexDirection="column" textAlign="center" color="white" h="full">
            <Heading size="2xl" fontWeight="extrabold" color="#fff">
              8 Week Bouldering Performance Plan
            </Heading>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              Improve overall bouldering performance
            </Text>
          </Center>
        </Box>
      </Box>

      <Container
        pt={{ base: '16', md: '24' }}
        pb={{ base: '0', md: '0', sm: '16' }}
        maxWidth="container.lg"
      >
        <Stack align="end">
          <HStack>{renderPaymentButton()}</HStack>
        </Stack>
      </Container>
      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                This training plan is a 3-day-per-week plan that features two 4-week blocks of
                training.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                For sake of simplicity, we will call phase 1 the{' '}
                <Text as="span" fontWeight="bold">
                  Strength
                </Text>{' '}
                phase and phase 2 the{' '}
                <Text as="span" fontWeight="bold">
                  Power
                </Text>{' '}
                phase, although both phases feature a mixture of both.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                The aim of this plan is to improve overall bouldering performance. This can then be
                translated to route climbing by adding a conditioning phase after completion of this
                program.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                If you simply want to be stronger for bouldering, you can do this plan for 8 weeks,
                followed by a 2-4 week bouldering performance phase where you’d simply try to send
                hard problems and let training slide for a little while.
              </Text>
            </Stack>
          </Stack>
          <Stack spacing="3" justifyContent="center" align="center">
            <UnorderedList fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
              <ListItem>8 Weeks</ListItem>
              <ListItem>3 days per week</ListItem>
              <ListItem>Videos explaining each exercise</ListItem>
              <ListItem>4 weeks of strength focused training</ListItem>
              <ListItem>4 weeks of power focused training</ListItem>
              <ListItem>Flexible to suit your lifestyle</ListItem>
            </UnorderedList>
          </Stack>
        </SimpleGrid>
      </Container>

      <Image
        alt="Training Psych"
        src={ClimbStrongHero6}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Gallery images={images} />
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing={{ base: '4', md: '6' }}>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Each of the 4 weeks follows a similar total time and session format. The progress
                comes by pursuing more load. Try harder problems. Add weight to the barbell. Use a
                smaller crimp. Advance.
              </Text>
              <Text fontWeight="bold">Weekly Time Commitment:</Text>
              <UnorderedList mt="0" fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                <ListItem>Strength Phase: 3 training days, 60-90 minutes per session.</ListItem>
                <ListItem>
                  Power Phase: 2 training days, 75-115 minutes per session, plus 1-2 boulder
                  performance days.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box bg="white">
        <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing="3" align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Ready to improve your bouldering?</Heading>
              <Text color="on-accent-muteed" maxW="2xl" textAlign="center" fontSize="xl">
                With this cycled strength/power - 8 week bouldering performance plan you will be
                ticking all your projects in no time.
              </Text>
            </Stack>
            <HStack justify="center">
              <HStack>{renderPaymentButton()}</HStack>
            </HStack>
          </Stack>
        </Container>
      </Box>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Grid templateColumns="repeat(3, 1fr)" gap={16}>
          <GridItem colSpan={1}>
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>FAQs</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Everything you need to know about the plan and billing.
                </Text>
              </Stack>
            </Stack>
          </GridItem>
          <GridItem colSpan={2}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Accordion allowMultiple>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>How is the plan delivered</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    After you have successfully purchased the plan, you will be able to add the plan
                    to your Sequence planner.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Can I do the plan more than once?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    You pay for the plan once but keep access to it forever. You can choose to re-do
                    the plan for as long as you maintain your Sequence membership.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Is it available on mobile?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    100%. As long as you have the Sequence mobile app installed, you can access your
                    current schedule and log your training sessions.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </GridItem>
        </Grid>
      </Container>

      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }} align="center" justifyContent="center">
          <Stack spacing={{ base: '4', md: '5' }} maxW="xl">
            <Box align="center">
              <Stack spacing="3">
                <Heading size={{ base: 'sm', md: 'md' }}>Still have questions?</Heading>

                <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Can't find the answer you're looking for? Our team is happy to answer your
                  questions.
                </Text>
                <HStack justifyContent="center">
                  <ChakraLink
                    color="brand.500"
                    fontWeight="bold"
                    href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
                  >
                    Send us an email
                  </ChakraLink>
                </HStack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={ClimbStrongHero4}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />
    </>
  )
}

export default EightWeekBoulderingPerformance
