import React, { useContext, useState } from 'react'
import { isSameDay, parseISO } from 'date-fns'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processGetError, processApiError, dateToString } from 'helpers/utils'
import { useScheduledWorkoutCount, useStateManager } from 'hooks'
import { UserContext } from 'providers/UserProvider'

import DraggableWorkout from 'domain/Planner/DraggableWorkout'
import DraggableNote from 'domain/Planner/DraggableNote'

import { workoutManipulation, noteManipulation } from '../helpers'

const usePlannerMethods = (data) => {
  const { validContext, planContext, trialEndedContext, showUpgradeOverlayContext } =
    useContext(UserContext)
  const [userPlan] = planContext
  const [userValid, setUserValid] = validContext
  const [, setTrialEnded] = trialEndedContext
  const [, setShowUpgradeOverlay] = showUpgradeOverlayContext

  const { setAccountStatus } = useStateManager()
  const getScheduledWorkoutCount = useScheduledWorkoutCount((state) => state.fetch)

  // add
  const [selectedDay, setSelectedDay] = useState()
  const [isDayActionsModalOpen, setIsDayActionsModalOpen] = useState(false)

  // delete
  const [activityToDeleteId, setActivityToDeleteId] = useState(null)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)

  // menstrual
  const [isMenstrualCycleModalOpen, setIsMenstrualCycleModalOpen] = useState(false)

  const isTrial = userPlan?.slice(0, 5) === 'Trial'

  const refreshData = () => {
    revalidateLiveQueries()

    if (isTrial) {
      getScheduledWorkoutCount()

      // this currently does an extra accounts/status call to update
      // (the getScheduledWorkoutCount hook does one as well)
      // TODO have a unified hook that updates count as well as other account data
      securedApi
        .get(`accounts/status`)
        .then(({ data }) => {
          setAccountStatus(data)
        })
        .catch((error) => processGetError(error))
    }
  }

  const renderDraggableWorkout = (day) => {
    const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

    if (!dayData.length) {
      // `day` could not be found in `data`
      return
    } else {
      return dayData[0].content.map((item, idx) => (
        <DraggableWorkout
          key={idx}
          idx={idx}
          item={item}
          handleContextMenuDelete={handleContextMenuDelete}
        />
      ))
    }
  }

  const renderDraggableNote = (day) => {
    const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

    if (!dayData.length) {
      // `day` could not be found in `data`
      return
    } else {
      return dayData[0].notes.map((item, idx) => (
        <DraggableNote key={idx} idx={idx} item={item} refresh={() => refreshData()} />
      ))
    }
  }

  const showDayActions = (day) => {
    if (userValid) {
      setSelectedDay(day)
      setIsDayActionsModalOpen(true)
    } else {
      setTrialEnded(true)
      setShowUpgradeOverlay(true)
    }
  }

  const handleAddWorkoutToDay = ({ id }) => {
    // selectedDay is a date object with timezone and date info that can interfere
    const selectedDayString = dateToString(selectedDay)

    securedApi
      .post(`api/v1/scheduled_activities/new_calendar`, {
        activity_id: id,
        date: selectedDayString,
      })
      .then(({ data }) => {
        if (data._trial_limit_reached) {
          // set these here for a more responsive UI
          // (refreshData will do a full account status refresh)
          setTrialEnded(true)
          setShowUpgradeOverlay(true)
          setUserValid(false)
        }

        if (data._trial_limit_reached_client) {
          const message = 'Client trial limit has now been reached'
          Notify({ content: message, type: 'warning' })
        }

        refreshData()
      })
      .catch((error) => {
        processApiError(error, 'Error adding workout. Please try again.', function () {
          setTrialEnded(true)
          setShowUpgradeOverlay(true)
          setUserValid(false)
        })
      })
      .finally(() => {
        setIsDayActionsModalOpen(false)
      })
  }

  const handleOnDragEnd = (result) => {
    if (result.type === 'NOTE') {
      noteManipulation({ result, data })
    } else {
      workoutManipulation({ result, data })
    }
  }

  const deleteScheduledActivity = (id) => {
    // TODO: optimistic update

    securedApi
      .delete(`api/v1/scheduled_activities/${id}`)
      .then(() => {
        refreshData()
        Notify({ content: 'Workout removed', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  const handleDeleteScheduledActivity = () => {
    deleteScheduledActivity(activityToDeleteId)
    setIsConfirmDeleteModalOpen(false)
  }

  const handleContextMenuDelete = (event, data) => {
    event.stopPropagation()

    if (data.isCompleted || data.hasNotes) {
      setActivityToDeleteId(data.id)
      setIsConfirmDeleteModalOpen(true)
    } else if (data.action === 'remove') {
      deleteScheduledActivity(data.id)
    }
  }

  const openMensutralCycleForDay = (day) => {
    if (userValid) {
      setSelectedDay(day)
      setIsMenstrualCycleModalOpen(true)
    }
  }

  return {
    // util
    refreshData,
    renderDraggableWorkout,
    renderDraggableNote,
    // add
    showDayActions,
    handleAddWorkoutToDay,
    selectedDay,
    isDayActionsModalOpen,
    setIsDayActionsModalOpen,
    // modify
    handleOnDragEnd,
    // delete
    deleteScheduledActivity,
    handleDeleteScheduledActivity,
    handleContextMenuDelete,
    isConfirmDeleteModalOpen,
    setIsConfirmDeleteModalOpen,
    // menstrual
    openMensutralCycleForDay,
    isMenstrualCycleModalOpen,
    setIsMenstrualCycleModalOpen,
  }
}

export default usePlannerMethods
