import { create } from 'zustand'

import securedApi from 'backend/axios'
import { processGetError } from 'helpers/utils'

const useScheduledWorkoutCount = create((set) => ({
  count: 0,
  no_counter: false,
  fetch: async () => {
    return securedApi
      .get('/accounts/status')
      .then(({ data }) => {
        set({ count: data.trial_count })

        // conditions where we don't want counter to appear
        // (usually because user can still subscribe, but a workout limit is not applicable)
        // these checks need the === true condition because the value can be undefined
        // (which we want to keep as false)
        set({
          no_counter:
            data.bonus_subscription_with_plan_purchase === true ||
            data.ceased_coaching_client_amnesty_plan === true,
        })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
}))

export default useScheduledWorkoutCount
