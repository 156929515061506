import LeeCujes from './LeeCujes/assets/lee-cujes.jpeg'
import NineDLogo from './assets/9DLogo.png'
import ClimbStrong from './assets/climb-strong.jpeg'

import { LC_URL, CS_URL, ND_URL } from 'domain/Plans/constants'

export const premium = [
  {
    name: '9 Degrees',
    description: '9 Degrees',
    url: ND_URL,
    logo: NineDLogo,
  },
  {
    name: 'Climb Strong',
    description: 'Climb Strong',
    url: CS_URL,
    logo: ClimbStrong,
  },
  {
    name: 'Lee Cujes',
    description: 'Lee Cujes',
    url: LC_URL,
    logo: LeeCujes,
  },
]
