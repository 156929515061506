import { Draggable } from 'react-beautiful-dnd'
import { Icon, Box, Spacer, Text } from '@chakra-ui/react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { Edit3, Target, Move } from 'react-feather'

import { usePlannerNote } from 'hooks'
import { processApiError } from 'helpers/utils'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import CoachingBadgeIcon from 'domain/Notes/components/CoachingBadgeIcon'

import LinkedGoalsIcon from './components/LinkedGoalsIcon'

const MILESTONE = 'milestone'
const REST = 'rest'

const DraggableNote = ({ item, idx, refresh }) => {
  const setPlannerNoteOpen = usePlannerNote((state) => state.setPlannerNoteOpen)
  const setPlannerNoteId = usePlannerNote((state) => state.setPlannerNoteId)

  const { linked_goals: linkedGoals } = item
  const linkedGoalIconColour = item.style === MILESTONE && 'white' // use default if not milestone
  const dragIconColour = item.style === MILESTONE ? 'white' : 'gray.400' // use default if not milestone

  const handleNoteOpen = (id) => {
    setPlannerNoteId(id)
    setPlannerNoteOpen()
  }

  const handleContextMenuDelete = (event, data) => {
    event.stopPropagation()
    if (data.action === 'remove') {
      const { id } = data
      const deleteMessage = `${data.style === MILESTONE ? 'Milestone' : 'Note'} deleted.`

      securedApi
        .delete(`api/v1/scheduled_notes/${id}`)
        .then(() => {
          Notify({
            content: deleteMessage,
            type: 'success',
          })
          refresh()
        })
        .catch((error) => processApiError(error))
    }
  }

  if (item.style === REST) {
    return null
  }
  return (
    <Draggable key={item.id} draggableId={item.id} index={idx}>
      {(provided, snapshot) => {
        const getStyle = (style, snapshot) => {
          if (!snapshot.isDropAnimating) {
            return style
          }
          return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
          }
        }

        return (
          <Box
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
            mb="1.5"
            _last={{ marginBottom: 0 }}
          >
            <Box
              bg="white"
              border="1px"
              borderColor="gray.100"
              fontSize="small"
              p="2"
              mb="2"
              color={item.style === MILESTONE ? '#fff' : ''}
              background={item.style === MILESTONE ? 'green.400' : '#fff'}
              onClick={() => handleNoteOpen(item.id)}
              cursor="pointer"
              _hover={{ cursor: 'grab' }}
              {...provided.dragHandleProps}
            >
              <ContextMenuTrigger id={item.id}>
                <Box display="flex" flexDirection="row" width="100%" p="1">
                  <Box display="flex" alignItems="center">
                    {item.style === MILESTONE && (
                      <Icon as={Target} color="white" strokeWidth="1px" w="4" h="4" mr="1" />
                    )}

                    {item.style === 'note' && (
                      <Icon as={Edit3} strokeWidth="1px" w="4" h="4" mr="1" />
                    )}
                    <Text>{item.title}</Text>
                    <CoachingBadgeIcon style={item.style} />
                  </Box>
                  <Spacer />
                  {linkedGoals && (
                    <LinkedGoalsIcon linkedGoals={linkedGoals} color={linkedGoalIconColour} />
                  )}
                  <Icon
                    as={Move}
                    color={dragIconColour}
                    strokeWidth="1px"
                    w="2"
                    h="2"
                    ml="1"
                    mr="1"
                  />
                </Box>
              </ContextMenuTrigger>
            </Box>

            <Box
              id={item.id}
              as={ContextMenu}
              background="gray.100"
              borderRadius="sm"
              border="1px"
              borderColor="gray.300"
              boxShadow="lg"
              p="2"
            >
              <Box
                as={MenuItem}
                _hover={{ background: 'brand.500', color: 'white' }}
                cursor="pointer"
                px="2"
                py="1"
                borderRadius="sm"
                data={{
                  action: 'remove',
                  id: item.id,
                }}
                onClick={handleContextMenuDelete}
              >
                Delete {item.style === MILESTONE ? 'Milestone' : 'Note'}
              </Box>
              <Spacer />
            </Box>
          </Box>
        )
      }}
    </Draggable>
  )
}

export default DraggableNote
