import React from 'react'
import { Box, Text, Link } from '@chakra-ui/react'

const NoMatch = () => (
  <Box marginTop="20">
    <Text align="center" fontSize="large" fontWeight="bold">
      Unfortunately something went wrong
    </Text>
    <Box marginTop="4">
      <Text align="center">
        If you continue to see this page please don't hesitate to{' '}
        <Link href="mailto:help@sequence-app.com" fontWeight="bold">
          contact us
        </Link>
        .
      </Text>
    </Box>
  </Box>
)

export default NoMatch
