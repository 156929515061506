import React, { useState } from 'react'
import { Alert, AlertIcon, AlertDescription, Flex, Box, Text } from '@chakra-ui/react'

import { pluralize } from '../../helpers/utils'

import LastXDaysStatic from 'components/General/LastXDaysStatic'

import LoadingSpinner from '../../components/LoadingSpinner'

import BigOverview from '../Metrics/components/BigOverview'
import ColorOverview from '../Metrics/components/ColorOverview'
import SessionOverview from '../Metrics/components/SessionOverview'
import { BreakdownLink, CategoryBarChart } from '../Metrics/components/CategoryBarChart'

const Snapshot = ({ data }) => {
  const [sessionBreakdownType, setSessionBreakdownType] = useState('time')

  if (!Object.keys(data).length) {
    return <LoadingSpinner />
  }

  const {
    demo,
    sessions_completed,
    felt,
    motivated,
    performed,
    rpe,
    session_breakdown,
    days_on_rock,
    rest_days,
  } = data

  const { value: feltValue, change: feltChange } = felt
  const { value: motivatedValue, change: motivatedChange } = motivated
  const { value: performedValue, change: performedChange } = performed
  const { value: rpeValue, change: rpeChange } = rpe
  const { hours: sessionHours, change: sessionChange, categories } = session_breakdown

  const SectionHeader = ({ text, ...props }) => {
    return (
      <Text fontSize="xs" textTransform="uppercase" color="gray.500" {...props}>
        {text}
      </Text>
    )
  }

  return (
    <div>
      <Flex direction={{ base: 'column', md: 'row' }}>
        <Box>
          <Text fontSize="large">Snapshot</Text>
          <LastXDaysStatic days="7" />
        </Box>

        {demo && (
          <Box mt={{ base: '3', md: '0' }} ml={{ base: '0', md: '3' }}>
            <Alert status="info" height={{ base: 'auto', md: '8' }}>
              <AlertIcon boxSize="1em" />
              <AlertDescription fontSize="14">
                This snapshot will show demo data until you complete your first workout
              </AlertDescription>
            </Alert>
          </Box>
        )}
      </Flex>

      <Flex mt="10" direction={{ base: 'column', md: 'row' }}>
        <Box w={{ base: '100%', md: '50%' }}>
          <SectionHeader text="Sessions" />
          <Box mt="5">
            <SessionOverview value={sessions_completed} />
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Box w={{ base: '100%', md: '50%' }} mb={{ base: '6', md: '0' }}>
              <SectionHeader text="Feeling" align="center" />
              <ColorOverview value={feltValue} field="felt" label="Felt Score" />
              <Text fontSize="large" textAlign="center" color="gray.500">
                {feltChange} from last week
              </Text>
            </Box>

            <Box w={{ base: '100%', md: '50%' }} mb={{ base: '6', md: '0' }}>
              <SectionHeader text="Motivation" align="center" />
              <ColorOverview value={motivatedValue} field="motivated" label="Motivation Score" />
              <Text fontSize="large" textAlign="center" color="gray.500">
                {motivatedChange} from last week
              </Text>
            </Box>
          </Flex>
          <Flex direction={{ base: 'column', md: 'row' }} my={{ base: '0', md: '10' }}>
            <Box w={{ base: '100%', md: '50%' }} mb={{ base: '6', md: '0' }}>
              <SectionHeader text="Performance" align="center" />
              <ColorOverview value={performedValue} field="performed" label="Performance Score" />
              <Text fontSize="large" textAlign="center" color="gray.500">
                {performedChange} from last week
              </Text>
            </Box>

            <Box w={{ base: '100%', md: '50%' }}>
              <SectionHeader text="RPE" align="center" />
              <ColorOverview value={rpeValue} field="felt" label="RPE Score" />
              <Text fontSize="large" textAlign="center" color="gray.500">
                {rpeChange} from last week
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box w={{ base: '100%', md: '50%' }}>
          <Box mt="5">
            <SectionHeader text="Session Breakdown" />
            <Flex marginLeft={3} marginTop={2}>
              <BreakdownLink
                type="time"
                sessionBreakdownType={sessionBreakdownType}
                setSessionBreakdownType={setSessionBreakdownType}
              />
              <BreakdownLink
                type="total"
                sessionBreakdownType={sessionBreakdownType}
                setSessionBreakdownType={setSessionBreakdownType}
              />
            </Flex>
            <Box
              mt="8"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CategoryBarChart data={categories} breakdown={sessionBreakdownType} />

              <Box mt="8" ml={{ base: '0', md: '10' }} style={{ textAlign: 'center' }}>
                <BigOverview value={sessionHours} label={pluralize(sessionHours, 'Hour')} />
                <Box mt="2" fontSize="lg" color="gray.600">
                  {sessionChange} from last week
                </Box>

                <Flex mt="4">
                  <Box ml="6" mr="6">
                    <BigOverview
                      value={days_on_rock}
                      label={`${pluralize(days_on_rock, 'Day')} on Rock`}
                    />
                  </Box>
                  <Box ml="6" mr="6">
                    <BigOverview value={rest_days} label={`Rest ${pluralize(rest_days, 'day')}`} />
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </div>
  )
}

export default Snapshot
