import React from 'react'
import { Icon as ChakraIcon, Tooltip } from '@chakra-ui/react'

const Icon = ({
  as,
  onClick,
  tooltipContent,
  w = 5,
  h = 5,
  strokeWidth = '2px',
  color = 'gray.500',
}) => (
  <Tooltip label={tooltipContent}>
    <ChakraIcon
      _hover={{ cursor: 'pointer', color: 'brand.500' }}
      {...{ as, onClick, h, w, strokeWidth, color }}
    />
  </Tooltip>
)

export default Icon
