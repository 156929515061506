import React from 'react'
import Modal from 'components/Modal'

const DeleteWorkout = ({ isOpen, onClose, onAccept }) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      title="Are you sure?"
      subTitle="This action can't be undone."
      onAccept={onAccept}
    />
  )
}

export default DeleteWorkout
