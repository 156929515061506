import React, { useEffect, useState, useRef } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import _ from 'lodash'

import { useDemoData, useMetricsDays } from 'hooks'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import ConfigureDailyMetricsLink from './components/ConfigureDailyMetricsLink'

import MetricsDaily from './MetricDaily'
import DailyMetricsNoAttributes from 'domain/DailyMetrics/DailyMetricsNoAttributes'

import LastXDays from './CommonMetrics'
import { SelectableMetricsContainer, ItemCard } from './CommonMeasure'

const DailyMetricsContent = () => {
  const isFirstRender = useRef(true)
  const [loading, setLoading] = useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const [biometricsData, setBiometricsData] = useState({})

  const [hasDataMeasures, setHasDataMeasures] = useState([])
  const [showMeasures, setShowMeasures] = useState([])
  const [hideMeasures, setHideMeasures] = useState([])

  const getIds = (list) => {
    return list.map(({ measure }) => measure.biometric_id)
  }

  const getData = () => {
    securedApi
      .get(`/api/v1/metrics/biometrics?demo=${demoData}&days=${days}`)
      .then((response) => {
        setBiometricsData(response.data)

        const { biometrics, all_biometrics, settings } = response.data

        if (_.isEmpty(settings)) {
          setShowMeasures(getIds(biometrics))
          setHideMeasures([])
        } else {
          setShowMeasures(settings.show)
          setHideMeasures(settings.hide)
        }

        const x = biometrics.map((x) => x.measure.biometric_id)
        const y = all_biometrics.map((x) => x.measure.biometric_id)
        const z = _.intersection(x, y)

        setHasDataMeasures(z)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
        setTriggerRefresh(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [demoData])

  useEffect(() => {
    // avoid a double getData on mount
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      getData()
    }
  }, [days])

  useEffect(() => {
    if (triggerRefresh) {
      getData()
    }
  }, [triggerRefresh])

  if (loading && !triggerRefresh) {
    return <LoadingSpinner />
  }

  const { biometrics, all_biometrics, annotations, no_ba, no_br } = biometricsData

  // sometimes this happens..?
  if (biometrics === undefined) {
    return <Text>Checking for daily metrics data</Text>
  }

  if (!biometrics.length) {
    if (no_ba) {
      return <DailyMetricsNoAttributes />
    } else if (no_br) {
      return <Text>No values record yet</Text>
    } else {
      return (
        <Box>
          <Flex justifyContent="flex-end">
            <Box>
              <ConfigureDailyMetricsLink />
            </Box>
          </Flex>
          <LastXDays />
          <Text color="gray.500" mt="4">
            No data for this period.
          </Text>
        </Box>
      )
    }
  }

  const BiometricCard = ({ id }) => {
    const biometric_measure = all_biometrics.find((x) => id === x.measure.biometric_id)

    const { measure } = biometric_measure
    const key = measure.biometric_id
    const title = measure.biometric_name
    const subtitle = measure.units
    const hasData = hasDataMeasures.indexOf(id) >= 0

    return <ItemCard key={key} title={title} subtitle={subtitle} hasData={hasData} />
  }

  // add the hidden measures at the end
  const sortingArray = [...showMeasures, ...hideMeasures]
  const biometricMeasuresSorted = biometrics
    .slice()
    .sort(
      (a, b) =>
        sortingArray.indexOf(a.measure.biometric_id) - sortingArray.indexOf(b.measure.biometric_id)
    )

  return (
    <SelectableMetricsContainer
      type="biometrics"
      hasDataMeasures={hasDataMeasures}
      showMeasures={showMeasures}
      hideMeasures={hideMeasures}
      setShowMeasures={setShowMeasures}
      setHideMeasures={setHideMeasures}
      cardComponent={BiometricCard}
      demo={demoData}
      extraButtons={<ConfigureDailyMetricsLink />}
    >
      {biometricMeasuresSorted.map(({ measure }) => {
        const { biometric_id } = measure
        const showMeasure = showMeasures.indexOf(biometric_id) >= 0

        if (!showMeasure) {
          return null
        }

        return (
          <MetricsDaily
            key={biometric_id}
            data={measure}
            annotations={annotations}
            setTriggerRefresh={setTriggerRefresh}
          />
        )
      })}
    </SelectableMetricsContainer>
  )
}

export default DailyMetricsContent
