import React from 'react'
import { Flex, Text, Circle } from '@chakra-ui/react'

const ColorOverview = ({ value, field, label }) => {
  const bg = () => {
    switch (field) {
      case 'felt':
        return '#FF794F'
      case 'motivated':
        return '#FF9535'
      case 'performed':
        return '#FDB619'
      default: {
        return '#FDB619'
      }
    }
  }

  return (
    <Flex mt={3} flexDirection="column" alignItems="center">
      <Circle bg={bg()} h={36} w={36}>
        <Text as="span" fontSize="7xl" color="white">
          {value}
        </Text>
      </Circle>
      <Flex my={3}>
        <Text fontSize="xs" textTransform="uppercase" color="gray">
          {label}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ColorOverview
