import React from 'react'
import { Box, Checkbox, Text } from '@chakra-ui/react'

const OutdoorToggle = ({ outdoor, onSetOutdoor, readOnly }) => {
  const toggleOutdoor = () => {
    onSetOutdoor(!outdoor)
  }

  if (!outdoor) {
    return null
  }

  return (
    <Box>
      <Checkbox
        colorScheme="yellow"
        onChange={toggleOutdoor}
        isChecked={outdoor}
        isReadOnly={readOnly}
      >
        <Text fontSize="sm" fontWeight="medium" color="gray.600">
          On Rock
        </Text>
      </Checkbox>
    </Box>
  )
}

export default OutdoorToggle
