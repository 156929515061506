import { Box, MenuItem } from '@chakra-ui/react'

import { useCoaching, useImpersonate, useUserPreferences } from 'hooks'

import securedApi from 'backend/axios'

const NavLink = ({ onClick, children }) => (
  <Box
    as="button"
    onClick={onClick}
    _hover={{ cursor: 'pointer', textDecoration: 'none', color: 'brand.500' }}
    color="gray.500"
    fontSize="sm"
  >
    {children}
  </Box>
)

const CoachingModeToggle = ({ isMobile = false }) => {
  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)
  const setCoachingInterfaceEnabled = useCoaching((state) => state.setCoachingInterfaceEnabled)
  const setCoachingInterfaceDisabled = useCoaching((state) => state.setCoachingInterfaceDisabled)
  const resetImpersonateData = useImpersonate((state) => state.resetImpersonateData)
  const isCoach = useUserPreferences((state) => state.isCoach)

  const handleTurnCoachingModeOn = () => {
    window.location.assign('/coaching')
    setCoachingInterfaceEnabled()
  }

  const handleTurnCoachingModeOff = () => {
    securedApi
      .post(`/impersonate/stop`)
      .then(() => {
        setCoachingInterfaceDisabled()
        resetImpersonateData()
        window.location.assign('/dashboard')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const renderToggleButton = (Component, onClick, label) => (
    <Component p="2" onClick={onClick}>
      {label}
    </Component>
  )

  if (!isCoach) {
    return null
  }

  const ToggleComponent = isMobile ? MenuItem : NavLink
  const toggleProps = isCoachingInterfaceEnabled
    ? { onClick: handleTurnCoachingModeOff, label: 'Exit Coaching' }
    : { onClick: handleTurnCoachingModeOn, label: 'Enter Coaching' }

  return renderToggleButton(ToggleComponent, toggleProps.onClick, toggleProps.label)
}

export default CoachingModeToggle
