import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link, Text } from '@chakra-ui/react'

const ImportButton = ({ id }) => {
  return (
    <Link
      as={NavLink}
      to={`/workouts?library=1&import=${id}`}
      width="100%"
      fontSize="sm"
      _hover={{ textDecoration: 'none' }}
    >
      <Text p="2">Add to this client</Text>
    </Link>
  )
}

export default ImportButton
