export const statusMapText = {
  in_progress: 'in progress',
  idea: 'idea',
  complete: 'complete',
  cancelled: 'cancelled',
}

export const statusMapColour = {
  in_progress: 'brand',
  idea: 'purple',
  complete: 'green',
  cancelled: 'red',
}

export const styleMapText = {
  undefined: 'N/A',
  short_term: 'Short term',
  medium_term: 'Medium term',
  long_term: 'Long term',
}
