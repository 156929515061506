import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { shortEnglishHumanizer } from '../../../helpers/duration'

const Duration = ({ item }) => {
  return (
    <>
      {item.duration && item.duration > 0 && (
        <Box marginTop="6">
          <Text color="black.500" fontWeight="semibold">
            Duration
          </Text>
          <Text marginTop="1">{shortEnglishHumanizer(item.duration)}</Text>
        </Box>
      )}
    </>
  )
}

export default Duration
