import React from 'react'
import { Box, Text, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { useQuery, useDemoData, useMetricsDays } from 'hooks'
import { revalidateLiveQueries } from 'helpers/swrConfig'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import LastXDays from '../CommonMetrics'
import IncompleteReasonsSummary from './IncompleteReasonsSummary'
import IncompleteReasonsAccordion from './IncompleteReasonsAccordion'

const IncompleteReasonsContainer = () => {
  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const { data, isLoading, hasError } = useQuery(
    `api/v1/metrics/breakdown_by_incomplete_reason?demo=${demoData}&days=${days}`
  )

  const refresh = () => {
    revalidateLiveQueries()
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { summary, data: incompleteReasons } = data.breakdown_by_incomplete_reason

  const noData = incompleteReasons === null

  if (noData && days === 90) {
    return (
      <Box>
        <Text color="gray">
          You haven't set any workouts to an incomplete status.{' '}
          <Link
            href="https://docs.sequence-app.com/features/incomplete-workouts"
            isExternal
            variant="brand"
            fontWeight="bold"
          >
            Learn more about setting workouts as incomplete <ExternalLinkIcon />
          </Link>
        </Text>
        <Text mt={4} color="gray">
          Tip: try clicking the "Display Demo Data" button above to get an idea of what this chart
          will look like!
        </Text>
      </Box>
    )
  }

  return (
    <>
      <LastXDays />
      {noData && days !== 90 ? (
        <Text color="gray" mt="4">
          You haven't set any workouts to an incomplete status for the selected date range.
        </Text>
      ) : (
        <>
          <IncompleteReasonsSummary data={summary} />
          <IncompleteReasonsAccordion data={incompleteReasons} demo={demoData} {...{ refresh }} />
        </>
      )}
    </>
  )
}

export default IncompleteReasonsContainer
