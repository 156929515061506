import React from 'react'

import {
  Box,
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

const Modal = ({ children, closeModal, isOpen, onAccept, title, subTitle, minWidth }) => {
  const renderButtons = () => {
    if (!onAccept) {
      return null
    }

    return (
      <ModalFooter>
        <Button mr={3} variant="outline" onClick={closeModal} data-cy="cancel-modal">
          Cancel
        </Button>
        <Button colorScheme="brand" onClick={onAccept} data-cy="confirm-modal">
          Confirm
        </Button>
      </ModalFooter>
    )
  }

  return (
    <ChakraModal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent minWidth={minWidth}>
        {title && <ModalHeader mr="12">{title}</ModalHeader>}
        <ModalCloseButton />
        {subTitle && (
          <Box mt="2" px="6">
            {subTitle}
          </Box>
        )}
        <ModalBody pb="4">{children}</ModalBody>
        {renderButtons()}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
