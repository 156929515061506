import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, FormControl, FormLabel, Select, HStack } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { processApiError } from 'helpers/utils'

const WidgetSelects = ({ selects, onSetSelects, scheduledActivityId, readOnly }) => {
  const [forceRerender, setForceRerender] = useState(false)

  useEffect(() => {
    if (forceRerender) {
      setForceRerender(false)
    }
  }, [forceRerender])

  const handleChange = (id, value) => {
    const newState = selects
    const selectIndex = selects.findIndex((x) => x.widget_select_id === id)
    const select = selects[selectIndex]

    // update state value so complete submit has correct value
    newState[selectIndex].value = value
    onSetSelects(newState)

    // make sure components refresh
    setForceRerender(true)

    // update entry or create one if none exists yet
    securedApi
      .put(`api/v1/widget_select_entries/control`, {
        id: select.id,
        widget_select_entry: {
          scheduled_activity_id: scheduledActivityId,
          widget_select_id: select.widget_select_id,
          value: value,
        },
      })
      .catch((error) => processApiError(error))
  }

  if (!selects?.length) {
    return null
  }

  return (
    <>
      <FormControl>
        <FormLabel fontSize="sm">Selects</FormLabel>

        {selects.map((select) => {
          return (
            <Box key={select.name} mb={3}>
              <Flex flexDirection="row" alignItems="center" mb="1">
                <Text fontSize="sm">{select.name}</Text>
              </Flex>
              <HStack>
                <Select
                  onChange={(event) => {
                    const { value } = event.target
                    handleChange(select.widget_select_id, value)
                  }}
                  variant="outline"
                  placeholder="Select option"
                  value={select.value || ''}
                >
                  {select.options.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.content}
                    </option>
                  ))}
                </Select>
              </HStack>
            </Box>
          )
        })}
      </FormControl>
    </>
  )
}

export default WidgetSelects
