import React from 'react'
import { Routes, Route } from 'react-router-dom'

import TestsHome from './TestsHome'
import TestCreate from './TestCreate'
import TestEdit from './TestEdit'
import TestDetails from './TestDetails'

const Tests = () => {
  return (
    <Routes>
      <Route path="/" element={<TestsHome />} />
      <Route path="create" element={<TestCreate />} />
      <Route path=":id" element={<TestDetails />} />
      <Route path="edit/:id" element={<TestEdit />} />
    </Routes>
  )
}

export default Tests
