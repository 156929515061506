import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Flex, Spacer, Link, Text } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

const RecentMilestones = ({ data }) => {
  const renderMilestone = ({ id, title }) => {
    return (
      <Box key={id}>
        <Link
          fontSize="18"
          mb="2"
          display="inline-block"
          as={NavLink}
          to={`/milestone/${id}`}
          fontWeight="bold"
          color="white"
          background="green.400"
          px="2"
          py="1"
          _hover={{ textDecoration: 'none', background: 'green.600' }}
        >
          {title}
        </Link>
      </Box>
    )
  }

  const renderRecentMilestones = () => {
    if (!data.length) {
      return (
        <Text>
          You currently haven't recorded any Milestones.{' '}
          <Link
            href="https://docs.sequence-app.com/features/milestones"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            <br />
            Learn more about Milestones <ExternalLinkIcon />
          </Link>
        </Text>
      )
    }

    return data.map((milestone) => renderMilestone(milestone))
  }

  return (
    <Flex flexDirection="column" height="100%">
      <Box mb="2">
        <Text fontSize="large">Recent Milestones</Text>
      </Box>
      {renderRecentMilestones()}
      <Spacer />
      <Box display="flex" flexDirection="row-reverse">
        <Link fontSize="small" color="yellow.500" as={NavLink} to={`/milestones`}>
          View Milestones
        </Link>
      </Box>
    </Flex>
  )
}

export default RecentMilestones
