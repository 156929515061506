import { useQuery } from 'hooks'

const useQueryProviderPlans = (key, revalidateOnFocus = true) => {
  let url = `api/v1/products?provider=${key.provider}`

  // don't add the code param if not present in key
  if (key.code) {
    url = url + `&code=${key.code}`
  }

  const { data, isLoading, isValidating, hasError, mutate } = useQuery(url, {
    revalidateOnFocus: revalidateOnFocus,
  })

  return {
    data,
    isLoading,
    isValidating,
    hasError,
    mutate,
  }
}

export default useQueryProviderPlans
