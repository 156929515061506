import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Heading,
  Text,
  Link,
  Stack,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { tzSafeDateFormat } from 'helpers/utils'
import { useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'

import CoachingBadge from 'domain/Notes/components/CoachingBadge'

const Notes = () => {
  const { pathname } = useLocation()
  const { data, isLoading, hasError } = useQuery('/api/v1/notes')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const renderCreateButton = () => {
    return (
      <Box display="flex">
        <Button as={NavLink} to="/note/create" colorScheme="brand" color="white" marginLeft="auto">
          New Note
        </Button>
      </Box>
    )
  }

  const renderNotes = () => {
    if (!data.length) {
      return (
        <Text>
          You currently haven't recorded any Notes.{' '}
          <Link
            href="https://docs.sequence-app.com/features/notes"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            <br />
            Learn more about Notes <ExternalLinkIcon />
          </Link>
        </Text>
      )
    }

    return data.map((item) => {
      const formattedDate = tzSafeDateFormat(item.scheduled_at, 'd/M/yy')

      return (
        <Link
          key={item.id}
          as={NavLink}
          to={`/note/${item.id}`}
          border="1px"
          borderColor="transparent"
          _hover={{ borderColor: 'brand.500', cursor: 'pointer' }}
        >
          <Card rounded="0">
            <CardHeader>
              <Heading size="sm">
                {item.title} <CoachingBadge style={item.style} />
              </Heading>
              <Text fontSize="xs" color="gray" display="inline-block">
                {formattedDate}
              </Text>
            </CardHeader>
          </Card>
        </Link>
      )
    })
  }

  return (
    <Container maxW="container.lg" py="6" mb="24">
      <Box width="100%" margin="0 auto" maxW="600px">
        <Stack spacing="4">
          {renderCreateButton()}
          {renderNotes()}
        </Stack>
      </Box>
    </Container>
  )
}

export default Notes
