import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Icon, MenuDivider } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'

const InAppHelp = () => {
  return (
    <Menu autoSelect={false}>
      <MenuButton position="fixed" right="16px" bottom="16px">
        <Icon as={QuestionIcon} w={7} h={7} color="brand.500" />
      </MenuButton>

      <MenuList fontSize="xs">
        <MenuItem as="a" href="https://instagram.com/sequence_app" target="_blank" p="2">
          Follow Sequence on Instagram
        </MenuItem>
        <MenuItem as="a" href="https://www.youtube.com/watch?v=hHZx-MRpT5Y" target="_blank" p="2">
          View introduction video
        </MenuItem>
        <MenuItem as="a" href="https://sequence-app.com/blog" target="_blank" p="2">
          Read the Sequence blog
        </MenuItem>

        <MenuDivider />

        <MenuItem
          as="a"
          href="mailto:help@sequence-app.com?subject=Sequence App Feedback"
          target="_blank"
          p="2"
        >
          Send feedback
        </MenuItem>

        <MenuItem as="a" href="https://docs.sequence-app.com/" target="_blank" p="2">
          Help docs
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default InAppHelp
