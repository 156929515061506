import React from 'react'
import {
  Box,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
} from '@chakra-ui/react'

import Notify from 'components/Notification'

const ShareAction = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const protocol = window.location.protocol
  const domain = window.location.hostname
  const port = window.location.port
  const portColon = `:${port}`

  const sharePath = `${protocol}//${domain}${port ? portColon : ''}`

  const ShareLink = ({ link }) => {
    return (
      <Input
        variant="unstyled"
        color="brand.500"
        fontWeight="bold"
        fontSize="sm"
        width="90%"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        display="block"
        isReadOnly
        value={link}
        onFocus={(event) => event.target.select()}
        onClick={() => {
          navigator.clipboard.writeText(link)
        }}
      />
    )
  }

  return (
    <>
      <Box onClick={onOpen} width="100%" p="2">
        <Text fontSize="sm" fontWeight="normal">
          Share
        </Text>
      </Box>

      <Modal {...{ isOpen, onClose }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Workout</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="2">
            <Stack spacing="4">
              <Text fontSize="md">
                Give this link to a friend so that they can get a copy of your workout to use:
              </Text>
              <ShareLink link={`${sharePath}/workouts?import=${id}`} />
              <Button
                size="sm"
                colorScheme="brand"
                onClick={() => {
                  navigator.clipboard.writeText(`${sharePath}/workouts?import=${id}`)
                  Notify({ content: 'Link copied to clipboard', type: 'success' })
                }}
              >
                Copy link to clipboard
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShareAction
