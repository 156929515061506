import { useNavigate } from 'react-router-dom'

import securedApi from 'backend/axios'

import { useStateManager } from 'hooks'

const useSignOut = () => {
  const { clearAll } = useStateManager()

  const navigate = useNavigate()

  const signOut = () => {
    if (!localStorage.signedIn) {
      return
    }

    securedApi
      .delete('/signin')
      .then(() => {
        clearAll()
        navigate('/')
      })
      .catch((error) => {
        console.log('Error signing out', error)
        clearAll()
        navigate('/')
      })
  }

  return { signOut }
}

export default useSignOut
