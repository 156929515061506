import React, { useState } from 'react'
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Icon,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import { format, sub } from 'date-fns'
import { Calendar, Check, X } from 'react-feather'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'

import { tzSafeDateFormat } from 'helpers/utils'
import { shortEnglishHumanizer } from 'helpers/duration'
import { useQuery } from 'hooks'

import ActivityModal from 'domain/Planner/components/ActivityModal'

export const WorkoutsTable = ({ days, userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [scheduledActivityId, setScheduledActivityId] = useState(null)

  const today = format(new Date(), 'yyyy-M-dd')
  const startDateOfPeriod = format(sub(new Date(), { days: days }), 'yyyy-M-dd')

  const { data, isLoading, hasError } = useQuery(
    `api/v1/schedules/user_table?user_id=${userId}&start_date_of_period=${startDateOfPeriod}&end_date_of_period=${today}`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { table } = data

  if (table.length === 0) {
    return (
      <Box marginTop={2}>
        <Text color="gray.500" fontStyle="italic">
          Client has no workouts for the given period
        </Text>
      </Box>
    )
  }

  const DayHeader = ({ item }) => {
    const date = `${item.title.substring(0, 3)} – ${tzSafeDateFormat(item.date, 'd MMM')}`

    return (
      <Flex justifyContent="space-between" marginTop="4" pt="2" color="gray.500">
        <Box>
          <Text fontSize="xs">{date}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">
            Total time:{' '}
            <Text as="span" textTransform="lowercase">
              {shortEnglishHumanizer(item.total_actual_duration)}
            </Text>
          </Text>
        </Box>
      </Flex>
    )
  }

  const openWorkoutModal = (id) => {
    onOpen()
    setScheduledActivityId(id)
  }

  const TdWorkout = ({ activityName, categoryName, categoryHexColour, scheduledActivityId }) => {
    const stroke = categoryHexColour

    return (
      <Td width="20%">
        <Flex alignItems="center">
          <Box bgColor={stroke} tooltip={categoryName} width={5} height={5} />
          <Link onClick={() => openWorkoutModal(scheduledActivityId)}>
            <Box ml="2">
              <Text color="gray.600">{activityName}</Text>
            </Box>
          </Link>
        </Flex>
      </Td>
    )
  }

  const workoutState = (state) => {
    if (state === 'completed') {
      return <Icon as={Check} color="green.400" w="4" h="4" />
    }
    if (state === 'incomplete') {
      return <Icon as={X} color="red.400" w="4" h="4" />
    }

    return <Icon as={Calendar} w="4" h="4" />
  }

  const TableNested = () => {
    return (
      <>
        {table.map((row) => {
          return (
            <>
              <DayHeader item={row} />
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {row.content.map((log) => {
                    return (
                      <>
                        <Tr key={log.id} opacity={log.state === 'completed' ? '1' : '0.5'}>
                          <TdWorkout
                            activityName={log.activity_name}
                            categoryName={log.category_name}
                            categoryHexColour={log.category_hex_colour}
                            scheduledActivityId={log.id}
                          />
                          <Td color="gray.600">{log.notes}</Td>
                          <Td isNumeric>{workoutState(log.state)}</Td>
                        </Tr>
                      </>
                    )
                  })}
                </Tbody>
              </Table>
            </>
          )
        })}
      </>
    )
  }

  return (
    <Box>
      <TableNested />
      {isOpen && (
        <ActivityModal id={scheduledActivityId} isOpen={isOpen} onClose={onClose} readOnly={true} />
      )}
    </Box>
  )
}
