import React from 'react'
import { Box, Text, List, ListItem, ListIcon, Flex } from '@chakra-ui/react'
import { Minus } from 'react-feather'

const WidgetContainer = ({ widgets }) => {
  return (
    <Box>
      <Text marginTop="6" color="black.500" fontWeight="semibold">
        Widgets
      </Text>
      <List>
        {widgets.map((widget) => (
          <ListItem key={widget.id}>
            <Flex alignItems="center">
              <ListIcon as={Minus} />
              <Text textTransform="capitalize">
                {widget.content.name}{' '}
                <Text as="span" fontSize="xs">
                  ({widget.type})
                </Text>
              </Text>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default WidgetContainer
