import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Container,
  HStack,
  FormControl,
  FormLabel,
  Switch,
  Spacer,
  Link,
} from '@chakra-ui/react'

import { useDemoData, useHasLogbook } from 'hooks'

const NavigationLink = ({ to, children }) => {
  return (
    <Link
      fontSize="small"
      as={NavLink}
      _activeLink={{ color: 'black' }}
      color="gray.500"
      fontWeight="light"
      whiteSpace="nowrap"
      _hover={{ textDecoration: 'none', color: 'brand.500' }}
      {...{ to }}
      end
    >
      {children}
    </Link>
  )
}

const MetricsContainer = ({ children }) => {
  const { pathname } = useLocation()

  const demoData = useDemoData((state) => state.demoData)
  const toggleDemoData = useDemoData((state) => state.toggleDemoData)

  const hasLogbook = useHasLogbook((state) => state.hasLogbook)
  const getHasLogbook = useHasLogbook((state) => state.fetch)
  getHasLogbook()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      {demoData && (
        <Alert status="info">
          <AlertIcon boxSize="1em" />
          <AlertDescription fontSize="14">You are currently viewing demo data.</AlertDescription>
        </Alert>
      )}
      <Box background="#fff">
        <Container maxW="container.xl" py="6" overflowX={{ md: 'inherit', sm: 'scroll' }}>
          <HStack spacing={{ base: '4', md: '8' }}>
            <NavigationLink to="/metrics">Snapshot</NavigationLink>
            <NavigationLink to="/metrics/zoom">Zoom</NavigationLink>
            <NavigationLink to="/metrics/incomplete-reasons">Incomplete Reasons</NavigationLink>
            <NavigationLink to="/metrics/custom-measures">Custom Measures</NavigationLink>
            <NavigationLink to="/metrics/custom-selects">Custom Selects</NavigationLink>
            {hasLogbook && <NavigationLink to="/metrics/logbooks">Logbooks</NavigationLink>}
            <NavigationLink to="/metrics/daily-metrics">Daily Metrics</NavigationLink>

            <Spacer />
            <FormControl
              display="flex"
              alignItems="center"
              flexDirection={{ base: 'row', md: 'row-reverse' }}
              _hover={{ cursor: 'pointer' }}
              pr="2"
            >
              <FormLabel
                htmlFor="demo-data"
                mb="0"
                ml={{ base: '0', md: '2' }}
                color="gray"
                fontSize="13"
                _hover={{ cursor: 'pointer' }}
              >
                Display Demo Data
              </FormLabel>
              <Switch
                id="demo-data"
                colorScheme="yellow"
                onChange={toggleDemoData}
                isChecked={demoData}
                size="sm"
              />
            </FormControl>
          </HStack>
        </Container>
      </Box>

      <Box display="flex" padding="10" width="100%" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          maxWidth="5xl"
          paddingBottom="40"
        >
          {children}
        </Box>
      </Box>
    </>
  )
}

export default MetricsContainer
