import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import LeeCujesCollection from './LeeCujes/LeeCujesCollection'
import ClimbStrongCollection from './ClimbStrong/ClimbStrongCollection'
import NineDegreesCollection from './NineDegrees/NineDegreesCollection'

import { LC_URL, CS_URL, ND_URL, TEST_URL } from 'domain/Plans/constants'
import PlansTest from './PlansTest'

const PlanCollections = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const renderPlanCollection = (id) => {
    switch (id) {
      case LC_URL:
        return <LeeCujesCollection />
      case CS_URL:
        return <ClimbStrongCollection />
      case ND_URL:
        return <NineDegreesCollection />
      case TEST_URL:
        return <PlansTest />
      default:
        return null
    }
  }

  return renderPlanCollection(id)
}

export default PlanCollections
