export const LC_URL = 'lee-cujes'
export const LC_PROVIDER = 'leecujes'
export const LC_MOONBOARD_BENCHMARK_BUSTER = 'Moonboard Benchmark Buster'
export const LC_MOONBOARD_BENCHMARK_BUSTER_CODE = 'lee-cujes-moonboard-benchmark-buster'
export const LC_MOONBOARD_BENCHMARK_BUSTER_URL = 'lee-cujes-moonboard-benchmark-buster'

export const CS_URL = 'climb-strong'
export const CS_PROVIDER = 'climbstrong'
export const CS_BOULDERING_PERFORMANCE = 'Climb Strong - Bouldering Performance'
export const CS_BOULDERING_PERFORMANCE_CODE = 'climb-strong-bouldering-performance'
export const CS_BOULDERING_PERFORMANCE_URL = 'climb-strong-8-week-bouldering-performance'
export const CS_FULL_TILT = 'Climb Strong - Full Tilt'
export const CS_FULL_TILT_CODE = 'climb-strong-full-tilt'
export const CS_FULL_TILT_URL = 'climb-strong-full-tilt'

export const SEQ_BOULDER_PLAN_FREE = 'Boulder'
export const SEQ_BOULDER_PLAN_FREE_CODE = '1_b_4'
export const SEQ_BOULDER_PLAN_FREE_URL = 'sequence-boulder-plan-free'
export const SEQ_ROUTES_PLAN_FREE = 'Routes'
export const SEQ_ROUTES_PLAN_FREE_CODE = '1_r_4'
export const SEQ_ROUTES_PLAN_FREE_URL = 'sequence-routes-plan-free'
export const SEQ_COMBINED_PLAN_FREE = 'Combined'
export const SEQ_COMBINED_PLAN_FREE_CODE = '1_c_4'
export const SEQ_COMBINED_PLAN_FREE_URL = 'sequence-combined-plan-free'

export const ND_URL = '9-degrees'

export const TEST_URL = 'test'
