import React from 'react'
import { Button, Box, Container, Stack, Text, VStack } from '@chakra-ui/react'

const PlanCard = ({ plan, description, handleConfirm, handlePreview }) => (
  <Container py={{ base: '2', md: '4' }} maxW="3xl">
    <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
      <Text fontSize="xl" fontWeight="bold">
        {plan}
      </Text>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '5', md: '6' }}
        justify="space-between"
      >
        <Stack spacing="3" maxW="lg">
          <Text>{description}</Text>
        </Stack>
        <VStack justifyContent="center" spacing="2">
          <Button colorScheme="brand" onClick={handleConfirm}>
            Apply Plan
          </Button>
          <Button variant="link" color="yellow.500" size="sm" onClick={handlePreview}>
            Preview Plan
          </Button>
        </VStack>
      </Stack>
    </Box>
  </Container>
)
export default PlanCard
