import React from 'react'

import { Box, Center, Text } from '@chakra-ui/react'

const SubscriptionSuccessful = () => {
  return (
    <Center mt="12">
      <Box width="500px" margin="4">
        <Text textAlign="center" fontSize="2xl">
          Subscription Successful
        </Text>

        <Box boxShadow="md" p="6" rounded="md" bg="white" mt="4">
          <Text fontWeight="bold">Thank you for supporting Sequence, it means a lot!</Text>

          <Text mt="2">
            Our mission is to empower you to become a better climber by making a world class
            training platform.
          </Text>

          <Text mt="2">
            Good luck with the training and if you have any issues, questions or feedback please
            don't hesitate to reach out to us.
          </Text>
          <Text mt="2">
            Cheers,
            <br />
            The team at Sequence
          </Text>
        </Box>
      </Box>
    </Center>
  )
}

export default SubscriptionSuccessful
