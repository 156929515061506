import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  item: {
    p: '0',
    _hover: {
      bg: 'gray.100',
    },
    _focus: {
      bg: 'gray.100',
    },
  },
})

export const menuTheme = defineMultiStyleConfig({ baseStyle })
