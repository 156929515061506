import React from 'react'
import { Box, FormControl, FormLabel, Input, InputGroup, InputRightAddon } from '@chakra-ui/react'

const ActivityDuration = ({ duration, onSetDuration, readOnly }) => {
  const adjustedDuration = Math.floor(Number(duration) / 60 / 1000)

  const handleChange = (event) => {
    onSetDuration(event.target.value)
  }

  return (
    <Box my="2">
      <FormControl>
        <FormLabel fontSize="sm">Actual Workout Duration</FormLabel>
        <InputGroup size="sm">
          <Input
            width="60px"
            type="number"
            defaultValue={adjustedDuration}
            onChange={handleChange}
            isReadOnly={readOnly}
          />
          <InputRightAddon size="sm" children="minutes" />
        </InputGroup>
      </FormControl>
    </Box>
  )
}

export default ActivityDuration
