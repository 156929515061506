import React, { useState, useEffect } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'

import { useQuery, useMetricsDays } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import LastXDays from 'domain/Metrics/CommonMetrics'
import Measure from 'domain/Metrics/Measure'

const WorkoutMetricsCustomMeasures = ({ id }) => {
  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const days = useMetricsDays((state) => state.days)

  const { data, isLoading, hasError, mutate } = useQuery(
    `api/v1/activities/${id}/metrics/custom_measures?days=${days}`
  )

  useEffect(() => {
    if (triggerRefresh) {
      mutate()
      setTriggerRefresh(false)
    }
  }, [triggerRefresh])

  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(data).length) {
    return null
  }

  const { widget_measures, annotations } = data

  const renderCustomMeasures = () => {
    if (!widget_measures.length) {
      return (
        <Box>
          <LastXDays />
          <Text color="gray.500" mt="4">
            No data for this period.
          </Text>
        </Box>
      )
    } else {
      return widget_measures.map((measure) => {
        return (
          <Measure
            key={measure.measure_id}
            data={measure}
            annotations={annotations}
            setTriggerRefresh={setTriggerRefresh}
          />
        )
      })
    }
  }

  return (
    <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
      <Box maxWidth="670px" margin="0 auto" px={{ base: '10', md: '0' }}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box>
            <Text fontSize="large">Custom Measures</Text>
          </Box>
        </Flex>
        {renderCustomMeasures()}
      </Box>
    </Box>
  )
}

export default WorkoutMetricsCustomMeasures
