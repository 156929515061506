import { Box, GridItem, Spacer, Icon } from '@chakra-ui/react'
import { Plus } from 'react-feather'

const GridDay = ({
  provided,
  isRestDay,
  day,
  data,
  refreshData,
  showDayActions,
  renderDraggableWorkout,
  ...props
}) => {
  return (
    <>
      <GridItem
        key={day.toString()}
        w="100%"
        bg={isRestDay ? '#e5e5e5' : '#fff'}
        onClick={() => showDayActions(day)}
        _hover={{ cursor: 'pointer', background: 'rgba(255, 255, 255, 0.7)' }}
        {...props}
      >
        <Box display="flex" flexDirection="column" minH="32">
          <Box onClick={(e) => e.stopPropagation()} p="2">
            {renderDraggableWorkout(day)}
            {provided.placeholder}
          </Box>
          <Spacer />
          <Box display="flex" justifyContent="end" p="1">
            <Icon
              as={Plus}
              strokeWidth="1px"
              w="4"
              h="4"
              color="gray.400"
              _hover={{ color: 'brand.500', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </GridItem>
    </>
  )
}

export default GridDay
