import { forwardRef } from 'react'
import ReactSelect from 'react-select'

// FYI - can access these props if needed
// { data, isDisabled, isFocused, isSelected }
// https://react-select.com/styles#examples

const colourStyles = {
  control: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'default',
    borderRadius: 0,
    borderColor: isFocused ? '#fdb619' : '#a0aec0',
    borderWidth: '1px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? '#fdb619' : '#a0aec0',
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: '#A0AEC0',
      color: 'white',
    },
  }),
}

const Select = ({ options, ...props }, ref) => {
  return <ReactSelect styles={colourStyles} {...props} {...{ options }} />
}

export default forwardRef(Select)
