import React from 'react'
import {
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

const Details = ({ activityDetails }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!activityDetails?.length) {
    return null
  }

  return (
    <>
      <Link
        color="yellow.500"
        onClick={onOpen}
        fontWeight="bold"
        display="inline-block"
        my="2"
        fontSize="sm"
      >
        View workout details
      </Link>

      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} size="lg">
        <ModalOverlay />
        <ModalContent top="3.75rem">
          <ModalHeader>Workout Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingBottom="6" color="gray.600">
            <span
              dangerouslySetInnerHTML={{
                __html: activityDetails,
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Details
