import React from 'react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import Impersonate from 'components/General/Impersonate'

const PLANNER_ROUTE = '/planner'
const WORKOUTS_ROUTE = '/workouts'
const GOALS_ROUTE = '/goals'
const TESTS = '/tests'
const COACHING_ROUTE = '/coaching'
const COACHING_WORKOUTS_ROUTE = '/coaching/workouts'
const COACHING_BULK_ADD_ROUTE = '/bulk-add-coaching-workouts'
const COACHING_WIDGETS = '/coaching/widgets'
const COACHING_WORKOUT_CREATE = '/create-coaching-workout'

// sub path routes
const COACHING_WORKOUT = 'coaching-workout'
const METRICS = 'metrics'

const ClientSelect = () => {
  const {
    data: clientData = [],
    isLoading: isClientsLoading,
    hasError: hasErrorClients,
  } = useQuery('/api/v1/coaching/connections?select=true')

  if (hasErrorClients) return <Error />

  const impersonateCallback = (active) => {
    const validRoutes = [
      PLANNER_ROUTE,
      WORKOUTS_ROUTE,
      GOALS_ROUTE,
      TESTS,
      COACHING_WORKOUTS_ROUTE,
      COACHING_BULK_ADD_ROUTE,
      COACHING_WIDGETS,
      COACHING_WORKOUT_CREATE,
    ]

    const validPaths = [COACHING_WORKOUT, METRICS]

    if (!active) {
      window.location.assign(COACHING_ROUTE)
      return
    }

    if (validPaths.includes(window.location.pathname.split('/')[1])) {
      window.location.reload()
      return
    }

    if (validRoutes.includes(window.location.pathname)) {
      window.location.reload()
      return
    }

    window.location.assign(COACHING_ROUTE)
  }

  if (isClientsLoading) {
    return <LoadingSpinner margin="0" />
  }

  const activeClients = clientData.filter((client) => client.status !== 'inactive')

  if (!activeClients.length) {
    return null
  }

  return <Impersonate users={activeClients} callback={impersonateCallback} />
}

export default ClientSelect
