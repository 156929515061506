import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Tooltip,
  ButtonGroup,
  Spacer,
  Icon,
  InputRightAddon,
  InputGroup,
} from '@chakra-ui/react'

import { Info } from 'react-feather'

import { revalidateLiveQueries } from 'helpers/swrConfig'
import { useQuery } from 'hooks'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import { processApiError } from 'helpers/utils'

import DailyMetricsNoAttributes from 'domain/DailyMetrics/DailyMetricsNoAttributes'

const MENSTRUAL_STYLE = 'menstrual_start'

const DailyMetricsContent = ({ date, highlightAttributeId, highlightMenstrualCycle, onClose }) => {
  const { register, handleSubmit } = useForm()

  const { data, isLoading, hasError } = useQuery(`api/v1/biometrics/day?date=${date}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onSubmit = (data) => {
    const recordsData = Object.entries(data).map(([key, value]) => {
      return {
        biometric_attribute_id: key,
        value: value,
      }
    })

    securedApi
      .patch('/api/v1/biometric_records/many', {
        date,
        records: recordsData,
      })
      .then(() => {
        Notify({
          content: 'Daily metrics updated',
          type: 'success',
        })
        revalidateLiveQueries()
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleStartMenstrualCycleTracking = (id, biometric_record_id) => {
    const _then = () => {
      revalidateLiveQueries()
      Notify({
        content: 'Start of cycle set for this day',
        type: 'success',
      })
    }

    if (biometric_record_id) {
      securedApi
        .patch(`/api/v1/biometric_records/${biometric_record_id}`, {
          value: 1,
        })
        .then(_then)
        .catch((error) => processApiError(error))
    } else {
      securedApi
        .post(`/api/v1/biometric_records`, {
          biometric_attribute_id: id,
          date,
          value: 1,
        })
        .then(_then)
        .catch((error) => processApiError(error))
    }
  }

  const handleStopMenstrualCycleTracking = (record) => {
    const { biometric_record_id, notes } = record
    const hasNotes = !!notes

    const _then = () => {
      revalidateLiveQueries()
      Notify({
        content: 'Start of cycle removed from this day',
        type: 'success',
      })
    }

    if (hasNotes) {
      securedApi
        .patch(`/api/v1/biometric_records/${biometric_record_id}`, {
          value: 0,
        })
        .then(_then)
        .catch((error) => processApiError(error))
    } else {
      securedApi
        .delete(`/api/v1/biometric_records/${biometric_record_id}`)
        .then(_then)
        .catch((error) => processApiError(error))
    }
  }

  const renderValueField = ({ data, shouldFocusField }) => {
    if (data.style === MENSTRUAL_STYLE) {
      const StartCycleButton = ({ biometric_record_id }) => {
        return (
          <Button
            size="xs"
            colorScheme="yellow"
            color="white"
            onClick={() =>
              handleStartMenstrualCycleTracking(data.biometric_attribute_id, biometric_record_id)
            }
          >
            Cycle started today
          </Button>
        )
      }

      const StopCycleButton = ({ record }) => (
        <Button
          size="xs"
          variant="outline"
          onClick={() => handleStopMenstrualCycleTracking(record)}
        >
          Remove cycle started today
        </Button>
      )

      const CycleInfoIcon = () => (
        <Tooltip label="Add notes and adjust the cycle stage by clicking on the coloured cycle day number in the planner">
          <Icon
            as={Info}
            w="4"
            h="4"
            color="gray.500"
            _hover={{ cursor: 'pointer', color: 'brand.500' }}
          />
        </Tooltip>
      )

      const CycleButton = () => {
        if (data.records.length) {
          const record = data.records[0]
          const { biometric_record_id, value } = record

          if (value === '1') {
            return <StopCycleButton record={record} />
          } else {
            return <StartCycleButton biometric_record_id={biometric_record_id} />
          }
        } else {
          return <StartCycleButton />
        }
      }

      return (
        <Flex gap={2} alignItems="center">
          <CycleButton />
          <CycleInfoIcon />
        </Flex>
      )
    }

    const isHighlighted = data.biometric_attribute_id === highlightAttributeId
    const highlightStyle = isHighlighted ? { backgroundColor: '#FEDC74' } : {}

    return (
      <Input
        htmlSize={4}
        width="auto"
        defaultValue={data.records[0]?.value}
        autoFocus={isHighlighted || shouldFocusField ? true : false}
        style={{
          ...highlightStyle,
        }}
        {...register(data.biometric_attribute_id)}
      />
    )
  }

  const renderBiometricsData = () => {
    if (!data.content.length) {
      return <DailyMetricsNoAttributes />
    }

    return data.content.map((data, index) => {
      const isMenstrualCycle = data.style === MENSTRUAL_STYLE
      const MenstrualCycleHighlightStyle =
        isMenstrualCycle && highlightMenstrualCycle
          ? {
              backgroundColor: '#FEDC74',
              padding: '4px 6px',
              display: 'inline-block',
              borderRadius: '4px',
            }
          : {}

      const shouldFocusField = index === 0

      return (
        <FormControl key={data.name} marginBottom="4">
          <FormLabel
            fontSize="sm"
            mb="1"
            style={{
              ...MenstrualCycleHighlightStyle,
            }}
          >
            {data.name}
          </FormLabel>

          <HStack>
            <Box>
              <InputGroup size="sm">
                {renderValueField({ data, shouldFocusField })}
                {data.units && <InputRightAddon children={data.units} />}
              </InputGroup>
            </Box>
          </HStack>
        </FormControl>
      )
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderBiometricsData()}
      <ButtonGroup spacing="4" marginTop="8" display="flex">
        <Spacer />
        <Button variant="outline" onClick={onClose} size="sm">
          Cancel
        </Button>

        <Button type="submit" colorScheme="brand" size="sm">
          Save
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default DailyMetricsContent
