import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { Button, ButtonGroup, FormControl, FormLabel, Input, Text, Stack } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import RichTextArea from 'components/RichTextArea'
import Notify from 'components/Notification'
import DatePicker from 'components/DatePicker/DatePicker'
import LoadingSpinner from 'components/LoadingSpinner'

import NoteGoalLinker from 'domain/Notes/NoteGoalLinker'

const CreateNoteForm = ({ isMilestone }) => {
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)

  const noteType = isMilestone ? 'milestone' : 'note'
  const successPath = isMilestone ? 'milestones' : 'notes'

  const methods = useForm({
    defaultValues: {
      scheduled_at: new Date(),
    },
  })
  const { control, register, handleSubmit } = methods

  const onSubmit = ({ scheduled_at, title, description, goals }) => {
    // create new array of goal ids
    const goalIds = goals?.map((goal) => goal.value)

    setProcessing(true)

    securedApi
      .post(`api/v1/scheduled_notes`, {
        scheduled_note: {
          title,
          scheduled_at,
          description,
          goal_ids: goalIds,
          style: noteType,
        },
      })
      .then(() => {
        setProcessing(false)
        Notify({
          content: `${noteType.charAt(0).toUpperCase() + noteType.slice(1)} created.`,
          type: 'success',
        })
        navigate(`/${successPath}`)
      })
      .catch(() => {
        setProcessing(false)
        Notify({
          content: `Error creating ${noteType}. Please try again.`,
          type: 'error',
        })
      })
  }

  if (processing) return <LoadingSpinner />

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="4">
          <FormControl marginBottom="4" isRequired>
            <FormLabel fontSize="sm" mb="0">
              Title
            </FormLabel>
            <Input bg="white" placeholder="Awesome training day" {...register('title')} />
          </FormControl>

          <FormControl marginBottom="4" isRequired>
            <FormLabel fontSize="sm" mb="0">
              Date
            </FormLabel>
            <Controller
              control={control}
              name="scheduled_at"
              render={({ field }) => (
                <DatePicker
                  required
                  placeholderText="Select date"
                  dateFormat="d/M/yy"
                  onChange={(e) => field.onChange(e)}
                  selectedDate={field.value}
                />
              )}
            />
          </FormControl>

          <FormControl marginBottom="4">
            <FormLabel fontSize="sm" mb="0">
              Details
            </FormLabel>

            <Controller
              name="description"
              control={control}
              required
              render={({ field }) => <RichTextArea {...field} />}
            />
          </FormControl>

          <NoteGoalLinker {...{ control }} />
        </Stack>

        <ButtonGroup spacing="4" marginTop="8" display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="brand">
            Create
          </Button>
        </ButtonGroup>

        {isMilestone && (
          <Text fontStyle="italic" fontSize="xs">
            *Once you have created your Milestone, data will be visible.
          </Text>
        )}
      </form>
    </FormProvider>
  )
}

export default CreateNoteForm
