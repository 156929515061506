import React, { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import {
  Badge,
  Button,
  Box,
  Card,
  CardHeader,
  Container,
  Heading,
  Text,
  Link,
  Tooltip,
  Stack,
  CardBody,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Edit } from 'react-feather'

import securedApi from 'backend/axios'

import { processGetError, processApiError } from 'helpers/utils'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import HeaderDivider from 'components/General/HeaderDivider'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import GoalDates from './components/GoalDates'
import LinkedItemDate from './components/LinkedItemDate'

import { statusMapText, statusMapColour, styleMapText } from './helpers'

const GoalsDetails = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [goal, setGoal] = useState({})
  const [scheduledActivities, setScheduledActivities] = useState([])
  const [milestones, setMilestones] = useState([])
  const [notes, setNotes] = useState([])

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/goals/${id}`)
      .then(({ data }) => {
        setGoal(data)
        setScheduledActivities(data.scheduled_activities)

        const milestoneData = data.scheduled_notes.filter((item) => item.style === 'milestone')
        const notesData = data.scheduled_notes.filter((item) => item.style === 'note')

        setMilestones(milestoneData)
        setNotes(notesData)

        setLoading(false)
      })
      .catch((error) => {
        processGetError(error)
        setLoading(false)
      })
  }, [id])

  const handleUnlinkScheduledActivities = (activityId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_activity_id]=${activityId}`)
      .then(() => {
        const newState = scheduledActivities.filter((item) => item.id !== activityId)
        setScheduledActivities(newState)

        Notify({ content: 'Activity has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking activity. Please try again.')
      })
  }

  const handleNoteUnlinkGoal = (noteId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_note_id]=${noteId}`)
      .then(() => {
        const newState = notes.filter((item) => item.id !== noteId)
        setNotes(newState)

        Notify({ content: 'Note has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking note. Please try again.')
      })
  }

  const handleMilestoneUnlinkGoal = (milestoneId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_note_id]=${milestoneId}`)
      .then(() => {
        const newState = milestones.filter((item) => item.id !== milestoneId)
        setMilestones(newState)

        Notify({ content: 'Milestone has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking milestone. Please try again.')
      })
  }

  const renderNoLinks = () => {
    if (scheduledActivities.length || milestones.length || notes.length) {
      return null
    } else {
      return (
        <Box mt="8" background="white" padding="6" maxW="600px" margin="20px auto">
          <Heading as="h4" size="sm">
            No items linked
          </Heading>
          <Box mt="4">
            <Text color="gray.500">
              You haven't linked any workouts, notes or milestones to this goal yet.
            </Text>
            <Link
              href="https://docs.sequence-app.com/features/goals"
              isExternal
              fontWeight="bold"
              color="yellow.500"
            >
              Learn how <ExternalLinkIcon />
            </Link>
          </Box>
        </Box>
      )
    }
  }

  const renderScheduledActivities = () => {
    if (!scheduledActivities.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Workouts" />

        {scheduledActivities.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Text display="inline" fontWeight="bold">
                  {item.activity_name}
                </Text>
                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleUnlinkScheduledActivities(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text color="gray.500" fontSize="sm">
                {item.notes}
              </Text>
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderMilestones = () => {
    if (!milestones.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Milestones" />

        {milestones.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Link as={NavLink} to={`/milestone/${item.id}`} variant="brand" fontWeight="bold">
                  {item.title}
                </Link>
                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleMilestoneUnlinkGoal(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text
                color="gray"
                my="4"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderNotes = () => {
    if (!notes.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Notes" />

        {notes.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Link as={NavLink} to={`/note/${item.id}`} variant="brand" fontWeight="bold">
                  {item.title}
                </Link>

                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleNoteUnlinkGoal(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text
                color="gray"
                my="4"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderGoal = ({ id, title, status, style, details, start_date, end_date, notes }) => (
    <>
      <Card rounded="0" key={id} my="8">
        <CardHeader pb="0">
          <Heading size="md">{title}</Heading>
          <GoalDates startDate={start_date} endDate={end_date} />
          <Badge colorScheme={statusMapColour[status]}>{statusMapText[status]}</Badge>
          <Badge ml="2">{styleMapText[style]}</Badge>
        </CardHeader>
        <CardBody>
          <Text
            color="gray"
            dangerouslySetInnerHTML={{
              __html: details,
            }}
          />

          <Text maxW="500px" color="gray">
            {notes}
          </Text>
        </CardBody>
      </Card>

      {renderNoLinks()}
      {renderScheduledActivities()}
      {renderNotes()}
      {renderMilestones()}
    </>
  )

  if (loading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(goal).length) {
    return <Text>No goal found</Text>
  }

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions backAction="/goals">
        <Tooltip label="Edit goal">
          <Link as={NavLink} to={`/goals/edit/${goal.id}`} variant="brand" fontWeight="bold">
            <Edit size="18px" />
          </Link>
        </Tooltip>
      </InnerPageWrapperActions>
      <Box marginBottom="20">{renderGoal(goal)}</Box>
    </Container>
  )
}

export default GoalsDetails
