import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { Check, X, PlusCircle } from 'react-feather'

import { WidgetIcon } from 'domain/Widgets/components'
import {
  ADD_MODAL_NEW_ORDER,
  ADD_MODAL_EXISTING_ORDER,
  LOGBOOK_TYPE,
} from 'domain/Widgets/constants'
import { widgetTextMap } from 'domain/Widgets/mappers'

import { ModalButtonContent } from './AddWidgetModal/ModalButtonContent'

import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

const AddWidgetModal = ({
  onAddNew,
  onAddExisting,
  isOpen,
  onClose,
  activity,
  activityId,
  templatedActivity,
  widgetsAddedToWorkout,
}) => {
  // There are 3 situations this modal can be called (with different requirements):
  // 1. from the widgets page
  // 2. when creating a new workout
  // 3. when editing an existing workout
  //
  // if this is called from a workout create/edit, we want to allow adding an existing widget
  // otherwise if it is called from the master widgets list then we want to hide existing widgets

  const showAddNewWidgets = true
  const showAddExistingWidgets = activity
  const isNewActivity = activityId === undefined

  const [allWidgets, setAllWidgets] = useState([])
  const [loadingExistingWidgets, setLoadingExistingWidgets] = useState(showAddExistingWidgets)

  useEffect(() => {
    if (isOpen && showAddExistingWidgets) {
      handleLoadActivityWidgets()
    }
  }, [isOpen, showAddExistingWidgets])

  const handleLoadActivityWidgets = () => {
    setLoadingExistingWidgets(true)

    const endpoint = `api/v1/${templatedActivity ? 'templated_widgets' : 'widgets'}?${
      templatedActivity ? 'templated_activity_id' : 'activity_id'
    }=${activityId}`

    securedApi
      .get(endpoint)
      .then(({ data }) => {
        setAllWidgets(data)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => setLoadingExistingWidgets(false))
  }

  const noExistingWidgets = allWidgets?.total?.length === 0

  const presentWidgetTypes = Object.keys(allWidgets).filter((x) => x !== 'total')

  const newWidgetTypes = ADD_MODAL_NEW_ORDER

  let existingWidgetTypes
  if (showAddExistingWidgets) {
    existingWidgetTypes = presentWidgetTypes
      .filter((x) => ADD_MODAL_EXISTING_ORDER.includes(x))
      .sort((a, b) => {
        return ADD_MODAL_EXISTING_ORDER.indexOf(a) - ADD_MODAL_EXISTING_ORDER.indexOf(b)
      })
  }

  // TODO: calculate from length of above?
  const newWidgetColumns = 1
  const existingWidgetColumns = 3

  const SectionHeader = ({ children }) => {
    return (
      <Text ml={1} fontWeight="bold" textAlign="center">
        {children}
      </Text>
    )
  }

  const WidgetHeader = ({ type }) => {
    return (
      <Flex mt={6} mb={2} alignItems="center">
        <WidgetIcon type={type} />
        <Text ml={1} fontWeight="bold">
          {widgetTextMap[type]}
        </Text>
      </Flex>
    )
  }

  const NewWidgetButton = ({ type }) => {
    return (
      <Button
        onClick={() => {
          onAddNew({ type: type, new: true })
          onClose()
        }}
        height={20}
        background="gray.100"
        alignItems="center"
        data-cy={`new-${type}-widget`}
      >
        <Flex alignItems="center">
          <WidgetIcon type={type} boxSize={6} />
          <Text ml={2} mt={1}>
            {widgetTextMap[type]}
          </Text>
        </Flex>
      </Button>
    )
  }

  const ExistingWidgetButton = ({ widget, children }) => {
    const foundWidget = widgetsAddedToWorkout.find(
      (currentWidget) => currentWidget.id === widget.id
    )
    const alreadyOnActivity =
      widget.content.this_activity || widget.content.this_templated_activity || foundWidget

    // if this is a logbook widget and workout already has a logbook widget
    // then don't allow a second one
    const workoutHasLogbook = widgetsAddedToWorkout.some((w) => w.type === LOGBOOK_TYPE)
    const widgetIsLogbook = widget.type === LOGBOOK_TYPE

    const disableButton = alreadyOnActivity || (workoutHasLogbook && widgetIsLogbook)

    return (
      <Box
        onClick={() => {
          if (disableButton) {
            return
          } else {
            onAddExisting({ widget: widget, widgets: widgetsAddedToWorkout })
            onClose()
          }
        }}
        _hover={{ cursor: disableButton ? 'not-allowed' : 'pointer', background: 'gray.300' }}
        opacity={disableButton ? 0.5 : 1}
        background="gray.200"
        padding="12px"
      >
        <Flex alignContent="flex-start" justifyContent="flex-start" alignItems="center">
          <Flex flexGrow="1">{children}</Flex>
          {alreadyOnActivity && (
            <Tooltip label="This widget is already on this workout">
              <Check />
            </Tooltip>
          )}
          {widgetIsLogbook && workoutHasLogbook && !alreadyOnActivity && (
            <Tooltip label="This workout already has a logbook widget">
              <X />
            </Tooltip>
          )}
        </Flex>
      </Box>
    )
  }

  const renderContent = () => {
    if (loadingExistingWidgets) {
      return <LoadingSpinner />
    }
    return (
      <>
        <ModalHeader>Add Widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="2" pb="6">
          {showAddNewWidgets && (
            <Box mb="10">
              <SectionHeader>New Widget</SectionHeader>
              <SimpleGrid columns={[newWidgetColumns]} spacing="4" mt={6} mx={50}>
                {newWidgetTypes.map((widgetType) => {
                  return <NewWidgetButton key={widgetType} type={widgetType} />
                })}
              </SimpleGrid>
            </Box>
          )}
          {showAddExistingWidgets && !noExistingWidgets && (
            <Box mb="10">
              <SectionHeader>Existing Widgets</SectionHeader>
              <Box>
                {existingWidgetTypes.map((widgetType) => {
                  if (!allWidgets[widgetType].length) {
                    return null
                  }

                  return (
                    <Box key={widgetType}>
                      <WidgetHeader type={widgetType} />
                      <SimpleGrid columns={[existingWidgetColumns]} spacing="12px">
                        {allWidgets[widgetType].map((widget) => {
                          return (
                            <ExistingWidgetButton key={widget.id} widget={widget}>
                              <ModalButtonContent widget={widget} />
                            </ExistingWidgetButton>
                          )
                        })}
                      </SimpleGrid>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )}
        </ModalBody>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  )
}

export default AddWidgetModal
