import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  Container,
  Text,
  FormControl,
  FormLabel,
  Input,
  Spacer,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import RichTextArea from 'components/RichTextArea'
import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import Modal from 'components/Modal'
import DatePicker from 'components/DatePicker/DatePicker'

import { processGetError, tzSafeNewDate } from 'helpers/utils'

import NoteGoalLinker from 'domain/Notes/NoteGoalLinker'

const NoteEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const methods = useForm()
  const { control, register, handleSubmit, setValue } = methods

  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState({})
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/scheduled_notes/${id}`)
      .then(({ data }) => {
        setNote(data)

        if (data.scheduled_at) {
          setValue('scheduled_at', tzSafeNewDate(data?.scheduled_at))
        }
        setLoading(false)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id, setValue])

  const defaultValues = note?.goals?.map((goal) => ({
    value: goal.id,
    label: goal.title,
  }))

  const onSubmit = ({ scheduled_at, title, description, goals }) => {
    setLoading(true)

    // create new array of goal ids
    const goalIds = goals?.map((goal) => goal.value)

    securedApi
      .patch(`/api/v1/scheduled_notes/${id}`, {
        scheduled_note: {
          title,
          scheduled_at,
          description,
          goal_ids: goalIds,
        },
      })
      .then(() => {
        setLoading(false)
        Notify({ content: 'Note updated.', type: 'success' })
        navigate(`/note/${id}`)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        Notify({
          content: 'Error updating note. Please try again.',
          type: 'error',
        })
      })
  }

  const handleDelete = () => {
    securedApi
      .delete(`api/v1/scheduled_notes/${id}`)
      .then(() => {
        Notify({ content: 'Note has been deleted.', type: 'success' })
        navigate('/notes')
      })
      .catch((error) => console.log(error))
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(note).length) {
    return <Text>No note found</Text>
  }

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl marginBottom="4" isRequired>
            <FormLabel fontSize="sm" mb="0">
              Title
            </FormLabel>
            <Input defaultValue={note.title} bg="white" {...register('title')} />
          </FormControl>

          <FormControl marginBottom="4">
            <FormLabel fontSize="sm" mb="0">
              Date
            </FormLabel>
            <Controller
              control={control}
              name="scheduled_at"
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  dateFormat="d MMM yyyy"
                  onChange={(e) => field.onChange(e)}
                  selectedDate={field.value}
                />
              )}
            />
          </FormControl>

          <FormControl marginBottom="4">
            <FormLabel fontSize="sm" mb="0">
              Description
            </FormLabel>
            <Controller
              name="description"
              control={control}
              defaultValue={note.description}
              render={({ field }) => <RichTextArea {...field} />}
            />
          </FormControl>

          <NoteGoalLinker {...{ control, defaultValues }} />

          <ButtonGroup spacing="4" marginTop="8" display="flex">
            <Button onClick={() => setDeleteModalOpen(true)} variant="outline">
              Delete
            </Button>
            <Spacer />
            <Button variant="outline" onClick={() => navigate(`/note/${id}`)}>
              Cancel
            </Button>

            <Button type="submit" colorScheme="brand">
              Update
            </Button>
          </ButtonGroup>
        </form>
      </FormProvider>
      <Modal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        title="Are you sure?"
        subTitle="This action can't be undone"
        onAccept={() => handleDelete()}
      />
    </Container>
  )
}

export default NoteEdit
