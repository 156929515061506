import React from 'react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

const Purchases = () => {
  const query = `api/v1/purchases`
  const { data, isLoading, hasError } = useQuery(query)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <h2>All Purchases</h2>
      {data.map((x) => {
        return (
          <>
            <h3>Purchase:</h3>
            <ul>
              <li>{x.stripe_product_name}</li>
              <li>{x.stripe_product_description}</li>
              <li>{x.stripe_price_unit_amount}</li>
              <li>{x.purchase_date}</li>
            </ul>
          </>
        )
      })}
    </>
  )
}

export default Purchases
