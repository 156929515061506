import { AbsoluteCenter, Divider, Box, Heading } from '@chakra-ui/react'

const HeaderDivider = ({ heading, size = 'md' }) => (
  <Box position="relative" padding="10">
    <Divider />
    <AbsoluteCenter bg="#F6F6F6" px="4">
      <Heading {...{ size }}>{heading}</Heading>
    </AbsoluteCenter>
  </Box>
)

export default HeaderDivider
