import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link, Text } from '@chakra-ui/react'

const DailyMetricsNoAttributes = () => {
  return (
    <>
      <Text color="gray">You currently haven't setup any daily metrics to record.</Text>

      <Link
        as={ReactRouterLink}
        to="/metrics/daily-metrics-settings"
        fontWeight="bold"
        color="yellow.500"
        display="block"
        mt="2"
      >
        Setup daily metrics
      </Link>
    </>
  )
}

export default DailyMetricsNoAttributes
