import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { useDemoData } from 'hooks'

import {
  ChartContainer,
  ChartHeader,
  MeasureLineChart,
  MeasureBarChart,
  MeasureScatterChart,
  NoData,
} from './CommonMeasure'
import LastXDays from './CommonMetrics'
import ActivityModal from 'domain/Planner/components/ActivityModal'
import { getDataValues } from 'helpers/charts'

const Measure = ({ data, annotations, activities, setTriggerRefresh, isChangeable }) => {
  const demoData = useDemoData((state) => state.demoData)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [workoutId, setWorkoutId] = useState(null)

  const { week_labels } = annotations

  const handlePointClick = (fn, event) => {
    if (demoData) {
      return null
    }
    if (setTriggerRefresh !== undefined) {
      const { scheduled_activity_id } = event.payload
      setWorkoutId(scheduled_activity_id)
      onOpen()
    }
  }

  const refresh = () => {
    setTriggerRefresh(true)
  }

  const renderGraph = (item, geom) => {
    const { y_values } = getDataValues(item.data)

    if (y_values.length === 0) {
      return <NoData />
    } else {
      if (geom === 'LineChart') {
        return (
          <MeasureLineChart
            item={item}
            annotations={annotations}
            activities={activities}
            handlePointClick={handlePointClick}
            setTriggerRefresh={setTriggerRefresh}
          />
        )
      } else if (geom === 'BarChart') {
        return <MeasureBarChart item={item} week_labels={week_labels} />
      } else if (geom === 'ScatterChart') {
        return <MeasureScatterChart item={item} week_labels={week_labels} />
      }
    }
  }

  /// for when charts have custom types
  // var graph_type = item.graph_type || 'LineChart'

  var graph_type = 'LineChart'

  return (
    <ChartContainer key={data.measure_name}>
      <ChartHeader title={data.measure_name} />
      <LastXDays {...{ isChangeable }} />
      {renderGraph(data, graph_type)}
      {isOpen && (
        <ActivityModal
          id={workoutId}
          isOpen={isOpen}
          onClose={onClose}
          highlightMeasureId={data.measure_id}
          refresh={refresh}
        />
      )}
    </ChartContainer>
  )
}

export default Measure
