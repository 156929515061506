import { Box, Button, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'

export const AuthWrapper = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" p="2" mt="50">
    <Box w={[300, 400]}>{children}</Box>
  </Box>
)

export const EmailField = ({ register, errors, ...props }) => {
  return (
    <FormControl marginBottom="4" isInvalid={errors.email} {...props}>
      <FormLabel>Email</FormLabel>
      <Input
        type="email"
        {...register('email', {
          required: true,
        })}
      />
      <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
    </FormControl>
  )
}

export const PasswordFields = ({ register, errors }) => {
  return (
    <>
      <FormControl marginBottom="4" isInvalid={errors.password}>
        <FormLabel>Password</FormLabel>

        <Input
          type="password"
          {...register('password', {
            required: true,
            minLength: 6,
          })}
        />
        <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
      </FormControl>

      <FormControl marginBottom="10" isInvalid={errors.password_confirmation}>
        <FormLabel>Password Confirmation</FormLabel>
        <Input
          type="password"
          {...register('password_confirmation', {
            required: true,
          })}
        />
        <FormErrorMessage>
          {errors.password_confirmation && errors.password_confirmation.message}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}

export const SignUpButton = ({ loading }) => (
  <Button type="submit" colorScheme="brand" width="100%" disabled={loading} isLoading={loading}>
    Sign up
  </Button>
)
