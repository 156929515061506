import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Text } from '@chakra-ui/react'

import { useScheduledWorkoutCount } from 'hooks'
import { TRIAL_PERIOD_SCHEDULED_LIMIT } from 'helpers/constants'

const UpgradeButton = ({ text, counter = true }) => {
  const navigate = useNavigate()
  const DEFAULT_TEXT = 'Upgrade Today'
  const displayText = text || DEFAULT_TEXT

  const scheduledWorkoutCount = useScheduledWorkoutCount((state) => state.count)
  const getScheduledWorkoutCount = useScheduledWorkoutCount((state) => state.fetch)
  const noCounter = useScheduledWorkoutCount((state) => state.no_counter)

  getScheduledWorkoutCount()

  const handleShowUpgradeOverlay = () => {
    navigate('/subscription-create')
  }

  return (
    <Button
      onClick={handleShowUpgradeOverlay}
      variant="primary"
      data-cy="upgrade-button"
      fontSize="small"
      fontWeight="bold"
    >
      {displayText}
      {counter && !noCounter && (
        <Text ml="1" as="span" fontSize="small" fontWeight="bold">
          ({scheduledWorkoutCount}/{TRIAL_PERIOD_SCHEDULED_LIMIT})
        </Text>
      )}
    </Button>
  )
}

export default UpgradeButton
