import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const OwnerNotes = ({ item }) => {
  return (
    <>
      {item?.owner_notes && (
        <Box marginTop="6">
          <Text color="black.500" fontWeight="semibold">
            Notes
          </Text>
          <Text marginTop="1" fontSize="x-small">
            (not viewable by clients)
          </Text>
          <Text marginTop="1">{item?.owner_notes}</Text>
        </Box>
      )}
    </>
  )
}

export default OwnerNotes
