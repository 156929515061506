import { create } from 'zustand'

const useDailyMetrics = create((set) => ({
  dailyMetricsVisible: false,
  dailyMetricsDate: null,
  setDailyMetricsDate: (day) => set(() => ({ dailyMetricsDate: day })),
  setDailyMetricsVisible: () =>
    set((state) => ({ dailyMetricsVisible: !state.dailyMetricsVisible })),
}))

export default useDailyMetrics
