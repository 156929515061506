import React, { useState } from 'react'
import { Button, Box, Container, Stack, Text, useDisclosure } from '@chakra-ui/react'

import { tzSafeDateFormat } from 'helpers/utils'
import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { PlanApplyModal } from 'domain/Plans/components/PlanModals'

import Navigation from './components/Navigation'

const links = [
  {
    name: 'Plans',
    url: '/plans',
  },
]

const PurchasedPlans = () => {
  const [planName, setPlanName] = useState(null)
  const [planCode, setPlanCode] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, isLoading, hasError } = useQuery('api/v1/purchases')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onConfirmOpen = (purchase) => {
    setPlanName(purchase.stripe_product_name)
    setPlanCode(purchase.code)
    onOpen()
  }

  return (
    <>
      <Navigation {...{ links }} />

      <Container py="4" maxWidth="container.lg">
        <Stack spacing="4" alignItems="center">
          <Text textAlign="center" fontSize="2xl">
            Purchased plans
          </Text>
          {data.map((purchase) => {
            const formattedDate = tzSafeDateFormat(purchase.purchase_date, 'd-M-yyyy')

            return (
              <Box
                key={purchase.stripe_product_name}
                boxShadow="md"
                p="6"
                rounded="md"
                bg="white"
                minW={{ base: '100%', md: '50%' }}
              >
                <Stack spacing="3">
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      {purchase.stripe_product_name}
                    </Text>
                    <Text>{purchase.stripe_product_description}</Text>
                    <Text fontStyle="italic" fontSize="sm">
                      Purchase date: {formattedDate}
                    </Text>
                  </Box>
                  <Button variant="primary" onClick={() => onConfirmOpen(purchase)}>
                    Apply Plan
                  </Button>
                </Stack>
              </Box>
            )
          })}
        </Stack>
      </Container>
      <PlanApplyModal {...{ planName, planCode, isOpen, onClose }} />
    </>
  )
}

export default PurchasedPlans
