export const LOGBOOK_TYPE = 'logbook'
export const MEASURE_TYPE = 'measure'
export const SELECT_TYPE = 'select'
export const SLIDER_TYPE = 'slider'

// order that widget types will appear in the each section of AddWidgetModal if applicable
// if a type does not appear, it will not be shown at all
export const ADD_MODAL_NEW_ORDER = [SELECT_TYPE, MEASURE_TYPE]
export const ADD_MODAL_EXISTING_ORDER = [SLIDER_TYPE, LOGBOOK_TYPE, SELECT_TYPE, MEASURE_TYPE]

export const LOGBOOK_ENDPOINT = 'widget_logbooks'
export const MEASURE_ENDPOINT = 'widget_measures'
export const SELECT_ENDPOINT = 'widget_selects'
export const SLIDER_ENDPOINT = 'widget_sliders'

export const TEMPLATED_LOGBOOK_ENDPOINT = 'templated_widget_logbooks'
export const TEMPLATED_MEASURE_ENDPOINT = 'templated_widget_measures'
export const TEMPLATED_SELECT_ENDPOINT = 'templated_widget_selects'
export const TEMPLATED_SLIDER_ENDPOINT = 'templated_widget_sliders'

export const LOGBOOK_RESOURCE = 'widget_logbook'
export const MEASURE_RESOURCE = 'widget_measure'
export const SELECT_RESOURCE = 'widget_select'
export const SLIDER_RESOURCE = 'widget_slider'

export const TEMPLATED_LOGBOOK_RESOURCE = 'templated_widget_logbook'
export const TEMPLATED_MEASURE_RESOURCE = 'templated_widget_measure'
export const TEMPLATED_SELECT_RESOURCE = 'templated_widget_select'
export const TEMPLATED_SLIDER_RESOURCE = 'templated_widget_slider'

export const widgetEndpointMapper = (templatedActivity) => ({
  [LOGBOOK_TYPE]: templatedActivity ? TEMPLATED_LOGBOOK_ENDPOINT : LOGBOOK_ENDPOINT,
  [MEASURE_TYPE]: templatedActivity ? TEMPLATED_MEASURE_ENDPOINT : MEASURE_ENDPOINT,
  [SELECT_TYPE]: templatedActivity ? TEMPLATED_SELECT_ENDPOINT : SELECT_ENDPOINT,
  [SLIDER_TYPE]: templatedActivity ? TEMPLATED_SLIDER_ENDPOINT : SLIDER_ENDPOINT,
})

export const widgetResourceMapper = (templatedActivity) => ({
  [LOGBOOK_TYPE]: templatedActivity ? TEMPLATED_LOGBOOK_RESOURCE : LOGBOOK_RESOURCE,
  [MEASURE_TYPE]: templatedActivity ? TEMPLATED_MEASURE_RESOURCE : MEASURE_RESOURCE,
  [SELECT_TYPE]: templatedActivity ? TEMPLATED_SELECT_RESOURCE : SELECT_RESOURCE,
  [SLIDER_TYPE]: templatedActivity ? TEMPLATED_SLIDER_RESOURCE : SLIDER_RESOURCE,
})
