import React, { useEffect, useState } from 'react'
import { Line, YAxis, Legend } from 'recharts'

import { useDemoData, useMetricsDays } from 'hooks'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import { MetricsChart } from './CommonMeasure'

const Performance = () => {
  const [metricsData, setMetricsData] = useState({})

  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  useEffect(() => {
    securedApi
      .get(`api/v1/metrics/performance?demo=${demoData}&days=${days}`)
      .then((response) => {
        setMetricsData(response.data.performance)
      })
      .catch((error) => processGetError(error))
  }, [demoData, days])

  if (!Object.keys(metricsData).length) {
    return <LoadingSpinner />
  }

  const { weekly, annotations } = metricsData

  return (
    <MetricsChart item={{ data: weekly }} annotations={annotations} yMin={'zero'} yMax={10}>
      <YAxis yAxisId="left" type="number" ticks={[0, 2, 4, 6, 8, 10]} domain={[0, 10]} />
      <Legend
        wrapperStyle={{
          paddingTop: '15px',
        }}
      />
      <Line
        yAxisId="left"
        connectNulls
        dataKey="felt"
        stroke="#8884d8"
        type="monotone"
        activeDot={{ r: 8 }}
      />
      <Line
        yAxisId="left"
        connectNulls
        dataKey="motivated"
        stroke="#fdb619"
        type="monotone"
        activeDot={{ r: 8 }}
      />
      <Line
        yAxisId="left"
        connectNulls
        dataKey="performed"
        stroke="#82ca9d"
        type="monotone"
        activeDot={{ r: 8 }}
      />
      <Line
        yAxisId="left"
        connectNulls
        dataKey="rpe"
        name="RPE"
        stroke="#e74c3c"
        type="monotone"
        activeDot={{ r: 8 }}
      />
    </MetricsChart>
  )
}

export default Performance
