import React from 'react'
import { Input } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { handleNonNumericalKeyPress } from 'helpers/utils'
import { hideAttemptsStyles } from 'domain/Widgets/Logbook/constants'

const InputAttempts = ({ entry }) => {
  const { register, watch } = useFormContext()

  // show/hide attempts depending on style
  const style = watch('style')
  if (hideAttemptsStyles.includes(style)) {
    return null
  }

  return (
    // note: NumberInput doesn't seem to work
    // (and it needs handleNonNumericalKeyPress anyway)
    <Input
      size="sm"
      marginTop="2px"
      marginLeft="1px"
      step={1}
      onKeyPress={handleNonNumericalKeyPress}
      {...register('attempts', { value: entry.attempts })}
    />
  )
}

export default InputAttempts
