import { DateTime } from 'luxon'

export const strToLxTime = (timeStr) => {
  const hour = parseInt(timeStr.substring(0, 2))
  const minute = parseInt(timeStr.substring(2))

  const time = DateTime.local().set({ hour: hour, minute: minute })
  return time
}

export const lxTimeToStr = (time) => {
  const { hour, minute } = time.c
  const hourStr = hour.toString().padStart(2, '0')
  const minuteStr = minute.toString().padStart(2, '0')
  return hourStr + minuteStr
}

export const disabledMinutes = () => {
  const excludedOption = [0, 15, 30, 45]
  const arr = []
  for (let i = 0; i < 60; i += 1) {
    if (!excludedOption.includes(i)) {
      arr.push(i)
    }
  }

  return arr
}
