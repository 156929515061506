import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const Details = ({ item }) => {
  return (
    <Box marginTop="6">
      {item?.activity_details && (
        <Box>
          <Text color="black.500" fontWeight="semibold">
            Details
          </Text>
          <Text marginTop="1">
            <span
              dangerouslySetInnerHTML={{
                __html: item?.activity_details,
              }}
            />
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default Details
