import React from 'react'
import { Badge, Flex, Text } from '@chakra-ui/react'

export const ModalButtonContentSlider = ({ content }) => {
  let text
  if (content.name === 'felt') {
    text = 'How I Felt'
  } else if (content.name === 'motivated') {
    text = 'How Motivated'
  } else if (content.name === 'performed') {
    text = 'How I Performed'
  } else {
    text = content.name
  }

  return (
    <Flex>
      <Text fontSize="sm" fontWeight="bold">
        {text}
      </Text>
    </Flex>
  )
}
