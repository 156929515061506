import { Box, Card, CardHeader, Heading, CardBody, Text, Stack } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

const ClientGoals = ({ goals }) => {
  const renderGoals = () => {
    if (!goals.length) {
      return <Text fontSize="sm">Client currently hasn't setup any goals.</Text>
    }

    const inProgressGoals = goals.filter((goal) => goal.status === 'in_progress')
    const ideaGoals = goals.filter((goal) => goal.status === 'idea')
    const completedGoals = goals.filter((goal) => goal.status === 'complete')
    const cancelledGoals = goals.filter((goal) => goal.status === 'cancelled')

    return (
      <Stack spacing="4">
        {inProgressGoals.length && (
          <Box>
            <Heading size="xs">In Progress</Heading>
            {inProgressGoals.map((goal) => (
              <Text fontSize="sm">{goal.title}</Text>
            ))}
          </Box>
        )}

        {ideaGoals.length && (
          <Box>
            <Heading size="xs">Ideas</Heading>
            {ideaGoals.map((goal) => (
              <Text fontSize="sm">{goal.title}</Text>
            ))}
          </Box>
        )}

        {completedGoals.length && (
          <Box>
            <Heading size="xs">Completed</Heading>
            {completedGoals.map((goal) => (
              <Text fontSize="sm">{goal.title}</Text>
            ))}
          </Box>
        )}

        {cancelledGoals.length && (
          <Box>
            <Heading size="xs">Cancelled</Heading>
            {cancelledGoals.map((goal) => (
              <Text fontSize="sm">{goal.title}</Text>
            ))}
          </Box>
        )}
      </Stack>
    )
  }

  return (
    <Stack>
      <Card>
        <CardHeader paddingBottom="0">
          <Heading size="sm">Goals</Heading>
        </CardHeader>
        <CardBody pt="2" color="gray.500">
          {renderGoals()}
        </CardBody>
      </Card>
    </Stack>
  )
}
export default ClientGoals
