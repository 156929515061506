import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Spacer,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { Info } from 'react-feather'

import { MEASURE_TYPE } from 'domain/Widgets/constants'
import { EditWidgetModalActions, FieldErrorText } from 'domain/Widgets/components'

const MeasuresFormFields = ({
  widget,
  activityId,
  onCreateWidget,
  onUpdateWidget,
  onDeleteWidget,
  onEditWidgetClose,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const {
    register,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext()

  const isEditingActivity = !!activityId
  const isEditingOrNewWidget = isEditingActivity || !widget.new

  // Need to create form fields with unique id because a workout can have many widgets
  // and we don't want a clash for the same field with different widgets
  const name = `${widget.id}_measure_name`
  const units = `${widget.id}_measure_units`
  const defaultValue = `${widget.id}_measure_default_value`
  const defaultValue2 = `${widget.id}_measure_default_value_2`
  const defaultValue3 = `${widget.id}_measure_default_value_3`
  const smartDefault = `${widget.id}_measure_smart_default`

  // If the widget has been created then we need to populate the form field
  const defaultNameValue = isEditingOrNewWidget ? widget?.content?.name : null
  const defaultUnitsValue = isEditingOrNewWidget ? widget?.content?.units : null
  const defaultDefaultValue = isEditingOrNewWidget ? widget?.content?.default_value : null
  const defaultDefaultValue2 = isEditingOrNewWidget ? widget?.content?.default_value_2 : null
  const defaultDefaultValue3 = isEditingOrNewWidget ? widget?.content?.default_value_3 : null
  const defaultSmartDefaultValue = widget.new ? true : widget?.content?.smart_default

  const handleChange = () => {
    clearErrors()

    // values will be undefined if not set, and <empty string> after rerender
    const units_2_blank = [undefined, ''].includes(getValues(defaultValue2))
    const units_3_blank = [undefined, ''].includes(getValues(defaultValue3))
    const units_2 = units_2_blank ? null : 'sets'
    const units_3 = units_3_blank ? null : 'reps'

    const editWidgetData = {
      ...widget,
      content: {
        name: getValues(name),
        units: getValues(units),
        units_2: units_2,
        units_3: units_3,
        default_value: getValues(defaultValue),
        default_value_2: getValues(defaultValue2),
        default_value_3: getValues(defaultValue3),
        smart_default: getValues(smartDefault),
      },
    }

    if (!getValues(name)) {
      trigger(name, { shouldFocus: true })
      return
    }

    if (!getValues(units)) {
      trigger(units, { shouldFocus: true })
      return
    }

    if (widget.new) {
      onCreateWidget({ type: MEASURE_TYPE, widgetData: editWidgetData })
      onEditWidgetClose()
    } else {
      onUpdateWidget(editWidgetData)
      onEditWidgetClose()
    }
  }

  const FieldErrorTextUnits = ({ errors }) => {
    return (
      errors[units]?.message && (
        <Box>
          <FieldErrorText errors={errors} field={units} />
          <Text fontSize="xs">
            Tip: if you are measuring a count or number, use <UnitsUpdateLink value="#" /> or{' '}
            <UnitsUpdateLink value="num" />
          </Text>
        </Box>
      )
    )
  }

  const UnitsUpdateLink = ({ value }) => {
    return (
      <Text as="b" cursor="pointer" onClick={() => setValue(units, value)}>
        {value}
      </Text>
    )
  }

  return (
    <Box>
      <Stack>
        <HStack>
          <FormControl isRequired width="180px">
            <FormLabel fontSize="sm" mb="0">
              Name
            </FormLabel>
            <Input
              data-cy="measure-name"
              placeholder="Bench press"
              type="text"
              defaultValue={defaultNameValue}
              {...register(name, {
                required: "Name required, can't be blank",
              })}
            />
          </FormControl>

          <FormControl isRequired width="100px">
            <FormLabel fontSize="sm" mb="0">
              Units
            </FormLabel>
            <Input
              data-cy="measure-units"
              placeholder="kg"
              defaultValue={defaultUnitsValue}
              {...register(units, {
                required: "Units required, can't be blank",
              })}
            />
          </FormControl>

          <FormControl width="80px">
            <FormLabel fontSize="sm" mb="0">
              Default
            </FormLabel>
            <Input
              defaultValue={defaultDefaultValue}
              {...register(defaultValue)}
              placeholder="10"
              type="number"
            />
          </FormControl>

          <FormControl width="150px">
            <FormLabel fontSize="sm" mb="0">
              <Box display="flex">
                Smart Default
                <Box ml="1">
                  <Tooltip
                    label={
                      'The default value for the measure in each workout will be the value recorded the previous time the workout was completed.'
                    }
                  >
                    <Icon
                      as={Info}
                      w="4"
                      h="4"
                      color="gray.500"
                      ml="1"
                      _hover={{ cursor: 'pointer', color: 'brand.500' }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </FormLabel>
            <Checkbox
              defaultChecked={defaultSmartDefaultValue}
              colorScheme="brand"
              background="white"
              size="lg"
              height="40px"
              width="auto"
              type="checkbox"
              {...register(smartDefault)}
            />
          </FormControl>
        </HStack>

        <HStack>
          <FormControl width="80px">
            <FormLabel fontSize="sm" mb="0">
              Sets
            </FormLabel>
            <Input
              defaultValue={defaultDefaultValue2}
              {...register(defaultValue2)}
              placeholder="3"
              type="number"
            />
          </FormControl>
          <FormControl width="80px">
            <FormLabel fontSize="sm" mb="0">
              Reps
            </FormLabel>
            <Input
              defaultValue={defaultDefaultValue3}
              {...register(defaultValue3)}
              placeholder="8"
              type="number"
            />
          </FormControl>
        </HStack>

        <Text fontSize="xs">
          Sets and Reps are completely optional and can be updated when completing this workout. If
          you do decided to utilise them, you'll get some bonus metrics to your track progress.
        </Text>
      </Stack>
      <Box mt="2">
        <FieldErrorText errors={errors} field={name} />
        <FieldErrorTextUnits errors={errors} />
      </Box>
      <EditWidgetModalActions {...{ widget, handleChange, onDeleteWidget, onEditWidgetClose }} />
    </Box>
  )
}

export default MeasuresFormFields
