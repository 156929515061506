import React from 'react'
import { Icon } from '@chakra-ui/react'

import { widgetIconMap } from 'domain/Widgets/mappers'

const WidgetIcon = ({ type, ...props }) => {
  const IconComponent = widgetIconMap[type]

  return <Icon as={IconComponent} {...props} />
}

export default WidgetIcon
