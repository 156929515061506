import React from 'react'
import { NavLink } from 'react-router-dom'
import { Flex, Container, Text, Link } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import BusinessStats from './Components/BusinessStats'
import { sortClientsByType } from './helpers'

const CoachingBilling = () => {
  const { data, isLoading, hasError } = useQuery(
    `/api/v1/coaching/connections?list_clients=true&metrics=true`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { pendingClients, invitedClients, activeClients, inactiveClients } = sortClientsByType(data)

  const hasClients = data.length > 0

  const renderZeroState = () => {
    if (hasClients) {
      return null
    }
    return (
      <>
        <Text color="gray.500" textAlign="center">
          You currently haven't added any clients.
          <br />
          Add your first{' '}
          <Link as={NavLink} to={`/coaching/clients`}>
            client
          </Link>{' '}
          to get started.
        </Text>
      </>
    )
  }

  return (
    <Container maxW="container.xl" py="6" mb="28">
      <Flex justifyContent="center">
        {renderZeroState()}
        {hasClients && (
          <BusinessStats {...{ pendingClients, invitedClients, activeClients, inactiveClients }} />
        )}
      </Flex>
    </Container>
  )
}

export default CoachingBilling
