import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { useQuery, useImpersonate } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import WorkoutContainer from 'domain/Workouts/WorkoutComponents/WorkoutContainer'
import TitleHeader from 'domain/Workouts/WorkoutComponents/TitleHeader'
import Duration from 'domain/Workouts/WorkoutComponents/Duration'
import Description from 'domain/Workouts/WorkoutComponents/Description'
import Details from 'domain/Workouts/WorkoutComponents/Details'
import OwnerNotes from 'domain/Workouts/WorkoutComponents/OwnerNotes'
import WidgetContainer from 'domain/Workouts/WidgetContainer'

import ActionsMenu from './Components/ActionsMenu'

const CoachingWorkout = () => {
  const { id } = useParams()

  const impersonateData = useImpersonate((state) => state.impersonateData)

  const { data, isLoading, hasError } = useQuery(`api/v1/templated_activities/${id}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(data).length) {
    return <Error />
  }

  const hasWidgets = data.templated_widgets.length > 0

  const isSharable = impersonateData.impersonating

  return (
    <WorkoutContainer>
      <Box>
        <InnerPageWrapperActions backAction="/coaching/workouts">
          <ActionsMenu {...{ id, isSharable }} />
        </InnerPageWrapperActions>

        <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
          <TitleHeader item={data} />
          <Box marginLeft="8%" maxWidth="670px">
            <Duration item={data} />
            <Description item={data} />
            {hasWidgets && <WidgetContainer widgets={data.templated_widgets} />}
            <Details item={data} />
            <OwnerNotes item={data} />
          </Box>
        </Box>
      </Box>
    </WorkoutContainer>
  )
}

export default CoachingWorkout
