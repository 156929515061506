import React from 'react'
import { camelCase } from 'lodash'
import { ResponsiveContainer, Legend, PieChart, Pie, Cell, Tooltip } from 'recharts'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  // TODO change between white/black depending on underlying colour
  const fill = 'white'

  return (
    <text x={x} y={y} fill={fill} textAnchor={'middle'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const CategoryPieChart = ({ data, breakdown = 'time' }) => {
  if (!data.length) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height={450}>
      <PieChart>
        <Legend layout="horizontal" />
        <Pie data={data} labelLine={false} label={renderCustomizedLabel} dataKey={breakdown}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.hex_colour} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default CategoryPieChart
