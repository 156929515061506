import React from 'react'
import { Box, Container } from '@chakra-ui/react'

const WorkoutContainer = ({ children }) => {
  return (
    <Container maxW="container.lg">
      <Box>{children}</Box>
    </Container>
  )
}

export default WorkoutContainer
