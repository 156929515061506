import { useNavigate } from 'react-router-dom'
import { Box, Link, Spacer } from '@chakra-ui/react'
import { ChevronLeft } from 'react-feather'

const InnerPageWrapperActions = ({ backAction = -1, children }) => {
  const navigate = useNavigate()

  return (
    <Box display="flex" alignItems="center" my="8">
      <Link onClick={() => navigate(backAction)} color="gray">
        <ChevronLeft size="18px" />
      </Link>
      <Spacer />
      {children}
    </Box>
  )
}

export default InnerPageWrapperActions
