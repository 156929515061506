import React from 'react'
import { Box, Text, Heading } from '@chakra-ui/react'

const FormHeading = ({ heading, children }) => {
  return (
    <Box textAlign="center">
      <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold">
        {heading}
      </Text>
      {children && (
        <Heading fontWeight="normal" size="sm" color="gray.500" mt="2">
          {children}
        </Heading>
      )}
    </Box>
  )
}

export default FormHeading
