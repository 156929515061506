import logo from 'assets/logo.png'
import stagingLogo from 'assets/logo-staging.png'
import localLogo from 'assets/logo-local.png'

const { REACT_APP_SEQ_ENV } = process.env

export const ENV_LOGO =
  REACT_APP_SEQ_ENV === 'production'
    ? logo
    : REACT_APP_SEQ_ENV === 'staging'
    ? stagingLogo
    : REACT_APP_SEQ_ENV === 'development'
    ? localLogo
    : localLogo
