import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { format, isToday, isSameMonth } from 'date-fns'

const CalendarDay = ({ day, firstDayCurrentMonth }) => {
  let opacityOfDay = '1'
  if (firstDayCurrentMonth) {
    opacityOfDay = isSameMonth(day, firstDayCurrentMonth) ? '1' : '0.6'
  }

  return (
    <Box minW="60px">
      {isToday(day) ? (
        <Text
          fontSize="x-small"
          fontWeight="bold"
          minWidth="max-content"
          background="brand.500"
          color="white"
          padding="1"
          marginTop="-1"
        >
          {format(day, 'EEE do')}
        </Text>
      ) : (
        <Text fontSize="x-small" minW="12" opacity={opacityOfDay}>
          {format(day, 'EEE do')}
        </Text>
      )}
    </Box>
  )
}

export default CalendarDay
