import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Center, Link, Text } from '@chakra-ui/react'

const SubscriptionCancelled = () => {
  const Para = ({ children, ...props }) => {
    return (
      <Text mt="4" {...props}>
        {children}
      </Text>
    )
  }

  return (
    <Center mt="12">
      <Box width="600px" margin="4">
        <Text textAlign="center" fontSize="2xl">
          Subscription Cancelled
        </Text>

        <Box boxShadow="md" p="6" rounded="md" bg="white" mt="4">
          <Para textAlign="center">😢</Para>
          <Para>
            No seriously, we totally understand that circumstances and priorities change — so no
            hard feelings on our end.
          </Para>

          <Para fontWeight="bold" textAlign="center">
            Thank you for taking the time to try Sequence
          </Para>
          <Para>
            Our mission is to empower you to become a better climber by making a world class
            training platform. We're heavily committed to improving Sequence as much as we can, so
            hopefully you'll stop by again to see if it suits you in the future :)
          </Para>
          <Para>
            Your data is still sitting there waiting for you if you do decide to come back. You can
            also{' '}
            <Link as={NavLink} to="/settings/account">
              export your data
            </Link>{' '}
            if you want to. Or if you prefer, you can instead{' '}
            <Link as={NavLink} to="/settings/delete-account">
              delete your account
            </Link>{' '}
            and all of the data associated with it.
          </Para>
          <Para>
            If you have any questions or feedback please don't hesitate to reach out to us, we'd
            love to hear about your experience using Sequence and how we could make a better
            platform.
          </Para>
          <Para>
            Cheers,
            <br />
            The team at Sequence
          </Para>
        </Box>
      </Box>
    </Center>
  )
}

export default SubscriptionCancelled
