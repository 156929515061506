import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Box, Flex, Grid, Spacer } from '@chakra-ui/react'
import { isSameDay, parseISO } from 'date-fns'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { dateToString } from 'helpers/utils'
import { useQuery } from 'hooks'

import DayActions from './DayActions'
import DeleteWorkout from './DeleteWorkout'
import MenstrualCycleModal from './MenstrualCycleModal'
import DroppableDay from './DroppableDay'
import DroppableNote from './DroppableNote'
import GridDay from './GridDay'
import { isRestDay } from './helpers'

import DailyMetricsPlannerModal from 'domain/DailyMetrics/DailyMetricsPlannerModal'

import CalendarDay from './components/CalendarDay'
import ExtraDayDetails from './components/ExtraDayDetails'
import WeekLabel from './components/WeekLabel'

import { usePlannerMethods } from './hooks'

const WeekView = ({ daysOfWeek }) => {
  const date = dateToString(daysOfWeek[0])
  const { data, isLoading, hasError, mutate } = useQuery(
    `api/v1/schedules/user_week?start_date_of_period=${date}`
  )

  const {
    refreshData,
    renderDraggableWorkout,
    showDayActions,
    handleAddWorkoutToDay,
    selectedDay,
    isDayActionsModalOpen,
    setIsDayActionsModalOpen,
    handleOnDragEnd,
    handleDeleteScheduledActivity,
    isConfirmDeleteModalOpen,
    setIsConfirmDeleteModalOpen,
    openMensutralCycleForDay,
    isMenstrualCycleModalOpen,
    setIsMenstrualCycleModalOpen,
  } = usePlannerMethods(data)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const WeekDay = ({ provided, day }) => {
    return (
      <GridDay
        isRestDay={isRestDay(day, data)}
        day={day}
        provided={provided}
        data={data}
        refreshData={refreshData}
        showDayActions={showDayActions}
        renderDraggableWorkout={renderDraggableWorkout}
      />
    )
  }

  const renderWeekDays = () => {
    return daysOfWeek.map((day, index) => {
      const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

      return (
        <Box key={day.toString()} minW="130px">
          <WeekLabel refresh={refreshData} {...{ day, dayData }} />
          <Flex alignItems="center" height="6">
            <CalendarDay refresh={refreshData} {...{ day, dayData }} />
            <Spacer />
            <ExtraDayDetails {...{ data, day, openMensutralCycleForDay }} />
          </Flex>

          <DroppableNote {...{ index, day, data }} />

          <DroppableDay index={index} day={day} Day={WeekDay} />
        </Box>
      )
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Grid templateColumns="repeat(7, 1fr)" gap={6}>
          {renderWeekDays()}
        </Grid>
      </DragDropContext>
      {isDayActionsModalOpen && (
        <DayActions
          refresh={refreshData}
          day={selectedDay}
          isOpen={isDayActionsModalOpen}
          onClose={() => setIsDayActionsModalOpen(false)}
          onAddWorkout={handleAddWorkoutToDay}
        />
      )}

      <DeleteWorkout
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onAccept={handleDeleteScheduledActivity}
      />
      <MenstrualCycleModal
        isOpen={isMenstrualCycleModalOpen}
        onClose={() => setIsMenstrualCycleModalOpen(false)}
        day={selectedDay}
        data={data}
        refreshPlannerData={() => mutate()}
      />
      <DailyMetricsPlannerModal />
    </>
  )
}

export default WeekView
