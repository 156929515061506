import { Draggable } from 'react-beautiful-dnd'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { Box } from '@chakra-ui/react'

import ActivityDetails from 'domain/Planner/components/ActivityDetails'
import { usePlannerMethods } from 'domain/Planner/hooks'

const DraggableWorkout = ({ item, idx, handleContextMenuDelete }) => {
  const { refreshData } = usePlannerMethods()

  return (
    <Draggable key={item.id} draggableId={item.id} index={idx}>
      {(provided, snapshot) => {
        const getStyle = (style, snapshot) => {
          if (!snapshot.isDropAnimating) {
            return style
          }
          return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
          }
        }

        return (
          <Box
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
            mb="1.5"
            _first={{ marginTop: 1.5 }}
            _last={{ marginBottom: 0 }}
          >
            <ContextMenuTrigger id={item.id}>
              <Box
                bg="white"
                border="1px"
                borderColor="gray.100"
                _hover={{ cursor: 'grab' }}
                {...provided.dragHandleProps}
              >
                <ActivityDetails
                  id={item.id}
                  name={item.activity_name}
                  category={item.category_name}
                  categoryHexColour={item.category_hex_colour}
                  description={item.activity_description}
                  duration={item.actual_duration || item.duration}
                  completed={item.completed}
                  notes={item.notes}
                  incomplete={item.incomplete}
                  refresh={refreshData}
                  linkedGoals={item.linked_goals}
                />
              </Box>
            </ContextMenuTrigger>

            <Box
              id={item.id}
              as={ContextMenu}
              background="gray.100"
              borderRadius="sm"
              border="1px"
              borderColor="gray.300"
              boxShadow="lg"
              p="2"
            >
              <Box
                as={MenuItem}
                _hover={{ background: 'brand.500', color: 'white' }}
                cursor="pointer"
                px="2"
                py="1"
                borderRadius="sm"
                data={{
                  action: 'remove',
                  id: item.id,
                  isCompleted: item.completed,
                  hasNotes: !!item.notes,
                }}
                onClick={handleContextMenuDelete}
              >
                Remove from schedule
              </Box>
            </Box>
          </Box>
        )
      }}
    </Draggable>
  )
}

export default DraggableWorkout
