import React, { useContext } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Center, Alert, AlertTitle, AlertDescription, Link } from '@chakra-ui/react'

import { UserContext } from 'providers/UserProvider'

import {
  ACCOUNT_PAGE,
  SUBSCRIPTION_CANCELLED_PAGE,
  SUBSCRIPTION_CREATE_PAGE,
} from 'helpers/constants'

const ExpiredOverlay = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { planContext } = useContext(UserContext)
  const [userPlan] = planContext

  const isTrial = userPlan?.slice(0, 5) === 'Trial'
  const onSubscriptionCancelledPage = location.pathname.includes(SUBSCRIPTION_CANCELLED_PAGE)
  const onSubscriptionCreatePage = location.pathname.includes(SUBSCRIPTION_CREATE_PAGE)

  if (onSubscriptionCancelledPage) {
    return null
  }

  if (onSubscriptionCreatePage) {
    return null
  }

  const handleExpiredOverlayClick = () => {
    if (isTrial) {
      navigate(SUBSCRIPTION_CREATE_PAGE)
    }
  }

  return (
    <Center>
      <Alert
        status="warning"
        borderWidth="1px"
        borderRadius="lg"
        maxWidth="500px"
        cursor="pointer"
        boxShadow="dark-lg"
        style={{
          display: 'block',
          position: 'fixed',
          bottom: 50,
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 20,
          paddingBottom: 20,
          textAlign: 'center',
        }}
        onClick={handleExpiredOverlayClick}
      >
        {isTrial ? (
          <>
            <AlertTitle mb="1">Trial Limit Reached</AlertTitle>
            <AlertDescription display="block">
              Since you have used all the scheduled workouts allowed for the trial, you need to
              subscribe to continue using Sequence.
            </AlertDescription>
          </>
        ) : (
          <>
            <AlertTitle mb="1">Subscription Cancelled</AlertTitle>
            <AlertDescription display="block">
              Please visit your{' '}
              <Link as={NavLink} to={ACCOUNT_PAGE} style={{ color: 'black' }}>
                account page
              </Link>{' '}
              and reactivate your subscription to continue using Sequence.
            </AlertDescription>
          </>
        )}
      </Alert>
    </Center>
  )
}

export default ExpiredOverlay
