import React from 'react'
import {
  AspectRatio,
  HStack,
  Box,
  Center,
  SimpleGrid,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  ListItem,
  UnorderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Link as ChakraLink,
  VStack,
} from '@chakra-ui/react'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { Gallery } from '../components/Gallery'
import Navigation from '../components/Navigation'
import { BuyPlanButton } from '../components/BuyPlanButton'
import { useQueryProviderPlans } from '../hooks'

import { LC_URL, LC_PROVIDER, LC_MOONBOARD_BENCHMARK_BUSTER_CODE } from 'domain/Plans/constants'

import LeeCujesHero3 from './assets/lee-cujes-hero-3.jpeg'
import LeeCujesHero5 from './assets/lee-cujes-hero-5.png'

import Slide1 from './assets/mbb-slide-1.png'
import Slide2 from './assets/mbb-slide-2.png'
import Slide3 from './assets/mbb-slide-3.png'
import Slide5 from './assets/mbb-slide-5.png'
import Slide6 from './assets/mbb-slide-6.png'
import Slide7 from './assets/mbb-slide-7.png'
import Slide8 from './assets/mbb-slide-8.png'

export const images = [
  {
    id: '01',
    src: Slide1,
    alt: 'Slide 1',
  },
  {
    id: '02',
    src: Slide2,
    alt: 'Slide 2',
  },
  {
    id: '03',
    src: Slide3,
    alt: 'Slide 3',
  },
  {
    id: '04',
    src: Slide5,
    alt: 'Slide 5',
  },
  {
    id: '05',
    src: Slide6,
    alt: 'Slide 6',
  },
  {
    id: '06',
    src: Slide7,
    alt: 'Slide 7',
  },
  {
    id: '07',
    src: Slide8,
    alt: 'Slide 8',
  },
]

const LeeCujesBenchmarkBuster = () => {
  const links = [
    {
      name: 'Lee Cujes Plans',
      url: `/plans/${LC_URL}`,
    },
  ]

  const { data, isLoading, hasError } = useQueryProviderPlans({
    provider: LC_PROVIDER,
    code: LC_MOONBOARD_BENCHMARK_BUSTER_CODE,
  })

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const renderPaymentButton = () => {
    const { purchased } = data[0]

    if (purchased) {
      return (
        <VStack>
          <Text color="brand.500" fontWeight="bold">
            Already purchased!
          </Text>
          <ChakraLink
            href="/plans/purchased-plans"
            color="gray.500"
            fontWeight="light"
            fontSize="small"
            _hover={{ textDecoration: 'none', color: 'brand.500' }}
            end
          >
            View purchased plan
          </ChakraLink>
        </VStack>
      )
    }

    return <BuyPlanButton plan={data[0]} />
  }

  return (
    <>
      <Navigation {...{ links }} />
      <Box
        as="section"
        bg="gray.800"
        py="12"
        position="relative"
        h={{ base: '560px', md: '640px' }}
        bgImage={LeeCujesHero3}
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
          h: 'full',
          bg: 'blackAlpha.700',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center flexDirection="column" textAlign="center" color="white" h="full">
            <Heading size="2xl" fontWeight="extrabold" color="#fff">
              Moonboard Benchmark Buster Plan
            </Heading>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              Designed specifically for the Moonboard
            </Text>
          </Center>
        </Box>
      </Box>

      <Container
        pt={{ base: '16', md: '24' }}
        pb={{ base: '0', md: '0', sm: '16' }}
        maxWidth="container.lg"
      >
        <Stack align="end">
          <HStack>{renderPaymentButton()}</HStack>
        </Stack>
      </Container>
      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                I designed my Benchmark Buster plan based on my own training protocols on my
                Moonboards over the past five years.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                It can be adapted to almost any climbing level. For those who are a bit unsure about
                the Moonboard, or training boards in general, this plan will help show you exactly
                what to do with your sessions, as well as providing supplementary (non-Moonboard)
                training that will support what you are doing on the board. Get stronger, stay
                injury free.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                This training plan should be dropped into your yearly training schedule as a defined
                5-week block. Don’t attempt to continue repeating it indefinitely. Without
                introducing different stimulus, you will stagnate, plateau and in the worst case,
                get injured. A good block length for this program is between 5 to 10 weeks. It’s
                feasible to do two or three blocks of this training (10-15 weeks) in each 12 month
                period.
              </Text>
            </Stack>
          </Stack>
          <Stack spacing="3" justifyContent="center" align="center">
            <UnorderedList fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
              <ListItem>5 weeks or 10 weeks — you choose</ListItem>
              <ListItem>Moonboard session plans, adaptable to your level</ListItem>
              <ListItem>Volume and projecting sessions</ListItem>
              <ListItem>Supplementary training</ListItem>
              <ListItem>Fingerboard sessions</ListItem>
              <ListItem>Weekly videos</ListItem>
              <ListItem>‘Explainer’ videos</ListItem>
            </UnorderedList>
          </Stack>
        </SimpleGrid>
      </Container>

      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }} align="center">
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/wPbMfUyYQJE?rel=0"
            title="Mooonboard Benchmark Buster Plan"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Box>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                Goals of this plan
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                <Text as="span" fontWeight="bold">
                  Primary goal:
                </Text>{' '}
                Smash through those benchmarks!
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                <Text as="span" fontWeight="bold">
                  Underlying benefits:
                </Text>{' '}
                Smashing benchmarks and developing board fitness develops many useful traits for a
                rock climber.
              </Text>

              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                <Text as="span" fontWeight="bold">
                  Also:
                </Text>{' '}
                Finger strength (particularly in unusual and varied positions not achievable on a
                fingerboard), recruitment (speed of contraction on holds), core tension and general
                ‘snappiness’, which is extremely useful for route climbers who often tend towards a
                slower, more static style.
              </Text>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={LeeCujesHero5} alt="Lee Cujes" />
          </AspectRatio>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Gallery images={images} />
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                You’ll be undertaking an introductory week, with the following 3 weeks building in
                training load and intensity, followed by a de-load week where the magic happens.
              </Text>
              <Text fontWeight="bold">Weekly time commitment:</Text>
              <UnorderedList fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                <ListItem>
                  2 - 3 sessions on the Moonboard per week. Session length varies between 1.5 to 2
                  hours.
                </ListItem>
                <ListItem>
                  Supplementary strength and flexibility sessions throughout the week, no more than
                  1 hour a week.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box bg="white">
        <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing="3" align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Ready to smash some benchmarks?</Heading>
              <Text color="on-accent-muteed" maxW="2xl" textAlign="center" fontSize="xl">
                Smashing benchmarks and developing board fitness develops many useful traits for a
                rock climber.
              </Text>
            </Stack>
            <HStack justify="center">{renderPaymentButton()}</HStack>
          </Stack>
        </Container>
      </Box>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Grid templateColumns="repeat(3, 1fr)" gap={16}>
          <GridItem colSpan={1}>
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>FAQs</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Everything you need to know about the plan and billing.
                </Text>
              </Stack>
            </Stack>
          </GridItem>
          <GridItem colSpan={2}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Accordion allowMultiple>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>What equipment do I need?</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    You’ll need ready access to a Moonboard as you’ll be climbing on the board twice
                    a week. Not all Moonboards are created equal and specifically, there are
                    currently 6 different ‘sets’:
                    <UnorderedList>
                      <ListItem>2016 @ 40 degrees</ListItem>
                      <ListItem>2017 @ 25 degrees</ListItem>
                      <ListItem>2017 @ 40 degrees</ListItem>
                      <ListItem>2019 @ 25 degrees</ListItem>
                      <ListItem>2019 @ 40 degrees</ListItem>
                      <ListItem>Moonboard Mini</ListItem>
                    </UnorderedList>
                    Most boards are not adjustable, hence it’s important to determine which of the
                    sets you have access to.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Is this plan good for my level?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    The plan is adaptable to nearly all climbing levels, provided you have been
                    climbing regularly for at least a year. V0-V2 level climbers should only use a
                    25 degree board. 40 degree boards are suitable for V3+ climbers. Another good
                    test to determine if this plan is suitable is seeing if you can climb from the
                    bottom to the top of the Moonboard using any holds. If yes, good. If no, keep
                    climbing and circle back to this plan at a later time.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>Your goals?</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    There’s little point investing time into a plan that does not align with your
                    goals. If your goals include...
                    <UnorderedList>
                      <ListItem>Improving strength for bouldery cruxes</ListItem>
                      <ListItem>Developing dynamism</ListItem>
                      <ListItem>Increasing core strength and tension</ListItem>
                    </UnorderedList>
                    …then this plan is for you.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>How is the plan delivered</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    After you have successfully purchased the plan, you will be able to add the plan
                    to your Sequence planner.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Can I do the plan more than once?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    You pay for the plan once but keep access to it forever. You can choose to re-do
                    the plan for as long as you maintain your Sequence membership.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading size={{ base: 'sm', md: 'base' }}>
                        Is it available on mobile?
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    100%. As long as you have the Sequence mobile app installed, you can access your
                    current schedule and log your training sessions.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </GridItem>
        </Grid>
      </Container>

      <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }} align="center" justifyContent="center">
          <Stack spacing={{ base: '4', md: '5' }} maxW="xl">
            <Box align="center">
              <Stack spacing="3">
                <Heading size={{ base: 'sm', md: 'md' }}>Still have questions?</Heading>

                <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Can't find the answer you're looking for? Our team is happy to answer your
                  questions.
                </Text>
                <HStack justifyContent="center">
                  <ChakraLink
                    color="brand.500"
                    fontWeight="bold"
                    href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
                  >
                    Send us an email
                  </ChakraLink>
                </HStack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={LeeCujesHero3}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />
    </>
  )
}

export default LeeCujesBenchmarkBuster
