import React from 'react'
import { Box } from '@chakra-ui/react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const NotificationContainer = () => {
  return (
    <Box data-cy="notification">
      <ToastContainer hideProgressBar />
    </Box>
  )
}

export default NotificationContainer
