import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Button, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'

import { useImpersonate, useStateManager } from 'hooks'

import { FormHeading } from 'components/Forms'
import ActionLink from 'components/General/ActionLink'

import Notify from 'components/Notification'
import { AuthWrapper, EmailField } from 'domain/Auth/Elements'

const schema = z.object({
  email: z.string().email({ message: 'Email required' }),
  password: z.string().min(1, { message: 'Password required' }),
})

const SignIn = () => {
  let navigate = useNavigate()

  const { clearAll, setSignin, setAccountStatus } = useStateManager()
  const resetImpersonateData = useImpersonate((state) => state.resetImpersonateData)

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  // This would fix mismatched nav header when manualy visiting /sign-in
  // but it means that visiting /sign-in essentially does a sign out
  // useEffect(() => {
  //   clearAll();
  // });

  const onSubmit = ({ email, password }) => {
    setLoading(true)
    securedApi
      .post('/signin', { email, password })
      .then((response) => {
        signInSuccessful(response)
      })
      .catch((error) => {
        signInFailed(error)
      })
  }

  const signInSuccessful = async (response) => {
    if (!response.data.csrf) {
      await signInFailed(response)
      return
    }

    setSignin(response.data)
    setAccountStatus(response.data.user)

    resetImpersonateData()

    const { id, name, email, plan, overdue } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    if (overdue) {
      navigate('/settings/account')
    } else {
      navigate('/dashboard')
    }
  }

  const signInFailed = async (error) => {
    setLoading(false)

    clearAll()

    Notify({
      content: 'Error signing in. Please check your details and try again.',
      type: 'error',
    })
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading heading="Sign in to your account">
          Or <ActionLink to="/sign-up">sign up for an account</ActionLink>
        </FormHeading>

        <EmailField {...{ register, errors }} marginTop="8" />

        <FormControl marginBottom="4" isInvalid={errors.password}>
          <FormLabel>Password</FormLabel>

          <Input
            type="password"
            {...register('password', {
              required: true,
              minLength: 6,
            })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>

        <Box marginBottom="4">
          <ActionLink to="/forgot-password">Forgot Password?</ActionLink>
        </Box>

        <Button
          type="submit"
          colorScheme="brand"
          width="100%"
          disabled={loading}
          isLoading={loading}
        >
          Sign in
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default SignIn
