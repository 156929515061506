import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

import { UserContext } from 'providers/UserProvider'

import { useCoaching, useIsMobile } from 'hooks'

import Navigation from 'domain/Navigation'
import CoachingClientNavigation from 'domain/Navigation/CoachingClientNavigation'

import {
  SETTINGS_PAGE,
  SUBSCRIPTION_CANCELLED_PAGE,
  SUBSCRIPTION_CREATE_PAGE,
} from 'helpers/constants'
import PublicHeader from 'domain/Navigation/PublicHeader'

const TOKEN = 'csrf'

export const ViewWrapper = ({ children, hideNavigation }) => {
  const { isMobile } = useIsMobile()
  const location = useLocation()
  const { validContext } = useContext(UserContext)
  const [userValid] = validContext

  // must explicitly test for false (to ignore undefined and null)
  const showExpiredOverlay = userValid === false
  const onSettingsPage = location.pathname.includes(SETTINGS_PAGE)
  const onSubscriptionCancelledPage = location.pathname.includes(SUBSCRIPTION_CANCELLED_PAGE)
  const onSubscriptionCreatePage = location.pathname.includes(SUBSCRIPTION_CREATE_PAGE)
  const applyOpacity =
    showExpiredOverlay &&
    !onSettingsPage &&
    !onSubscriptionCancelledPage &&
    !onSubscriptionCreatePage

  if (hideNavigation) return children

  return (
    <Flex opacity={applyOpacity ? 0.4 : 1} flexDirection={isMobile ? 'column' : 'row'}>
      {children}
    </Flex>
  )
}

export const checkAuth = () => {
  if (localStorage.getItem(TOKEN) && localStorage.signedIn) {
    return true
  }
  return false
}

export const PrivateRouteWrapper = ({ children, hideNavigation }) => {
  const { isMobile } = useIsMobile()
  const location = useLocation()
  const validSignin = checkAuth()

  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)

  if (validSignin) {
    return (
      <ViewWrapper {...{ hideNavigation }}>
        {!hideNavigation && <Navigation />}
        <Flex height="100vh" flexGrow="1" flexDirection="column" pt={isMobile ? '57px' : '0'}>
          {isCoachingInterfaceEnabled && <CoachingClientNavigation />}
          <Flex height="100vh" overflow="auto" flexGrow="1" flexDirection="column">
            {children}
          </Flex>
        </Flex>
      </ViewWrapper>
    )
  } else {
    return (
      <Navigate
        to={{
          pathname: '/sign-in',
          state: { from: location },
        }}
      />
    )
  }
}

export const PublicRouteWrapper = ({ children }) => {
  return (
    <ViewWrapper>
      <Flex flexDirection="column" flexGrow="1">
        <PublicHeader />
        {children}
      </Flex>
    </ViewWrapper>
  )
}
