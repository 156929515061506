import { create } from 'zustand'

import securedApi from 'backend/axios'
import { processGetError } from 'helpers/utils'

const useHasLogbook = create((set) => ({
  hasLogbook: false,
  fetch: async () => {
    return securedApi
      .get('/api/v1/widget_logbooks')
      .then(({ data }) => {
        set({ hasLogbook: data.length > 0 })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
}))

export default useHasLogbook
