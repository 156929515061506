import React, { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { Box, Container, Link, Text, Tooltip, Divider, Stack, Badge } from '@chakra-ui/react'
import { Edit } from 'react-feather'

import securedApi from 'backend/axios'

import { processGetError, tzSafeDateFormat } from 'helpers/utils'

import LoadingSpinner from 'components/LoadingSpinner'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import MilestoneMetrics from '../Metrics/MilestoneMetrics'

const Milestone = ({ milestoneId }) => {
  const { id } = useParams()
  const [milestone, setMilestone] = useState({})
  const [milestoneData, setMilestoneData] = useState({})

  const isModal = milestoneId !== undefined
  const idToUse = isModal ? milestoneId : id

  useEffect(() => {
    securedApi
      .get(`api/v1/scheduled_notes/${idToUse}`)
      .then(({ data }) => setMilestone(data))
      .catch((error) => processGetError(error))
  }, [idToUse])

  useEffect(() => {
    const date = milestone?.scheduled_at

    if (!Object.keys(milestone).length) {
      return
    }

    securedApi
      .get(`api/v1/metrics/milestone?date=${date}&weeks=6`)
      .then(({ data }) => setMilestoneData(data))
      .catch((error) => processGetError(error))
  }, [milestone])

  const renderMilestone = () => {
    if (!Object.keys(milestone).length) {
      return <LoadingSpinner />
    }

    const formattedDate = tzSafeDateFormat(milestone.scheduled_at, 'd/M/yy')

    return (
      <Box background="white">
        <Box p="4" pb="0">
          <Text fontSize="2xl" color="black">
            {milestone.title}
          </Text>

          <Text fontSize="sm" color="gray" display="inline-block">
            {formattedDate}
          </Text>

          <Text
            maxW="500px"
            color="gray"
            my="4"
            dangerouslySetInnerHTML={{
              __html: milestone?.description,
            }}
          />

          {milestone.goals && milestone.goals.length > 0 && (
            <Box mt="2">
              <Divider />
              <Text fontWeight="medium" fontSize="sm" mt="2">
                Linked Goals
              </Text>
              <Stack direction="row" mt="2">
                {milestone.goals.map((goal) => (
                  <Badge key={goal.id} variant="outline">
                    {goal.title}
                  </Badge>
                ))}
              </Stack>
            </Box>
          )}
        </Box>

        <Box p="4" pt="0">
          <MilestoneMetrics data={milestoneData} />
        </Box>
      </Box>
    )
  }

  if (isModal) {
    return <Box>{renderMilestone()}</Box>
  }

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions backAction="/milestones">
        <Tooltip label="Edit milestone">
          <Link
            as={NavLink}
            to={`/milestone/edit/${milestone.id}`}
            variant="brand"
            fontWeight="bold"
          >
            <Edit size="18px" />
          </Link>
        </Tooltip>
      </InnerPageWrapperActions>
      <Box marginBottom="20">{renderMilestone()}</Box>
    </Container>
  )
}

export default Milestone
