import React from 'react'
import {
  Box,
  Card,
  CardBody,
  Stack,
  HStack,
  Text,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'

const ClientInfo = ({ title, data }) => {
  if (!data.length) {
    return null
  }

  return (
    <Card>
      <CardBody>
        <Stat>
          <StatLabel>{title}</StatLabel>
          <StatNumber color="gray.600">{data.length}</StatNumber>
        </Stat>
      </CardBody>
    </Card>
  )
}

const BusinessStats = ({ pendingClients, invitedClients, activeClients, inactiveClients }) => {
  const calculateCost = activeClients.length * 5
  return (
    <Box>
      <HStack justifyContent="center">
        <ClientInfo title="Pending Clients" data={pendingClients} />
        <ClientInfo title="Invited Clients" data={invitedClients} />
        <ClientInfo title="Active Clients" data={activeClients} />
        <ClientInfo title="Inactive Clients" data={inactiveClients} />
      </HStack>
      <Stack>
        <Text mt="2" fontSize="sm" textAlign="center">
          Total Cost: ${calculateCost} per month
        </Text>
        <Text fontSize="xs" fontStyle="italic" textAlign="center">
          An invoice will be sent on the 1st of every month. This is currently not an automated
          payment process.
        </Text>
      </Stack>
    </Box>
  )
}

export default BusinessStats
