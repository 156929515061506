import React from 'react'
import { Text } from '@chakra-ui/react'

const FieldErrorText = ({ errors, field }) => {
  return (
    errors[field]?.message && (
      <Text color="red.500" fontSize="sm" fontWeight="bold">
        {errors[field]?.message}
      </Text>
    )
  )
}

export default FieldErrorText
