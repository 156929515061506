import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  AspectRatio,
  SimpleGrid,
  Box,
  Container,
  HStack,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  Divider,
  Link as ChakraLink,
} from '@chakra-ui/react'

import TrainingHero from '../assets/training-hero.jpg'

import ClimbStrongBio from './assets/climb-strong-bio.png'
import ClimbStrongHero from './assets/climb-strong-hero-1.jpeg'
import ClimbStrongHero3 from './assets/climb-strong-hero-3.jpeg'
import ClimbStrongHero4 from './assets/climb-strong-hero-4.jpeg'
import ClimbStrongHero5 from './assets/climb-strong-hero-5.jpeg'

import { CS_BOULDERING_PERFORMANCE_URL, CS_FULL_TILT_URL } from 'domain/Plans/constants'

const ClimbStrongCollection = () => {
  return (
    <>
      <Image
        alt="Climb Strong"
        src={ClimbStrongHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'lg' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Heading size={{ base: '2xl', md: 'xl' }}>Climb Strong Training</Heading>

              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                We build the most effective climbing training plans.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We build all of our training plans to work for real people. Built on 4-week cycles,
                our plans can be assembled to address almost any training goal.
              </Text>

              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Whether bouldering, big-wall climbing, redpointing, or just getting generally
                stronger, this is your starting point for getting better.
              </Text>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={ClimbStrongHero4} alt="Climb Strong Plans" />
          </AspectRatio>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={ClimbStrongHero3} alt="More about Climb Strong plans" />
          </AspectRatio>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing={{ base: '4', md: '6' }}>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We are continually updating and revising and testing new training ideas. We are
                working hard to provide real-world training and performance advice that avoids
                commercial bias. Most importantly, we try to help get quality training advice to
                everyone.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We work hard to continually learn, test, and improve. Our mission is to make your
                training simpler and more effective than ever before.{' '}
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }}>
          <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Plans
            </Text>
            <Divider />
          </HStack>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Stack spacing="8">
              <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '5', md: '6' }}
                  justify="space-between"
                >
                  <Stack spacing="3">
                    <Text fontSize="xl" fontWeight="bold">
                      The Full Tilt Training Plan
                    </Text>
                    <Text textStyle="sm" color="fg.muted" maxW="md">
                      A three phase program (12 weeks) aimed at improving single-pitch rock
                      climbing.
                    </Text>
                    <Text textStyle="sm" color="fg.muted" maxW="md">
                      The training is divide between two major categories,{' '}
                      <Text as="span" fontWeight="bold">
                        Strength
                      </Text>{' '}
                      and{' '}
                      <Text as="span" fontWeight="bold">
                        Energy System Development
                      </Text>
                      . In each phase, your training will include elements of both types, and your
                      climbing will reflect the goals of the phase.
                    </Text>
                  </Stack>
                  <HStack>
                    <Button
                      as={NavLink}
                      to={`/plans/plan/${CS_FULL_TILT_URL}`}
                      variant="primary"
                      _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                    >
                      View Plan
                    </Button>
                  </HStack>
                </Stack>
              </Box>

              <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '5', md: '6' }}
                  justify="space-between"
                >
                  <Stack spacing="3">
                    <Text fontSize="xl" fontWeight="bold">
                      8 Week Bouldering Performance Plan
                    </Text>
                    <Text textStyle="sm" color="fg.muted" maxW="md">
                      This training plan is a 3-day-per-week plan that features two 4-week blocks of
                      training.
                    </Text>
                    <Text textStyle="sm" color="fg.muted" maxW="md">
                      For sake of simplicity, we will call phase 1 the{' '}
                      <Text as="span" fontWeight="bold">
                        Strength
                      </Text>{' '}
                      phase and phase 2 the{' '}
                      <Text as="span" fontWeight="bold">
                        Power
                      </Text>{' '}
                      phase, although both phases feature a mixture of both.
                    </Text>
                  </Stack>
                  <HStack>
                    <Button
                      as={NavLink}
                      to={`/plans/plan/${CS_BOULDERING_PERFORMANCE_URL}`}
                      variant="primary"
                      _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                    >
                      View Plan
                    </Button>
                  </HStack>
                </Stack>
              </Box>
            </Stack>
          </Container>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                    More coming soon...
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={ClimbStrongHero5}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: '2xl' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Heading size={{ base: '2xl', md: 'xl' }}>Climb Strong Bio</Heading>

              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                Climb Strong was established in 2007. It started as a blog, and then was a blog and
                a book, and then was a website with a discussion forum.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Over the years, we’ve morphed Climb Strong to continually try to serve the needs of
                the athletes who contact us for advice. We started building template training plans,
                writing strength articles, and filming videos of exercises. In 2007, it was Steve
                Bechtel and a laptop. Now, we have a full time crew working on various aspects of
                our mission and a coaching staff of seven people.
              </Text>

              <ChakraLink
                fontSize="sm"
                href="https://www.climbstrong.com/"
                isExternal
                color="brand.500"
                fontWeight="bold"
              >
                Climb Strong website
              </ChakraLink>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image
              objectFit="cover"
              src={ClimbStrongBio}
              alt="Climb Strong Logo"
              borderRadius="full"
            />
          </AspectRatio>
        </SimpleGrid>

        <Stack pt={{ base: '16', md: '24' }} spacing="3" align="center">
          <Heading size={{ base: 'sm', md: 'md' }}>Do you have any questions?</Heading>
          <HStack>
            <Button
              as={ChakraLink}
              variant="primary"
              _hover={{ textDecoration: 'none', bg: 'brand.600' }}
              href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
            >
              Send us an email
            </Button>
          </HStack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={TrainingHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />
    </>
  )
}

export default ClimbStrongCollection
