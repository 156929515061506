import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useImpersonate = create(
  persist(
    (set) => ({
      impersonateData: {},
      resetImpersonateData: () => {
        set({
          impersonateData: {
            impersonating: false,
            impersonateAsAdmin: false,
            impersonateUserId: null,
            impersonatingUserName: null,
            impersonatingUserEmail: null,
            coachingConnectionId: null,
          },
        })
      },
    }),
    {
      name: 'impersonate-storage',
    }
  )
)

export default useImpersonate
