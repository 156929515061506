import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useCoaching = create(
  persist(
    (set) => ({
      isCoachingInterfaceEnabled: false,
      setCoachingInterfaceEnabled: () => {
        set(() => ({ isCoachingInterfaceEnabled: true }))
      },
      setCoachingInterfaceDisabled: () => {
        set(() => ({ isCoachingInterfaceEnabled: false }))
      },
      toggleCoachingInterface: () =>
        set((state) => ({ isCoachingInterfaceEnabled: !state.isCoachingInterfaceEnabled })),
    }),
    {
      name: 'coaching-storage',
    }
  )
)

export default useCoaching
