const ColorSchemePicker = (num_scheduled_activities) => {
  // if count is equal to or less then these values that colour will be chosen
  // (only the first found colour will be used)
  // if count is greater than largest number then chosen colour will be green
  var colorScheme = 'green'
  const conditions = {
    10: 'red',
    20: 'yellow',
  }

  for (var limit in conditions) {
    if (num_scheduled_activities <= limit) {
      colorScheme = conditions[limit]
      break
    }
  }

  return colorScheme
}

export default ColorSchemePicker
