import { groupBy } from 'lodash'
import { DateTime } from 'luxon'

import Notify from 'components/Notification'

const DEFAULT_ERROR_MESSAGE = 'There was an error. Please try again.'

// some input components (e.g. chakra-ui NumberInput) still accept certain characters
// when in number only mode (i.e. 'e' for exponent and '.' for decimal point)
// this helper can be used in conjunction with an onKeyPress callback to only allow integers
export const handleNonNumericalKeyPress = (event) => {
  const numericalRegexPattern = new RegExp('^[0-9]+$')

  if (!numericalRegexPattern.test(event.key)) {
    event.preventDefault()
  }
}

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`

export const processGetError = (error, message) => {
  if (message) {
    console.log(message, error)
  } else {
    console.log('GET error', error)
  }
}

export const processApiError = (error, ifValidMessage, ifInvalidCallback) => {
  if (!Object.keys(error).includes('response')) {
    // unexpected error in code (rather than an API error)
    console.log('Error', error)
  } else if (error.response.data.error) {
    const { valid, message } = error.response.data.error

    // prefer explicit message from code over API response
    const content = ifValidMessage || message || DEFAULT_ERROR_MESSAGE

    if (!valid) {
      // API access is not valid (i.e. trial ended or subscription cancelled)
      // ifInvalidCallback allows UI cleanup to be performed if needed
      Notify({ content: content, type: 'error' })
      if (ifInvalidCallback !== undefined) {
        ifInvalidCallback()
      }
    } else {
      // other API error (FE parameters or BE bug)
      // console.log(error);
      Notify({ content: content, type: 'error' })
    }
  } else {
    // unspecified error
    // console.log(error);
    const content = ifValidMessage || DEFAULT_ERROR_MESSAGE
    Notify({ content: content, type: 'error' })
  }
}

export const processAppError = (error) => {
  if (error.response.data.error) {
    // TODO: check error is only a string
    Notify({ content: error.response.data.error, type: 'error' })
  } else {
    Notify({ content: DEFAULT_ERROR_MESSAGE, type: 'error' })
  }
}

export const processAppStatus = (response) => {
  if (response.data.status) {
    // TODO: check status is only a string
    Notify({ content: response.data.status, type: 'success' })
  } else {
    Notify({ content: 'Success', type: 'success' })
  }
}

// a little function to help us with reordering a list
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const checkAllFieldsEmpty = (obj) => {
  for (let value of Object.values(obj)) {
    if (value !== null && value !== undefined && value !== '') {
      return false
    }
  }
  return true
}

export const groupNonArchivedWorkouts = (data) => {
  const nonArchivedWorkouts = data?.filter((workout) => workout.archived === false)

  const workoutOptions = nonArchivedWorkouts?.map((workout) => ({
    id: workout.id,
    label: workout.activity_name,
    value: workout.id,
    category_name: workout.category_name || 'No Category',
    category_hex_colour: workout.category_hex_colour,
    activity_description: workout.activity_description,
    duration: workout.duration,
  }))
  const categoryGroups = groupBy(workoutOptions, 'category_name')

  const groupedOptions = Object.entries(categoryGroups).map(([key, value]) => {
    return {
      label: key,
      colour: value[0].category_hex_colour,
      options: value,
    }
  })

  return groupedOptions
}

export const categoryOptionFormatter = (data) => {
  const options = data.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return options
}

export const formatPrice = (price, currency) => {
  const priceString = price.toString()

  let amount
  if (/00$/.test(priceString)) {
    amount = priceString.slice(0, -2)
  } else {
    amount = priceString.slice(0, -2) + '.' + priceString.slice(-2)
  }

  let symbol
  let suffix
  switch (currency) {
    case 'aud':
      symbol = '$'
      suffix = 'AUD'
      break
    case 'usd':
      symbol = '$'
      suffix = 'USD'
      break
    default:
      suffix = '$'
      break
  }

  return `${symbol}${amount} ${suffix}`
}

const padDatePart = (i) => {
  return i < 10 ? '0' + i : '' + i
}

export const dateToString = (date) => {
  return (
    padDatePart(date.getFullYear()) +
    '-' +
    padDatePart(1 + date.getMonth()) + // getMonth is zero-indexed
    '-' +
    padDatePart(date.getDate())
  )
}

export const tzSafeDate = (dateString) => {
  if (isNaN(dateString)) {
    return DateTime.fromISO(dateString, { zone: 'utc' })
  } else {
    // TODO check if need to use DateTime.fromSeconds instead
    return DateTime.fromMillis(dateString, { zone: 'utc' })
  }
}

export const tzSafeDateFormat = (dateString, formatString) => {
  return tzSafeDate(dateString).toFormat(formatString)
}

// return parsed dateString as yyyy-mm-dd format
export const tzSafeDateFormatISODate = (dateString) => {
  return tzSafeDate(dateString).toISODate()
}

export const tzSafeDatetimeFormat = (dateString, formatString) => {
  return DateTime.fromISO(dateString).toFormat(formatString)
}

// the only other time to use `new Date()` is when wanting
// the current date for the user in their timezone (even if the time zone is not used)
// e.g. to populate a form with the current date
// any other usage is problematic due to how JS Date objects behave
export const tzSafeNewDate = (dateString) => {
  const partYear = dateString.substring(0, 4)
  const partMonth = dateString.substring(5, 7)
  const partDay = dateString.substring(8, 10)

  return new Date(partYear, partMonth - 1, partDay)
}
