import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Box, Container, Stack, Text, useDisclosure } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { PlanApplyModal } from 'domain/Plans/components/PlanModals'

const PurchaseLanding = () => {
  const { id } = useParams()
  const [planName, setPlanName] = useState(null)
  const [planCode, setPlanCode] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const query = `api/v1/purchases/landing?cs_id=${id}`
  const { data, isLoading, hasError } = useQuery(query)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onConfirmOpen = (purchase) => {
    setPlanName(purchase.stripe_product_name)
    setPlanCode(purchase.code)
    onOpen()
  }

  const purchase = data[0]

  return (
    <>
      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing="3" alignItems="center">
          <Text textAlign="center" fontSize="2xl">
            Purchase Successful
          </Text>

          <Box boxShadow="md" p="6" rounded="md" bg="white" maxW="lg">
            <Stack spacing="3">
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  {purchase.stripe_product_name}
                </Text>
                <Text>{purchase.stripe_product_description}</Text>
              </Box>
              <Button variant="primary" onClick={() => onConfirmOpen(purchase)}>
                Apply Plan
              </Button>
              <Text fontSize="xs">
                If you don't want to apply your plan now, that is all good. You will always have
                access to it within the plans section of the site.
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <PlanApplyModal {...{ planName, planCode, isOpen, onClose }} />
    </>
  )
}

export default PurchaseLanding
