import React, { useEffect, useState, useRef } from 'react'
import { Box, Link, Text } from '@chakra-ui/react'

import { useDemoData, useMetricsDays } from 'hooks'

import _ from 'lodash'
import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import Measure from './Measure'
import LastXDays from './CommonMetrics'
import { SelectableMetricsContainer, ItemCard } from './CommonMeasure'

const Measures = () => {
  const isFirstRender = useRef(true)
  const [loading, setLoading] = useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const [metricsData, setMetricsData] = useState({})

  const [hasDataMeasures, setHasDataMeasures] = useState([])
  const [showMeasures, setShowMeasures] = useState([])
  const [hideMeasures, setHideMeasures] = useState([])

  const getIds = (list) => {
    return list.map(({ measure }) => measure.measure_id)
  }

  const getData = () => {
    securedApi
      .get(`/api/v1/metrics/measures?demo=${demoData}&days=${days}`)
      .then((response) => {
        setMetricsData(response.data)

        const { widget_measures, all_measures, settings } = response.data

        if (_.isEmpty(settings)) {
          setShowMeasures(getIds(widget_measures))
          setHideMeasures([])
        } else {
          setShowMeasures(settings.show)
          setHideMeasures(settings.hide)
        }

        const x = widget_measures.map((x) => x.measure.measure_id)
        const y = all_measures.map((x) => x.measure.measure_id)
        const z = _.intersection(x, y)

        setHasDataMeasures(z)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
        setTriggerRefresh(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [demoData])

  useEffect(() => {
    // avoid a double getData on mount
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      getData()
    }
  }, [days])

  useEffect(() => {
    if (triggerRefresh) {
      getData()
    }
  }, [triggerRefresh])

  if (loading && !triggerRefresh) {
    return <LoadingSpinner />
  }

  const { widget_measures, all_measures, annotations, no_widgets, no_entries } = metricsData

  // sometimes this happens..?
  // maybe was related to the double useEffect with demo,days; maybe race condition?
  if (widget_measures === undefined) {
    return <Text>Checking for measures data</Text>
  }

  if (no_widgets) {
    return (
      <Box>
        <Text color="gray.500" mt="4">
          You currently don't have any custom measure data yet.{' '}
          <Link
            href="https://docs.sequence-app.com/features/Widgets/measures-widget"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            Learn about custom measures
          </Link>
          .
        </Text>
      </Box>
    )
  } else {
    if (no_entries) {
      return (
        <Box>
          <LastXDays />
          <Text color="gray.500" mt="4">
            No data for this period.
          </Text>
        </Box>
      )
    }
  }

  const MeasureCard = ({ id }) => {
    const widget_measure = all_measures.find((x) => id === x.measure.measure_id)

    const { measure } = widget_measure
    const key = measure.measure_id
    const title = measure.measure_name
    const subtitle = measure.units
    const hasData = hasDataMeasures.indexOf(id) >= 0

    return <ItemCard key={key} title={title} subtitle={subtitle} hasData={hasData} />
  }

  // add the hidden measures at the end
  const sortingArray = [...showMeasures, ...hideMeasures]
  const widgetMeasuresSorted = widget_measures
    .slice()
    .sort(
      (a, b) =>
        sortingArray.indexOf(a.measure.measure_id) - sortingArray.indexOf(b.measure.measure_id)
    )

  const nothingToDisplay = _.intersection(showMeasures, hasDataMeasures).length === 0

  if (nothingToDisplay) {
    return (
      <SelectableMetricsContainer
        type="custom_measures"
        hasDataMeasures={hasDataMeasures}
        showMeasures={showMeasures}
        setShowMeasures={setShowMeasures}
        hideMeasures={hideMeasures}
        setHideMeasures={setHideMeasures}
        cardComponent={MeasureCard}
        demo={demoData}
      >
        <LastXDays />
        <Box mt="4">
          <Text>No selected measures have data for the chosen period</Text>
        </Box>
      </SelectableMetricsContainer>
    )
  }

  return (
    <SelectableMetricsContainer
      type="custom_measures"
      hasDataMeasures={hasDataMeasures}
      showMeasures={showMeasures}
      setShowMeasures={setShowMeasures}
      hideMeasures={hideMeasures}
      setHideMeasures={setHideMeasures}
      cardComponent={MeasureCard}
      demo={demoData}
    >
      {widgetMeasuresSorted.map(({ measure, activities }) => {
        const { measure_id, measure_name } = measure

        const showMeasure = showMeasures.indexOf(measure_id) >= 0

        if (!showMeasure) {
          return null
        }

        return (
          <Measure
            key={measure_id}
            data={measure}
            annotations={annotations}
            activities={activities}
            setTriggerRefresh={setTriggerRefresh}
          />
        )
      })}
    </SelectableMetricsContainer>
  )
}

export default Measures
