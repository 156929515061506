import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { FormHeading } from 'components/Forms'

import { processAppStatus, processAppError } from 'helpers/utils'

import { AuthWrapper } from './Elements'

const schema = z
  .object({
    new_password: z.string().min(6),
    new_password_confirmation: z.string().min(6),
  })
  .refine((data) => data.new_password === data.new_password_confirmation, {
    message: "Passwords don't match",
    path: ['new_password_confirmation'],
  })

const ResetPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ new_password, new_password_confirmation }) => {
    setLoading(true)
    securedApi
      .post('password/reset', {
        new_password,
        new_password_confirmation,
        token,
      })
      .then((response) => {
        processAppStatus(response)
        navigate('/sign-in')
      })
      .catch((error) => {
        processAppError(error)
        setLoading(false)
      })
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading heading="Reset password" />

        <FormControl marginTop="8" marginBottom="4" isInvalid={errors.new_password}>
          <FormLabel>New Password</FormLabel>

          <Input
            type="password"
            {...register('new_password', {
              required: true,
              minLength: 6,
            })}
          />
          <FormErrorMessage>{errors.new_password && errors.new_password.message}</FormErrorMessage>
        </FormControl>

        <FormControl marginBottom="10" isInvalid={errors.new_password_confirmation}>
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            type="password"
            {...register('new_password_confirmation', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.new_password_confirmation && errors.new_password_confirmation.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          colorScheme="brand"
          width="100%"
          disabled={loading}
          isLoading={loading}
        >
          Reset password
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default ResetPassword
