import React, { useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError, processApiError } from 'helpers/utils'

const MarkAsIncomplete = (props) => {
  const { currentActivityId, onSetCompleted, onSetIncomplete, onCloseModal, incomplete, refresh } =
    props

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [reasonsLoading, setReasonsLoading] = useState(false)
  const [reasons, setReasons] = useState({})
  const [reason, setReason] = useState('')

  const handleMarkAsIncomplete = () => {
    // closing modal here offers a better experience. Not the best but better that having it after the api call
    onCloseModal()
    securedApi
      .put(`api/v1/scheduled_activities/${currentActivityId}/incomplete`, {
        scheduled_activity: {
          incomplete_reason: 'no_reason',
        },
      })
      .then(() => {
        //used for refreshing the dashboard
        if (refresh) {
          refresh()
        }

        Notify({
          content: 'Workout updated',
          type: 'success',
        })
        onSetIncomplete(true)
        onSetCompleted(false)
      })
      .catch((error) => {
        processApiError(error, 'Error adding a reason. Please try again.')
      })
  }

  const handleRemoveIncompleteStatus = () => {
    onCloseModal()
    securedApi
      .put(`api/v1/scheduled_activities/${currentActivityId}/reschedule`)
      .then(() => {
        //used for refreshing the dashboard
        if (refresh) {
          refresh()
        }
        Notify({
          content: 'Workout updated',
          type: 'success',
        })
        onSetIncomplete(false)
      })
      .catch((error) => {
        processApiError(error, 'Error updating workout. Please try again.')
      })
  }

  const handleLoadReasonModal = () => {
    setReasonsLoading(true)
    onOpen()

    securedApi
      .get(`api/v1/scheduled_activities/${currentActivityId}`)
      .then(({ data }) => {
        if (data?.incomplete_reason) {
          setReason(data?.incomplete_reason)
        }
      })
      .catch((error) => {
        processGetError(error)
      })

    securedApi
      .get(`api/v1/scheduled_activities/incomplete_reasons`)
      .then(({ data }) => {
        setReasons(data)
      })
      .catch((error) => {
        processGetError(error, 'Error loading reasons. Please try again.')
      })
      .finally(() => {
        setReasonsLoading(false)
      })
  }

  const handleAddReason = () => {
    setReasonsLoading(true)

    securedApi
      .put(`api/v1/scheduled_activities/${currentActivityId}/incomplete`, {
        scheduled_activity: {
          incomplete_reason: reason,
        },
      })
      .then(() => {
        //used for refreshing the dashboard
        if (refresh) {
          refresh()
        }

        Notify({
          content: 'Workout updated',
          type: 'success',
        })
        onSetIncomplete(true)
        onSetCompleted(false)
        onCloseModal()
      })
      .catch((error) => {
        processApiError(error, 'Error adding a reason. Please try again.')
      })
  }

  const handleSelectReason = (event) => {
    setReason(event.target.value)
  }

  const renderReasons = () => {
    if (!reasons.length) {
      return null
    }

    return reasons.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.value}
        </option>
      )
    })
  }

  const renderModalContent = () => {
    if (reasonsLoading) {
      return <LoadingSpinner />
    }

    return (
      <>
        <FormControl>
          <Select
            placeholder="Select your reason"
            onChange={handleSelectReason}
            value={reason}
            variant="outline"
          >
            {renderReasons()}
          </Select>
        </FormControl>
        <Box mt="2">
          <Link
            href="https://docs.sequence-app.com/features/incomplete-workouts"
            isExternal
            color="yellow.500"
            fontSize="xs"
          >
            Learn more
          </Link>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box>
        <ButtonGroup size="xs" isAttached variant="outline">
          <Button onClick={incomplete ? handleRemoveIncompleteStatus : handleMarkAsIncomplete}>
            {incomplete ? 'Remove Incomplete Status' : 'Mark as Incomplete'}
          </Button>
          <IconButton
            aria-label="Add reason"
            onClick={handleLoadReasonModal}
            icon={<AddIcon w={2} h={2} />}
          />
        </ButtonGroup>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderModalContent()}</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              disabled={!reason}
              onClick={handleAddReason}
              isLoading={reasonsLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MarkAsIncomplete
