import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Checkbox,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import { MoreHorizontal as GraphSettingsIcon } from 'react-feather'

import { useDemoData, useMetricsDays } from 'hooks'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import LastXDays from 'domain/Metrics/CommonMetrics'

import { EntriesByStyle } from './EntriesByStyle'
import { GradePyramid } from './GradePyramid'
import { EntriesTable } from 'domain/Widgets/Logbook/components'

const LogbookMetrics = () => {
  const isFirstRender = useRef(true)
  const [loading, setLoading] = useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const [showAttempts, setShowAttempts] = useState(false)
  const [showRepeats, setShowRepeats] = useState(false)

  const days = useMetricsDays((state) => state.days)
  const demoData = useDemoData((state) => state.demoData)

  const [metricsData, setMetricsData] = useState({})

  const getData = () => {
    securedApi
      .get(`/api/v1/metrics/logbooks?demo=${demoData}&days=${days}`)
      .then((response) => {
        setMetricsData(response.data)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
        setTriggerRefresh(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [demoData])

  useEffect(() => {
    // avoid a double getData on mount
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      getData()
    }
  }, [days])

  useEffect(() => {
    if (triggerRefresh) {
      getData()
    }
  }, [triggerRefresh])

  if (loading && !triggerRefresh) {
    return <LoadingSpinner />
  }

  const { widget_logbooks, annotations, no_wl, no_wle } = metricsData

  // sometimes this happens..?
  // maybe was related to the double useEffect with demo,days; maybe race condition?
  if (widget_logbooks === undefined) {
    return <Text>Checking for logbook data</Text>
  }

  if (!widget_logbooks.length) {
    if (no_wl || no_wle) {
      return (
        <Box>
          <Text color="gray.500" mt="4">
            You currently don't have any logbook data yet.
            {/*{' '}<Link
              href="https://support.sequence-app.com/article/27-measure-widgets"
              isExternal
              fontWeight="bold"
              color="yellow.500"
            >
              Learn about logbooks TODO LINK
            </Link>
            .*/}
          </Text>
        </Box>
      )
    } else {
      return (
        <Box>
          <LastXDays />
          <Text color="gray.500" mt="4">
            No data for this period.
          </Text>
        </Box>
      )
    }
  }

  const nothingToDisplay = false // TODO what is the equivalent for this?

  if (nothingToDisplay) {
    return (
      <>
        <LastXDays />
        <Box mt="4">
          <Text>No logbooks have data for the chosen period</Text>
        </Box>
      </>
    )
  }

  const MetricsHeading = ({ label }) => {
    return (
      <Text fontSize="lg" marginTop="2">
        {label}
      </Text>
    )
  }

  const pickStylesToShow = () => {
    const stylesToShow = ['onsight', 'flash', 'redpoint']
    if (showAttempts) {
      stylesToShow.push('attempt', 'work')
    }
    if (showRepeats) {
      stylesToShow.push('repeat')
    }

    return stylesToShow
  }

  const renderGraphOptions = () => (
    <Flex>
      <Flex flexDirection="column">
        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={
              <Icon
                as={GraphSettingsIcon}
                w="4"
                h="4"
                color="gray.500"
                // strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            }
            bg="transparent"
            _hover={{
              cursor: 'pointer',
              color: 'brand.500',
              bg: 'transparent',
            }}
            size="x-small"
            width="18px"
            _expanded={{ color: 'brand.500', bg: 'transparent' }}
          />

          <MenuList>
            <MenuOptionGroup fontSize="small" title="Graph Options">
              <MenuItem fontSize="small" p="2">
                <Checkbox
                  isChecked={showAttempts}
                  onChange={(e) => setShowAttempts(e.target.checked)}
                  colorScheme="brand"
                  width="100%"
                >
                  <Text mt={1} fontSize="sm">
                    Show attempts
                  </Text>
                </Checkbox>
              </MenuItem>
              <MenuItem fontSize="small" p="2">
                <Checkbox
                  isChecked={showRepeats}
                  onChange={(e) => setShowRepeats(e.target.checked)}
                  colorScheme="brand"
                  width="100%"
                >
                  <Text mt={1} fontSize="sm">
                    Show repeats
                  </Text>
                </Checkbox>
              </MenuItem>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )

  return (
    <Tabs colorScheme="gray">
      <TabList>
        {widget_logbooks.map(({ logbook, metrics }) => (
          <Tab key={logbook.id}>{logbook.name}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {widget_logbooks.map(({ logbook, metrics }) => {
          const { entries } = logbook
          const { by_style, by_grade_by_style } = metrics

          const stylesToShow = pickStylesToShow()

          // Find the index of the first and last element with a matching style
          const firstIndex = by_grade_by_style.findIndex((item) =>
            stylesToShow.some((style) => item.hasOwnProperty(style))
          )

          const lastIndexReverse = by_grade_by_style
            .reverse()
            .findIndex((item) => stylesToShow.some((style) => item.hasOwnProperty(style)))

          // Adjust the last index to match the original array index, and undo the reverse
          const lastIndex = lastIndexReverse >= 0 ? by_grade_by_style.length - lastIndexReverse : -1
          by_grade_by_style.reverse()

          const filter_by_style = by_style.filter((x) => stylesToShow.includes(x.style_text))
          const filter_by_grade_by_style = [...by_grade_by_style].splice(firstIndex, lastIndex)

          return (
            <TabPanel key={logbook.id}>
              <LastXDays />
              <Stack spacing="20">
                <Box>
                  <Flex justifyContent="space-between" alignItems="center" mb="4">
                    <MetricsHeading label="Grade pyramid" />
                    {renderGraphOptions()}
                  </Flex>
                  <GradePyramid data={filter_by_grade_by_style} {...{ logbook, stylesToShow }} />
                </Box>

                <Box>
                  <Flex justifyContent="space-between" alignItems="center" mb="4">
                    <MetricsHeading label="Entries by ascent style" />
                    {renderGraphOptions()}
                  </Flex>
                  <EntriesByStyle data={filter_by_style} {...{ logbook, stylesToShow }} />
                </Box>
                <Box>
                  <Flex mb="4">
                    <MetricsHeading label="Entries table" />
                  </Flex>
                  <EntriesTable data={entries} {...{ logbook }} />
                </Box>
              </Stack>
            </TabPanel>
          )
        })}
      </TabPanels>
    </Tabs>
  )
}

export default LogbookMetrics
