import React from 'react'
import { Badge, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { format } from 'date-fns'
import { groupBy } from 'lodash'

import { tzSafeDateFormat } from 'helpers/utils'

const MeasureTable = ({ data }) => {
  const TrYear = ({ item }) => {
    return (
      <Tr>
        <Td>
          <Badge colorScheme="brand">{item.year}</Badge>
        </Td>
        <Td></Td>
        <Td></Td>
      </Tr>
    )
  }

  const TdPreWrap = ({ children }) => {
    return <Td style={{ whiteSpace: 'pre-wrap' }}>{children}</Td>
  }

  const TrNotes = ({ item }) => {
    return (
      <Tr key={item.id}>
        <Td title={item.scheduled_at}>{tzSafeDateFormat(item.date_value, 'MMM d')}</Td>
        <TdPreWrap>{item.value ? item.value : '-'}</TdPreWrap>
        {info.dimensions >= 2 && <TdPreWrap>{item.value_2 ? item.value_2 : '-'}</TdPreWrap>}
        {info.dimensions >= 3 && <TdPreWrap>{item.value_3 ? item.value_3 : '-'}</TdPreWrap>}
        <TdPreWrap>{item.notes ? item.notes : '-'}</TdPreWrap>
      </Tr>
    )
  }

  const { info, entries } = data

  // table
  const grp = groupBy(entries, 'year')
  const years = Object.keys(grp).sort().reverse()
  const thisYear = format(new Date(), 'yyyy')
  const multipleYears = years.length > 1
  const onlyYearIsNotThisYear = !multipleYears && years[0] !== thisYear
  const showYearRows = multipleYears || onlyYearIsNotThisYear

  return (
    <Table size="sm" variant="striped">
      <Thead>
        <Tr>
          <Th width="80px">Date</Th>
          <Th>{info.units}</Th>
          {info.dimensions >= 2 && <Th>{info.units_2}</Th>}
          {info.dimensions >= 3 && <Th>{info.units_3}</Th>}
          <Th>Notes</Th>
        </Tr>
      </Thead>
      <Tbody>
        {years.map((year) => {
          const items = grp[year]

          return items.map((item, idx) => {
            const firstRowOfYear = idx === 0

            if (showYearRows) {
              if (firstRowOfYear) {
                return (
                  <>
                    <TrYear item={item} />
                    <TrNotes item={item} />
                  </>
                )
              } else {
                return <TrNotes item={item} />
              }
            } else {
              return <TrNotes item={item} />
            }
          })
        })}
      </Tbody>
    </Table>
  )
}

export default MeasureTable
