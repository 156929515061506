import { Link as ReactRouterLink } from 'react-router-dom'
import { Link, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import { Settings } from 'react-feather'

export const ConfigureDailyMetricsLink = () => {
  return (
    <Link
      as={ReactRouterLink}
      to="/metrics/daily-metrics-settings"
      _hover={{
        textDecoration: 'none',
        color: 'brand.500',
        cursor: 'pointer',
      }}
    >
      <Tooltip label="Configure Daily Metrics">
        <IconButton
          variant="ghost"
          _hover={{ cursor: 'pointer', color: 'brand.500' }}
          icon={<Icon as={Settings} w="5" h="5" strokeWidth="1px" backgroundColor="transparent" />}
        />
      </Tooltip>
    </Link>
  )
}

export default ConfigureDailyMetricsLink
