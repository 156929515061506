import MetricsContainer from 'domain/Metrics/MetricsContainer'
import LogbookMetrics from './LogbookMetrics'

const LogbookMetricsWrapper = () => {
  return (
    <MetricsContainer>
      <LogbookMetrics />
    </MetricsContainer>
  )
}

export default LogbookMetricsWrapper
