import React from 'react'
import { Box, Text, Link } from '@chakra-ui/react'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import {
  calcXYDomains,
  calcXTicks,
  calcYTicks,
  getDataValues,
  tickFormatterDate,
} from 'helpers/charts'
import { CustomTooltipMeasure } from 'domain/Metrics/CommonMeasure'

const MeasureChart = ({ data, measurePlotState, setMeasurePlotState }) => {
  const { info, entries, annotations } = data

  const { x_ticks } = calcXTicks(annotations.range)

  const LineChart1D = ({ dataKey, unitsKey }) => {
    const data1Dim = entries.map((e, i) => {
      const date_text = e.date_text
      const date_value = e.date_value
      const value = parseFloat(e.value)
      const value_2 = e.value_2
      const value_3 = e.value_3
      const units = e.units
      const units_2 = e.units_2
      const units_3 = e.units_3

      return Object.assign({
        date_text,
        date_value,
        value,
        value_2,
        value_3,
        units,
        units_2,
        units_3,
      })
    })

    const { y_values } = getDataValues(entries, dataKey)
    const { xy_domains } = calcXYDomains(annotations, y_values)
    const { y_ticks } = calcYTicks(xy_domains)

    if (!y_values.length) {
      return (
        <Box mt={10} mb={5} textAlign="center">
          <Text>No values have been recorded for the last 90 days</Text>
          <Link as="span" color="yellow.500" onClick={() => setMeasurePlotState('table')}>
            View table
          </Link>
        </Box>
      )
    }

    return (
      <ResponsiveContainer width="100%" aspect={1.8 / 1.0}>
        <LineChart data={data1Dim} margin={{ top: 20, right: 20, bottom: 5, left: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            name="date"
            type="number"
            tick={{ fontSize: 'small' }}
            ticks={x_ticks}
            tickFormatter={tickFormatterDate}
            dataKey="date_value"
            domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
          />
          <YAxis
            domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
            ticks={y_ticks}
            label={{ value: info[unitsKey], position: 'insideLeft' }}
          />
          <Tooltip
            content={<CustomTooltipMeasure dataKey={dataKey} showSingleDimension={true} />}
          />
          <Line dataKey={dataKey} fill="#8884d8" animationDuration={0} connectNulls />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  const LineChart2D = () => {
    const data2Dim = entries
      .map((e, i) => {
        const date_text = e.date_text
        const date_value = e.date_value

        // need to normalise this based on value 2 & 3 and time period
        const k = 400000
        const scale_2 = e.value_2 || 1
        const scale_3 = e.value_3 || 1
        const d = scale_2 * scale_3

        const startTs = date_value - k * d
        const endTs = date_value + k * d

        const value = e.value
        const value_2 = e.value_2
        const value_3 = e.value_3
        const units = e.units
        const units_2 = e.units_2
        const units_3 = e.units_3
        const dataForTs = []

        return [
          Object.assign({
            date_text: date_text,
            date_value: date_value,
            dimensions: info.dimensions,
            ts: startTs,
            value: null,
            value_2: null,
            value_3: null,
            units: null,
            units_2: null,
            units_3: null,
          }),
          Object.assign({
            date_text: date_text,
            date_value: date_value,
            dimensions: info.dimensions,
            ts: startTs,
            value,
            value_2,
            value_3,
            units,
            units_2,
            units_3,
          }),
          Object.assign(
            {
              date_text: date_text,
              date_value: date_value,
              dimensions: info.dimensions,
              ts: endTs,
              value,
              value_2,
              value_3,
              units,
              units_2,
              units_3,
            },
            ...dataForTs
          ),
          Object.assign({
            date_text: date_text,
            date_value: date_value,
            dimensions: info.dimensions,
            ts: endTs,
            value: null,
            value_2: null,
            value_3: null,
            units: null,
            units_2: null,
            units_3: null,
          }),
        ]
      })
      .flat()

    const { y_values } = getDataValues(entries, 'value')
    const { xy_domains } = calcXYDomains(annotations, y_values)
    const { y_ticks } = calcYTicks(xy_domains)

    return (
      <>
        <ResponsiveContainer width="100%" aspect={1.5 / 1.0}>
          <AreaChart data={data2Dim}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              name="date"
              type="number"
              tick={{ fontSize: 'small' }}
              ticks={x_ticks}
              tickFormatter={tickFormatterDate}
              dataKey="ts"
              domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
            />
            <YAxis
              domain={[xy_domains.y_domain_min, xy_domains.y_domain_max]}
              ticks={y_ticks}
              label={{ value: info.units, position: 'insideLeft' }}
            />
            <Tooltip cursor={false} content={<CustomTooltipMeasure />} />
            <Area
              dataKey="value"
              dot={false}
              activeDot={false}
              stroke={false}
              animationDuration={0}
              fill="#B6760C"
            />
          </AreaChart>
        </ResponsiveContainer>
        <Text fontSize="xs" marginLeft={20}>
          The width of each bar is proportional to the total number of reps performed (i.e. sets ×
          reps)
        </Text>
      </>
    )
  }

  const showSingle = measurePlotState === 'single'
  const showCombined = measurePlotState === 'combined'

  return (
    <Box>
      {showSingle && <LineChart1D dataKey="value" unitsKey="units" />}
      {showSingle && info.units_2 && <LineChart1D dataKey="value_2" unitsKey="units_2" />}
      {showSingle && info.units_3 && <LineChart1D dataKey="value_3" unitsKey="units_3" />}
      {showCombined && <LineChart2D />}
    </Box>
  )
}

export default MeasureChart
