export const categoryColour = {
  strengthPower: '#af67fc',
  cardio: '#ada296',
  endurance: '#ddd14a',
  conditioning: '#a55878',
  core: '#1E91D6',
  powerEndurance: '#EE964B',
  rehab: '#43c2a6',
  none: '#DBDBDB',
  null: '#DBDBDB',
}

export const categoryColours = [
  '#af67fc',
  '#a55878',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#1E91D6',
  '#03a9f4',
  '#ddd14a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#EE964B',
  '#43c2a6',
  '#009688',
  '#4caf50',
  '#DBDBDB',
  '#f44336',
  '#e91e63',
  '#795548',
  '#ada296',
  '#607d8b',
]
