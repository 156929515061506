import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const classes = {
  reactQuillContainer: 'bg-white',
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ list: 'bullet' }, { list: 'ordered' }],
    ['link', 'image', 'video'],
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

const formats = [
  'bold',
  'italic',
  'underline',
  'blockquote',
  'bullet',
  'list',
  'link',
  'image',
  'video',
]

const RichTextArea = React.forwardRef((props, ref) => {
  const { name, onChange, value } = props

  return (
    <div className={classes.reactQuillContainer}>
      <div className="reactQuillBounds">
        <ReactQuill
          innerRef={ref}
          modules={modules}
          formats={formats}
          bounds=".reactQuillBounds"
          {...{ name, onChange, value }}
        />
      </div>
    </div>
  )
})

export default RichTextArea
