import React from 'react'
import { camelCase } from 'lodash'
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { Box, Text } from '@chakra-ui/react'

import { pluralize } from '../../../helpers/utils'

const CustomTooltip = (props) => {
  if (!props.active) {
    return null
  }

  return (
    <Box
      style={{
        border: '#bbb 1.5px solid',
      }}
    >
      <Box
        style={{
          padding: '7.5px 7.5px 0px 7.5px',
          backgroundColor: 'white',
        }}
      >
        <Text fontWeight="semibold" color={props.payload[0].payload.hex_colour || '#999999'}>
          {props.payload && props.payload[0] != null && props.payload[0].payload.name}
        </Text>
      </Box>
      <Box
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {props.breakdown === 'time' ? (
          <>
            <Text>
              {props.payload[0].payload.time} {pluralize(props.payload[0].payload.time, 'hr')} ✓
            </Text>
            {props.payload[0].payload.time_miss > 0 && (
              <Text>
                {props.payload[0].payload.time_miss}{' '}
                {pluralize(props.payload[0].payload.time_miss, 'hr')} not completed
              </Text>
            )}
          </>
        ) : (
          <>
            <Text>
              {props.payload[0].payload.total}{' '}
              {pluralize(props.payload[0].payload.total, 'session')} ✓
            </Text>
            {props.payload[0].payload.total_miss > 0 && (
              <Text>
                {props.payload[0].payload.total_miss}{' '}
                {pluralize(props.payload[0].payload.total_miss, 'session')} not completed
              </Text>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export const BreakdownLink = ({ type, sessionBreakdownType, setSessionBreakdownType }) => {
  const isActive = type === sessionBreakdownType

  const activeStyle = { color: 'black' }
  const inactiveStyle = { color: '#CCC' }
  const style = isActive ? activeStyle : inactiveStyle

  const toggleBreakdown = (type) => {
    setSessionBreakdownType(type)
  }

  return (
    <Box
      style={{ cursor: 'pointer' }}
      paddingLeft={2}
      paddingRight={2}
      onClick={() => toggleBreakdown(type)}
    >
      <Text textTransform="uppercase" fontSize="xs" fontWeight="semibold" style={style}>
        {type}
      </Text>
    </Box>
  )
}

export const CategoryBarChart = ({ data, breakdown = 'time' }) => {
  if (!data.length) {
    return <p>You haven't logged any workouts yet....</p>
  }

  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
  const ceil = (num, precision) => {
    return Math.ceil(num / precision) * precision
  }

  const maxValue = Math.max(...data.map((i) => i[breakdown] + i[breakdown + '_miss']))
  var ticks
  var interval

  if (breakdown === 'time') {
    interval = 0.5
    if (maxValue >= 5) {
      interval = 1
    }
    if (maxValue >= 10) {
      interval = 2
    }
    ticks = range(0, ceil(maxValue, interval), interval)
  } else {
    interval = 1
    if (maxValue >= 10) {
      interval = 2
    }
    if (maxValue >= 25) {
      interval = 5
    }
    ticks = range(0, ceil(maxValue, interval), interval)
  }

  const lastTick = ticks[ticks.length - 1]
  const labelXAxis = breakdown === 'time' ? 'Hours' : 'Sessions'

  return (
    <ResponsiveContainer width="100%" height={330}>
      <BarChart layout="vertical" data={data} margin={{ bottom: 20 }}>
        <XAxis
          type="number"
          domain={[0, lastTick]}
          ticks={ticks}
          label={{ value: labelXAxis, position: 'bottom' }}
        />
        <YAxis type="category" dataKey={'name'} tick={{ fontWeight: 'bold' }} width={160} />
        <Tooltip content={<CustomTooltip breakdown={breakdown} />} cursor={false} />

        <Bar stackId="a" dataKey={breakdown}>
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={data[index].hex_colour || '#999999'} />
          ))}
        </Bar>
        <Bar stackId="a" dataKey={`${breakdown}_miss`}>
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={`${data[index].hex_colour}33` || '#999999'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CategoryBarChart
