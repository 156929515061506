import React, { useState } from 'react'
import { Box, Text, Icon, Image, SimpleGrid, Input } from '@chakra-ui/react'
import { Search, XCircle } from 'react-feather'

import CategoryImage from './assets/categoryIcon.png'
import CategoryStrip from './CategoryStrip'

const WorkoutSearch = ({ categories, onCategorySelection, onSearchClose, onChangeSearch }) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState(false)

  const handleSearchVisibility = () => {
    setSearchOpen(!searchOpen)
    onSearchClose()
  }

  const handleCategoryVisibility = () => {
    setCategoryOpen(!categoryOpen)
    onSearchClose()
  }

  return (
    <Box display="flex">
      {!searchOpen && !categoryOpen && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={handleSearchVisibility}
          _hover={{ cursor: 'pointer' }}
        >
          <Icon as={Search} w="6" h="6" color="#999" />
          <Text mt="2" fontSize="x-small" textTransform="uppercase">
            Search
          </Text>
        </Box>
      )}

      {!categoryOpen && !searchOpen && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={handleCategoryVisibility}
          ml="4"
          _hover={{ cursor: 'pointer' }}
        >
          <Image mt="2" width="50px" height="16px" src={CategoryImage} alt="Category" />
          <Text mt="2" fontSize="x-small" textTransform="uppercase">
            Category
          </Text>
        </Box>
      )}

      {searchOpen && (
        <Box display="flex" alignItems="center" ml={['0', '2']}>
          <Box maxWidth="400px">
            <Input placeholder="Search workouts..." onChange={onChangeSearch} autoFocus />
          </Box>
          <Box ml="2" _hover={{ cursor: 'pointer' }}>
            <Icon as={XCircle} strokeWidth={1} w="6" h="6" onClick={handleSearchVisibility} />
          </Box>
        </Box>
      )}

      {categoryOpen && (
        <Box display="flex" alignItems="center" mb={['0', '6']}>
          <SimpleGrid columns={[2, null, 4]} spacing={2}>
            {categories.map(({ id, name, hex_colour }) => {
              return (
                <Box
                  key={id}
                  textAlign="center"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => onCategorySelection({ id, name })}
                >
                  <CategoryStrip name={name} colour={hex_colour} />
                </Box>
              )
            })}
          </SimpleGrid>
          <Box ml="4" _hover={{ cursor: 'pointer' }}>
            <Icon as={XCircle} strokeWidth={1} w="6" h="6" onClick={handleCategoryVisibility} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default WorkoutSearch
