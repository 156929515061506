import React, { useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@chakra-ui/react'

const TextOverflow = ({ children, ...props }) => {
  const ref = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)

  useEffect(() => {
    const element = ref.current
    setIsOverflown(element.scrollWidth > element.clientWidth)
  }, [])

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}

export default TextOverflow
