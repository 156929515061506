import { NavLink } from 'react-router-dom'
import { Text, Flex, Link } from '@chakra-ui/react'
import Logo from 'domain/Navigation/Components/Logo'

const PublicHeader = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      px="6"
      py="2"
      borderBottom="1px"
      borderColor="gray.300"
      background="gray.50"
      width="100%"
    >
      <Link as={NavLink} to="/">
        <Flex alignItems="center">
          <Logo />
          <Text
            fontSize="sm"
            pt="1"
            pl="2"
            color="gray.700"
            textTransform="uppercase"
            fontWeight="semibold"
          >
            Sequence
          </Text>
        </Flex>
      </Link>
    </Flex>
  )
}

export default PublicHeader
