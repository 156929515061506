import React, { useState } from 'react'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { format, getDay } from 'date-fns'
import {
  Button,
  ButtonGroup,
  Box,
  Stack,
  RadioGroup,
  Radio,
  Checkbox,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { useBulkAddWorkout, useQuery } from 'hooks'
import { groupNonArchivedWorkouts, processApiError, pluralize } from 'helpers/utils'
import { revalidateLiveQueries } from 'helpers/swrConfig'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import DatePicker from 'components/DatePicker/DatePicker'

import { customStyles } from '../helpers'

const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const BulkAddWorkout = () => {
  const [isProcessing, setIsProcessing] = useState(false)

  const numWeeks = 4
  const now = new Date()

  const { data, isLoading, hasError } = useQuery('api/v1/activities/add_list')
  const {
    control,
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      repeat_every: '1',
      endDate: now.setDate(now.getDate() + numWeeks * 7),
      numTimes: '10',
      numDurations: '5',
    },
  })

  const bulkAddWorkoutOpen = useBulkAddWorkout((state) => state.bulkAddWorkoutOpen)
  const bulkAddWorkoutDate = useBulkAddWorkout((state) => state.bulkAddWorkoutDate)
  const setBulkAddWorkoutOpen = useBulkAddWorkout((state) => state.setBulkAddWorkoutOpen)

  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const currentDay = format(new Date(bulkAddWorkoutDate), 'PPPP')

  const { activities } = data

  const workoutOptions = groupNonArchivedWorkouts(activities)

  const endType = watch('endType')
  const currentRepeatEveryValue = watch('repeat_every')
  const currentNumTimesValue = watch('numTimes')
  const currentNumDurationsValue = watch('numDurations')

  const onSubmit = async (data) => {
    const days = DAYS_OF_WEEK.filter((day) => data[day])

    if (!days.length) {
      // hook into registered monday field to show message for all.
      setError('monday')
      return
    }

    setIsProcessing(true)

    securedApi
      .post(`api/v1/scheduled_activities/new_calendar_bulk`, {
        start_date: format(new Date(bulkAddWorkoutDate), 'yyyy-MM-dd'),
        activity_id: data.workout.id,
        repeat_every: data.repeat_every,
        repeat_duration: 'week',
        monday: data.monday,
        tuesday: data.tuesday,
        wednesday: data.wednesday,
        thursday: data.thursday,
        friday: data.friday,
        saturday: data.saturday,
        sunday: data.sunday,
        back_date_this_week: data.back_date_this_week,
        end_date:
          data.endType === 'customEndDate' ? format(new Date(data.endDate), 'yyyy-MM-dd') : null,
        num_times: data.endType === 'numTimes' ? data.numTimes : null,
        num_durations: data.endType === 'numDurations' ? data.numDurations : null,
      })
      .then(({ data }) => {
        Notify({ content: 'Workouts added', type: 'success' })

        revalidateLiveQueries()
        reset()

        setBulkAddWorkoutOpen()
      })
      .catch((error) => {
        processApiError(error, 'Error adding workouts. Please try again.')
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <Modal isOpen={bulkAddWorkoutOpen} onClose={setBulkAddWorkoutOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box display="flex" alignItems="center">
            <Box>
              Bulk Add Workout <Text fontSize="sm">{currentDay}</Text>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={errors.workout}>
                <FormLabel>Workout</FormLabel>
                <Controller
                  name="workout"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      autoFocus
                      {...field}
                      options={workoutOptions}
                      styles={customStyles}
                      placeholder="Search workouts..."
                    />
                  )}
                />
                <FormErrorMessage>Please select a workout.</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.repeat_every}>
                <FormLabel>Repeat every</FormLabel>
                <HStack alignItems="center">
                  <NumberInput
                    min={1}
                    size="sm"
                    width="60px"
                    bg="white"
                    {...register('repeat_every', {
                      valueAsNumber: true,
                      required: true,
                    })}
                    onChange={(valueString) => setValue('repeat_every', valueString)}
                  >
                    <NumberInputField name="repeat_every" />
                    <NumberInputStepper>
                      <NumberIncrementStepper color="gray.500" />
                      <NumberDecrementStepper color="gray.500" />
                    </NumberInputStepper>
                  </NumberInput>

                  <Text>{pluralize(currentRepeatEveryValue, 'Week')}</Text>
                </HStack>
                <FormErrorMessage>Please add a value.</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.monday}>
                <FormLabel as="legend">Repeat on</FormLabel>

                <HStack alignItems="center">
                  {DAYS_OF_WEEK.map((day, index) => (
                    <Checkbox
                      key={index}
                      textTransform="capitalize"
                      {...register(day)}
                      colorScheme="brand"
                      borderColor="gray.300"
                    >
                      {day.substring(0, 3)}
                    </Checkbox>
                  ))}
                </HStack>

                <FormErrorMessage>Please select at least one day.</FormErrorMessage>
              </FormControl>

              <Box>
                <FormControl isInvalid={errors.endType}>
                  <FormLabel>Ends</FormLabel>
                  <Controller
                    name="endType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction="row" spacing="20px">
                          <Radio value="customEndDate" colorScheme="brand">
                            On{' '}
                            <Text as="span" fontSize="xs">
                              (Date)
                            </Text>
                          </Radio>
                          <Radio value="numTimes" colorScheme="brand">
                            After{' '}
                            <Text as="span" fontSize="xs">
                              (No. of Workouts)
                            </Text>
                          </Radio>
                          <Radio value="numDurations" colorScheme="brand">
                            Amount{' '}
                            <Text as="span" fontSize="xs">
                              (Weeks)
                            </Text>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage>Please select a value.</FormErrorMessage>
                </FormControl>
                {endType === 'customEndDate' && (
                  <FormControl isInvalid={errors.endDate} mt="2">
                    <Controller
                      control={control}
                      name="endDate"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="Select date"
                          dateFormat="d/MM/yyyy"
                          onChange={(e) => field.onChange(e)}
                          selectedDate={field.value}
                        />
                      )}
                    />
                    <FormErrorMessage>Please enter a date.</FormErrorMessage>
                  </FormControl>
                )}
                {endType === 'numTimes' && (
                  <FormControl isInvalid={errors.numTimes} mt="2">
                    <HStack as="label" alignItems="center" minHeight="40px">
                      <NumberInput
                        min={1}
                        size="sm"
                        width="80px"
                        bg="white"
                        defaultValue={1}
                        {...register('numTimes', {
                          valueAsNumber: true,
                          required: true,
                        })}
                        onChange={(valueString) => setValue('numTimes', valueString)}
                      >
                        <NumberInputField name="numTimes" />
                        <NumberInputStepper>
                          <NumberIncrementStepper color="gray.500" />
                          <NumberDecrementStepper color="gray.500" />
                        </NumberInputStepper>
                      </NumberInput>

                      <Text>{pluralize(currentNumTimesValue, 'Workout')}</Text>
                    </HStack>

                    <FormErrorMessage>Please enter a value.</FormErrorMessage>
                  </FormControl>
                )}
                {endType === 'numDurations' && (
                  <FormControl isInvalid={errors.numDurations} mt="2">
                    <HStack as="label" alignItems="center" minHeight="40px">
                      <NumberInput
                        min={1}
                        size="sm"
                        width="80px"
                        bg="white"
                        defaultValue={1}
                        {...register('numDurations', {
                          valueAsNumber: true,
                          required: true,
                        })}
                        onChange={(valueString) => setValue('numDurations', valueString)}
                      >
                        <NumberInputField name="numDurations" />
                        <NumberInputStepper>
                          <NumberIncrementStepper color="gray.500" />
                          <NumberDecrementStepper color="gray.500" />
                        </NumberInputStepper>
                      </NumberInput>

                      <Text>{pluralize(currentNumDurationsValue, 'Week')}</Text>
                    </HStack>

                    <FormErrorMessage>Please enter a value.</FormErrorMessage>
                  </FormControl>
                )}
              </Box>

              {/* if selected date is not monday then show this */}
              {getDay(new Date(bulkAddWorkoutDate)) !== 1 && (
                <FormControl>
                  <FormLabel as="legend">Back date</FormLabel>
                  <Checkbox
                    {...register('back_date_this_week')}
                    colorScheme="brand"
                    borderColor="gray.300"
                  >
                    This will add the workout to the start of this week
                  </Checkbox>
                </FormControl>
              )}
            </Stack>
            <ButtonGroup spacing="4" marginTop="6" display="flex" justifyContent="flex-end">
              <Button variant="outline" onClick={setBulkAddWorkoutOpen}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand" isLoading={isProcessing}>
                Bulk Add
              </Button>
            </ButtonGroup>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BulkAddWorkout
