const { REACT_APP_SEQ_ENV } = process.env

const STRIPE_PK_PROD_LIVE =
  'pk_live_51J0mH8LwZz5rhm1wAiMSBylvP8hI9He25z9BCA6DWjgPtH9JWsSQtBPMjrsKI8siITKAukvDnsmghaVUlWX7CDpI00PmxnIjFC'
const STRIPE_PK_PROD_TEST =
  'pk_test_51J0mH8LwZz5rhm1wj06NZZG0jpyXZ7OAbuvWzZOcWmGLnvKrxWuDg6S0fhISqcDBYuXdsMq6Ue1ziEg5Dq8h7Dms00lMJpp3ge'
const STRIPE_PK_LOCAL_TEST =
  'pk_test_51M5pMuBalUayk4e4EVJeQESlwwi2GfmNsbjydMRlLC3XHRljdETsCJdUceggeT9RDObYfgecQ0pCT3ZCZDjAIOWH00S1TybBm2'

export const STRIPE_PKEY =
  REACT_APP_SEQ_ENV === 'production'
    ? STRIPE_PK_PROD_LIVE
    : REACT_APP_SEQ_ENV === 'staging'
    ? STRIPE_PK_PROD_TEST
    : REACT_APP_SEQ_ENV === 'development'
    ? STRIPE_PK_LOCAL_TEST
    : STRIPE_PK_LOCAL_TEST

const PRICE_YEARLY_PROD_LIVE = 'price_1JKLEfLwZz5rhm1wnh7kyVjQ'
const PRICE_YEARLY_PROD_TEST = 'price_1NUfCMLwZz5rhm1w8LQKjaUU'
const PRICE_YEARLY_LOCAL_TEST = 'price_1NOrlkBalUayk4e46b8KK4k8'

const PRICE_MONTHLY_PROD_LIVE = 'price_1JKLEfLwZz5rhm1w7TqmJ4Au'
const PRICE_MONTHLY_PROD_TEST = 'price_1NUfCLLwZz5rhm1wc1L5f8I0'
const PRICE_MONTHLY_LOCAL_TEST = 'price_1NOrlkBalUayk4e4iaJR1AXw'

export const YEARLY_PRICE_ID =
  REACT_APP_SEQ_ENV === 'production'
    ? PRICE_YEARLY_PROD_LIVE
    : REACT_APP_SEQ_ENV === 'staging'
    ? PRICE_YEARLY_PROD_TEST
    : REACT_APP_SEQ_ENV === 'development'
    ? PRICE_YEARLY_LOCAL_TEST
    : PRICE_YEARLY_LOCAL_TEST

export const MONTHLY_PRICE_ID =
  REACT_APP_SEQ_ENV === 'production'
    ? PRICE_MONTHLY_PROD_LIVE
    : REACT_APP_SEQ_ENV === 'staging'
    ? PRICE_MONTHLY_PROD_TEST
    : REACT_APP_SEQ_ENV === 'development'
    ? PRICE_MONTHLY_LOCAL_TEST
    : PRICE_MONTHLY_LOCAL_TEST
