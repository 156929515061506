import { extendTheme, theme as base, withDefaultVariant } from '@chakra-ui/react'

import { modalTheme } from './components/modal'
import { inputTheme } from './components/input'
import { numberInputTheme } from './components/numberInput'
import { textareaTheme } from './components/textarea'
import { selectTheme } from './components/select'
import { headingTheme } from './components/heading'
import { menuTheme } from './components/menu'

export const globalBackgroundColour = '#F6F6F6'

const ButtonStyle = {
  baseStyle: {
    fontWeight: 'base',
    borderRadius: 'sm',
  },

  variants: {
    primary: {
      bg: 'brand.500',
      color: '#fff',
      _hover: {
        bg: 'brand.600',
        _disabled: {
          bg: 'brand.600',
        },
      },
      _focus: {
        bg: 'brand.600',
      },
      _active: {
        bg: 'brand.600',
      },
    },
    outline: {
      bg: 'white',
      _hover: {
        bg: 'white',
        _disabled: {
          bg: 'white',
        },
      },
      _focus: {
        bg: 'white',
      },
      _active: {
        bg: 'white',
      },
    },
  },
}

const theme = extendTheme(
  {
    styles: {
      global: {
        'html, body': {
          background: globalBackgroundColour,
          color: '#4A5568',
        },
        'ul, ol': {
          padding: 'revert',
        },
      },
    },
    fonts: {
      heading: base.fonts?.heading,
      body: base.fonts?.body,
    },
    colors: {
      black: {
        500: '#0E0E0E',
      },
      brand: {
        100: '#FEF6D0',
        200: '#FEEBA2',
        300: '#FEDC74',
        400: '#FDCD52',
        500: '#FDB619',
        600: '#D99512',
        700: '#B6760C',
        800: '#925A07',
        900: '#794604',
      },
    },
    components: {
      Button: {
        ...ButtonStyle,
      },
      Link: {
        variants: {
          brand: ({ colorScheme = 'brand' }) => ({
            color: `${colorScheme}.500`,
            _hover: {
              color: `${colorScheme}.400`,
            },
          }),
        },
      },
      Input: inputTheme,
      // Select: inputTheme,
      Select: selectTheme,
      Textarea: textareaTheme,
      NumberInput: numberInputTheme,
      Modal: modalTheme,
      Heading: headingTheme,
      Menu: menuTheme,
    },
  },
  withDefaultVariant({
    variant: 'filled',
    components: ['Input', 'Select', 'NumberInput'],
  })
)

export default theme
