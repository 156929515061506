import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Flex, Text, Spacer, Link, Grid, GridItem, useDisclosure } from '@chakra-ui/react'

import { tzSafeDateFormat } from 'helpers/utils'

import CategoryStrip from 'components//Workouts/CategoryStrip'

import ActivityModal from 'domain/Planner/components/ActivityModal'

const UpcomingWorkouts = ({ data, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [workoutId, setWorkoutId] = useState(null)
  const finalRef = useRef()

  const handleWorkoutClick = (id) => {
    onOpen()
    setWorkoutId(id)
  }

  const renderWorkout = ({
    id,
    activity_name,
    category_name,
    category_hex_colour,
    scheduled_at,
  }) => {
    const formattedDate = tzSafeDateFormat(scheduled_at, 'EEE')

    return (
      <GridItem w="100%" key={id}>
        <Text fontSize="smaller" fontWeight="semibold" textTransform="uppercase" color="gray.500">
          {formattedDate}
        </Text>
        <Box
          background="#fff"
          mt="1"
          p="2"
          onClick={() => handleWorkoutClick(id)}
          cursor="pointer"
          borderWidth="1px"
          borderColor="transparent"
          _hover={{ borderColor: 'yellow.500' }}
        >
          <Text fontSize="small" color="black">
            {activity_name}
          </Text>
          <CategoryStrip name={category_name} colour={category_hex_colour} />
        </Box>
      </GridItem>
    )
  }

  const renderUpcomingWorkouts = () => {
    if (!data.length) {
      return (
        <Box mb="4">
          <Text fontSize="smaller">You currently don't have any workouts planned.</Text>
        </Box>
      )
    }

    return (
      <Grid
        templateColumns={{
          sm: 'repeat(2, 1fr)',
          md: 'repeat(5, 1fr)',
        }}
        gap={6}
        mb="4"
      >
        {data.map((workout) => renderWorkout(workout))}
      </Grid>
    )
  }

  return (
    <>
      <Flex flexDirection="column" height="100%">
        <Box mb="2" ref={finalRef}>
          <Text>Upcoming Workouts</Text>
        </Box>

        {renderUpcomingWorkouts()}

        <Spacer />
        <Box display="flex" flexDirection="row-reverse">
          <Link fontSize="sm" color="yellow.500" as={NavLink} to={`/planner`}>
            View Planner
          </Link>
        </Box>
      </Flex>

      {isOpen && (
        <ActivityModal
          id={workoutId}
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={finalRef}
          refresh={refresh}
        />
      )}
    </>
  )
}

export default UpcomingWorkouts
