import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Alert,
  AlertDescription,
  Badge,
  Box,
  ButtonGroup,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Spacer,
  FormControl,
  FormLabel,
  IconButton,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure,
} from '@chakra-ui/react'
import { ChevronDown, ChevronUp, MoreHorizontal } from 'react-feather'

import { useForm, Controller } from 'react-hook-form'

import { useQuery } from 'hooks'
import { tzSafeDateFormat, tzSafeDatetimeFormat } from 'helpers/utils'
import { revalidateLiveQueries } from 'helpers/swrConfig'

import securedApi from 'backend/axios'

import { processApiError, pluralize } from 'helpers/utils'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import RichTextArea from 'components/RichTextArea'
import LastXDaysStatic from 'components/General/LastXDaysStatic'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import ClientCoachingStatus from './Components/ClientCoachingStatus'
import ClientInvite from './Components/ClientInvite'
import ClientCancelInvite from './Components/ClientCancelInvite'
import ClientDate from './Components/ClientDate'
import ClientGoals from './Components/ClientGoals'
import ColorSchemePicker from './Components/ColorSchemePicker'

import ClientStats from './Components/ClientStats'

import { WorkoutsTable } from 'domain/Metrics/WorkoutsTable/WorkoutsTable'

import { PENDING_TYPE, INVITED_TYPE, ACTIVE_TYPE, INACTIVE_TYPE } from './constants'

const CoachingClient = () => {
  const { id } = useParams()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose } = useDisclosure()
  const {
    isOpen: isCancelInviteOpen,
    onOpen: onCancelInviteOpen,
    onClose: onCancelInviteClose,
  } = useDisclosure()
  const { isOpen: isNotesOpen, onToggle: onNotesToggle } = useDisclosure()
  const { isOpen: isDateOpen, onOpen: onDateOpen, onClose: onDateClose } = useDisclosure()

  const { data, isLoading, hasError } = useQuery(`api/v1/coaching/connections/${id}`)

  const [processing, setProcessing] = useState(false)
  const recentWorkoutsDays = 10
  const recentStatsDays = 7

  const { control, handleSubmit } = useForm()

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { client_id, goals, start_date, status, invited_at, num_scheduled_activities, metrics } =
    data
  const { name, email } = data.client

  const showRecentWorkouts = ![PENDING_TYPE, INVITED_TYPE, INACTIVE_TYPE].includes(status)
  const showRecentStats = ![PENDING_TYPE, INVITED_TYPE, INACTIVE_TYPE].includes(status)

  const onSubmit = ({ notes }) => {
    setProcessing(true)

    securedApi
      .put(`api/v1/coaching/connections/${id}`, {
        notebook: notes,
      })
      .then(() => {
        Notify({ content: 'Notes updated', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error updating note. Please try again.')
      })
      .finally(() => {
        setProcessing(false)
        revalidateLiveQueries()
      })
  }

  const formattedStartDate = start_date ? (
    tzSafeDateFormat(start_date, 'd/M/yyyy')
  ) : (
    <Text as="span" fontStyle="italic">
      No date set
    </Text>
  )

  const statusColourScheme = {
    active: 'green',
    pending: 'yellow',
    invited: 'yellow',
    inactive: 'red',
  }

  return (
    <>
      <Container maxW="container.lg" pb="6">
        <InnerPageWrapperActions>
          {[ACTIVE_TYPE, INACTIVE_TYPE].includes(status) && (
            <Menu gutter="0">
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={
                  <Icon
                    as={MoreHorizontal}
                    w="4"
                    h="4"
                    color="gray.500"
                    _hover={{ cursor: 'pointer', color: 'brand.500' }}
                  />
                }
                variant="none"
              />
              <MenuList fontSize="small">
                <MenuItem onClick={onOpen} p="2">
                  Change coaching status
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </InnerPageWrapperActions>

        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
          <GridItem colSpan={4}>
            <Card>
              <CardHeader paddingBottom="0">
                <Flex alignItems="center">
                  <Heading size="md" textTransform="capitalize">
                    {name}
                    {status === 'active' ? (
                      <Badge colorScheme={ColorSchemePicker(num_scheduled_activities)} ml="2">
                        {num_scheduled_activities} {pluralize(num_scheduled_activities, 'workout')}{' '}
                        scheduled
                      </Badge>
                    ) : (
                      <Badge ml="4" colorScheme={statusColourScheme[status]}>
                        {status}
                      </Badge>
                    )}
                  </Heading>
                  <Spacer />
                </Flex>
              </CardHeader>

              <CardBody pt="2">
                <Text fontSize="sm" textTransform="lowercase" color="gray.500">
                  {email}
                </Text>

                <Text fontSize="sm" color="gray.500">
                  Start date - {formattedStartDate}{' '}
                  <Button
                    variant="unstyled"
                    size="xs"
                    _hover={{ cursor: 'pointer', color: 'brand.500' }}
                    onClick={onDateOpen}
                  >
                    (Edit)
                  </Button>
                </Text>

                {status === PENDING_TYPE && (
                  <Alert status="warning" mt="2">
                    <AlertDescription fontSize="sm">
                      {`Currently ${name} is in a pending coaching status. This means you can plan and get things all setup. Once you are ready, simply invite them to Sequence.`}

                      <Box>
                        <Button variant="primary" size="sm" mt="1" onClick={onInviteOpen}>
                          Invite
                        </Button>
                      </Box>
                    </AlertDescription>
                  </Alert>
                )}

                {status === INVITED_TYPE && (
                  <Alert status="warning" mt="2">
                    <AlertDescription fontSize="sm">
                      {`${name} hasn't completed the sign up process yet. You can continue to plan and get things all setup. Once they sign up you'll get an email.`}

                      {invited_at.length > 0 && (
                        <Text fontStyle="italic">
                          Invite{invited_at.length === 1 ? '' : 's'} sent at:
                          {invited_at.map((x, index) => (
                            <Text key={index} ml="2">
                              {tzSafeDatetimeFormat(x, 'tt - d/MM/yyyy')}
                            </Text>
                          ))}
                        </Text>
                      )}

                      <Flex gap={2}>
                        <Button variant="primary" size="sm" mt="1" onClick={onInviteOpen}>
                          Reinvite
                        </Button>
                        <Button
                          variant="ghost"
                          colorScheme="red"
                          size="sm"
                          mt="1"
                          onClick={onCancelInviteOpen}
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </AlertDescription>
                  </Alert>
                )}

                <Box pt="2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mt="6">
                      <FormLabel
                        display="flex"
                        alignItems="center"
                        onClick={onNotesToggle}
                        _hover={{ cursor: 'pointer' }}
                      >
                        Notes{' '}
                        <Text as="span" fontSize="sm" color="gray.500" ml="1">
                          (Enter notes about this client (e.g. training history, injuries, etc.)
                        </Text>
                        <Icon
                          as={isNotesOpen ? ChevronUp : ChevronDown}
                          w="5"
                          h="5"
                          color="gray.500"
                          ml="1"
                          strokeWidth="1px"
                          _hover={{ cursor: 'pointer', color: 'brand.500' }}
                        />
                      </FormLabel>
                      <Collapse in={isNotesOpen} animateOpacity>
                        <Controller
                          name="notes"
                          defaultValue={data.notebook}
                          control={control}
                          render={({ field }) => <RichTextArea {...field} />}
                        />

                        <ButtonGroup spacing="4" marginTop="4" display="flex" width="100%">
                          <Spacer />
                          <Button type="submit" colorScheme="brand" isLoading={processing}>
                            Save
                          </Button>
                        </ButtonGroup>
                      </Collapse>
                    </FormControl>
                  </form>
                </Box>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <ClientGoals {...{ goals }} />
          </GridItem>
        </Grid>
      </Container>

      {showRecentStats && (
        <Container maxW="container.lg" pb="6">
          <Card>
            <CardHeader paddingBottom="0">
              <Heading size="sm">Recent Stats</Heading>
              <LastXDaysStatic days={recentStatsDays} />
            </CardHeader>
            <CardBody pt="0">
              <ClientStats data={metrics} />
            </CardBody>
          </Card>
        </Container>
      )}

      {showRecentWorkouts && (
        <Container maxW="container.lg" pb="6" mb="28">
          <Card>
            <CardHeader paddingBottom="0">
              <Heading size="sm">Recent workouts</Heading>
              <LastXDaysStatic days={recentWorkoutsDays} />
            </CardHeader>
            <CardBody pt="0" maxHeight="500px" overflow="auto">
              <WorkoutsTable days={recentWorkoutsDays} userId={client_id} />
            </CardBody>
          </Card>
        </Container>
      )}

      <ClientCoachingStatus defaultStatus={status} {...{ id, isOpen, onClose }} />
      <ClientInvite
        isOpen={isInviteOpen}
        onClose={onInviteClose}
        {...{ id, name, email, status }}
      />
      <ClientCancelInvite
        isOpen={isCancelInviteOpen}
        onClose={onCancelInviteClose}
        {...{ id, name, email }}
      />
      <ClientDate isOpen={isDateOpen} onClose={onDateClose} {...{ id }} />
    </>
  )
}

export default CoachingClient
