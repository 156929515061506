import React from 'react'
import { Alert, AlertIcon } from '@chakra-ui/react'

const Error = ({ children }) => {
  return (
    <Alert status="error" variant="subtle">
      <AlertIcon />
      {children || 'An error has occurred. Please try again or contact support.'}
    </Alert>
  )
}

export default Error
