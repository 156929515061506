import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Link,
  Text,
  Icon,
  FormControl,
  FormLabel,
  SimpleGrid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  InputGroup,
  InputRightAddon,
  Tooltip,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { BarChart2 as PreviousDataIcon, Info as InfoIcon } from 'react-feather'
import { range } from 'lodash'

import securedApi from 'backend/axios'
import { processApiError } from 'helpers/utils'

import { ActivityModalHeader } from 'domain/Widgets/components'
import WidgetMeasureModal from './WidgetMeasureModal'

const WidgetMeasures = ({
  measures,
  onSetMeasures,
  scheduledActivityId,
  highlightMeasureId,
  readOnly,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  // measure to pass to modal
  const [measure, setMeasure] = useState(null)

  // so that updating onSetMeasures triggers a rerender
  // for when updatedProp is changed (so info icon disappears)
  const [forceRerender, setForceRerender] = useState(false)

  useEffect(() => {
    if (forceRerender) {
      setForceRerender(false)
    }
  }, [forceRerender])

  // TODO to helper and shared between web/mobile
  // sequence-mobile/views/schedule/widgets/WidgetMeasures.js
  // sequence-web/frontend/src/domain/Widgets/Measure/ActivityComponents/WidgetMeasures.js
  const suffixProps = (num) => {
    const suffix = num === 1 ? '' : `_${num}`
    const valueProp = 'value' + suffix
    const defaultValueProp = 'default_value' + suffix
    const updatedProp = 'updated' + suffix
    const unitsProp = 'units' + suffix

    return {
      valueProp: valueProp,
      defaultValueProp: defaultValueProp,
      updatedProp: updatedProp,
      unitsProp: unitsProp,
    }
  }

  // TODO to helper and shared between web/mobile
  // sequence-mobile/views/schedule/widgets/WidgetMeasures.js
  // sequence-web/frontend/src/domain/Widgets/Measure/ActivityComponents/WidgetMeasures.js
  const processAndSendWidgetMeasureControl = (
    measure,
    value,
    num,
    scheduledActivityId,
    onSetMeasures
  ) => {
    const { valueProp, defaultValueProp, updatedProp } = suffixProps(num)

    const oldValue = measure[valueProp]
    const defaultValue = measure[defaultValueProp]

    // don't do anything if default value is unchanged
    // it is important to have the oldValue === null condition (which means it has not been saved)
    if (parseFloat(value) === parseFloat(defaultValue) && oldValue === null) {
      return
    }

    // don't do anything if value is not changed
    if (parseFloat(value) === parseFloat(oldValue)) {
      return
    }

    const newState = measures
    const index = measures.findIndex((m) => m.widget_measure_id === measure.widget_measure_id)

    newState[index][valueProp] = value
    newState[index][updatedProp] = true
    onSetMeasures(newState)

    // update entry or create one if none exists yet
    let putParams = {
      scheduled_activity_id: scheduledActivityId,
      widget_measure_id: measure.widget_measure_id,
      notes: measure.notes,
    }

    // need to add the valueProp param independently so that changing one value
    // does not send params for any others
    putParams[valueProp] = value

    securedApi
      .put(`api/v1/widget_measure_entries/control`, {
        id: measure.id,
        widget_measure_entry: putParams,
      })
      .then((response) => {
        newState[index].id = response.data.id
        onSetMeasures(newState)
      })
      .catch((error) => processApiError(error))
  }

  const handleChange = ({ event, measure, num }) => {
    const { value } = event.target

    processAndSendWidgetMeasureControl(measure, value, num, scheduledActivityId, onSetMeasures)

    setForceRerender(true)
  }

  const handleLoadPreviousMeasureDataModal = (measure) => {
    setMeasure(measure)
    onOpen()
  }

  const MeasureNumberInput = ({ measure, num }) => {
    const { valueProp, defaultValueProp, updatedProp, unitsProp } = suffixProps(num)

    const showInfoNoRecords = measure.no_entries && !measure[updatedProp]
    const showInfoSmartDefault = !showInfoNoRecords && !measure[updatedProp]

    // if no value in the set and reps we don't want to show the input
    //sets
    if (num === 2 && measure['default_value_2'] === null) {
      return null
    }

    //reps
    if (num === 3 && measure['default_value_3'] === null) {
      return null
    }

    const MeasureInfoIcon = ({ children }) => {
      // the NumberIncrementStepper is hijacked for use as an info icon
      return (
        <NumberInputStepper>
          <NumberIncrementStepper
            border={'none'}
            cursor="default"
            children={
              <Tooltip label={children}>
                <Icon as={InfoIcon} w={4} h={4} />
              </Tooltip>
            }
          />
        </NumberInputStepper>
      )
    }

    return (
      <InputGroup size="sm" width="auto">
        <NumberInput
          name={valueProp}
          size="sm"
          width="100px"
          step={0} // to prevent NumberIncrementStepper functionality
          defaultValue={measure[updatedProp] ? measure[valueProp] : measure[defaultValueProp]}
          onBlur={(event) => handleChange({ event, measure, num })}
          isReadOnly={readOnly}
        >
          {showInfoNoRecords && (
            <MeasureInfoIcon>
              <Box textAlign="center">
                <Text fontSize="xs">Enter a value for this measure,</Text>
                <Text fontSize="xs">leave it blank if no value is needed</Text>
              </Box>
            </MeasureInfoIcon>
          )}
          {showInfoSmartDefault && (
            <MeasureInfoIcon>
              <Box textAlign="center">
                <Text>This was the last value you entered</Text>
                <Text fontSize="xs">(populated with Smart Default)</Text>
                <Text>
                  You can change or clear the value as you need, or when you complete the workout it
                  will save this same value for the measure
                </Text>
              </Box>
            </MeasureInfoIcon>
          )}
          <NumberInputField />
        </NumberInput>
        <InputRightAddon size="sm" children={measure[unitsProp]} />
      </InputGroup>
    )
  }

  if (!measures?.length) {
    return null
  }

  const gridColumns = measures.length > 3 ? 2 : 1

  return (
    <>
      <FormControl>
        <ActivityModalHeader label="Measures" />
        <SimpleGrid columns={gridColumns} spacing={2}>
          {measures.map((measure) => {
            const isHighlighted = highlightMeasureId === measure.widget_measure_id
            const bgStyle = isHighlighted
              ? {
                  backgroundColor: '#FEDC74',
                  padding: 8,
                  borderRadius: 6,
                  display: 'inline-block',
                }
              : {}

            return (
              <Box
                key={measure.name}
                mb={3}
                style={{
                  ...bgStyle,
                }}
              >
                <Flex flexDirection="row" alignItems="center" mb="1">
                  <Text fontSize="sm">{measure.name}</Text>
                  <Link
                    as="span"
                    color="brand.600"
                    onClick={() => handleLoadPreviousMeasureDataModal(measure)}
                    _hover={{ cursor: 'pointer', color: 'brand.500' }}
                  >
                    <Icon as={PreviousDataIcon} w={5} h={5} pb={1} />
                  </Link>
                </Flex>
                <HStack>
                  {
                    // add a number input for each dimension on this measure
                    range(1, measure.dimensions + 1).map((num, i) => {
                      return <MeasureNumberInput key={i} measure={measure} num={num} />
                    })
                  }
                </HStack>
              </Box>
            )
          })}
        </SimpleGrid>
      </FormControl>
      <WidgetMeasureModal {...{ isOpen, onOpen, onClose, measure }} />
    </>
  )
}

export default WidgetMeasures
