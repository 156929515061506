import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Spacer,
  HStack,
  Button,
  Icon,
  Tooltip,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react'
import { Trash2 } from 'react-feather'

import securedApi from 'backend/axios'
import Modal from 'components/Modal'
import Notify from 'components/Notification'
import { processApiError } from 'helpers/utils'

import EditCategoryItem from './EditCategoryItem'

const CategoryItem = ({ item, onUpdate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isHover, setIsHover] = useBoolean()
  const [isEdit, setIsEdit] = useBoolean()

  const isNoneCategory = item.name === 'None'

  const handleDelete = () => {
    securedApi
      .delete(`api/v1/categories/${item.id}`)
      .then(() => {
        onUpdate()
        onClose()
        Notify({ content: 'Category deleted', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, "Can't delete category")
      })
  }

  if (isNoneCategory) {
    return (
      <Card rounded="0" border="1px" borderColor="transparent" cursor="default">
        <Tooltip label="This category can't be changed or removed">
          <CardBody>
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Box width="40px" height="40px" backgroundColor={item.hex_colour} mr="4" />
                <Heading size="sm">{item.name}</Heading>
              </Flex>
            </Flex>
          </CardBody>
        </Tooltip>
      </Card>
    )
  }

  return (
    <>
      <Card
        onMouseOver={setIsHover.on}
        onMouseOut={setIsHover.off}
        rounded="0"
        border="1px"
        borderColor="transparent"
        _hover={{ borderColor: 'brand.500', cursor: 'pointer' }}
      >
        <CardBody>
          {isEdit ? (
            <EditCategoryItem onClose={setIsEdit.off} {...{ item, onUpdate }} />
          ) : (
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Box width="40px" height="40px" backgroundColor={item.hex_colour} mr="4" />
                <Heading size="sm">{item.name}</Heading>
              </Flex>
              <Spacer />

              <HStack display={isHover ? 'flex' : 'none'} spacing="4">
                <Tooltip label={'Delete category'}>
                  <Icon
                    as={Trash2}
                    w="5"
                    h="5"
                    color="gray.500"
                    _hover={{ cursor: 'pointer', color: 'red.500' }}
                    onClick={onOpen}
                  />
                </Tooltip>
                <Button onClick={setIsEdit.on}>Edit</Button>
              </HStack>
            </Flex>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpen}
        closeModal={onClose}
        title={`Are you sure you want to delete the "${item.name}" category?`}
        subTitle="The category will be removed from your workouts. This action cannot be undone."
        onAccept={handleDelete}
      ></Modal>
    </>
  )
}

export default CategoryItem
