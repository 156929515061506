import React from 'react'
import { Flex, Box } from '@chakra-ui/react'

import { pluralize } from '../../../helpers/utils'

const WorkoutHours = ({ value }) => {
  return (
    <Flex justifyContent={{ base: 'none', md: 'center' }}>
      <Box fontSize={{ base: '8xl', md: '9xl' }} color="gray.600">
        {value}
      </Box>
      <Box mt={{ base: '9', md: '12' }} ml="4" fontSize="lg" color="gray.600">
        {`You have spent`}
        <br /> {`${pluralize(value, 'hour')} training this workout`}
      </Box>
    </Flex>
  )
}

export default WorkoutHours
