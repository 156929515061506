import { create } from 'zustand'

const usePlannerNote = create((set) => ({
  plannerNoteOpen: false,
  plannerNoteId: null,
  setPlannerNoteOpen: () => set(() => ({ plannerNoteOpen: true })),
  setPlannerNoteClose: () => set(() => ({ plannerNoteOpen: false })),
  setPlannerNoteId: (value) => set(() => ({ plannerNoteId: value })),
}))

export default usePlannerNote
