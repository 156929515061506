import React, { useEffect, useState } from 'react'
import { Badge, Button, Box, Text, VStack, HStack, Spacer, useDisclosure } from '@chakra-ui/react'
import { Edit as EditIcon, X as RemoveWidgetIcon, ChevronUp, ChevronDown } from 'react-feather'
import { v4 as uuidv4 } from 'uuid'

// import { Box, Button, Icon, useDisclosure } from '@chakra-ui/react'
import { PlusCircle } from 'react-feather'

import securedApi from 'backend/axios'

import Icon from 'components/Icons/Icon'
import Notify from 'components/Notification'
import Modal from 'components/Modal'

import { processApiError, pluralize } from 'helpers/utils'

import {
  LOGBOOK_TYPE,
  MEASURE_TYPE,
  SELECT_TYPE,
  SLIDER_TYPE,
  LOGBOOK_ENDPOINT,
  MEASURE_ENDPOINT,
  TEMPLATED_MEASURE_ENDPOINT,
  SLIDER_ENDPOINT,
  LOGBOOK_RESOURCE,
  MEASURE_RESOURCE,
  TEMPLATED_MEASURE_RESOURCE,
  SLIDER_RESOURCE,
} from 'domain/Widgets/constants'
import { useWidgetMethods } from 'domain/Widgets/hooks'

import { AddWidgetModal, EditWidgetModal, WidgetIcon } from 'domain/Widgets/components'

const WorkoutWidgets = ({ activityId, widgets, setCreateWidgets, templatedActivity }) => {
  const [editWidgetData, setEditWidgetData] = useState({})
  const [widgetIndex, setWidgetIndex] = useState(null)

  const {
    isOpen: isEditWidgetOpen,
    onOpen: onEditWidgetOpen,
    onClose: onEditWidgetClose,
  } = useDisclosure()
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
  const {
    isOpen: isConfirmRemoveOpen,
    onOpen: onConfirmRemoveOpen,
    onClose: onConfirmRemoveClose,
  } = useDisclosure()

  const isEditingActivity = !!activityId

  const groupedWidgets = widgets.reduce((acc, widget) => {
    const key = widget.type
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(widget)
    return acc
  }, {})

  const widgetGroups = Object.keys(groupedWidgets)

  useEffect(() => {
    setWidgetIndex(null)
  }, [widgets])

  const {
    handleAddNewWidget,
    handleAddExistingWidget,
    handleCreateWidget,
    handleUpdateWidget,
    handleRemoveWidget,
    handleDeleteWidget,
  } = useWidgetMethods({
    isWorkoutsPage: true,
    setCreateWidgets,
    widgets,
    setEditWidgetData,
    onEditWidgetOpen,
    onEditWidgetClose,
    onConfirmRemoveOpen,
    onConfirmRemoveClose,
    templatedActivity,
    activityId,
  })

  const handleOpenEditWidget = (widget) => {
    const currentIndex = widgets.findIndex((i) => i.id === widget.id)
    setEditWidgetData(widget)
    setWidgetIndex(currentIndex)
    onEditWidgetOpen()
  }

  // these could stay in here for now, only for WorkoutWidgets.js
  // be nice to put them all into hook though
  const handleConfirmRemoveWidget = (widget) => {
    const currentIndex = widgets.findIndex((i) => i.id === widget.id)
    setWidgetIndex(currentIndex)

    // what is going on with this bit?
    // bypass the confirm modal?
    if (widget.type === SLIDER_TYPE) {
      handleRemoveWidget(widgets, currentIndex)
    } else {
      onConfirmRemoveOpen()
    }
  }

  // same for all widgets
  const handleMove = (widget, direction) => {
    const delta = direction === 'up' ? -1 : 1
    const idxOld = widgets.findIndex((i) => i.id === widget.id)
    const idxNew = idxOld + delta

    const fieldsNew = [...widgets]
    const field = fieldsNew.splice(idxOld, 1)[0]
    fieldsNew.splice(idxNew, 0, field)

    const endpoint = `api/v1/${
      templatedActivity ? 'templated_activities' : 'activities'
    }/${activityId}/reorder_widget`

    const payload = {
      ...(!templatedActivity && { widget_id: widget.id }),
      ...(!templatedActivity && { widget_type: widget.type }),
      ...(templatedActivity && { templated_widget_id: widget.id }),
      ...(templatedActivity && { templated_widget_type: widget.type }),
      pos: direction,
    }

    if (isEditingActivity) {
      securedApi
        .put(endpoint, payload)
        .then(() => {
          setCreateWidgets(fieldsNew)
        })
        .catch((error) => {
          processApiError(error, "Can't move widget")
        })
    } else {
      setCreateWidgets(fieldsNew)
    }
  }

  const handleMoveUp = (widget) => {
    handleMove(widget, 'up')
  }

  const handleMoveDown = (widget) => {
    handleMove(widget, 'down')
  }

  const renderMoveIcons = ({ groupedWidgets, widget, index }) => {
    //Don't show if only one widget. Doesn't make sense to reorder only 1 widget
    if (groupedWidgets.length === 1) {
      return
    }

    return (
      <>
        {index !== 0 && (
          <Icon
            as={ChevronUp}
            color="gray.400"
            tooltipContent="Move up"
            onClick={() => handleMoveUp(widget)}
          />
        )}

        {index !== groupedWidgets.length - 1 && (
          <Icon
            as={ChevronDown}
            color="gray.400"
            tooltipContent="Move down"
            onClick={() => handleMoveDown(widget)}
          />
        )}
      </>
    )
  }

  const renderWorkoutWidgets = () => {
    if (!widgetGroups.length) {
      return null
    }

    return widgetGroups.map((key, index) => {
      const widget = groupedWidgets[key][0]
      const widgetType = widget.type

      const widgetGroupTitle = pluralize(groupedWidgets[key].length, widgetType)

      return (
        <Box key={index} mt="2" mb="2" width="100%">
          <Badge pt={1.5} pl={1.5} pr={2} colorScheme="brand" mb="1">
            <HStack alignContent="center" mb="1">
              <WidgetIcon type={widgetType} boxSize={3} />
              <Text>{widgetGroupTitle}</Text>
            </HStack>
          </Badge>

          {groupedWidgets[key].map((w, i) => {
            return (
              <HStack
                key={w.id}
                spacing="6"
                background="white"
                paddingY="2"
                paddingX="4"
                width="100%"
                borderWidth="1px"
                borderColor="gray.400"
                justifyContent="center"
                mt="1"
              >
                <Box>
                  <Text mt="1" fontWeight="semibold" textTransform="capitalize">
                    {w.content.name}
                  </Text>
                </Box>

                <Spacer />

                {w.type !== 'slider' && (
                  <>
                    {renderMoveIcons({ groupedWidgets: groupedWidgets[key], widget: w, index: i })}
                    <Icon
                      as={EditIcon}
                      onClick={() => handleOpenEditWidget(w)}
                      tooltipContent="Edit widget"
                    />
                  </>
                )}

                <Icon
                  as={RemoveWidgetIcon}
                  onClick={() => handleConfirmRemoveWidget(w)}
                  tooltipContent="Remove widget"
                />
              </HStack>
            )
          })}
        </Box>
      )
    })
  }

  return (
    <>
      <VStack align="flex-start" spacing="3">
        {renderWorkoutWidgets()}
        <Button
          data-cy="add-widget"
          onClick={onAddOpen}
          colorScheme="brand"
          variant="outline"
          rightIcon={<Icon as={PlusCircle} w="4" h="4" stroke="1px" color="brand" />}
        >
          Add Widget
        </Button>

        <AddWidgetModal
          onAddNew={handleAddNewWidget}
          onAddExisting={handleAddExistingWidget}
          isOpen={isAddOpen}
          onClose={onAddClose}
          activity={true} // is this activity page or widgets page (could be better named)
          activityId={activityId}
          templatedActivity={templatedActivity}
          widgetsAddedToWorkout={widgets}
        />
      </VStack>
      {isEditWidgetOpen && (
        <EditWidgetModal
          type={editWidgetData.type}
          widget={editWidgetData}
          onCreateWidget={({ type, widgetData }) => handleCreateWidget({ type, widgetData })}
          onUpdateWidget={(updatedWidgetData) =>
            handleUpdateWidget(updatedWidgetData, widgets, widgetIndex)
          }
          onDeleteWidget={() => handleDeleteWidget(editWidgetData, widgets, widgetIndex)}
          isEditWidgetOpen={isEditWidgetOpen}
          onEditWidgetClose={onEditWidgetClose}
          {...{ activityId }}
        />
      )}

      <Modal
        isOpen={isConfirmRemoveOpen}
        closeModal={onConfirmRemoveClose}
        title="Remove widget from workout?"
        onAccept={() => handleRemoveWidget(widgets, widgetIndex)}
      >
        <VStack spacing={2}>
          <Text>
            This will only remove the widget from this workout, it won't delete the widget or any
            data associated with it.
          </Text>
          <Text>
            If you do want to delete the widget completely, edit this widget and choose delete.
          </Text>
        </VStack>
      </Modal>
    </>
  )
}

export default WorkoutWidgets
