import { useState } from 'react'
import { Box, Flex, useBoolean } from '@chakra-ui/react'
import { CirclePicker } from 'react-color'

import { categoryColours } from 'helpers/colours'

const CategoryColorPicker = ({ currentColor, onSetColor }) => {
  const [color, setColor] = useState(currentColor)
  const [isPickerVisible, setPickerVisible] = useBoolean()

  const handleChange = (color) => {
    setColor(color.hex)
    onSetColor(color.hex)
    setPickerVisible.off()
  }

  return (
    <Flex alignItems="center">
      <Box onClick={setPickerVisible.on}>
        <Box
          width="40px"
          height="40px"
          backgroundColor={color}
          mr="4"
          _hover={{ opacity: '.8', cursor: 'pointer' }}
        />
      </Box>
      {isPickerVisible && (
        <Box
          mt="1"
          position="absolute"
          zIndex="2"
          background="white"
          p="4"
          borderRadius="2px"
          boxShadow="0 0 0 1px rgba(0,0,0,.1)"
        >
          <Box
            position="fixed"
            top="0"
            right="0"
            bottom="0"
            left="0"
            onClick={setPickerVisible.off}
          />
          <CirclePicker {...{ color }} colors={categoryColours} onChange={handleChange} />
        </Box>
      )}
    </Flex>
  )
}

export default CategoryColorPicker
