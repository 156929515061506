import React, { useEffect, useRef, useMemo } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { AlertTriangle as Warning } from 'react-feather'

import securedApi from 'backend/axios'
import { useQuery, useImpersonate } from 'hooks'
import { pluralize } from 'helpers/utils'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'
import HeaderDivider from 'components/General/HeaderDivider'
import CategoryStrip from 'components/Workouts/CategoryStrip'

const flagsTextLookup = {
  already_has_name: 'Client already has a workout with this name',
  already_has_name_coach: 'Client already has a workout from a coach with this name',
}

const BulkCoachingWorkouts = () => {
  const { data, isLoading, hasError, mutate } = useQuery(
    `api/v1/templated_activities?bulk_list=true`
  )

  const impersonateData = useImpersonate((state) => state.impersonateData)
  const isSharable = impersonateData.impersonating
  const { impersonateUserId } = impersonateData

  const [rowSelection, setRowSelection] = React.useState({})

  useEffect(() => {
    if (impersonateUserId) {
      mutate()
    }
  }, [impersonateUserId, mutate])

  const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }) => {
    const ref = useRef(null)

    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return <input type="checkbox" ref={ref} {...rest} />
  }

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        header: 'Select Workouts',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'activity_name',
            header: '',

            cell: (info) => {
              const { flags } = info.row.original
              const bullet = flags.length > 1 ? '• ' : ''
              const flagsText = flags.map((f) => (
                <Text>
                  {bullet}
                  {flagsTextLookup[f]}
                </Text>
              ))

              return (
                <Flex minW="150px" justifyContent="space-between">
                  <Text as="b">{info.getValue()}</Text>
                  <Box marginLeft={4}>
                    {flags.length > 0 && (
                      <Tooltip label={flagsText}>
                        <Icon as={Warning} color="gray.400" />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              )
            },

            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'category_hex_colour',
            header: '',
            cell: (props) => (
              <Box minW="100px" textAlign="center">
                <CategoryStrip
                  name={props.row.original.category_name}
                  colour={props.row.original.category_hex_colour}
                />
              </Box>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'activity_description',
            header: '',
            cell: (info) => (
              <Box>
                <Text fontSize="sm">{info.getValue()}</Text>
              </Box>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },

    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),

    getRowId: (rowData, rowIndex) => (!!rowData.id ? rowData.id : rowIndex),
  })

  const renderAddButtonContent = () => {
    const num = Object.keys(rowSelection).length
    const text = pluralize(num, 'workout')

    if (num === 0) {
      return 'No workouts selected'
    }

    return `Add ${num} ${text} to client`
  }

  const handleAddWorkoutsToClient = () => {
    const selectedWorkouts = Object.keys(rowSelection)

    securedApi
      .post(`/api/v1/templated_activities/import_bulk`, {
        templated_activity_ids: selectedWorkouts,
      })
      .then(({ data }) => {
        // just use FE count at the moment
        // can return more data from backend (i.e. num added, num not added if necessary)
        const num = Object.keys(rowSelection).length
        const text = pluralize(num, 'workout')
        Notify({ content: `Added ${num} ${text} to this client`, type: 'success' })
        mutate()
        table.toggleAllRowsSelected(false)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions />

      <HeaderDivider heading="Bulk Add Workouts" />

      {!isSharable ? (
        <Alert status="warning">
          <AlertIcon />
          Select a client to bulk add workouts.
        </Alert>
      ) : (
        <Box bg="white" mb="28" pb="8">
          <Table>
            <Tbody>
              <Tr>
                <Td>
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllRowsSelected(),
                      indeterminate: table.getIsSomeRowsSelected(),
                      onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                  />
                </Td>
                <Td colSpan={20}>
                  <Text fontSize="sm" fontStyle="italic">
                    Select all workouts
                  </Text>
                </Td>
              </Tr>

              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  bg={row.getIsSelected() ? 'gray.100' : null}
                  onClick={row.getToggleSelectedHandler()}
                  _hover={{ cursor: 'pointer' }}
                >
                  {row.getVisibleCells().map((cell) => {
                    // make space for flags if activity_name column
                    const paddingRight = cell.column.id === 'activity_name' ? 0 : ''

                    return (
                      <Td key={cell.id} paddingRight={paddingRight}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Flex justifyContent="center" mt="8">
            <Button
              colorScheme="brand"
              isDisabled={!Object.keys(rowSelection).length}
              onClick={handleAddWorkoutsToClient}
            >
              {renderAddButtonContent()}
            </Button>
          </Flex>
        </Box>
      )}
    </Container>
  )
}

export default BulkCoachingWorkouts
