import React, { useState, useEffect } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  BarChart,
  Bar,
  CartesianAxis,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import Calendar from 'react-calendar'
import './Calendar.css'

import moment from 'moment'

import { useDemoData, useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import { pluralize, tzSafeNewDate } from 'helpers/utils'

import MetricsContainer from './MetricsContainer'
import { BreakdownLink } from './components/CategoryBarChart'

const CustomTooltip = (props) => {
  if (!props.active) {
    return null
  }

  const payload = props.payload[0].payload

  return (
    <Box
      style={{
        border: '#bbb 1.5px solid',
        fontSize: 12,
      }}
    >
      <Box
        style={{
          padding: '7.5px 7.5px 0px 7.5px',
          backgroundColor: 'white',
        }}
      >
        <>
          <Text key={payload.id} fontWeight="semibold" color={payload.hex_colour}>
            {payload.name}
          </Text>
          {props.breakdown === 'time' ? (
            <>
              <Text>
                {payload.time} {pluralize(payload.time, 'hr')} ✓
              </Text>
              {payload.time_miss > 0 && (
                <Text>
                  {payload.time_miss} {pluralize(payload.time_miss, 'hr')} not completed
                </Text>
              )}
            </>
          ) : (
            <>
              <Text>
                {payload.total} {pluralize(payload.total, 'session')} ✓
              </Text>
              {payload.total_miss > 0 && (
                <Text>
                  {payload.total_miss} {pluralize(payload.total_miss, 'session')} not completed
                </Text>
              )}
            </>
          )}
        </>
      </Box>
    </Box>
  )
}

const CalendarChart = ({ view, data, tileLabel, breakdown, lastTick }) => {
  if (!data) {
    return (
      <>
        <ResponsiveContainer height={100}>
          <BarChart>
            <CartesianGrid fill="white" vertical={false} horizontal={false} />
          </BarChart>
        </ResponsiveContainer>
        {tileLabel && <Text>{tileLabel}</Text>}
      </>
    )
  }

  return (
    <>
      <ResponsiveContainer height={100}>
        <BarChart layout="vertical" data={data}>
          <CartesianGrid fill="white" strokeDasharray="0 0" vertical={false} horizontal={false} />
          <CartesianAxis tick={false} axisLine={false} />
          <XAxis type="number" hide={true} domain={[0, lastTick]} />
          <YAxis type="category" hide={true} dataKey={'category'} />
          <Tooltip content={<CustomTooltip breakdown={breakdown} data={data} />} cursor={false} />
          <Bar dataKey={breakdown}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={data[index].hex_colour || '#999999'} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {tileLabel && <Text>{tileLabel}</Text>}
    </>
  )
}

const MetricsZoomChart = () => {
  const [sessionBreakdownType, setSessionBreakdownType] = useState('time')

  const demoData = useDemoData((state) => state.demoData)

  const { data: zoomData, isLoading, hasError } = useQuery(`api/v1/metrics/zoom?demo=${demoData}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const noData = zoomData.days.data.length === 0

  if (noData) {
    return (
      <Box>
        <Text>You haven't completed any workouts yet</Text>
        <Text mt={4}>
          Tip: try clicking the "Display Demo Data" button above to get an idea of what this chart
          will look like!
        </Text>
      </Box>
    )
  }

  const minDate = zoomData.days.data[0].date
  const maxDate = zoomData.days.data[zoomData.days.data.length - 1].date

  return (
    <Box>
      <Flex marginLeft={3} marginTop={2} marginBottom={1}>
        <BreakdownLink
          type="time"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
        <BreakdownLink
          type="total"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
      </Flex>

      <Flex>
        <Calendar
          minDetail="year"
          defaultView="year"
          minDate={tzSafeNewDate(minDate)}
          maxDate={tzSafeNewDate(maxDate)}
          prev2Label={null}
          next2Label={null}
          formatDay={() => ''}
          tileContent={({ date, view }) => {
            const dateStr = moment(date).format('YYYY-MM-DD')
            const dayNo = moment(date).format('DD')

            if (view === 'month') {
              const dayDataNode = zoomData.days.data.find((item) => item.date === dateStr)

              if (dayDataNode) {
                const dayData = dayDataNode.data
                const maxValue = zoomData.days.meta.max_time

                return (
                  <CalendarChart
                    data={dayData}
                    view={view}
                    tileLabel={dayNo}
                    breakdown={sessionBreakdownType}
                    lastTick={maxValue}
                  />
                )
              } else {
                return <CalendarChart data={null} tileLabel={dayNo} />
              }
            } else if (view === 'year') {
              const monthDataNode = zoomData.months.data.find(
                (item) => item.start_of_month === dateStr
              )

              if (monthDataNode) {
                const monthData = monthDataNode.data
                const maxValue = zoomData.months.meta.max_time

                return (
                  <CalendarChart
                    data={monthData}
                    view={view}
                    tileLabel={''}
                    breakdown={sessionBreakdownType}
                    lastTick={maxValue}
                  />
                )
              } else {
                return <CalendarChart data={null} tileLabel={''} />
              }
            }
          }}
        />
      </Flex>
    </Box>
  )
}

export const MetricsZoom = () => {
  return (
    <MetricsContainer>
      <MetricsZoomChart />
    </MetricsContainer>
  )
}

export default MetricsZoom
