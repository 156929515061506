import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from '@chakra-ui/react'

import LoadingSpinner from 'components/LoadingSpinner'
import BasicModal from 'components/Modal'

import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

const ACTIVE = 'active'
const INACTIVE = 'inactive'

const ClientCoachingStatus = ({ id, isOpen, onClose, defaultStatus }) => {
  const [loading, setLoading] = useState(false)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)
  const { register, handleSubmit, watch } = useForm({ defaultValues: { status: defaultStatus } })

  const handleSubmitConfirmationConfirm = () => {
    handleSubmit(onSubmit)()
    setShowSubmitConfirmation(false)
  }

  const handleSubmitConfirmation = () => {
    setShowSubmitConfirmation(true)
  }

  const handleSubmitConfirmationCancel = () => {
    setShowSubmitConfirmation(false)
  }

  const onSubmit = ({ status }) => {
    setLoading(true)
    securedApi
      .put(`api/v1/coaching/connections/${id}`, {
        status,
      })
      .then(() => {
        Notify({ content: 'Status updated', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error updating status. Please try again.')
      })
      .finally(() => {
        onClose()
        revalidateLiveQueries()
        setLoading(false)
      })
  }

  const currentStatus = watch('status')

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(handleSubmitConfirmation)}>
          <ModalContent>
            <ModalHeader>Coaching Status</ModalHeader>

            {loading ? (
              <ModalBody pb="4">
                <LoadingSpinner />
              </ModalBody>
            ) : (
              <>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <Select
                      bg="white"
                      defaultValue={defaultStatus}
                      {...register('status')}
                      variant="outline"
                    >
                      <option value={ACTIVE}>Active</option>
                      <option value={INACTIVE}>Inactive</option>
                    </Select>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="brand"
                    type="submit"
                    isDisabled={currentStatus === defaultStatus}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </form>
      </Modal>
      <BasicModal
        isOpen={showSubmitConfirmation}
        closeModal={handleSubmitConfirmationCancel}
        onAccept={handleSubmitConfirmationConfirm}
        title="Are you sure?"
        subTitle={
          <Text>
            This client will {currentStatus === ACTIVE ? 'now ' : 'no longer'} be included as part
            of your monthly coaching client usage.
          </Text>
        }
      />
    </>
  )
}

export default ClientCoachingStatus
