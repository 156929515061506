import React from 'react'
import { Badge, Text, Tooltip, Spacer, Icon, Box } from '@chakra-ui/react'
import { format } from 'date-fns'
import { Activity } from 'react-feather'

import { useDailyMetrics } from 'domain/DailyMetrics/hooks'

import RestDayAction from './RestDayAction'

const ExtraDayDetails = ({ data, day, openMensutralCycleForDay }) => {
  const date = format(day, 'yyyy-MM-dd')
  const dayData = data.find((workout) => workout.date === date)

  const setDailyMetricsDate = useDailyMetrics((state) => state.setDailyMetricsDate)
  const setDailyMetricsVisible = useDailyMetrics((state) => state.setDailyMetricsVisible)

  if (dayData === undefined) {
    return
  }

  const { notes, biometrics } = dayData

  const showRestDay = notes.find((i) => i.style === 'rest')
  const showDailyMetrics = biometrics.present === true
  const showMenstrualCycle = !!biometrics.cycle_day

  const DailyMetricsIcon = () => (
    <Icon
      as={Activity}
      onClick={() => {
        setDailyMetricsDate(date)
        setDailyMetricsVisible()
      }}
      cursor="pointer"
      color="gray.800"
      strokeWidth="1px"
      w="3"
      h="3"
      ml="2"
      mt="1"
    />
  )

  const MenstrualCycleIcon = ({ details }) => {
    const pickVariant = (menstrualCyclePredicted, menstrualCycleStart) => {
      if (menstrualCyclePredicted) {
        return 'outline'
      } else {
        return 'subtle'
      }
    }

    const pickColorScheme = (menstrualCyclePredicted, menstrualCycleStage) => {
      switch (menstrualCycleStage) {
        case 'Menstruation':
          return 'blue'
        case 'Folicular':
          return 'blue'
        case 'Ovulation':
          return 'orange'
        case 'Luteal':
          return 'green'
      }
    }

    const menstrualCycleDay = details.cycle_day
    const menstrualCycleStart = details.cycle_start
    const menstrualCyclePredicted = details.predicted
    const menstrualCycleStage = details.stage

    const cycleWeek = Math.trunc((menstrualCycleDay - 1) / 7) + 1
    const cycleWeekDay = ((menstrualCycleDay - 1) % 7) + 1

    const variant = pickVariant(menstrualCyclePredicted, menstrualCycleStart)
    const colorScheme = pickColorScheme(menstrualCyclePredicted, menstrualCycleStage)

    return (
      <Tooltip
        label={
          <Text textAlign="center">
            {menstrualCycleStage}
            <br />
            Week {cycleWeek} Day {cycleWeekDay}
            <br />
            {menstrualCyclePredicted && 'Predicted'}
          </Text>
        }
      >
        <Badge
          variant={variant}
          colorScheme={colorScheme}
          marginLeft="2"
          cursor="pointer"
          fontSize="xx-small"
          height="14px"
          mt="0.5"
          onClick={() => openMensutralCycleForDay(day)}
        >
          {menstrualCycleDay}
        </Badge>
      </Tooltip>
    )
  }

  return (
    <Box key={dayData.id} display="flex" my="2" width="100%">
      <Spacer />
      {showMenstrualCycle && <MenstrualCycleIcon details={biometrics} />}
      {showRestDay && <RestDayAction {...{ notes }} />}
      {showDailyMetrics && <DailyMetricsIcon />}
    </Box>
  )
}

export default ExtraDayDetails
