import React from 'react'
import { Text } from '@chakra-ui/react'

export const CategoryStrip = ({ name, colour, inline }) => {
  return (
    <Text
      display={inline ? 'inline-block' : 'block'}
      mt={inline ? '1' : ''}
      p="1"
      fontSize="x-small"
      fontWeight="semibold"
      color="white"
      background={colour}
    >
      {name}
    </Text>
  )
}

export default CategoryStrip
