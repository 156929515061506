import { useContext } from 'react'
import { UserContext } from 'providers/UserProvider'
import { useNavigate } from 'react-router-dom'

import { YEARLY_PRICE_ID, MONTHLY_PRICE_ID } from 'helpers/keys'

import { useCoaching, useImpersonate } from 'hooks'

const useStateManager = () => {
  const {
    loggedInContext,
    userIdContext,
    userEmailContext,
    planContext,
    validContext,
    trialEndedContext,
    showUpgradeOverlayContext,
  } = useContext(UserContext)
  const [, setUser] = loggedInContext
  const [, setUserId] = userIdContext
  const [, setUserEmail] = userEmailContext
  const [, setUserPlan] = planContext
  const [, setUserValid] = validContext

  const [, setTrialEnded] = trialEndedContext

  const setCoachingInterfaceDisabled = useCoaching((state) => state.setCoachingInterfaceDisabled)

  const [, setShowUpgradeOverlay] = showUpgradeOverlayContext

  const navigate = useNavigate()

  const clearAll = () => {
    delete localStorage.csrf
    delete localStorage.signedIn
    delete localStorage.plan
    delete localStorage.userId
    delete localStorage.userEmail
    delete localStorage.userValid
    delete localStorage.trialEnded
    setUser(false)
    setUserId(null)
    setUserEmail(null)
    setUserPlan(null)
    setUserValid(null)
    setTrialEnded(null)
    setShowUpgradeOverlay(false)
    setCoachingInterfaceDisabled()
  }

  const clearSubscribed = () => {
    const plan = 'Cancelled'

    localStorage.plan = plan
    localStorage.userValid = false
    delete localStorage.trialEnded
    setUserPlan(plan)
    setUserValid(false)
    setTrialEnded(null)
    setShowUpgradeOverlay(false)
  }

  const setSignin = (data) => {
    localStorage.csrf = data.csrf
    localStorage.signedIn = true
    localStorage.plan = data.user.plan
    localStorage.userId = data.user.id
    localStorage.userEmail = data.user.email
    localStorage.userValid = data.user.valid
    localStorage.trialEnded = data.user.trial_ended

    setUser(true)

    setUserId(data.user.id)
    setUserEmail(data.user.email)
    setUserPlan(data.user.plan)
    setUserValid(data.user.valid)
    setTrialEnded(data.user.trial_ended)
  }

  // we can't use setAccountStatus(data) for clearSubscribed & setSubscribed
  // because the webhooks won't have come through in time to update the data
  // so we have to determine and set the values manually
  const setSubscribed = (priceId) => {
    // seems the only info in payload related to which price
    // obviously will need to change if prices change!

    let plan = ''
    switch (priceId) {
      case MONTHLY_PRICE_ID:
        plan = 'Sequence (monthly)'
        break
      case YEARLY_PRICE_ID:
        plan = 'Sequence (annual)'
        break
      default:
        plan = ''
    }

    localStorage.plan = plan
    localStorage.userValid = true
    delete localStorage.trialEnded
    setUserPlan(plan)
    setUserValid(true)
    setTrialEnded(null)
  }

  const setAccountStatus = (data) => {
    localStorage.plan = data.plan
    localStorage.userId = data.id
    localStorage.userEmail = data.email
    localStorage.userValid = data.valid
    localStorage.trialEnded = data.trial_ended

    setUserId(data.id)
    setUserEmail(data.email)
    setUserPlan(data.plan)
    setShowUpgradeOverlay(data.trial_ended)
    setUserValid(data.valid)
    setTrialEnded(data.trial_ended)

    if (data.trial_ended) {
      navigate('/subscription-create')
    }
  }

  const hideOverlay = () => {
    setShowUpgradeOverlay(false)
  }

  return {
    clearAll,
    clearSubscribed,
    setSignin,
    setSubscribed,
    setAccountStatus,
    hideOverlay,
  }
}

export default useStateManager
