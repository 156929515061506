import React from 'react'
import { Icon, Box, Text, Spacer } from '@chakra-ui/react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { isSameDay, parseISO } from 'date-fns'
import { Edit3, Target } from 'react-feather'

import { usePlannerNote } from 'hooks'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

import CoachingBadgeIcon from 'domain/Notes/components/CoachingBadgeIcon'

import { processApiError } from 'helpers/utils'

import LinkedGoalsIcon from './LinkedGoalsIcon'

const MILESTONE = 'milestone'

const DayNotes = ({ data, day, refresh }) => {
  const setPlannerNoteOpen = usePlannerNote((state) => state.setPlannerNoteOpen)
  const setPlannerNoteId = usePlannerNote((state) => state.setPlannerNoteId)

  const dayData = data.filter((workout) => isSameDay(parseISO(workout.date), day))

  const handleNoteOpen = (id) => {
    setPlannerNoteId(id)
    setPlannerNoteOpen()
  }

  const handleContextMenuDelete = (event, data) => {
    event.stopPropagation()
    if (data.action === 'remove') {
      const { id } = data
      const deleteMessage = `${data.style === MILESTONE ? 'Milestone' : 'Note'} deleted.`

      securedApi
        .delete(`api/v1/scheduled_notes/${id}`)
        .then(() => {
          Notify({
            content: deleteMessage,
            type: 'success',
          })
          refresh()
        })
        .catch((error) => processApiError(error))
    }
  }

  if (!dayData.length) {
    return
  }

  const renderDayData = () => {
    return dayData.map((item) =>
      item?.notes?.map((i, idx) => {
        if (i.style === 'rest') {
          return null
        }

        const linkedGoalIconColour = i.style === MILESTONE && 'white' // use default if not milestone

        return (
          <Box key={i.id}>
            <Box
              fontSize="small"
              bg="white"
              border="1px"
              borderColor="gray.100"
              p="2"
              mt="2"
              mb="2"
              color={i.style === MILESTONE ? '#fff' : ''}
              background={i.style === MILESTONE ? 'green.400' : '#fff'}
              onClick={() => handleNoteOpen(i.id)}
              cursor="pointer"
            >
              <ContextMenuTrigger id={i.id}>
                <Box display="flex" alignItems="center">
                  {i.style === MILESTONE && (
                    <Icon as={Target} color="white" strokeWidth="1px" w="4" h="4" mr="1" />
                  )}

                  {i.style === 'note' && <Icon as={Edit3} strokeWidth="1px" w="4" h="4" mr="1" />}
                  <Text>{i.title}</Text>
                  <CoachingBadgeIcon style={i.style} />
                  <Spacer />
                  {i.linked_goals && (
                    <LinkedGoalsIcon linkedGoals={i.linked_goals} color={linkedGoalIconColour} />
                  )}
                </Box>
              </ContextMenuTrigger>
            </Box>

            <Box
              id={i.id}
              as={ContextMenu}
              background="gray.100"
              borderRadius="sm"
              border="1px"
              borderColor="gray.300"
              boxShadow="lg"
              p="2"
            >
              <Box
                as={MenuItem}
                _hover={{ background: 'brand.500', color: 'white' }}
                cursor="pointer"
                px="2"
                py="1"
                borderRadius="sm"
                data={{
                  action: 'remove',
                  id: i.id,
                }}
                onClick={handleContextMenuDelete}
              >
                Delete {i.style === MILESTONE ? 'Milestone' : 'Note'}
              </Box>
            </Box>
          </Box>
        )
      })
    )
  }

  return <>{renderDayData()}</>
}

export default DayNotes
