import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { Box, Container, Link, Text, Tooltip, Divider, Stack, Badge } from '@chakra-ui/react'
import { Edit } from 'react-feather'

import { tzSafeDateFormat } from 'helpers/utils'
import { useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import CoachingBadge from './components/CoachingBadge'

const Note = () => {
  const { id } = useParams()

  const { data, isLoading, hasError } = useQuery(`api/v1/scheduled_notes/${id}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const formattedDate = tzSafeDateFormat(data.scheduled_at, 'd/M/yy')

  const renderNote = () => {
    return (
      <Box background="white">
        <Box p="5">
          <Text fontSize="2xl" color="black">
            {data.title} <CoachingBadge style={data.style} />
          </Text>

          <Box>
            <Text fontSize="sm" color="gray" display="inline-block">
              {formattedDate}
            </Text>
          </Box>

          <Text
            maxW="500px"
            color="gray"
            my="4"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          />

          {data.goals && data.goals.length > 0 && (
            <Box mt="2">
              <Divider />
              <Text fontWeight="medium" fontSize="sm" mt="2">
                Linked Goals
              </Text>
              <Stack direction="row" mt="2">
                {data.goals.map((goal) => (
                  <Badge key={goal.id} variant="outline">
                    {goal.title}
                  </Badge>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions backAction="/notes">
        <Tooltip label="Edit notes">
          <Link as={NavLink} to={`/note/edit/${data.id}`} variant="brand" fontWeight="bold">
            <Edit size="18px" />
          </Link>
        </Tooltip>
      </InnerPageWrapperActions>
      <Box marginBottom="20">{renderNote()}</Box>
    </Container>
  )
}

export default Note
