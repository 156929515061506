import React from 'react'

import MetricsContainer from '../MetricsContainer'

import IncompleteReasonsContainer from './IncompleteReasonsContainer'

const IncompleteReasons = () => {
  return (
    <MetricsContainer>
      <IncompleteReasonsContainer />
    </MetricsContainer>
  )
}

export default IncompleteReasons
