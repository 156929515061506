import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError, processApiError } from 'helpers/utils'

import { usePlannerMethods } from 'domain/Planner/hooks'
import NoteGoalLinker from 'domain/Notes/NoteGoalLinker'

const ScheduledActivityGoalLinking = ({ isOpen, onClose, currentActivityId }) => {
  const { refreshData: refresh } = usePlannerMethods()
  const [linkedGoals, setLinkedGoals] = useState([])
  const [goalsLinkedLoading, setGoalsLinkedLoading] = useState(false)

  const methods = useForm()
  const { control, handleSubmit } = methods

  useEffect(() => {
    setGoalsLinkedLoading(true)

    securedApi
      .get(`/api/v1/scheduled_activities/${currentActivityId}/goals`)
      .then(({ data }) => {
        setLinkedGoals(data)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setGoalsLinkedLoading(false)
      })
  }, [currentActivityId])

  const defaultValues = linkedGoals?.map((goal) => ({
    value: goal.id,
    label: goal.title,
  }))

  const onSubmit = ({ goals }) => {
    // create params for an array of goal ids
    const goalIds = goals?.map((goal) => goal.value)
    const idParams = goalIds?.map((id) => `goal_ids[]=${id}`).join('&')

    securedApi
      .put(`/api/v1/scheduled_activities/${currentActivityId}/set_goals?${idParams}`)
      .then(() => {
        Notify({ content: 'Goals updated', type: 'success' })
        refresh()
        onClose()
      })
      .catch((error) => {
        processApiError(error, 'Error linking goal. Please try again.')
      })
  }

  const renderModalContent = () => {
    if (goalsLinkedLoading) {
      return <LoadingSpinner />
    }

    return (
      <FormControl mt="4">
        <NoteGoalLinker {...{ control, defaultValues }} />
      </FormControl>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Manage Goal Linking</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{renderModalContent()}</ModalBody>
            <ModalFooter>
              <Button colorScheme="brand" type="submit">
                Link
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}

export default ScheduledActivityGoalLinking
