import React, { useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Checkbox,
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Text,
  VStack,
  Icon,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { Trash2 } from 'react-feather'

import { SELECT_TYPE } from 'domain/Widgets/constants'

import Modal from 'components/Modal'
import { EditWidgetModalActions, FieldErrorText } from 'domain/Widgets/components'

const SelectFormFields = ({
  widget,
  activityId,
  onCreateWidget,
  onUpdateWidget,
  onDeleteWidget,
  onEditWidgetClose,
}) => {
  const [optionDeleteIndex, setOptionDeleteIndex] = useState()
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()

  // Need to create form fields with unique id because a workout can have many widgets
  // and we don't want a clash for the same field with different widgets
  const name = `${widget.id}_select_name`
  // const options = `${widget.id}_select_options`
  const isEditingActivity = !!activityId
  const isEditingOrNewWidget = isEditingActivity || !widget.new

  // If the widget has been created then we need to populate the form field
  const defaultNameValue = isEditingOrNewWidget ? widget?.content?.name : null
  const defaultOptionsValue = isEditingOrNewWidget ? widget?.content?.options : null

  const {
    control,
    register,
    getValues,
    clearErrors,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: defaultOptionsValue,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  })

  const handleChange = () => {
    clearErrors()

    const editWidgetData = {
      ...widget,
      content: {
        name: getValues(name),
        options: getValues('options'),
      },
    }

    if (!getValues(name)) {
      trigger(name, { shouldFocus: true })
      return
    }

    if (!getValues('options').length) {
      append({ content: '', show: true })
      trigger('options', { shouldFocus: true })
      return
    }

    const isContentEmpty = getValues('options').some((item) => item.content === '')

    if (isContentEmpty) {
      setError('options', { type: 'custom', message: "Option value required, can't be blank" })
      return
    }

    if (widget.new) {
      onCreateWidget({ type: SELECT_TYPE, widgetData: editWidgetData })
      onEditWidgetClose()
    } else {
      onUpdateWidget(editWidgetData)
      onEditWidgetClose()
    }
  }

  const handleDeleteOption = (index) => {
    setOptionDeleteIndex(index)

    if (!isEditingOrNewWidget) {
      remove(index)
      return
    }

    onConfirmDeleteOpen()
  }

  const handleAcceptDeleteOption = () => {
    remove(optionDeleteIndex)
    onConfirmDeleteClose()
  }

  const renderOptionFields = () => {
    if (!fields.length) {
      return null
    }

    return (
      <FormControl isRequired>
        <FormLabel fontSize="sm" mb="0">
          Options
        </FormLabel>

        <Stack>
          {fields.map((field, index) => {
            return (
              <HStack key={field.id}>
                <Input {...register(`options.${index}.content`)} />

                {/* // TODO - drag and drop  */}
                <Input type="hidden" {...register(`options.${index}.value`)} />
                <Input type="hidden" {...register(`options.${index}.order`)} />

                <Checkbox colorScheme="yellow" {...register(`options.${index}.show`)}>
                  <Tooltip label="If you no longer want to see this option but are keen to keep your historical data you can use this option.">
                    <Text fontSize="sm" fontWeight="medium">
                      Display
                    </Text>
                  </Tooltip>
                </Checkbox>

                <Tooltip label="Delete option">
                  <Icon
                    as={Trash2}
                    w="5"
                    h="5"
                    color="gray.500"
                    _hover={{ cursor: 'pointer', color: 'red.500' }}
                    onClick={() => handleDeleteOption(index)}
                  />
                </Tooltip>
              </HStack>
            )
          })}
        </Stack>
      </FormControl>
    )
  }

  return (
    <>
      <Box>
        <Stack>
          <FormControl isRequired>
            <FormLabel fontSize="sm" mb="0">
              Name
            </FormLabel>
            <Input
              data-cy="widget-name"
              placeholder="Rock type"
              type="text"
              autoFocus={true}
              defaultValue={defaultNameValue}
              {...register(name, {
                required: "Name required, can't be blank",
              })}
            />
            <FieldErrorText errors={errors} field={name} />
          </FormControl>

          {renderOptionFields()}

          <FieldErrorText errors={errors} field={'options'} />
          <Button
            mt="4"
            onClick={() => {
              append({ content: '', show: true })
            }}
          >
            Add option
          </Button>
        </Stack>

        <EditWidgetModalActions {...{ widget, handleChange, onDeleteWidget, onEditWidgetClose }} />
      </Box>
      <Modal
        isOpen={isConfirmDeleteOpen}
        closeModal={onConfirmDeleteClose}
        title="Are you sure?"
        onAccept={handleAcceptDeleteOption}
      >
        <VStack spacing={2}>
          <Text>
            If you delete this option, it will delete{' '}
            <Text as="span" fontWeight="bold">
              ALL
            </Text>{' '}
            associated data.
          </Text>
          <Text>
            Consider archiving (show/hide checkbox) it instead so you can still view your historical
            data.
          </Text>
        </VStack>
      </Modal>
    </>
  )
}

export default SelectFormFields
