import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, Container, HStack, Link } from '@chakra-ui/react'

const Navigation = ({ links }) => {
  const renderLinks = () =>
    links.map((link) => (
      <Link
        key={link.name}
        as={NavLink}
        to={link.url}
        _activeLink={{ color: 'brand.500' }}
        color="gray.500"
        fontWeight="light"
        fontSize="small"
        _hover={{ textDecoration: 'none', color: 'brand.500' }}
        end
      >
        {link.name}
      </Link>
    ))

  return (
    <Box background="#fff">
      <Container maxW="container.xl" py="6">
        <HStack spacing={6}>{renderLinks()}</HStack>
      </Container>
    </Box>
  )
}

export default Navigation
