import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Plus } from 'react-feather'
import {
  Box,
  Center,
  Text,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Image,
  Icon,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { useStateManager } from 'hooks'

import Notify from 'components/Notification'

import SequenceLogo from './assets/SeqLogo.png'
import GymLogo from './assets/9DLogo.png'

import { EmailField, PasswordFields, SignUpButton } from 'domain/Auth/Elements'

const schema = z
  .object({
    name: z.string().min(1, { message: 'Required' }),
    email: z.string().email().min(2),

    password: z.string().min(6, { message: 'Required - must have at least 6 characters' }),
    password_confirmation: z.string().min(6),
    level: z.string().min(1, { message: 'Must select an option' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const SYDNEY = 'sydney'
const BRISBANE = 'brisbane'

const SignUp9D = () => {
  let navigate = useNavigate()

  const { clearAll, setSignin, setAccountStatus } = useStateManager()

  const [loading, setLoading] = useState(false)
  const [gym, setGym] = useState(SYDNEY)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ name, email, password, password_confirmation, level }) => {
    setLoading(true)
    securedApi
      .post('/signup', {
        signup: {
          email,
          name,
          password,
          password_confirmation,
        },
        gym: {
          name: '9d',
          level,
        },
      })
      .then((response) => {
        signUpSuccessful(response)
      })
      .catch((err) => {
        signUpFailed(err)
      })
  }

  const signUpSuccessful = (response) => {
    if (!response.data.csrf) {
      signUpFailed(response)
      return
    }
    setSignin(response.data)
    setAccountStatus(response.data.user)

    const { id, name, email, plan } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    navigate('/welcome')
  }

  const signUpFailed = (err) => {
    const errorContent = err.response?.data?.error || 'Error signing up. Please try again.'
    Notify({
      content: errorContent,
      type: 'error',
    })

    setLoading(false)

    clearAll()
  }

  return (
    <Center py="10" px="4">
      <Box maxWidth="500px" width="100%">
        <Center>
          <Image src={SequenceLogo} boxSize="100px" objectFit="cover" alt="Sequence" />

          <Icon as={Plus} w="5" h="5" color="gray.500" mr="3" />
          <Image src={GymLogo} boxSize="100px" objectFit="cover" alt="9 Degrees" />
        </Center>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box textAlign="center">
            <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold">
              Sign up to Sequence
            </Text>
            <Text fontWeight="bold" mb="4">
              Receive your 1 month training plan today!
            </Text>
          </Box>

          <FormControl
            mt="6"
            marginBottom="6"
            background="yellow.100"
            p="6"
            isInvalid={errors.level}
          >
            <FormLabel mb="0">Current 9 Degrees level</FormLabel>
            <FormHelperText mt="2" mb="4">
              What is the current colour you are climbing? This selection helps us create a custom
              training plan for you.
            </FormHelperText>

            <FormHelperText mt="2" mb="4">
              <Text mb="3">Gym location:</Text>
              <RadioGroup onChange={setGym} value={gym}>
                <Stack direction="row">
                  <Radio background="white" colorScheme="brand" value={SYDNEY}>
                    <Text>Sydney</Text>
                  </Radio>
                  <Radio background="white" colorScheme="brand" value={BRISBANE}>
                    <Text>Brisbane</Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormHelperText>
            <Select
              placeholder="Select option"
              bg="white"
              variant="outline"
              {...register('level', {
                required: true,
              })}
            >
              {gym === SYDNEY ? (
                <>
                  <option value="1">Yellow</option>
                  <option value="1">Green</option>
                  <option value="1">Blue</option>
                  <option value="2">Teal</option>
                  <option value="2">Pink</option>
                  <option value="3">Red</option>
                  <option value="3">Purple</option>
                  <option value="4">White</option>
                </>
              ) : (
                <>
                  <option value="1">Yellow</option>
                  <option value="1">Teal</option>
                  <option value="1">Blue</option>
                  <option value="2">Purple</option>
                  <option value="2">Green</option>
                  <option value="3">Pink</option>
                  <option value="3">Red</option>
                  <option value="4">Black</option>
                  <option value="4">White</option>
                </>
              )}
            </Select>
            <FormErrorMessage>{errors.level && errors.level.message}</FormErrorMessage>
          </FormControl>

          <FormControl marginBottom="4" isInvalid={errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              {...register('name', {
                required: true,
              })}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>

          <EmailField {...{ register, errors }} />
          <PasswordFields {...{ register, errors }} />
          <SignUpButton {...{ loading }} />
        </form>
      </Box>
    </Center>
  )
}

export default SignUp9D
