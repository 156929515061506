import React from 'react'
import { MoreHorizontal } from 'react-feather'
import { Icon, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

const Dropdown = ({ children }) => {
  const renderIcon = () => {
    return (
      <Icon
        as={MoreHorizontal}
        w="4"
        h="4"
        color="gray.500"
        ml="1"
        _hover={{ cursor: 'pointer', color: 'brand.500' }}
      />
    )
  }

  return (
    <Menu gutter="0">
      <MenuButton as={IconButton} icon={renderIcon()} variant="none" />
      <MenuList zIndex="2">
        {children.map((item, index) => {
          // handle case where children contains empty items from conditional logic upstream
          if (!item) {
            return null
          }

          return <MenuItem key={index}>{item}</MenuItem>
        })}
      </MenuList>
    </Menu>
  )
}

export default Dropdown
