import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Heading, Container, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import Modal from 'components/Modal'
import { processGetError, processApiError } from 'helpers/utils'

import TestForm from './TestForm'

const TestEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [test, setTest] = useState({})
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/tests/${id}`)
      .then(({ data }) => setTest(data))
      .catch((error) => processGetError(error))
      .finally(() => setLoading(false))
  }, [id])

  const onSubmit = (data) => {
    securedApi
      .patch(`/api/v1/tests/${id}`, {
        ...data,
      })
      .then(() => {
        Notify({ content: 'Test updated', type: 'success' })
        navigate(`/tests/${id}`)
      })
      .catch((error) => processApiError(error))
  }

  const handleDelete = () => {
    securedApi
      .delete(`api/v1/tests/${id}`)
      .then(() => {
        Notify({ content: 'Test deleted', type: 'success' })
        navigate('/tests')
      })
      .catch((error) => processApiError(error))
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(test).length) {
    return <Text>No test found</Text>
  }

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <Box background="white" padding="6">
        <Heading as="h2" size="lg" mb={4}>
          Test Number - {test.number}
        </Heading>
        <TestForm {...{ onSubmit }} data={test} onDeleteModal={() => setDeleteModalOpen(true)} />

        <Modal
          isOpen={deleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          title="Are you sure?"
          subTitle="This action can't be undone"
          onAccept={() => handleDelete()}
        />
      </Box>
    </Container>
  )
}

export default TestEdit
