export const customStyles = {
  groupHeading: (provided, state) => {
    const category = state.data.label
    const categoryColour = state.data.colour
    return {
      ...provided,
      borderLeft: '8px solid',
      borderColor: category ? categoryColour : '#DBDBDB',
    }
  },
}
