import { Stack, Divider } from '@chakra-ui/react'

// used by both DailyEmailSettings and WeeklyEmailSettings
import './timePickerStyles.css'

import { useQuery } from 'hooks'
import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'

import DailyEmailSettings from './DailyEmailSettings'
import WeeklyEmailSettings from './WeeklyEmailSettings'

const Email = () => {
  const { data, isLoading, hasError, mutate } = useQuery('/accounts/get_preferences')

  const toggleEmailSettingsDaily = (value) => {
    securedApi
      .post(`/accounts/set_preference?account[send_daily_emails]=${value}`)
      .then(() => {
        mutate()
      })
      .then(() => {
        Notify({ content: 'Daily email settings updated.', type: 'success' })
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
  }

  const handleSetDailyEmailTime = (time) => {
    securedApi
      .post(`/accounts/set_preference?account[daily_email_time]=${time}`)
      .then(() => {
        mutate()
      })
      .then(() => {
        Notify({ content: 'Daily email settings updated.', type: 'success' })
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
  }

  const toggleEmailSettingsWeekly = () => {
    securedApi
      .post(`/accounts/set_preference?account[send_weekly_emails]=${!send_weekly_emails}`)
      .then(() => {
        mutate()

        Notify({ content: 'Weekly email settings updated.', type: 'success' })
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
  }

  const handleSetWeeklyEmailTime = (time) => {
    securedApi
      .post(`/accounts/set_preference?account[weekly_email_time]=${time}`)
      .then(() => {
        mutate()
      })
      .then(() => {
        Notify({ content: 'Weekly email settings updated.', type: 'success' })
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
  }

  const handleSetWeeklyEmailDay = (day) => {
    securedApi
      .post(`/accounts/set_preference?account[weekly_email_day]=${day}`)
      .then(() => {
        mutate()
      })
      .then(() => {
        Notify({ content: 'Weekly email settings updated.', type: 'success' })
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const {
    send_daily_emails,
    daily_email_time,
    send_weekly_emails,
    weekly_email_day,
    weekly_email_time,
  } = data

  return (
    <Stack spacing="6" width="100%">
      <DailyEmailSettings
        onToggleEmailSettingsDaily={toggleEmailSettingsDaily}
        onHandleSetDailyEmailTime={handleSetDailyEmailTime}
        {...{
          send_daily_emails,
          daily_email_time,
        }}
      />

      <Divider />

      <WeeklyEmailSettings
        {...{
          send_weekly_emails,
          weekly_email_day,
          weekly_email_time,
        }}
        onToggleEmailSettingsWeekly={toggleEmailSettingsWeekly}
        onHandleSetWeeklyEmailTime={handleSetWeeklyEmailTime}
        onHandleSetWeeklyEmailDay={handleSetWeeklyEmailDay}
      />
    </Stack>
  )
}

export default Email
