import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { tzSafeDateFormat } from 'helpers/utils'

const LinkedItemDate = ({ date }) => {
  return (
    <Box>
      <Text fontSize="xs" color="gray" display="inline-block">
        {tzSafeDateFormat(date, 'd/M/yy')}
      </Text>
    </Box>
  )
}

export default LinkedItemDate
