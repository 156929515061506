import React from 'react'
import { Container, Flex, Box } from '@chakra-ui/react'

import { useQuery } from '../../hooks'
import { revalidateLiveQueries } from '../../helpers/swrConfig'

import LoadingSpinner from '../../components/LoadingSpinner'
import Error from '../../components/General/Error'

import UpcomingWorkouts from './UpcomingWorkouts'
import Goals from './Goals'
import Snapshot from './Snapshot'
import RecentMilestones from './RecentMilestones'
import RecentNotes from './RecentNotes'

const Dashboard = () => {
  const { data, isLoading, hasError } = useQuery('/api/v1/dashboard')

  const refreshData = () => {
    revalidateLiveQueries()
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Container maxW="container.xl" paddingBottom={20}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box
            flexGrow="1"
            borderRight={{ base: 'none', md: '1px solid #ccc' }}
            borderBottom={{ base: '1px solid #ccc', md: 'none' }}
          >
            <Box padding={4}>
              <UpcomingWorkouts data={data.upcoming_workouts} refresh={refreshData} />
            </Box>
          </Box>

          <Box minWidth={{ base: 'auto', md: 'sm' }} padding={4}>
            <Goals data={data.goals_in_progress} />
          </Box>
        </Flex>

        <Flex borderTop="1px solid #ccc" padding={4}>
          <Box flexGrow="1">
            <Snapshot data={data.snapshot} />
          </Box>
        </Flex>

        <Flex borderTop="1px solid #ccc" direction={{ base: 'column', md: 'row' }}>
          <Box
            w={{ base: '100%', md: '50%' }}
            borderRight={{ base: 'none', md: '1px solid #ccc' }}
            borderBottom={{ base: '1px solid #ccc', md: 'none' }}
          >
            <Box padding={4}>
              <RecentMilestones data={data.recent_milestones} />
            </Box>
          </Box>

          <Box w={{ base: '100%', md: '50%' }}>
            <Box padding={4}>
              <RecentNotes data={data.recent_notes} />
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export default Dashboard
