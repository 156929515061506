import React from 'react'

import { useUserPreferences } from 'hooks'

import { EmojiDisplay } from 'components/Emoji/Elements'

export const CompleteEmoji = () => {
  const emojiSkinTone = useUserPreferences((state) => state.emojiSkinTone)

  return <EmojiDisplay lookup={emojiSkinTone} />
}

export default CompleteEmoji
