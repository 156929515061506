import React, { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  ListItem,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react'
import { Edit } from 'react-feather'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError, tzSafeDateFormat } from 'helpers/utils'

import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

const TestDetails = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [test, setTest] = useState({})

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`api/v1/tests/${id}`)
      .then(({ data }) => setTest(data))
      .catch((error) => processGetError(error))
      .finally(() => setLoading(false))
  }, [id])

  const renderTest = (test) => {
    const {
      id,
      test_date,
      age,
      weight,
      height,
      hardest_boulder,
      hardest_route,
      hardest_boulder_indoor,
      hardest_route_indoor,
      max_pull_up,
      max_campus_pull_left,
      max_campus_pull_right,
      max_bench_press,
      deadlift,
      max_hang,
      max_anaerobic_hang,
      max_aerobic_hang,
      number,
    } = test

    return (
      <Box key={id} my="8">
        <Box background="white" padding="6">
          <Heading as="h2" size="lg" mb={4}>
            Test Number - {number}
          </Heading>
          <UnorderedList>
            <ListItem>Date: {test_date ? tzSafeDateFormat(test_date, 'd/M/yy') : '-'}</ListItem>
            <ListItem>Age: {age ? age : '-'}</ListItem>
            <ListItem>Weight: {weight ? `${weight} kg` : '-'}</ListItem>
            <ListItem>Height: {height ? `${height} cm` : '-'}</ListItem>
            <ListItem>Hardest Boulder: {hardest_boulder ? `V${hardest_boulder}` : '-'}</ListItem>
            <ListItem>Hardest Route: {hardest_route ? hardest_route : '-'}</ListItem>
            <ListItem>
              Hardest Boulder (Indoor):{' '}
              {hardest_boulder_indoor ? `V${hardest_boulder_indoor}` : '-'}
            </ListItem>
            <ListItem>
              Hardest Route (Indoor): {hardest_route_indoor ? hardest_route_indoor : '-'}
            </ListItem>
            <ListItem>Max Pull Up: {max_pull_up ? `${max_pull_up} kg` : '-'} </ListItem>
            <ListItem>
              Max Campus Pull Left: {max_campus_pull_left ? `${max_campus_pull_left} cm` : '-'}
            </ListItem>
            <ListItem>
              Max Campus Pull Right: {max_campus_pull_right ? `${max_campus_pull_right} cm` : '-'}
            </ListItem>
            <ListItem>Max Bench Press: {max_bench_press ? `${max_bench_press} kg` : '-'}</ListItem>
            <ListItem>Deadlift: {deadlift ? `${deadlift} kg` : '-'}</ListItem>
            <ListItem>Max Hang: {max_hang ? `${max_hang} kg` : '-'}</ListItem>
            <ListItem>
              Max Anaerobic Hang: {max_anaerobic_hang ? `${max_anaerobic_hang} reps` : '-'}
            </ListItem>
            <ListItem>
              Max Aerobic Hang: {max_aerobic_hang ? `${max_aerobic_hang} seconds` : '-'}
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(test).length) {
    return <Text>No test found</Text>
  }

  return (
    <Container maxW="container.sm" mt="10" mb="28">
      <InnerPageWrapperActions backAction="/tests">
        <Tooltip label="Edit test">
          <Link as={NavLink} to={`/tests/edit/${test.id}`} variant="brand" fontWeight="bold">
            <Edit size="18px" />
          </Link>
        </Tooltip>
      </InnerPageWrapperActions>

      <Box marginBottom="20">{renderTest(test)}</Box>
    </Container>
  )
}

export default TestDetails
