import React from 'react'
import { Flex, Select, Input } from '@chakra-ui/react'

const Filter = ({ column, table }) => {
  const columnFilterValue = column.getFilterValue()

  const fieldsText = ['name', 'notes']
  const fieldsOptions = ['grade', 'style']
  const fieldsRange = [] // ['attempts']

  if (fieldsText.includes(column.id)) {
    return (
      <Input
        size="sm"
        borderRadius={4}
        marginLeft="-8px" // align input content to table cell
        padding={2}
        placeholder={`Search...`}
        value={columnFilterValue ?? ''}
        onChange={(e) => column.setFilterValue(e.target.value)}
      />
    )
  } else if (fieldsOptions.includes(column.id)) {
    return (
      <Select
        size="sm"
        borderColor="gray.400"
        borderWidth="1px"
        borderRadius={4}
        marginLeft="-13px" // align input content to table cell
        placeholder=" "
        value={columnFilterValue ?? ''}
        onChange={(e) => column.setFilterValue(e.target.value)}
      >
        {column.columnDef.meta.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          )
        })}
      </Select>
    )
  } else if (fieldsRange.includes(column.id)) {
    return (
      <Flex gap={2}>
        <Input
          type="number"
          size="sm"
          value={columnFilterValue?.[0] ?? ''}
          onChange={(e) => column.setFilterValue((old) => [e.target.value, old?.[1]])}
          placeholder={`Min`}
          width={24}
        />
        <Input
          type="number"
          size="sm"
          value={columnFilterValue?.[1] ?? ''}
          onChange={(e) => column.setFilterValue((old) => [old?.[0], e.target.value])}
          placeholder={`Max`}
        />
      </Flex>
    )
  }
}

export default Filter
