import React, { useRef } from 'react'
import { Box, Icon, Spacer, Text } from '@chakra-ui/react'
import { Check, Move } from 'react-feather'

import { useUserPreferences, useActivityModal } from 'hooks'

import { shortEnglishHumanizer } from 'helpers/duration'

import CategoryBlock from 'domain/Workouts/CategoryBlock'

import LinkedGoalsIcon from './LinkedGoalsIcon'

const ActivityDetails = ({
  id,
  name,
  category,
  categoryHexColour,
  completed,
  description,
  duration,
  notes,
  incomplete,
  isMobile,
  linkedGoals,
}) => {
  const finalRef = useRef()

  const setActivityModalOpen = useActivityModal((state) => state.setActivityModalOpen)
  const setActivityModalWorkoutId = useActivityModal((state) => state.setActivityModalWorkoutId)

  const compactView = useUserPreferences((state) => state.compactView)

  const renderNote = (notes) => {
    const numberOfChars = 200
    if (notes.length > numberOfChars) {
      return notes.substring(0, numberOfChars) + '...'
    } else {
      return notes.substring(0, numberOfChars)
    }
  }

  const handleOpen = () => {
    setActivityModalWorkoutId(id)
    setActivityModalOpen()
  }

  return (
    <>
      <Box display="flex" onClick={handleOpen} ref={finalRef} opacity={incomplete && '0.3'}>
        <CategoryBlock colour={categoryHexColour} />

        <Box display="flex" flexDirection="row" width="100%" p="2">
          <Box width="100%">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Text fontSize="small" wordBreak="break-word">
                {name}
              </Text>
              <Spacer />
              {linkedGoals && <LinkedGoalsIcon {...{ linkedGoals }} />}
              {completed && <Icon as={Check} color="green.400" w="5" h="5" />}
            </Box>
            <Box>
              {compactView && (
                <Box>
                  {notes ? (
                    <Text fontStyle="italic" fontSize="small" color="gray.500" mb="1">
                      {renderNote(notes)}
                    </Text>
                  ) : (
                    <Text fontSize="small" color="gray.500" mb="1">
                      {description}
                    </Text>
                  )}
                </Box>
              )}
              <Box>
                {duration > 0 && (
                  <Text fontSize="x-small" color="" fontWeight="semibold">
                    {shortEnglishHumanizer(duration)}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>

          <Spacer />
          {!isMobile && <Icon as={Move} color="gray.400" strokeWidth="1px" w="2" h="2" ml="1" />}
        </Box>
      </Box>
    </>
  )
}

export default ActivityDetails
