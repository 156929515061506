import useSWR from 'swr'

const useQuery = (key, revalidateOnFocus = true) => {
  const { data, error, isValidating, mutate } = useSWR(key, {
    revalidateOnFocus: revalidateOnFocus,
  })

  return {
    data,
    isLoading: !error && !data,
    isValidating,
    hasError: error,
    mutate,
  }
}

export default useQuery
