import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { useActivityModal } from 'hooks'

import Dropdown from 'components/General/Dropdown'

import ScheduledActivityGoalLinking from '../../Goals/ScheduledActivityGoalLinking'
import RescheduleWorkout from './RescheduleWorkout'

const Menu = ({
  currentActivityId,
  currentActivityHasMeasures,
  hasNotes,
  isCompleted,
  originalActivityId,
  onDeleteActivity,
  onRemove,
  readOnly,
}) => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isRescheduleOpen,
    onOpen: onRescheduleOpen,
    onClose: onRescheduleClose,
  } = useDisclosure()
  const [goalLinkingModalOpen, setGoalLinkingModalOpen] = useState(false)

  const setActivityModalClose = useActivityModal((state) => state.setActivityModalClose)

  const renderRescheduleModal = (
    <RescheduleWorkout
      isOpen={isRescheduleOpen}
      onClose={onRescheduleClose}
      {...{ currentActivityId }}
    />
  )

  const handleDeleteStatus = () => {
    if (currentActivityHasMeasures || hasNotes || isCompleted) {
      onOpen()
    } else {
      handleDelete()
    }
  }

  const handleDelete = () => {
    onDeleteActivity({ id: currentActivityId })
    onRemove()
  }

  //only show this if measures are on the scheduled activity
  const renderRemoveModal = (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>This action can't be undone.</ModalBody>

        <ModalFooter>
          <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="brand" onClick={handleDelete}>
              Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  const handleGoalLinking = () => {
    setGoalLinkingModalOpen(true)
  }

  const renderGoalLinkingModal = (
    <ScheduledActivityGoalLinking
      isOpen={goalLinkingModalOpen}
      onClose={() => setGoalLinkingModalOpen(false)}
      {...{ currentActivityId }}
    />
  )

  const handleViewWorkoutMetrics = () => {
    setActivityModalClose()
    navigate(`/workout/${originalActivityId}`)
  }

  const handleEditWorkout = () => {
    setActivityModalClose()
    navigate(`/workout/${originalActivityId}/edit`)
  }

  return (
    <>
      <Dropdown>
        {!readOnly && (
          <Box onClick={() => handleGoalLinking()} p="2" width="100%">
            <Text fontSize="sm" fontWeight="normal">
              Link To Goal
            </Text>
          </Box>
        )}
        <Box onClick={handleViewWorkoutMetrics} p="2" width="100%">
          <Text fontSize="sm" fontWeight="normal">
            View Workout Metrics
          </Text>
        </Box>
        {!readOnly && (
          <Box onClick={handleEditWorkout} p="2" width="100%">
            <Text fontSize="sm" fontWeight="normal">
              Edit Original Workout
            </Text>
          </Box>
        )}
        {!readOnly && (
          <Box onClick={onRescheduleOpen} p="2" width="100%">
            <Text fontSize="sm" fontWeight="normal">
              Reschedule Workout
            </Text>
          </Box>
        )}
        {!readOnly && (
          <Box onClick={() => handleDeleteStatus()} p="2" width="100%">
            <Text fontSize="sm" fontWeight="normal">
              Remove From Schedule
            </Text>
          </Box>
        )}
      </Dropdown>
      {isOpen && renderRemoveModal}
      {isRescheduleOpen && renderRescheduleModal}
      {goalLinkingModalOpen && renderGoalLinkingModal}
    </>
  )
}

export default Menu
