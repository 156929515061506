import React, { createContext, useEffect, useState } from 'react'

export const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(false)
  const [userId, setUserId] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPlan, setUserPlan] = useState('')
  const [userValid, setUserValid] = useState(null)
  const [trialEnded, setTrialEnded] = useState(false)
  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false)

  const parseLocalStorage = (val) => {
    if (val === '') {
      return ''
    } else if (val === 'true') {
      return true
    } else if (val === 'false') {
      return false
    } else {
      return val
    }
  }

  useEffect(() => {
    const localStorageKeys = Object.keys(localStorage)

    if (localStorageKeys.includes('signedIn')) {
      setUser(parseLocalStorage(localStorage.signedIn))
    } else {
      setUser(false)
    }

    if (localStorageKeys.includes('userId')) {
      setUserId(parseLocalStorage(localStorage.userId))
    } else {
      setUserId('')
    }

    if (localStorageKeys.includes('userEmail')) {
      setUserEmail(parseLocalStorage(localStorage.userEmail))
    } else {
      setUserEmail('')
    }

    if (localStorageKeys.includes('plan')) {
      setUserPlan(parseLocalStorage(localStorage.plan))
    } else {
      setUserPlan('')
    }

    if (localStorageKeys.includes('userValid')) {
      setUserValid(parseLocalStorage(localStorage.userValid))
    } else {
      setUserValid(null)
    }

    if (localStorageKeys.includes('trialEnded')) {
      setTrialEnded(parseLocalStorage(localStorage.trialEnded))
    } else {
      setTrialEnded(false)
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        loggedInContext: [user, setUser],
        userIdContext: [userId, setUserId],
        userEmailContext: [userEmail, setUserEmail],
        planContext: [userPlan, setUserPlan],
        validContext: [userValid, setUserValid],
        trialEndedContext: [trialEnded, setTrialEnded],
        showUpgradeOverlayContext: [showUpgradeOverlay, setShowUpgradeOverlay],
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
