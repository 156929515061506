import React from 'react'
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent'

import { pickStyleMapping, styleColourMapping } from 'domain/Widgets/Logbook/constants'

const GradePyramidTooltip = (props) => {
  if (!props.active) {
    return null
  }

  if (!props.payload[0]) {
    return null
  }

  const payload = props.payload.filter((p) => !p.dataKey.startsWith('neg_'))
  const label = payload[0].payload.grade // show header in toolip

  return (
    <DefaultTooltipContent
      payload={payload}
      contentStyle={props.contentStyle}
      label={label}
      labelStyle={{ fontWeight: 'bold', fontSize: 'smaller' }}
    />
  )
}

export const GradePyramid = ({ data, logbook, stylesToShow }) => {
  const styleMapping = pickStyleMapping(logbook)

  const calculateTotal = (obj) =>
    Object.keys(obj)
      .filter((key) => stylesToShow.includes(key))
      .reduce((sum, key) => sum + obj[key], 0)

  const maxTotal = Math.max(...data.map(calculateTotal))
  const domain = [-maxTotal - 1, maxTotal + 1]

  const calculateFontSize = (dataLength) => {
    if (dataLength <= 14) return 16
    if (dataLength === 15) return 15

    const catchAll = 16 - (dataLength - 15)
    return Math.max(catchAll, 12)
  }

  const yAxisFontSize = calculateFontSize(data.length)

  return (
    <ResponsiveContainer height={500}>
      <BarChart data={data} layout="vertical" stackOffset="sign">
        <Tooltip content={<GradePyramidTooltip />} />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          type="category"
          dataKey="grade"
          tickCount={data.length}
          fontSize={yAxisFontSize}
          reversed={true}
        />
        <XAxis type="number" allowDecimals={false} tick={false} domain={domain} />
        <Legend />
        {stylesToShow.map((style) => {
          return (
            <React.Fragment key={style}>
              <Bar
                stackId="a"
                dataKey={style}
                name={styleMapping[style]}
                fill={styleColourMapping[style]}
              />
              <Bar
                stackId="a"
                dataKey={`neg_${style}`}
                name={styleMapping[style]}
                fill={styleColourMapping[style]}
                label={false}
                legendType="none"
                tooltip={false}
              />
            </React.Fragment>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}
