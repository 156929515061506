import React from 'react'
import { Badge, Flex, Text } from '@chakra-ui/react'

export const ModalButtonContentMeasure = ({ content }) => {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Text fontSize="sm" fontWeight="bold">
        {content.name}
      </Text>

      <Badge mt="1" variant="outline">
        {content.units}
      </Badge>
    </Flex>
  )
}
