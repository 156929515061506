import React, { useEffect, useState } from 'react'
import { Check } from 'react-feather'
import {
  Alert,
  AlertIcon,
  Flex,
  Box,
  Text,
  Icon,
  Spinner,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'

import securedApi from '../../../backend/axios'

import LoadingSpinner from '../../../components/LoadingSpinner'
import Notify from '../../../components/Notification'
import Error from '../../../components/General/Error'

const labelStyles = {
  color: 'gray.500',
  mt: '6',
  fontSize: 'sm',
  fontWeight: 'bold',
}

const middleLabelStyles = {
  ...labelStyles,
  marginLeft: '3',
}

const WorkoutMetricsMagicNumber = ({ id, workout }) => {
  const [magicData, setMagicData] = useState({})
  const [sliderMoving, setSliderMoving] = useState(false)
  const [magicNumber, setMagicNumber] = useState(6)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
    setIsLoading(true)
    securedApi
      .get(`api/v1/activities/${id}/metrics/magic_number?weeks=6`)
      .then(({ data }) => setMagicData(data))
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false))
  }, [id])

  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!Object.keys(magicData).length) {
    return (
      <Alert status="info">
        <AlertIcon />
        You currently haven't completed this workout. Metrics will be shown when this workout has
        been completed at least once.
      </Alert>
    )
  }

  const sessionNumber = magicData?.completed || 0

  const handleChangeMagicNumber = async (value) => {
    securedApi
      .get(`api/v1/activities/${id}/metrics/magic_number?weeks=${value}`)
      .then(({ data }) => {
        setMagicData(data)
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please contact support.',
          type: 'error',
        })
      })
      .finally(() => {
        setSliderMoving(false)
      })
  }

  return (
    <Box marginBottom="8" paddingBottom="8" borderBottom="1px solid #ccc">
      <Box>
        <Box maxWidth="670px" margin="0 auto" px={{ base: '10', md: '0' }}>
          <Flex direction="column">
            <Box>
              <Text fontSize="large">Magic Number</Text>
              <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
                How many {workout.activity_name} workouts have you done in the last{' '}
                {magicNumber === 1 ? 'week' : `${magicNumber} weeks?`}
              </Text>
            </Box>
          </Flex>

          <Box mt={4} pt={6} pb={2}>
            <Slider
              aria-label="slider-ex-6"
              colorScheme="yellow"
              min={1}
              max={10}
              step={1}
              focusThumbOnChange={false}
              defaultValue={magicNumber}
              onChangeStart={setSliderMoving}
              onChange={(val) => setMagicNumber(val)}
              onChangeEnd={(value) => handleChangeMagicNumber(value)}
            >
              <SliderMark value={1} {...labelStyles}>
                1
              </SliderMark>
              <SliderMark value={5} {...middleLabelStyles}>
                WEEKS
              </SliderMark>
              <SliderMark value={10} {...labelStyles}>
                10
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>

              <SliderThumb boxSize={8} borderWidth="1" borderColor="yellow.500">
                <Box color="yellow.500" fontWeight="bold">
                  {magicNumber}
                </Box>
              </SliderThumb>
            </Slider>
          </Box>
        </Box>
      </Box>

      <Flex justifyContent="center" my="2" minHeight="200px">
        {sliderMoving ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <>
            <Box fontSize={{ base: '8xl', md: '9xl' }} color="gray.600">
              {sessionNumber}
            </Box>
            <Box mt={{ base: '9', md: '12' }} ml="4" fontSize="lg" color="gray.600">
              <Icon as={Check} color="green.400" w="6" h="6" display="block" />
              {`You have completed`} <br /> in the last{' '}
              {magicNumber === 1 ? 'week' : `${magicNumber} weeks`}
            </Box>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default WorkoutMetricsMagicNumber
