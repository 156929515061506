import React from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'

import Impersonate from 'components/General/Impersonate'

const Admin = () => {
  const { data, isLoading, hasError } = useQuery('/api/v1/admin/users')

  const impersonateCallback = (active) => {
    const state = active ? 'active' : 'disabled'
    Notify({ content: `Impersonate mode ${state}`, type: 'success' })
  }

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <FormControl>
        <FormLabel>Impersonate a user</FormLabel>
        <Impersonate users={data} callback={impersonateCallback} admin={true} />
      </FormControl>
    </>
  )
}

export default Admin
