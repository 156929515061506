import React, { useState } from 'react'
import { Box, FormControl, FormLabel, FormHelperText, Switch } from '@chakra-ui/react'

import { lxTimeToStr, strToLxTime, disabledMinutes } from './helpers'
import TimePicker from 'rc-time-picker-luxon'

const DailyEmailSettings = ({
  send_daily_emails,
  daily_email_time,
  onToggleEmailSettingsDaily,
  onHandleSetDailyEmailTime,
}) => {
  const [sendDailyEmails, setSendDailyEmails] = useState(send_daily_emails)
  const [dailyEmailTime, setDailyEmailTime] = useState(daily_email_time)

  const handleToggleEmailSettingsDaily = () => {
    setSendDailyEmails(!sendDailyEmails)
    onToggleEmailSettingsDaily(!sendDailyEmails)
  }

  const handleSetDailyEmailTime = (time) => {
    const timeStr = lxTimeToStr(time)
    setDailyEmailTime(timeStr)
    onHandleSetDailyEmailTime(timeStr)
  }

  return (
    <>
      <FormControl>
        <FormLabel>
          <Box>Receive a daily email update</Box>
          <FormHelperText>
            A handy reminder each morning directly to your inbox of what you have on for that day.
          </FormHelperText>
        </FormLabel>

        <Switch
          id="daily-email"
          colorScheme="yellow"
          isChecked={sendDailyEmails}
          onChange={handleToggleEmailSettingsDaily}
        />
      </FormControl>

      {sendDailyEmails && (
        <FormControl>
          <FormLabel>
            <FormHelperText>
              Set the time you would like to receive your daily email:
            </FormHelperText>
          </FormLabel>
          <Box maxW="60px">
            <TimePicker
              showSecond={false}
              onChange={handleSetDailyEmailTime}
              value={strToLxTime(dailyEmailTime)}
              allowEmpty={false}
              disabledMinutes={disabledMinutes}
              hideDisabledOptions={true}
            />
          </Box>
        </FormControl>
      )}
    </>
  )
}
export default DailyEmailSettings
