import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
)

const baseStyle = definePartsStyle({
  field: {
    borderWidth: '1px',
    borderRadius: 'none',
    border: 'gray.400',
    background: 'white',
    _hover: {
      borderColor: 'brand.500',
    },
  },
  icon: {
    color: 'gray.400',
  },
})

const defaultProps = { focusBorderColor: 'brand.500' }

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps,
})
