import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import { widgetEndpointMapper, widgetResourceMapper } from 'domain/Widgets/constants'

const useWidgetMethods = ({
  isWidgetsPage,
  isWorkoutsPage,
  activityId,
  widgets,
  setCreateWidgets,
  setEditWidgetData,
  onEditWidgetOpen,
  onEditWidgetClose,
  onConfirmRemoveOpen,
  onConfirmRemoveClose,
  templatedActivity,
}) => {
  const isCreatingActivity = !activityId
  const isEditingActivity = !!activityId

  const WIDGET_RESOURCE_MAPPER = widgetResourceMapper(templatedActivity)
  const WIDGET_ENDPOINT_MAPPER = widgetEndpointMapper(templatedActivity)

  // create a new widget object stub to yield to an edit form so that the details can be filled in
  const handleAddNewWidget = ({ type }) => {
    // this handles all widget types,
    // starting basic widget object stub with a random id
    const newWidget = {
      type: type,
      id: uuidv4(),
      new: true,
    }

    // setCreateWidgets is not needed here (it is only used when a new widget is saved)
    setEditWidgetData(newWidget)
    onEditWidgetOpen()
  }

  // add an existing widget to an activity
  // (obviously only applicable for isWorkoutPage)
  const handleAddExistingWidget = ({ widget, widgets }) => {
    if (isWidgetsPage) {
      return
    }

    if (isEditingActivity) {
      const path = templatedActivity ? 'templated_activities' : 'activities'
      const endpoint = `api/v1/${path}/${activityId}/add_widget`

      const payload = {
        ...(!templatedActivity && { widget_id: widget.id }),
        ...(!templatedActivity && { widget_type: widget.type }),
        ...(templatedActivity && { templated_widget_id: widget.id }),
        ...(templatedActivity && { templated_widget_type: widget.type }),
      }

      securedApi
        .put(endpoint, payload)
        .then((response) => {
          setCreateWidgets([...widgets, response.data])
          Notify({ content: 'Widget added', type: 'success' })
        })
        .catch((error) => {
          processApiError(error, "Can't add widget")
        })
    } else {
      setCreateWidgets([...widgets, widget])
    }
  }

  // create a new widget
  const handleCreateWidget = ({ type, widgetData }) => {
    const widgetKey = WIDGET_RESOURCE_MAPPER[type]
    const widgetEndpoint = WIDGET_ENDPOINT_MAPPER[type]

    const postParam = templatedActivity ? 'templated_activity_id' : 'activity_id'

    const widgetParams = {
      id: widgetData.id,
      ...widgetData.content,
    }

    // if isWidgetsPage then activityId will be undefined
    // so `[postParam]: activityId` won't be included in request (desired behaviour)
    securedApi
      .post(`api/v1/${widgetEndpoint}`, {
        [postParam]: activityId,
        [widgetKey]: widgetParams,
      })
      .then((response) => {
        if (isWidgetsPage) {
          revalidateLiveQueries()
        }
        if (isWorkoutsPage) {
          setCreateWidgets([...widgets, response.data])
        }

        Notify({ content: 'Widget added', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, "Can't add widget")
      })
  }

  // update the content for a widget
  // (always used for a widget that exists)
  //
  // in the future when creating a widget this could all be cached so widgets are only created when workout is
  // and if creating workout is aborted then the widget will not be saved
  // this could be done by using isCreatingActivity and setCreateWidgets and not sending an api call
  const handleUpdateWidget = (updatedWidgetData, widgets, widgetIndex) => {
    const widgetEndpoint = WIDGET_ENDPOINT_MAPPER[updatedWidgetData.type]
    const widgetKey = WIDGET_RESOURCE_MAPPER[updatedWidgetData.type]

    securedApi
      .patch(`api/v1/${widgetEndpoint}/${updatedWidgetData.id}`, {
        [widgetKey]: {
          ...updatedWidgetData.content,
        },
      })
      .then(() => {
        if (isWidgetsPage) {
          revalidateLiveQueries()
        }

        if (isWorkoutsPage) {
          const updatedWidgets = [...widgets]
          updatedWidgets[widgetIndex] = updatedWidgetData
          setCreateWidgets(updatedWidgets)
        }

        Notify({ content: 'Widget modified', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error updating widget')
      })
  }

  // remove a widget from an activity
  // (only applicable for isWorkoutsPage)
  const handleRemoveWidget = (widgets, widgetIndex) => {
    const widget = widgets[widgetIndex]

    const path = templatedActivity ? 'templated_activities' : 'activities'
    const endpoint = `api/v1/${path}/${activityId}/remove_widget`

    const payload = {
      ...(!templatedActivity && { widget_id: widget.id }),
      ...(!templatedActivity && { widget_type: widget.type }),
      ...(templatedActivity && { templated_widget_id: widget.id }),
      ...(templatedActivity && { templated_widget_type: widget.type }),
    }

    if (isEditingActivity) {
      securedApi
        .put(endpoint, payload)
        .then(() => {
          setCreateWidgets(widgets.filter((_, i) => i !== widgetIndex))
          Notify({ content: 'Widget removed', type: 'success' })
        })
        .catch((error) => {
          processApiError(error, "Can't remove widget")
        })
        .finally(() => {
          onConfirmRemoveClose()
        })
    }

    if (isCreatingActivity) {
      setCreateWidgets(widgets.filter((_, i) => i !== widgetIndex))
      Notify({ content: 'Widget removed', type: 'success' })
      onConfirmRemoveClose()
    }
  }

  // delete a widget completely
  // always done from a widget edit form
  const handleDeleteWidget = (widget, widgets = null, widgetIndex = null) => {
    const widgetEndpoint = WIDGET_ENDPOINT_MAPPER[widget.type]

    securedApi
      .delete(`api/v1/${widgetEndpoint}/${widget.id}`)
      .then(() => {
        if (isWidgetsPage) {
          revalidateLiveQueries()
        }

        if (isWorkoutsPage) {
          setCreateWidgets(widgets.filter((_, i) => i !== widgetIndex))
        }

        Notify({ content: 'Widget deleted', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, "Can't delete widget")
      })
  }

  return {
    handleAddNewWidget,
    handleAddExistingWidget,
    handleCreateWidget,
    handleUpdateWidget,
    handleRemoveWidget,
    handleDeleteWidget,
  }
}

export default useWidgetMethods
