import { Text } from '@chakra-ui/react'

import { NavItem } from 'domain/Navigation/Components/Links'

export const MainNavigation = () => {
  const links = [
    {
      to: '/dashboard',
      title: 'Dashboard',
    },
    {
      to: '/planner',
      title: 'Planner',
    },
    {
      to: '/workouts',
      title: 'Workouts',
    },
    {
      to: '/plans',
      title: 'Plans',
    },
  ]

  return links.map(({ to, title }) => <NavItem key={title} {...{ to, title }} />)
}

export const SecondaryNavigation = () => {
  const links = [
    {
      to: '/metrics',
      title: 'Metrics',
    },
    {
      to: '/goals',
      title: 'Goals',
    },
    {
      to: '/tests',
      title: 'Tests',
    },
  ]

  return (
    <>
      <Text fontSize="xs" color="gray.400" fontWeight="semibold" textTransform="uppercase" mt="4">
        Performance
      </Text>
      {links.map(({ to, title }) => (
        <NavItem key={title} {...{ to, title }} />
      ))}
    </>
  )
}
