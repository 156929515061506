import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AspectRatio,
  Button,
  Box,
  Container,
  Image,
  Stack,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  RadioGroup,
  Radio,
  Checkbox,
  SimpleGrid,
  Divider,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Link as ChakraLink,
  Heading,
} from '@chakra-ui/react'

import { UserContext } from 'providers/UserProvider'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

import FullPageLoader from 'components/FullPageLoader'
import UpgradeButton from 'components/General/UpgradeButton'

import NineDLogo from './assets/9DLogo.png'
import TrainingHero1 from './assets/hero-1.jpeg'
import TrainingHero2 from './assets/hero-2.jpeg'
import FooterHero from './assets/footer-hero.png'
import NineDLevel1Plan from './assets/NineDLevel1Plan.png'
import NineDLevel2Plan from './assets/NineDLevel2Plan.png'
import NineDLevel3Plan from './assets/NineDLevel3Plan.png'
import NineDLevel4Plan from './assets/NineDLevel4Plan.png'

import PlanCard from './components/PlanCard'

import {
  YELLOW,
  GREEN,
  BLUE,
  TEAL,
  PINK,
  RED,
  PURPLE,
  BLACK,
  WHITE,
  sydneyPlans,
  brisbanePlans,
} from './planData'

const LEVEL_MAP = {
  Yellow: '1',
  Green: '1',
  Blue: '1',
  Teal: '2',
  Pink: '2',
  Red: '3',
  Purple: '3',
  White: '4',
}

const LEVEL_MAP_BRISBANE = {
  Yellow: '1',
  Teal: '1',
  Blue: '1',
  Purple: '2',
  Green: '2',
  Pink: '3',
  Red: '3',
  Black: '4',
  White: '4',
}

const SYDNEY = 'sydney'
const BRISBANE = 'brisbane'

const NineDegreesCollection = () => {
  const { planContext } = useContext(UserContext)
  const [userPlan] = planContext
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [gym, setGym] = useState(SYDNEY)
  const [level, setLevel] = useState(null)
  const [startValue, setStartValue] = useState('next_week')
  const [overwrite, setOverwrite] = useState(false)

  const renderPlanImage = () => {
    if (gym === SYDNEY) {
      if (level === YELLOW || level === GREEN || level === BLUE) {
        return NineDLevel1Plan
      }

      if (level === TEAL || level === PINK) {
        return NineDLevel2Plan
      }

      if (level === RED || level === PURPLE) {
        return NineDLevel3Plan
      }

      if (level === WHITE) {
        return NineDLevel4Plan
      }
    } else {
      if (level === YELLOW || level === TEAL || level === BLUE) {
        return NineDLevel1Plan
      }

      if (level === PURPLE || level === GREEN) {
        return NineDLevel2Plan
      }

      if (level === PINK || level === RED) {
        return NineDLevel3Plan
      }

      if (level === BLACK || level === WHITE) {
        return NineDLevel4Plan
      }
    }
  }

  const handlePreview = (level) => {
    setLevel(level)
    onPreviewOpen()
  }

  const handleConfirm = (level) => {
    setLevel(level)
    onOpen()
  }

  const handleApplyPlan = () => {
    const lookupCode = gym === SYDNEY ? `9d_${LEVEL_MAP[level]}` : `9d_${LEVEL_MAP_BRISBANE[level]}`
    setLoading(true)

    securedApi
      .post('api/v1/schedules/add_from_template', {
        lookup_code: lookupCode,
        behaviour: overwrite ? 'overwrite' : 'merge',
        when_to_add: startValue,
      })
      .then((response) => {
        console.log(response, 'response')
        Notify({
          content: 'New training plan has been applied',
          type: 'success',
        })

        navigate('/planner')
      })
      .catch((err) => {
        console.log((err, 'err'))
        Notify({
          content: 'Error adding new training plan. Please try again.',
          type: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderSydneyPlans = () => {
    return sydneyPlans.map(({ plan, description }) => (
      <PlanCard
        plan={plan}
        description={description}
        handlePreview={() => handlePreview(plan)}
        handleConfirm={() => handleConfirm(plan)}
      />
    ))
  }

  const renderBrisbanePlans = () => {
    return brisbanePlans.map(({ plan, description }) => (
      <PlanCard
        plan={plan}
        description={description}
        handlePreview={() => handlePreview(plan)}
        handleConfirm={() => handleConfirm(plan)}
      />
    ))
  }

  if (loading) {
    return <FullPageLoader />
  }

  // only subscribers can access plans
  const isTrial = userPlan?.slice(0, 5) === 'Trial'

  const renderIsTrial = () => (
    <Container pt={{ base: '16', md: '24' }} maxWidth="container.lg">
      <Center>
        <Text my="4" fontWeight="bold" fontSize="large" maxW="lg">
          Unfortunately the dedicated 9 Degree training plans are only available with a valid
          Sequence subscription.
        </Text>
      </Center>
      <Center>
        <UpgradeButton counter={false} />
      </Center>
    </Container>
  )

  return (
    <>
      <Box
        as="section"
        bg="gray.800"
        py="12"
        position="relative"
        h={{ base: '560px', md: '640px' }}
        bgImage={TrainingHero1}
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
          h: 'full',
          bg: 'blackAlpha.700',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center flexDirection="column" textAlign="center" color="white" h="full">
            <Image
              src={NineDLogo}
              boxSize="100px"
              objectFit="cover"
              alt="Gym Logo"
              marginBottom="4"
            />
            <Heading size="2xl" fontWeight="extrabold" color="#fff">
              9 Degrees Training Plans
            </Heading>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              Bouldering makes you happy!
            </Text>
          </Center>
        </Box>
      </Box>

      {isTrial ? (
        renderIsTrial()
      ) : (
        <>
          <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
              <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
                <Stack spacing="3">
                  <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                    Sequence has teamed up with 9 Degrees to bring you a series of training plans
                    specific to your level.
                  </Text>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                    Each training plan has been specifically designed to make the most out of the
                    facility and help you progress to the next level.
                  </Text>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                    All plans have been developed to help give direction, accountability and
                    progression.
                  </Text>
                  <Text color="fg.muted">
                    <ChakraLink color="brand.500" href="https://www.9degrees.com.au/" isExternal>
                      9degrees.com.au
                    </ChakraLink>
                  </Text>
                </Stack>
              </Stack>
              <AspectRatio ratio={1}>
                <Image objectFit="cover" src={TrainingHero2} alt="Nine Degrees Training" />
              </AspectRatio>
            </SimpleGrid>
          </Container>

          <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
            <HStack>
              <Divider />
              <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
                Plans
              </Text>
              <Divider />
            </HStack>

            <Center pt="6" pb="3">
              <Box background="#fff" padding="4" borderRadius="4">
                <Text textAlign="center" fontWeight="bold" mb="3">
                  Gym location
                </Text>
                <RadioGroup onChange={setGym} value={gym}>
                  <Stack direction="row">
                    <Radio background="white" colorScheme="brand" value={SYDNEY}>
                      <Text>Sydney</Text>
                    </Radio>
                    <Radio background="white" colorScheme="brand" value={BRISBANE}>
                      <Text>Brisbane</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </Center>

            {gym === SYDNEY ? renderSydneyPlans() : renderBrisbanePlans()}
          </Container>
          {/*
          <Image
            alt="Training Psych"
            src={TrainingHero3}
            objectFit="cover"
            objectPosition="center"
            maxH={{ base: 'sm', md: 'lg' }}
            width="full"
          /> */}
          <Box background="#fff">
            <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
              <Grid templateColumns="repeat(3, 1fr)" gap={16}>
                <GridItem colSpan={1}>
                  <Stack spacing={{ base: '8', md: '12' }}>
                    <Stack spacing={{ base: '4', md: '6' }}>
                      <Heading size={{ base: 'md', md: 'xl' }}>FAQs</Heading>
                      <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                        Everything you need to know about the plan and billing.
                      </Text>
                    </Stack>
                  </Stack>
                </GridItem>
                <GridItem colSpan={2}>
                  <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
                    <Accordion allowMultiple>
                      <AccordionItem border="none">
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Heading size={{ base: 'sm', md: 'base' }}>
                              How is the plan delivered
                            </Heading>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                          After you have successfully purchased the plan, you will be able to add
                          the plan to your Sequence planner.
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem border="none">
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Heading size={{ base: 'sm', md: 'base' }}>
                              Can I do the plan more than once?
                            </Heading>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                          You pay for the plan once but keep access to it forever. You can choose to
                          re-do the plan for as long as you maintain your Sequence membership.
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem border="none">
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Heading size={{ base: 'sm', md: 'base' }}>
                              Is it available on mobile?
                            </Heading>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                          100%. As long as you have the Sequence mobile app installed, you can
                          access your current schedule and log your training sessions.
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Stack>
                </GridItem>
              </Grid>
            </Container>

            <Container pb={{ base: '16', md: '24' }} maxWidth="container.lg">
              <Stack spacing={{ base: '12', md: '16' }} align="center" justifyContent="center">
                <Stack spacing={{ base: '4', md: '5' }} maxW="xl">
                  <Box align="center">
                    <Stack spacing="3">
                      <Heading size={{ base: 'sm', md: 'md' }}>Still have questions?</Heading>

                      <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
                        Can't find the answer you're looking for? Our team is happy to answer your
                        questions.
                      </Text>
                      <HStack justifyContent="center">
                        <ChakraLink
                          color="brand.500"
                          fontWeight="bold"
                          href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
                        >
                          Send us an email
                        </ChakraLink>
                      </HStack>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Container>
          </Box>

          <Image
            alt="Training Psych"
            src={FooterHero}
            objectFit="cover"
            objectPosition="center"
            maxH={{ base: 'sm', md: 'sm' }}
            width="full"
          />

          <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Are you sure?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                The{' '}
                <Text fontWeight="bold" display="inline">
                  {level}
                </Text>{' '}
                plan will be added to your schedule.
                <Text fontWeight="bold" marginTop="4" marginBottom="2">
                  Start your plan
                </Text>
                <RadioGroup onChange={setStartValue} value={startValue}>
                  <Stack direction="row">
                    <Radio value="this_week" colorScheme="yellow">
                      This week
                    </Radio>
                    <Radio value="next_week" colorScheme="yellow">
                      Next week
                    </Radio>
                  </Stack>
                </RadioGroup>
                <Checkbox
                  colorScheme="yellow"
                  marginTop="4"
                  isChecked={overwrite}
                  onChange={() => {
                    setOverwrite(!overwrite)
                  }}
                >
                  Overwrite any planned workouts
                </Checkbox>
                <Box marginY="4">
                  <Button
                    width="100%"
                    colorScheme="brand"
                    onClick={() => handleApplyPlan()}
                    isLoading={loading}
                  >
                    Confirm
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Modal isOpen={isPreviewOpen} onClose={onPreviewClose} autoFocus={false} size="6xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{level} Plan Preview</ModalHeader>
              <ModalCloseButton />
              <ModalBody background="gray.100">
                <Image
                  marginBottom="10"
                  objectFit="cover"
                  src={renderPlanImage()}
                  alt="Plan Preview"
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  )
}

export default NineDegreesCollection
