import React, { useState } from 'react'
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

import securedApi from 'backend/axios'

import { useActivityModal } from 'hooks'

import { usePlannerMethods } from 'domain/Planner/hooks'

const RescheduleWorkout = ({ isOpen, onClose, currentActivityId }) => {
  const [selected, setSelected] = useState()
  const { refreshData } = usePlannerMethods()
  const setActivityModalClose = useActivityModal((state) => state.setActivityModalClose)

  const handleReschedule = () => {
    const formattedDate = format(selected, 'y-MM-dd')

    securedApi
      .put(`api/v1/scheduled_activities/${currentActivityId}/move`, {
        date: formattedDate,
      })
      .then(() => {
        refreshData()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        onClose()
        setActivityModalClose()
      })
  }

  return (
    <Modal {...{ isOpen, onClose }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reschedule Workout</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DayPicker mode="single" weekStartsOn={1} selected={selected} onSelect={setSelected} />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="brand" onClick={handleReschedule}>
              Reschedule
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RescheduleWorkout
