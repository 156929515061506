import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Container, Button, ButtonGroup } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import { FormHeading } from 'components/Forms'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import { processApiError, categoryOptionFormatter } from 'helpers/utils'

import {
  NameSection,
  CategorySection,
  DurationSection,
  DescriptionSection,
  WidgetSection,
  OnRockSection,
  DetailsSection,
  OwnerNotesSection,
} from 'domain/Workouts/FormComponents'

const CreateCoachingWorkout = () => {
  const methods = useForm()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods
  const navigate = useNavigate()
  const [createWidgets, setCreateWidgets] = useState([])
  const { data, isLoading, hasError } = useQuery(`api/v1/templated_categories`, false)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const options = categoryOptionFormatter(data)

  const onSubmit = (data) => {
    const { name, description, category, details, duration, outdoor, owner_notes } = data
    const adjustedDuration = Math.floor(Number(duration) * 60 * 1000)

    securedApi
      .post('api/v1/templated_activities', {
        templated_activity: {
          name,
          description,
          templated_category_id: category.value,
          details,
          duration: adjustedDuration,
          outdoor,
          owner_notes,
          widgets: createWidgets,
        },
      })
      .then(() => {
        navigate('/coaching/workouts')
        Notify({ content: 'New coaching workout created', type: 'success' })
      })
      .catch((error) => processApiError(error))
  }

  return (
    <Container maxW="container.lg" mb="28">
      <InnerPageWrapperActions />
      <Container maxW="container.sm">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHeading heading="Add Coaching Workout" />
            <NameSection {...{ register, errors }} />
            <DescriptionSection {...{ register, errors }} />

            <CategorySection {...{ control, options, errors }} />
            <OnRockSection {...{ register }} />
            <DurationSection {...{ register }} />

            <WidgetSection {...{ createWidgets, setCreateWidgets }} templatedActivity />
            <DetailsSection {...{ control }} />

            <OwnerNotesSection {...{ register }} />

            <ButtonGroup mt="10" spacing="4" display="flex" justifyContent="flex-end">
              <Button variant="outline" data-cy="cancel" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                type="submit"
                data-cy="submit"
                colorScheme="brand"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Create
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Container>
    </Container>
  )
}

export default CreateCoachingWorkout
