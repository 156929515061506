import React from 'react'
import { Text } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

const InputRequired = ({ field }) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <Text mt={2} ml={3}>
      {errors && errors[field] && errors[field].message}
    </Text>
  )
}

export default InputRequired
