import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import CategoryBlock from '../CategoryBlock'

const TitleHeader = ({ item }) => {
  return (
    <Box>
      <Box background="#fff" display="flex">
        <CategoryBlock colour={item.category_hex_colour} />
        <Box marginLeft="2%" paddingY="4" color="black.500">
          <Text fontSize="small" textTransform="uppercase" fontWeight="semibold">
            {item.category_name}
          </Text>
          <Text fontSize="18px" fontWeight="bold">
            {item.activity_name}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default TitleHeader
