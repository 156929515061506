import React, { useEffect, useRef, useState } from 'react'
import { Textarea } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

const InputNotes = ({ entry, logbook, isNotesFocused, onSubmit }) => {
  const [notesClicked, setNotesClicked] = useState(false)

  const { handleSubmit, register, setFocus } = useFormContext()

  // not working
  // this is triggered whenever the form rerenders
  // i.e when style is change to show/hide attempts, this setFocus gets called again
  //
  // // focus on the notes field if desired
  // useEffect(() => {
  //   if (isNotesFocused && !isNotesFocusedRef.current) {
  //     setFocus('notes')
  //   }
  // }, [isNotesFocused, setFocus])

  const handleNotesClick = () => {
    setNotesClicked(true)
  }

  // move cursor to the end when focused, unless user clicks to focus
  // (this also triggers when the focus() method is called)
  const handleNotesFocus = (e) => {
    if (!notesClicked) {
      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.ctrlKey == true) {
      e.preventDefault()
      return handleSubmit((values) => {
        onSubmit(logbook, values)
      })()
    }
  }

  return (
    <Textarea
      size="sm"
      marginTop="2px"
      marginLeft="1px"
      onClick={handleNotesClick}
      onFocus={handleNotesFocus}
      onKeyPress={handleKeyPress}
      {...register('notes', { value: entry.notes })}
    />
  )
}

export default InputNotes
