import React, { useEffect, useState } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { useImpersonate } from 'hooks'
import { shortEnglishHumanizer } from 'helpers/duration'

import LoadingSpinner from 'components/LoadingSpinner'
import CategoryStrip from 'components//Workouts/CategoryStrip'

import WidgetContainer from './WidgetContainer'

const ImportWorkoutModal = ({ isOpen, handleImportModalClose, importWorkoutId, isLibrary }) => {
  const impersonateData = useImpersonate((state) => state.impersonateData)
  const { impersonatingUserName } = impersonateData

  const [loading, setLoading] = useState(true)
  const [currentActivity, setCurrentActivity] = useState()
  const [duration, setDuration] = useState()
  const [, setCurrentActivityId] = useState()
  const [outdoor, setOutdoor] = useState(false)
  const [widgets, setWidgets] = useState([])

  const [notFound, setNotFound] = useState(false)
  const [cantImportOwnWorkout, setCantImportOwnWorkout] = useState(false)

  const endpointResource = isLibrary ? 'templated_activities' : 'activities'

  // error alert dialog
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertErrorMessage, setAlertErrorMessage] = useState('')
  const onAlertClose = () => setIsAlertOpen(false)

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`/api/v1/${endpointResource}/${importWorkoutId}/find_for_import`)
      .then(({ data }) => {
        setCurrentActivity(data)
        setCurrentActivityId(data.id)
        setDuration(data.actual_duration || data.duration)
        setOutdoor(data.actual_outdoor || data.outdoor || false)
        setWidgets(data.templated_widgets)
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.data.error.message === "Can't import your own workout") {
          setCantImportOwnWorkout(true)
        } else {
          setNotFound(true)
        }
        setLoading(false)
      })
  }, [importWorkoutId])

  const handleCancel = () => {
    handleImportModalClose()
  }

  const handleImport = () => {
    securedApi
      .put(`/api/v1/${endpointResource}/${importWorkoutId}/import`)
      .then(({ data }) => {
        handleImportModalClose(true)
      })
      .catch((error) => {
        setAlertErrorMessage(error.response.data.error.message)
        setIsAlertOpen(true)
      })
  }

  const ImportActions = () => {
    return (
      <Box marginY="5">
        <Stack direction="row" spacing={4}>
          <Button width="100%" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button width="100%" colorScheme="yellow" color="white" onClick={() => handleImport()}>
            Add
          </Button>
        </Stack>
      </Box>
    )
  }

  const renderActivityWidgets = (widgets) => {
    if (!widgets) {
      return null
    }

    return <WidgetContainer widgets={widgets} />
  }

  const renderAlertBody = (alertErrorMessage) => {
    switch (alertErrorMessage) {
      case 'You already have a workout with this name':
        return (
          <>
            <Text>You already have a workout with this name</Text>
            <Text>Please change the name of your existing workout.</Text>
          </>
        )
      // occurs if shared status changes after modal is open
      case 'Workout is not shared':
        return (
          <>
            <Text>
              Workout is not shared. Please ask the owner of the workout to enable sharing of this
              workout.
            </Text>
          </>
        )
      case 'User already has a workout with this name':
        return (
          <>
            <Text>User already has a workout with this name.</Text>
            <Text mt="2">
              Please either rename the client's existing workout (preferred) or rename this template
              workout before trying to import this into the client's workout library.
            </Text>
          </>
        )
      default:
        return <Text>{alertErrorMessage}</Text>
    }
  }

  const renderModalContent = () => {
    if (cantImportOwnWorkout) {
      return (
        <>
          <ModalHeader pb="0">
            <Box display="flex" alignItems="center">
              <Box>Oops!</Box>
            </Box>
          </ModalHeader>

          <ModalBody color="gray.600">
            <Box>
              <Text mb="2">You can't import your own workout</Text>
            </Box>
          </ModalBody>
        </>
      )
    }

    if (notFound) {
      return (
        <>
          <ModalHeader pb="0">
            <Box display="flex" alignItems="center">
              <Box>Workout not found</Box>
            </Box>
          </ModalHeader>

          <ModalBody color="gray.600">
            <Box>
              <Text mb="2">
                Please check that the link is correct and that the workout has been shared
              </Text>
            </Box>
          </ModalBody>
        </>
      )
    }

    return (
      <>
        <ModalHeader pb="0">
          <Box display="flex" alignItems="center">
            <Box>{currentActivity.activity_name}</Box>
          </Box>
        </ModalHeader>

        <ModalBody color="gray.600">
          <Box>
            <CategoryStrip
              name={currentActivity.category_name}
              colour={currentActivity.category_hex_colour}
              inline={true}
            />

            <Box float="right" p={2} style={{ background: '#FEEDAB' }}>
              <Text fontWeight="bold">
                {isLibrary
                  ? `For: ${impersonatingUserName}`
                  : `Shared by ${currentActivity.user_name}`}
              </Text>
            </Box>

            {duration > 0 && (
              <Text mt="3" fontSize="xs" fontWeight="semibold" color="black">
                Duration approx. {shortEnglishHumanizer(duration)}
              </Text>
            )}

            <Box style={{ background: '#EFEFEF' }}>
              <Text my="2" p="2">
                {currentActivity?.activity_description}
              </Text>
            </Box>

            <Box>
              <span
                dangerouslySetInnerHTML={{
                  __html: currentActivity?.activity_details,
                }}
              />
            </Box>

            {renderActivityWidgets(widgets)}

            <Text>{outdoor ? 'Outdoor' : null}</Text>

            <Text fontSize="sm" mt="4" px="4">
              {isLibrary ? (
                <>
                  Note: this will create a copy of "{currentActivity.activity_name}" in the Workouts
                  page for <em>{impersonatingUserName}</em>. Any subsequent changes you make to your
                  library version won't update this copy.
                </>
              ) : (
                <>
                  Note: this will create a copy of "{currentActivity.activity_name}" in your
                  Workouts page. Any subsequent changes the sharing user makes to their original
                  version won't update your copy.
                </>
              )}
            </Text>

            <ImportActions onCloseModal={handleImportModalClose} />
          </Box>
        </ModalBody>
        <AlertDialog isOpen={isAlertOpen} onClose={onAlertClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Error
              </AlertDialogHeader>
              <AlertDialogBody>{renderAlertBody(alertErrorMessage)}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={onAlertClose}>OK</Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={handleImportModalClose} autoFocus={false} size="xl">
      <ModalOverlay />

      <ModalContent>{loading ? <LoadingSpinner /> : renderModalContent()}</ModalContent>
    </Modal>
  )
}

export default ImportWorkoutModal
