import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Icon,
  Text,
  Tooltip,
  Link,
} from '@chakra-ui/react'
import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons'

import { TRIAL_PERIOD_SCHEDULED_LIMIT } from 'helpers/constants'
import { pluralize, tzSafeDateFormat } from 'helpers/utils'

const SubscriptionLimit = ({ data }) => {
  const { valid, trial_count, trial_start_date, trial_end_date } = data

  // hide counter for user on the old system
  if (trial_end_date !== null) {
    return null
  }

  const trialStartDate = trial_start_date ? tzSafeDateFormat(trial_start_date, 'd MMM yyyy') : null

  const WorkoutsUsed = ({ num }) => {
    return (
      <Text as="span" fontWeight="bold">
        {num || 0}
      </Text>
    )
  }

  const WorkoutsAllowed = () => {
    return (
      <Text as="span" fontWeight="bold">
        {TRIAL_PERIOD_SCHEDULED_LIMIT}
      </Text>
    )
  }

  const LearnMoreLink = () => {
    return (
      <Link href="https://docs.sequence-app.com/faq/pricing" isExternal fontSize="small">
        Learn more <ExternalLinkIcon />
      </Link>
    )
  }

  return (
    <Alert status="info" maxWidth="480px">
      <AlertIcon />
      <AlertDescription>
        <Box mb={2}>
          You currently have <WorkoutsUsed num={trial_count} /> {pluralize(trial_count, 'workout')}{' '}
          scheduled in your planner.
          {trialStartDate && (
            <Tooltip label={`Starting from ${trialStartDate}`}>
              <Icon
                as={InfoIcon}
                w="4"
                h="4"
                color="gray.500"
                strokeWidth="1px"
                ml="1"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            </Tooltip>
          )}
        </Box>
        {valid ? (
          <Box mb={2}>
            Once you have used <WorkoutsAllowed /> you'll need to subscribe to continue using the
            platform. <LearnMoreLink />
          </Box>
        ) : (
          <Box mb={2}>
            Since you have used the <WorkoutsAllowed /> allowed for the trial, you need to subscribe
            to add more and continue using the platform. <LearnMoreLink />
          </Box>
        )}
      </AlertDescription>
    </Alert>
  )
}

export default SubscriptionLimit
