import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Heading,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Spacer,
} from '@chakra-ui/react'

import DatePicker from 'components/DatePicker/DatePicker'
import { tzSafeNewDate } from 'helpers/utils'

const TestForm = ({ onSubmit, create, onDeleteModal, data }) => {
  const navigate = useNavigate()
  const { control, register, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      test_date: new Date(),
    },
  })

  const renderOptionsBoulderGrades = () => {
    return (
      <>
        <option value="0">V0</option>
        <option value="1">V1</option>
        <option value="2">V2</option>
        <option value="3">V3</option>
        <option value="4">V4</option>
        <option value="5">V5</option>
        <option value="6">V6</option>
        <option value="7">V7</option>
        <option value="8">V8</option>
        <option value="9">V9</option>
        <option value="10">V10</option>
        <option value="11">V11</option>
        <option value="12">V12</option>
        <option value="13">V13</option>
        <option value="14">V14</option>
        <option value="15">V15</option>
        <option value="16">V16</option>
      </>
    )
  }

  const renderOptionsRouteGrades = () => {
    return (
      <>
        <option value="20">20/6b+</option>
        <option value="21">21/6c</option>
        <option value="22">22/6c+</option>
        <option value="23">23/7a</option>
        <option value="24">24/7a+</option>
        <option value="25">25/7b</option>
        <option value="26">26/7b+</option>
        <option value="27">27/7c</option>
        <option value="28">28/7c+</option>
        <option value="29">29/8a</option>
        <option value="30">30/8a+</option>
        <option value="31">31/8b</option>
        <option value="32">32/8b+</option>
        <option value="33">33/8c</option>
        <option value="34">34/8c+</option>
        <option value="25">35/9a</option>
        <option value="36">36/9a+</option>
        <option value="37">37/9b</option>
        <option value="37">38/9b+</option>
        <option value="37">39/9c</option>
      </>
    )
  }

  useEffect(() => {
    if (data?.test_date) {
      setValue('test_date', tzSafeNewDate(data?.test_date))
    }
  }, [data, setValue])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading as="h4" size="md">
        General
      </Heading>
      <Box rounded="4" border="1px solid #ccc" padding="4" my="4">
        <FormControl marginBottom="8" isRequired>
          <FormLabel>Test Date</FormLabel>
          <Controller
            control={control}
            name="test_date"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                dateFormat="d/MM/yyyy"
                onChange={(e) => field.onChange(e)}
                selectedDate={field.value}
                required
              />
            )}
          />
        </FormControl>

        <HStack spacing="24px" marginBottom="4">
          <FormControl maxW="150px">
            <FormLabel>Age</FormLabel>
            <Input defaultValue={data?.age} bg="white" type="number" {...register('age')} />
          </FormControl>

          <FormControl maxW="150px">
            <FormLabel>Height</FormLabel>
            <InputGroup>
              <Input defaultValue={data?.height} bg="white" type="number" {...register('height')} />
              <InputRightAddon children="cm" />
            </InputGroup>
          </FormControl>

          <FormControl maxW="150px">
            <FormLabel>Weight</FormLabel>
            <InputGroup>
              <Input
                defaultValue={data?.weight}
                bg="white"
                type="number"
                step="0.1"
                {...register('weight')}
              />
              <InputRightAddon children="kg" />
            </InputGroup>
          </FormControl>
        </HStack>
      </Box>

      <Heading as="h4" size="md">
        Climbing Specific
      </Heading>
      <Box rounded="4" border="1px solid #ccc" padding="4" my="4">
        <HStack spacing="24px" marginBottom="4">
          <FormControl>
            <FormLabel mb="0">Hardest Boulder</FormLabel>
            <FormHelperText mt="0" mb="2">
              Sent in the last 12 months
            </FormHelperText>
            <Select
              defaultValue={data?.hardest_boulder}
              maxW="180px"
              placeholder="Select option"
              bg="white"
              {...register('hardest_boulder')}
            >
              {renderOptionsBoulderGrades()}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel mb="0">Hardest Route</FormLabel>
            <FormHelperText mt="0" mb="2">
              Sent in the last 12 months
            </FormHelperText>
            <Select
              defaultValue={data?.hardest_route}
              maxW="180px"
              placeholder="Select option"
              bg="white"
              {...register('hardest_route')}
            >
              {renderOptionsRouteGrades()}
            </Select>
          </FormControl>
        </HStack>
        <HStack spacing="24px" marginBottom="4" marginTop="8">
          <FormControl>
            <FormLabel mb="0">Hardest Boulder (Indoor)</FormLabel>
            <FormHelperText mt="0" mb="2">
              Sent in the last 12 months
            </FormHelperText>
            <Select
              defaultValue={data?.hardest_boulder_indoor}
              maxW="180px"
              placeholder="Select option"
              bg="white"
              {...register('hardest_boulder_indoor')}
            >
              {renderOptionsBoulderGrades()}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel mb="0">Hardest Route (Indoor)</FormLabel>
            <FormHelperText mt="0" mb="2">
              Sent in the last 12 months
            </FormHelperText>
            <Select
              defaultValue={data?.hardest_route_indoor}
              maxW="180px"
              placeholder="Select option"
              bg="white"
              {...register('hardest_route_indoor')}
            >
              {renderOptionsRouteGrades()}
            </Select>
          </FormControl>
        </HStack>
      </Box>

      <Heading as="h4" size="md">
        Finger Specific
      </Heading>
      <Box rounded="4" border="1px solid #ccc" padding="4" my="4">
        <Alert status="info" mb={4} maxWidth={600}>
          <AlertIcon />
          Max hangs, anaerobic capacity and aerobic capacity should be performed in order and with
          at least 10 minutes rest between each.
        </Alert>
        <FormControl marginBottom="4">
          <FormLabel>Max Hang</FormLabel>
          <FormHelperText mt="0" mb="2">
            5 second - 2 arm (20mm edge - e.g. BM1000 outer edges).
          </FormHelperText>
          <InputGroup>
            <Input
              defaultValue={data?.max_hang}
              maxW="150px"
              bg="white"
              type="number"
              step="0.1"
              {...register('max_hang')}
            />
            <InputRightAddon children="kg" />
          </InputGroup>
        </FormControl>
        <FormControl marginBottom="4">
          <FormLabel mb="0">Anaerobic Capacity Hang</FormLabel>
          <FormHelperText mt="0" mb="2">
            7/3 repeaters performed at 80% of 2 arm max absolute value (ie 80% of BW + added weight)
            - maximum repetitions until failure - record which hang you failed on.
          </FormHelperText>
          <InputGroup>
            <Input
              defaultValue={data?.max_anaerobic_hang}
              maxW="150px"
              bg="white"
              type="number"
              {...register('max_anaerobic_hang')}
            />
            <InputRightAddon children="reps" />
          </InputGroup>
        </FormControl>
        <FormControl marginBottom="4">
          <FormLabel mb="0">Aerobic Capacity Hang</FormLabel>
          <FormHelperText mt="0" mb="2">
            Hang until failure with 60% of 2 arm max absolute value.
          </FormHelperText>

          <InputGroup>
            <Input
              defaultValue={data?.max_aerobic_hang}
              maxW="150px"
              bg="white"
              type="number"
              {...register('max_aerobic_hang')}
            />
            <InputRightAddon children="seconds" />
          </InputGroup>
        </FormControl>
      </Box>

      <Heading as="h4" size="md">
        Strength & Power
      </Heading>
      <Box rounded="4" border="1px solid #ccc" padding="4" my="4">
        <HStack spacing="24px" marginBottom="8">
          <FormControl>
            <FormLabel mb="0">Max Pull Up</FormLabel>
            <FormHelperText mt="0" mb="2">
              1 rep max
            </FormHelperText>
            <InputGroup>
              <Input
                defaultValue={data?.max_pull_up}
                maxW="150px"
                bg="white"
                type="number"
                step="0.1"
                {...register('max_pull_up')}
              />
              <InputRightAddon children="kg" />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel mb="0">Max Bench Press</FormLabel>
            <FormHelperText mt="0" mb="2">
              1 rep max
            </FormHelperText>
            <InputGroup>
              <Input
                defaultValue={data?.max_bench_press}
                maxW="150px"
                bg="white"
                type="number"
                step="0.1"
                {...register('max_bench_press')}
              />
              <InputRightAddon children="kg" />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel mb="0">Deadlift</FormLabel>
            <FormHelperText mt="0" mb="2">
              1 rep max
            </FormHelperText>
            <InputGroup>
              <Input
                defaultValue={data?.deadlift}
                maxW="150px"
                bg="white"
                type="number"
                step="0.1"
                {...register('deadlift')}
              />
              <InputRightAddon children="kg" />
            </InputGroup>
          </FormControl>
        </HStack>

        <Box>
          <Alert status="info" mb={4} maxWidth={600}>
            <AlertIcon />
            Use largest rung size available.
          </Alert>

          <HStack spacing="24px" marginBottom="4">
            <FormControl>
              <FormLabel mb="0">Max Campus Pull - Left</FormLabel>
              <FormHelperText mt="0" mb="2">
                Distance reached with left hand
              </FormHelperText>
              <InputGroup>
                <Input
                  defaultValue={data?.max_campus_pull_left}
                  maxW="150px"
                  bg="white"
                  type="number"
                  {...register('max_campus_pull_left')}
                />
                <InputRightAddon children="cm" />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel mb="0">Max Campus Pull - Right</FormLabel>
              <FormHelperText mt="0" mb="2">
                Distance reached with right hand.
              </FormHelperText>
              <InputGroup>
                <Input
                  defaultValue={data?.max_campus_pull_right}
                  maxW="150px"
                  bg="white"
                  type="number"
                  {...register('max_campus_pull_right')}
                />
                <InputRightAddon children="cm" />
              </InputGroup>
            </FormControl>
          </HStack>
        </Box>
      </Box>

      {create ? (
        <ButtonGroup spacing="4" marginTop="2" display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={() => navigate(`/tests`)}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="brand">
            Create
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup spacing="4" marginTop="2" display="flex">
          <Button onClick={() => onDeleteModal()} variant="outline">
            Delete
          </Button>
          <Spacer />
          <Button variant="outline" onClick={() => navigate(`/tests/${data.id}`)}>
            Cancel
          </Button>

          <Button type="submit" colorScheme="brand">
            Update
          </Button>
        </ButtonGroup>
      )}
    </form>
  )
}
export default TestForm
