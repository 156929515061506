import React, { useEffect, useState } from 'react'
import { Area, YAxis, Tooltip, Text } from 'recharts'
import { Box, Flex, Text as CText, Link } from '@chakra-ui/react'

import { useDemoData, useMetricsDays } from 'hooks'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { calcYDomain, calcYTicks } from 'helpers/charts'
import { processGetError } from 'helpers/utils'

import { ChartContainer, ChartHeader, MetricsChart } from 'domain/Metrics/CommonMeasure'
import { BreakdownLink } from 'domain/Metrics/components/CategoryBarChart'
import VolumeTooltip from 'domain/Metrics/components/VolumeTooltip'

import LastXDays from 'domain/Metrics/CommonMetrics'

const SelectMetrics = () => {
  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const [loading, setLoading] = useState(true)
  const [sessionBreakdownType, setSessionBreakdownType] = useState('time')
  const [metricsData, setMetricsData] = useState({})

  useEffect(() => {
    securedApi
      .get(`api/v1/metrics/selects?demo=${demoData}&days=${days}`)
      .then((response) => {
        setMetricsData(response.data)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [demoData, days])

  if (loading) {
    return <LoadingSpinner />
  }

  const {
    widget_selects,
    all_selects,
    annotations,
    settings,
    max_time,
    max_total,
    no_widgets,
    no_entries,
  } = metricsData
  const max = sessionBreakdownType === 'time' ? max_time : max_total

  const y_domain = calcYDomain([0, max])
  const { y_ticks } = calcYTicks(y_domain)

  // wrap around if value is too high
  const pickColour = (value) => {
    const colours = ['#1b9e77', '#d95f02', '#7570b3', '#66a61e', '#e6ab02', '#A6761D', '#666666']

    // Adjust value to fit within the range of colours array using modulo
    const index = (value - 1) % colours.length

    // Handle negative results of modulo
    const adjustedIndex = index < 0 ? index + colours.length : index

    return colours[adjustedIndex]
  }

  if (no_widgets) {
    return (
      <Box>
        <CText color="gray.500" mt="4">
          You currently don't have any custom select data yet.{' '}
          <Link
            href="https://docs.sequence-app.com/features/Widgets/select-widget"
            isExternal
            fontWeight="bold"
            color="yellow.500"
          >
            Learn about custom selects
          </Link>
          .
        </CText>
      </Box>
    )
  } else {
    if (no_entries) {
      return (
        <Box>
          <LastXDays />
          <CText color="gray.500" mt="4">
            No data for this period.
          </CText>
        </Box>
      )
    }
  }

  return (
    <Box>
      <CText textTransform="uppercase" fontSize="small" fontWeight="semibold" color="gray.500">
        Session breakdown
      </CText>
      <Flex marginLeft={3} marginTop={2} marginBottom={1}>
        <BreakdownLink
          type="time"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
        <BreakdownLink
          type="total"
          sessionBreakdownType={sessionBreakdownType}
          setSessionBreakdownType={setSessionBreakdownType}
        />
      </Flex>
      {widget_selects.map(({ widget_select, data }) => {
        const findSelect = all_selects.find((x) => x.select.select_id == widget_select).select
        const options = findSelect.options

        return (
          <ChartContainer key={findSelect.select_id}>
            <ChartHeader title={findSelect.select_name} />
            <LastXDays />
            <MetricsChart
              item={{ data: data }}
              annotations={annotations}
              yMin={'zero'}
              yMax={max}
              tooltip={
                <Tooltip
                  allowEscapeViewBox={{ x: true }}
                  position={{ y: 30 }}
                  wrapperStyle={{ outline: 'none' }}
                  contentStyle={{
                    opacity: 0.8,
                    position: 'relative',
                    left: 20,
                  }}
                  content={<VolumeTooltip />}
                />
              }
            >
              <YAxis
                yAxisId="left"
                orientation="left"
                domain={[y_domain.y_domain_min, y_domain.y_domain_max]}
                ticks={y_ticks}
                label={
                  <Text x={0} y={0} dx={50} dy={200} offset={0} angle={-90}>
                    {sessionBreakdownType == 'time' ? 'Hours' : 'Sessions'}
                  </Text>
                }
              />
              {options.map((option) => {
                const { content, value } = option

                const key = `${value}_${sessionBreakdownType}`
                const colour = pickColour(value)

                return (
                  <React.Fragment key={key}>
                    <Area
                      yAxisId="left"
                      key={key}
                      type="bump"
                      dataKey={key}
                      stackId="stack"
                      strokeWidth={0}
                      activeDot={false}
                      fill={colour}
                      fillOpacity={1}
                      name={content}
                      animationDuration={0}
                    />
                  </React.Fragment>
                )
              })}
            </MetricsChart>
          </ChartContainer>
        )
      })}
    </Box>
  )
}

export default SelectMetrics
