import React from 'react'

import MetricsContainer from './MetricsContainer'
import MetricsCollection from './MetricsCollection'

const Metrics = () => {
  return (
    <MetricsContainer>
      <MetricsCollection />
    </MetricsContainer>
  )
}

export default Metrics
