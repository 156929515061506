import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Alert, Box, Container, Flex, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'

import { useStateManager, useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import { FormHeading } from 'components/Forms'
import Notify from 'components/Notification'

import { PasswordFields, SignUpButton } from 'domain/Auth/Elements'

const schema = z
  .object({
    password: z.string().min(6, { message: 'Required - must have at least 6 characters' }),
    password_confirmation: z.string().min(6, { message: 'Required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const SignUpCoachingClient = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const query = `client_signup_find?token=${token}`
  const { data, isLoading, hasError } = useQuery(query)

  const [loading, setLoading] = useState(false)
  const { clearAll, setSignin, setAccountStatus } = useStateManager()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  if (hasError) {
    // show specific error message if available, otherwise show the default message in Error component
    let errorComponent = <Error />
    const errorMessage = hasError.response.data?.error

    if (hasError.response.status === 422 && errorMessage) {
      errorComponent = <Error>{errorMessage}</Error>
    }

    return (
      <Container py={{ base: '4', md: '6' }} maxWidth="container.sm">
        {errorComponent}
        <Box mt="4">
          <Text textAlign="center">
            An error has occurred. Please contact{' '}
            <a href="mailto:help@sequence-app.com" target="_blank" rel="noreferrer">
              support
            </a>{' '}
            or your coach.
          </Text>
        </Box>
      </Container>
    )
  }

  if (isLoading) {
    return (
      <Container py={{ base: '4', md: '6' }} maxWidth="container.lg">
        <LoadingSpinner />
      </Container>
    )
  }

  const { coach, client } = data

  const onSubmit = ({ password, password_confirmation }) => {
    setLoading(true)
    securedApi
      .post('/signup_client', {
        signup: {
          id: client.id,
          password,
          password_confirmation,
          token: token,
        },
      })
      .then((response) => {
        signUpSuccessful(response)
      })
      .catch((error) => {
        signUpFailed(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signUpSuccessful = (response) => {
    if (!response.data.csrf) {
      signUpFailed(response)
      return
    }

    setSignin(response.data)
    setAccountStatus(response.data.user)

    const { id, name, email, plan } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    navigate('/welcome-client')
  }

  const signUpFailed = (error) => {
    const errorContent = error.response?.data?.error || 'Error signing up. Please try again.'
    Notify({
      content: errorContent,
      type: 'error',
    })

    setLoading(false)

    clearAll()
  }

  const MainFlex = ({ children }) => {
    return (
      <Flex
        width="100%"
        py="10"
        px="4"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {children}
      </Flex>
    )
  }

  return (
    <MainFlex>
      <Container py={{ base: '4', md: '6' }} maxWidth="container.lg" alignItems="center">
        <Stack spacing="3" alignItems="center" mb="4">
          <Text textAlign="center" fontSize="2xl">
            Coaching Invitation
          </Text>
          <Box marginTop="2" marginBottom="3">
            <Text fontWeight="bold">{client.email}</Text>
          </Box>
          <Alert maxW="md">
            <Stack textAlign="center">
              <Text>
                You've been invited by{' '}
                <Text as="span" fontWeight="bold">
                  {coach}
                </Text>{' '}
                to use the Sequence platform for coaching. Whilst you are being coached, you'll be
                given a free subscription to Sequence.
              </Text>
            </Stack>
          </Alert>

          <Box maxWidth="400px" width="100%" marginTop="4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormHeading>
                <Text>Activate your account by entering a password</Text>
                <Text>Then we'll get you all set up with Sequence</Text>
              </FormHeading>
              <Stack mt="6">
                <PasswordFields {...{ register, errors }} />
                <SignUpButton {...{ loading }} />
              </Stack>
            </form>
          </Box>
        </Stack>
      </Container>
    </MainFlex>
  )
}

export default SignUpCoachingClient
