import React, { useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Badge,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import CategoryStrip from 'components/Workouts/CategoryStrip'

import { tzSafeDateFormat } from 'helpers/utils'

import ActivityModal from 'domain/Planner/components/ActivityModal'

const IncompleteReasonsAccordion = ({ data, demo, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [workoutId, setWorkoutId] = useState(null)

  const handleWorkoutClick = (id) => {
    if (demo) {
      return
    }
    setWorkoutId(id)
    onOpen()
  }

  const renderItem = () => {
    return data.map((item, index) => {
      return (
        <AccordionItem key={index}>
          <AccordionButton>
            <Box flex="1" textAlign="left" p="2">
              <Text color="gray.600" fontWeight="semibold">
                {item.incomplete_reason} <Badge ml="1">{item.count}</Badge>
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Grid
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(5, 1fr)',
              }}
              gap={6}
            >
              {renderPanelData(item.workouts)}
            </Grid>
          </AccordionPanel>
        </AccordionItem>
      )
    })
  }

  const renderPanelData = (workoutData) => {
    return workoutData.map((item) => {
      const formattedDate = tzSafeDateFormat(item.scheduled_at, 'd MMM yyyy')

      return (
        <GridItem w="100%" key={item.id}>
          <Box
            background="#fff"
            mt="1"
            p="2"
            onClick={() => handleWorkoutClick(item.id)}
            cursor="pointer"
            borderWidth="1px"
            borderColor="transparent"
            _hover={{ borderColor: 'yellow.500' }}
          >
            <Text>{item.activity_name}</Text>

            <CategoryStrip name={item.category_name} colour={item.category_hex_colour} />

            <Text fontSize="xs" textTransform="uppercase" mt="1">
              {formattedDate}
            </Text>
          </Box>
        </GridItem>
      )
    })
  }

  return (
    <Box>
      <Text color="gray.600" fontWeight="semibold" mb="2">
        Reason Breakdown
      </Text>
      <Accordion defaultIndex={[]} allowMultiple>
        {renderItem()}
      </Accordion>
      {isOpen && <ActivityModal id={workoutId} {...{ isOpen, onClose, refresh }} />}
    </Box>
  )
}

export default IncompleteReasonsAccordion
